<template>
  <div>
    <b-dropdown-item @click="dialog = true">Mark as Inactive</b-dropdown-item>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Recipient Inactive Reason"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <div class="text-muted">UID</div>
            <div class="h6">{{ item.recipient_registration_id }}</div>
          </b-col>
          <b-col cols="12" class="mt-2">
            <div class="text-muted">Recipient Name</div>
            <div class="h6">{{ item.first_name }}</div>
          </b-col>
          <b-col cols="12" class="mt-2">
            <input-field label="Notes" rules="required" v-model="reason">
            </input-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import recipientService from "../../assets/services/recipient.service";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      reason: "",
      delete_reason: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let { recipient_registrationid } = vm.item;
        let doc = {
          recipient_registration_id: recipient_registrationid,
          dml_indicator: "DA",
          transtan_status: "10",
          delete_reason: vm.reason,
        };
        let { status, msg } = (await recipientService.deleteReason(doc)).data;
        vm.$emit("fetch");
        if (status) {
          vm.dialog = false;
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
