<template>
  <expandable header="Consent Details" class="mb-10">
    <b-row class="pb-0">
      <b-col cols="12" md="4">
        <print-yes-no
          label="Consent Given"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.conset_given"
        ></print-yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="form.conset_given == 1">
        <print-select
          v-model.trim="form.type_of_consent"
          label="Type of Consent"
          :items="consents"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        ></print-select>
      </b-col>
      <b-col cols="12" md="4" v-if="isWritten">
        <print-file-input
          v-model.trim="form.type_of_consent_upload"
          label="Written Consent"
          :disabled="isDisabled"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          @input="onInput"
          rules="required|file-image-pdf|file-size"
        ></print-file-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-select
          v-model.trim="form.contact_id"
          label="Donor Relationship"
          :items="contactTypes"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        ></print-select>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.first_name"
          @input="onInput"
          :disabled="isDisabled"
          label="First Name"
          rules="required|max-50"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          @input="onInput"
          v-model.trim="form.last_name"
          label="Last Name"
          :disabled="isDisabled"
          rules="required|max-50"
        ></print-input>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <print-select
          @input="onInput"
          v-model.trim="form.gender"
          label="Gender"
          outlined
          :items="gender"
          rules="required|max-50"
        ></print-select>
      </b-col> -->
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.email_id"
          label="Email"
          :disabled="isDisabled"
          @input="onInput"
          rules="email|max-100"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.phone_number_1"
          :defaultCountry="form.country_code_1"
          :disabled="isDisabled"
          @input="onInput"
          label="Phone Number 1"
          @onCountryCode="form.country_code_1 = $event"
          rules="required|integer"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.phone_number_2"
          :defaultCountry="form.country_code_2"
          @input="onInput"
          rules="integer"
          label="Phone Number 2"
          :disabled="isDisabled"
          @onCountryCode="form.country_code_2 = $event"
        ></print-input>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import { TYPE_OF_CONSENTS } from "../../../assets/constants/donor.constants";
import { GENDERS } from "../../../assets/constants/hospital.contants";
import { DONOR_MASTER_TYPE } from "../../../assets/js/common";
import Expandable from "../../Expandable.vue";
import PrintFileInput from "../../ui/print/PrintFileInput.vue";
import PrintYesNo from "../../ui/print/PrintYesNo.vue";
import PrintInput from "../../ui/print/PrintInput.vue";
import PrintSelect from "../../ui/print/PrintSelect.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Expandable,
    PrintYesNo,
    PrintFileInput,
    PrintSelect,
    PrintInput,
  },
  data() {
    return {
      form: {},
      consents: TYPE_OF_CONSENTS,
    };
  },
  computed: {
    isWritten() {
      return this.form.conset_given == 1 && this.form.type_of_consent == 2;
    },
    gender() {
      return GENDERS;
    },
    contactTypes() {
      return this.masterContactTypes.filter((item) => {
        return item.contact_type == DONOR_MASTER_TYPE;
      });
    },
    master() {
      return this.$store.getters.master;
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
