<template>
  <div>
    <b-button
      variant="primary"
      v-if="isMobile"
      depressed
      @click="input = !input"
      tile
      :class="!input ? 'mb-4' : ''"
    >
      <b-icon icon="funnel" aria-hidden="true"></b-icon>
      Filter
    </b-button>
    <b-collapse v-model="input">
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      input: false,
    };
  },
  beforeMount() {
    if (this.value != null) {
      console.log(this.value);
      this.input = this.value == true;
    } else {
      this.input = !this.isMobile;
    }
  },
};
</script>

<style></style>
