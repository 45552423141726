<template>
  <b-modal v-model="dialog" hide-footer centered title="Confirm" size="sm">
    <p v-html="message"></p>
    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <b-button variant="text" class="text-danger mx-3" @click="cancel">
        {{ noText || "No" }}
      </b-button>
      <b-button variant="text" class="text-success" @click="confirm">
        {{ yesText || "Yes" }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import ConfirmModal from "./index";
export default {
  data() {
    return {
      message: "",
      yesText: "Yes",
      dialog: false,
      noText: "No",
      onConfirm: {},
      onCancel: {},
    };
  },
  methods: {
    confirm() {
      const vm = this;
      if (typeof vm.onConfirm === "function") {
        vm.onConfirm();
      }
      vm.dialog = false;
    },
    cancel() {
      const vm = this;
      if (typeof vm.onCancel === "function") {
        vm.onCancel();
      }
      vm.dialog = false;
    },
    show(params) {
      const vm = this;
      vm.yesText = "Yes";
      vm.noText = "No";
      Object.keys(params).forEach((key) => {
        vm[key] = params[key];
      });
      vm.dialog = true;
    },
  },
  beforeMount() {
    ConfirmModal.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
};
</script>

<style></style>
