<template>
  <div>
    <b-button size="sm" @click="dialog = true">View</b-button>
    <b-modal
      v-model="dialog"
      size="xl"
      title="Allocation Call Status"
      centered
      body-bg-variant="light"
      no-close-on-backdrop
      hide-footer
    >
      <table-view
        :isBusy="isLoading"
        title="Hospital Contact List"
        :fields="fields"
        :items="list"
      >
        <template v-slot:action>
          <b-button size="sm" variant="primary" @click="callNow"
            >Call Now</b-button
          >
        </template>
        <template v-slot:status="{ value }">
          {{ value == "0" ? "Unanswered" : "Answered" }}
        </template>
      </table-view>
      <!-- <table-view
        title="Contact Details"
        :fields="contactFields"
        :items="contactList"
      >
      </table-view> -->
    </b-modal>
  </div>
</template>

<script>
import donorService from "../../../../assets/services/donor.service";
import TableView from "../../../table/TableView.vue";
export default {
  props: {
    item: {},
  },
  components: { TableView },
  data() {
    return {
      dialog: false,
      isLoading: false,
      contactList: [],
      isFetched: false,
      list: [],
    };
  },
  computed: {
    fields() {
      let doc = {
        caller_name: "Contact Name",
        caller_number: "Phone Number",
        hospital_name: "Hospital Name",
        status: "Status",
        // action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
    contactFields() {
      let doc = {
        name: "Contact Name",
        date: "Date and Time",
        contacted_by: "Contacted By",
        audio_link: "Audio Link",
        action: "Action",
      };

      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  watch: {
    dialog() {
      this.fetchContact();
    },
  },
  methods: {
    async fetchContact() {
      const vm = this;
      if (vm.isFetched) return;
      try {
        vm.isLoading = true;
        vm.isFetched = true;
        let { organ_allocation_id, type_of_allocation, hospital_id } = vm.item;
        let { data } = await donorService.fetchAllocationContacts({
          dml_indicator: "CL",
          hospital_id,
          organ_allocation_id,
          type_of_allocation,
        });
        vm.isLoading = false;
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list;
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
    async callNow() {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await donorService.callNow({});
        let { status, msg } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.$alert.show("Call initiated successfully");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
