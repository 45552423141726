<template>
  <div>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Select Mulit Organ"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <autocomplete
              label="Organs"
              :items="filterMasterOrgans"
              v-model="organs"
              :multiple="true"
              rules="required"
            ></autocomplete>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      organs:[],
      dialog: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) this.organs = [];
    },
  },
  methods: {
    submit() {
      let organs = this.organs.map((organ_id) => {
        return { organ_id };
      });
      this.$emit("onOrganSelect", organs);
      this.dialog = false;
    },
  },
};
</script>

<style></style>
