<template>
  <div>
    <table-view
      v-for="(item, i) in tables"
      :title="item.title"
      :key="i"
      :fields="fields"
      :isBusy="isLoading"
      :items="item.list"
    >
      <template v-slot:payment_status="{ value }">
        {{ getPaymentStatus(value) }}
      </template>
      <template v-slot:transtan_status="{ value }">
        <div :class="getBg(getPatientStatus(value))">
          {{ getPatientStatus(value) }}
        </div>
      </template>
      <template v-slot:hospital_status="{ value }">
        {{ getBeReadyStatus(value) }}
      </template>
      <template v-slot:CM_insurance="{ value }">
        {{ getCMInsuranceStatus(value) }}
      </template>
      <template v-slot:gender="{ value }">
        {{ getGender(value) }}
      </template>
      <template v-slot:status="{ item }">
        <div class="badge bg-success font-size-13" v-if="item.current_town">
          Updated
        </div>
      </template>
      <template v-slot:action="{ item }">
        <be-ready-status-update
          @fetch="$emit('fetch')"
          :item="item"
        ></be-ready-status-update>
      </template>
    </table-view>
  </div>
</template>

<script>
import { encode, formatDate } from "../../../assets/js/common";
import TableView from "../../table/TableView.vue";
import recipientService from "../../../assets/services/recipient.service";
import BeReadyStatusUpdate from "./BeReadyStatusUpdate.vue";
export default {
  components: { TableView, BeReadyStatusUpdate },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
    };
  },
  computed: {
    tables() {
      return [
        {
          title: "Waiting List",
          list: this.live,
        },
      ];
    },
    live() {
      let list = this.list.map((item) => {
        item.created_on = formatDate(item.created_on);
        return item;
      });
      list = list.filter((item) => {
        let status = [5, 10];
        if (this.isHospitalContact)
          return status.indexOf(item.transtan_status) == -1;
        return true;
      });
      return list;
    },
    fields() {
      let doc = {
        sno: "S.No",
        recipient_registration_id: "UID",
        first_name: "Name",
        transtan_status: "Patient Status",
        group_desc: "Blood Group",
        age: "Age",
        gender: "Gender",
        created_on: "Date of Registration",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        // payment_status: "Payment Status",
        // CM_insurance: "CM Insurance",
        phone_number_1: "Phone Number",
        status: "Status",
        action: "Action",
      };
      if (this.isSubAdmin) delete doc.action;
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  methods: {
    getBg(val) {
      switch (val) {
        case "Active":
          return "badge bg-success font-size-13";
        case "Pending Approval":
          return "badge bg-warning font-size-13";
        case "Inactive":
          return "badge bg-danger font-size-13";
        case "Deleted":
          return "badge bg-danger font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },

    isDelete(item) {
      return item.transtan_status == 4 || item.transtan_status == 1;
    },
    isApproved(item) {
      return item.transtan_status == 4;
    },
    open({ recipient_registration_id }) {
      let id = encode(recipient_registration_id);
      this.$router.push(`/update-recipient?id=${id}`);
    },
    remove({ recipient_registration_id, transtan_status }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id,
            transtan_status: transtan_status == 1 ? 7 : 5,
            dml_indicator: "A",
          });
        },
      });
    },
    approve({ recipient_registration_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to approve this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id,
            transtan_status: 3,
            dml_indicator: "A",
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await recipientService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        // vm.fetch();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
