import { STATUS } from "../constants/hospital.contants";
import CryptoJS from "crypto-js";
// export const BASE_URL = "https://transtanapi.convenientcare.life/";
// export const BASE_URL = "https://2152-122-165-57-13.ngrok.io/";
// export const BASE_URL = "https://api.transtan.tn.gov.in/";
export const BASE_URL = "https://oldtesttranstanapi.convenientcare.life/";
// export const BASE_URL = "https://medwayvideo.convenientcare.life/";
// export const BASE_URL = "https://testapi.transtan.tn.gov.in/";
export const PAYMENT_LINK =
  "https://www.onlinesbi.com/sbicollect/icollecthome.htm?corpID=959165";
export const PAYMENT_URL =
  "https://surepay.ndml.in/SurePayPayment/sp/processRequest";
// export const PAYMENT_URL = "https://pilot.surepay.ndml.in/SurePayPayment/sp/processRequest"
export const RECIEPIENT_MASTER_TYPE = 3;
export const RECIEPIENT_MEDICAL_DETAILS_MASTER_TYPE = 5;
export const RECIEPIENT_DECLARATION_MASTER_TYPE = 8;
export const DONOR_MASTER_TYPE = 4;
export const CONSENT_MASTER_TYPE = 6;
export const DONOR_LEGAL_MASTER_TYPE = 7;
export const HEART_ID = "11";
export const LIVER_ID = "4";
export const SPLIT_LIVER_1_ID = "15";
export const SPLIT_LIVER_2_ID = "16";
export const LUNG_ID = "12";
export const LEFT_LUNG_ID = "19";
export const RIGHT_LUNG_ID = "20";
export const KIDNEY_ID = "1";
export const KIDNEY_LEFT_ID = "2";
export const KIDNEY_RIGHT_ID = "3";
export const PANCREAS_ID = "10";
export const HEART_LUNG_ID = "26";
export const BLOOD_GROUP_VALUES = {
  O: [5, 6],
  A: [1, 2],
  B: [3, 4],
  AB: [7, 8],
};
const moment = require("moment-timezone");
export function getDate() {
  return moment().format("YYYY-MM-DDTHH:mm:ss");
}
export function getDateOnly() {
  return moment().format("YYYY-MM-DD");
}
export function formatTable(date) {
  if (!date) return "";
  return moment(date).format("Do MMM YYYY");
}
export function sleep(sec) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), sec);
  });
}
export function isObject(item) {
  return item instanceof Object == true;
}
export function isDocObject(item) {
  return item && item[0] instanceof Object == true;
}
export function isArray(item) {
  return item instanceof Array == true;
}
export function encode(id) {
  let key = "purushothaman";
  id = CryptoJS.AES.encrypt(id.toString(), key).toString();
  return encodeURIComponent(id);
}
export function encrypt(id) {
  var key = CryptoJS.enc.Utf8.parse("Mocero1234567890");
  var iv = CryptoJS.enc.Utf8.parse("Mocero1234567890");
  var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(id), key, {
    keySize: key,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  return encryptedlogin;
}
export function decode(id) {
  let key = "purushothaman";
  id = decodeURIComponent(id);
  return CryptoJS.AES.decrypt(id, key).toString(CryptoJS.enc.Utf8);
}
export function formatDate(date) {
  if (date) return moment(date).local().format("DD/MM/YY HH:mm");
  return "";
}
export function formatDateOnly(date) {
  if (date) return moment(date).format("DD/MM/YYYY");
  return "";
}
export function getStart(date) {
  return moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
}
export function getEnd(date) {
  return moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
}
export function getRawDate() {
  return moment();
}

export function getSelect(list) {
  let items = [];
  if (list) {
    items = list.filter((item) => {
      if (item !== "All") return { name: item, value: item };
    });
  }
  items.unshift({ name: "Please select", value: "" });
  return items;
}
export function getDynamicSelect(list) {
  let items = [];
  if (list) {
    items = list.map((item) => {
      return { name: item.name, value: item.id };
    });
  }
  items.unshift({ name: "Please select", value: "" });
  return items;
}
export function openFile(url) {
  url = BASE_URL + url;
  // url = "http://transtanapi.convenientcare.life/" + url;
  window.open(url, "_blank").focus();
}
export function chatLink(url) {
  url = BASE_URL + url;
  return url;
}
export function getStatus(s) {
  let status = STATUS[s];
  return status != null ? status : STATUS[1];
}

export function getDocObject(obj, doc) {
  if (doc == undefined) doc = {};
  Object.keys(obj).forEach((key) => {
    doc[key] = obj[key];
  });
  return doc;
}
export function getAge(DOB) {
  if (!DOB) return "";
  var ageDifMs = Date.now() - new Date(DOB);
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
export const NOT_ALLOWED_FOR_HOSPITAL = ["/masters", "/transtan-management"];
export const NOT_ALLOWED_FOR_SUBADMIN = ["/masters"];
export const NOT_ALLOWED_FOR_MASTER = ["/manage-alf-approver"];
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const initPayment = (json) => {
  let keys = [
    "checksum",
    "messageType",
    "merchantId",
    "serviceId",
    "orderId",
    "customerId",
    "transactionAmount",
    "currencyCode",
    "requestDateTime",
    "successUrl",
    "failUrl",
    "additionalField1",
    "additionalField2",
    "additionalField3",
    "additionalField4",
    "additionalField5",
  ];
  let values = json.requestMsg.split("|");
  let $ = window.$;
  let list = [];
  keys.forEach((key, i) => {
    list.push(
      $("<input>", {
        name: key,
        id: key,
        value: values[i],
      })
    );
  });

  $("<form/>", {
    action: PAYMENT_URL,
    method: "POST",
  })
    .append(list)
    .appendTo("body")
    .submit();
};
