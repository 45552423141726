<template>
  <expandable header="Injuries" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <print-yes-no
          v-model="form.chest_injuries"
          label="Chest Injuries"
          rules="required"
          :disabled="isDisabled"
          @input="onInput"
        ></print-yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <print-yes-no
          v-model="form.abdomen_injuries"
          @input="onInput"
          label="Abdomen Injuries"
          :disabled="isDisabled"
          rules="required"
        ></print-yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.notes"
          @input="onInput"
          label="Notes"
          :disabled="isDisabled"
        ></print-input>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import PrintInput from "../../ui/print/PrintInput.vue";
import PrintYesNo from "../../ui/print/PrintYesNo.vue";
export default {
  components: { Expandable, PrintYesNo, PrintInput },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
