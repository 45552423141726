<template>
  <b-card tile :no-body="noBody">
    <b-card-title
      class="d-flex bg-primary bg-soft align-items-center px-2 py-2 mb-3"
    >
      <div class="h4 mb-0 text-primary">
        {{ header }}
      </div>
      <div class="flex-grow-1"></div>
      <slot name="action"></slot>
    </b-card-title>
    <div class="px-2">
      <slot></slot>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    noBody: {
      type: Boolean,
      default: false,
    },
    header: String,
  },
};
</script>

<style>
.expandable-title {
  border-left: 5px solid red !important;
  border-bottom: 1px solid lightgrey;
}
</style>
