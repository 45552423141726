<template>
  <div class="border-bottom py-2">
    <div class="d-flex mb-1 align-items-center">
      <span>
        {{ item.recipient_registration_id }}
      </span>
      <div class="flex-grow-1"></div>
      <span> {{ item.age }} / {{ item.gender }} / {{ item.group_desc }} </span>
      <b-dropdown
        variant="link"
        no-caret
        menu-class="mobile-menu"
        v-if="isAction"
      >
        <template #button-content>
          <b-icon icon="three-dots" class="font-size-16"></b-icon>
        </template>
        <position-change-reason
          v-if="isChangePosition"
          :isDropdown="true"
          @onReason="$emit('onReason', $event)"
          :item="item"
        ></position-change-reason>
      </b-dropdown>
    </div>
    <h5 class="mb-0 text-capitalize">{{ item.first_name }}</h5>
    <p class="mb-0 text-capitalize">{{ item.hospital_name }}</p>
    <div class="d-flex">
      {{ item.aadhar_number || "-" }}
      <div class="flex-grow-1"></div>
    </div>

    <p class="mb-0 font-size-11" v-if="isFinalRank">
      Final Status : {{ item.final_rank || "-" }}
    </p>
    <p class="mb-0 font-size-11" v-if="isZonalRank">
      Zonal Rank : {{ item.zone_rank || "-" }}
    </p>
    <p class="mb-0 font-size-11" v-if="isUpcomingRank">
      Upcoming Status : {{ item.upcoming_rank || "-" }}
    </p>
    <p class="mb-0 font-size-11" v-if="isReason">
      Reason : {{ item.inhouse_reason || "-" }}
    </p>
    <p class="mb-0 font-size-11">{{ item.organ_name }}</p>
    <div class="d-flex align-center">
      <p class="mb-0 flex-grow-1 font-size-11">{{ item.created_on }}</p>
      <div :class="item.bg">
        {{ item.transtan_status }}
      </div>
    </div>
  </div>
</template>

<script>
import PositionChangeReason from "./inhouseWaitingList/PositionChangeReason.vue";
export default {
  components: { PositionChangeReason },
  props: {
    item: {},
    isAction: { type: Boolean, default: false },
    isZonalRank: {
      type: Boolean,
      default: false,
    },
    isFinalRank: {
      type: Boolean,
      default: false,
    },
    isUpcomingRank: {
      type: Boolean,
      default: false,
    },
    isReason: Boolean,
    isChangePosition: { type: Boolean, default: false },
  },
};
</script>

<style></style>
