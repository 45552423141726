<template>
  <form-wrapper @submit="submit" v-if="!isLoading">
    <Expandable header="Donor Details" class="mb-10">
      <b-row class="pb-0">
        <b-col cols="12" md="4">
          <input-field
            v-model="form.donor_name"
            label="Donor Name"
            :disabled="isDisabled"
            rules="required"
          />
        </b-col>
        <b-col cols="12" md="4">
          <input-field
            v-model="form.age"
            type="number"
            label="Age"
            :disabled="isDisabled"
            rules="required"
          />
        </b-col>
        <b-col cols="12" md="4">
          <select-field
            v-model.trim="form.gender"
            label="Gender"
            outlined
            :items="genders"
            rules="required|max-50"
          ></select-field>
        </b-col>
        <b-col cols="12" md="4">
          <select-field
            label="Blood Group"
            :items="bloodGroups"
            :disabled="isDisabled"
            v-model="form.bloodgroup"
            rules="required"
          ></select-field>
        </b-col>
        <b-col cols="12" md="4">
          <yes-no
            :disabled="isDisabled"
            v-model="form.medical_legal_case"
            label="Is MLC Case?"
            rules="required"
          >
          </yes-no>
        </b-col>

        <b-col cols="12" md="4">
          <input-field
            :disabled="isDisabled"
            label="AR Case Number"
            :rules="form.medical_legal_case == 1 ? 'required' : ''"
            v-model="form.MLC_AR_No"
          >
          </input-field>
        </b-col>
        <b-col cols="12" md="4">
          <select-field
            v-model="form.cause_of_death_id"
            :items="masterCauseOfDeath"
            :disabled="isDisabled"
            label="Cause of Brain Death"
            rules="required"
          >
          </select-field>
        </b-col>
        <b-col cols="12" md="4" v-if="form.cause_of_death_id == 1003">
          <input-field
            :disabled="isDisabled"
            v-model="form.cause_of_death_notes"
            label="Cause of Brain Death - If others"
            rules="required|max-255"
          >
          </input-field>
        </b-col>
        <b-col cols="12" md="4">
          <date-picker
            :disabled="isDisabled"
            v-model="form.date_of_accident"
            label="Date of Accident"
            rules="required"
          >
          </date-picker>
        </b-col>
        <b-col cols="12" md="4">
          <date-picker
            :disabled="isDisabled"
            label="Date of Admission"
            v-model="form.date_of_admisssion"
            rules="required"
          >
          </date-picker>
        </b-col>

        <b-col cols="12" md="4">
          <input-group
            :disabled="isDisabled"
            label="No of Days on Ventilator"
            type="text"
            unit="Days"
            v-model="form.No_of_days_ventilator"
            rules="required|dec-0"
          >
          </input-group>
        </b-col>
        <b-col cols="12" md="4">
          <autocomplete
            v-model.trim="form.state"
            label="State"
            :items="states"
            rules="required"
          ></autocomplete>
        </b-col>
      </b-row>
      <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
        First Apnoea Details
      </div>
      <b-row>
        <b-col cols="12" md="4">
          <yes-no
            v-model.trim="form.first_apnea_status"
            label="First Apnoea Status"
            rules="required"
            :disabled="isDisabled"
          ></yes-no>
        </b-col>
        <b-col cols="12" md="4">
          <date-time-picker
            v-model.trim="form.first_apnea_updated_time"
            label="First Apnoea Date/Time"
            :disabled="isDisabled"
            :rules="form.first_apnea_status == 1 ? 'required' : ''"
          ></date-time-picker>
        </b-col>
      </b-row>
    </Expandable>
    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <close-button
        class="mx-3"
        @click="$router.push('/donor-identification')"
      ></close-button>
      <primary-button type="submit">Submit</primary-button>
    </div>
  </form-wrapper>
</template>
  
  <script>
import hospitalCreateMixins from "../../assets/mixins/hospital.create.mixins";
import Expandable from "../../components/Expandable.vue";
import SelectField from "../../components/form/SelectField.vue";
import { GENDERS } from "../../assets/constants/hospital.contants";
import DateTimePicker from "../../components/form/DateTimePicker.vue";
import DatePicker from "../../components/form/DatePicker.vue";
import YesNo from "../../components/form/YesNo.vue";
import Autocomplete from "../../components/form/Autocomplete.vue";
import masterService from "../../assets/services/master.service";
import FormWrapper from "../../components/form/FormWrapper.vue";
import PrimaryButton from "../../components/buttons/PrimaryButton.vue";
import CloseButton from "../../components/buttons/CloseButton.vue";
import donorService from "../../assets/services/donor.service";
import { decode } from "../../assets/js/common";
export default {
  mixins: [hospitalCreateMixins],
  components: {
    Expandable,
    SelectField,
    DateTimePicker,
    DatePicker,
    YesNo,
    Autocomplete,
    FormWrapper,
    PrimaryButton,
    CloseButton,
  },
  data() {
    return {
      genders: GENDERS,
      isDisabled: false,
      isLoading: false,
      states: [],
      hospital_id: "",
      form: {
        donor_name: "",
        age: "",
        gender: "",
        bloodgroup: "",
        MLC_AR_No: "",
        medical_legal_case: "",
        cause_of_death_id: "",
        cause_of_death_notes: "",
        date_of_accident: "",
        date_of_admisssion: "",
        No_of_days_ventilator: "",
        state: "",
        first_apnea_status: "",
        first_apnea_updated_time: "",
      },
    };
  },
  computed: {
    isCreate() {
      return this.$route.name == "create-donor-identification";
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let doc = { ...vm.form, Dml_Indicator: "IS" };
        if (this.isCreate) {
          doc.Hospitalid = vm.hospital_id;
          doc.Createdby = vm.created_by;
        } else {
          let id = decode(vm.$route.query.id);
          doc.Dml_Indicator = "UP";
          doc.donor_identificationid = id;
        }
        let { status, msg } = (await donorService.donorIdentification(doc))
          .data;
        if (status) {
          vm.$router.replace("/donor-identification");
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async init() {
      const vm = this;
      if (vm.isCreate) return;
      try {
        vm.$loader.show();
        vm.isLoading = true;
        let id = decode(vm.$route.query.id);
        let doc = { donor_identificationid: id, Dml_Indicator: "E" };
        let { status, msg, data } = (
          await donorService.donorIdentification(doc)
        ).data;
        if (status) {
          let item = data.Table[0] || {};
          Object.keys(vm.form).forEach((key) => {
            if (item[key] != undefined) {
              vm.form[key] = item[key];
            }
          });
          vm.form.first_apnea_status = vm.form.first_apnea_status.toString();
          vm.form.medical_legal_case = vm.form.medical_legal_case.toString();
          vm.form.state = Number(item.State);
          vm.isLoading = false;
        } else {
          vm.$router.replace("/donor-identification");
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async fetchStates() {
      const vm = this;
      let { data } = await masterService.getStateCity({
        country_id: "99",
      });
      let { list, status, msg } = data;
      if (status) {
        vm.states = list.map((item) => {
          return { name: item.name, value: item.id };
        });
      } else {
        vm.$alert.show(msg);
      }
    },
  },
  mounted() {
    this.fetchStates();
    this.hospital_id = this.$route.query.hospital_id || "";
    if (this.hospital_id) {
      this.hospital_id = decode(this.hospital_id);
    }
    this.init();
  },
};
</script>
  
  <style></style>
  