<template>
  <expandable header="ABG Test">
    <b-row class="mobile-table-header d-none d-md-flex px-2 py-2 mx-1">
      <b-col cols="12" md="4"> Name </b-col>
      <b-col cols="12" md="4"> File </b-col>
      <b-col cols="12" md="4"> FiO<sub>2</sub> </b-col>
    </b-row>
    <b-row v-for="i in 4" :key="i" class="px-md-4 py-2">
      <b-col
        cols="12"
        md="4"
        class="pb-2"
        v-html="formattedLabel(`ABG Test ${i}`, 'file-image-pdf|file-size')"
      ></b-col>
      <b-col cols="12" md="4" class="pb-2">
        <file-input-group
          v-model="form[`ABG_file_upload_${i}`]"
          @input="onInput"
          :label="'File ' + i"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          :disabled="isDisabled"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
      <b-col cols="12" md="4" class="pb-2">
        <input-group
          :prepend="i == 1 ? '100%' : ''"
          :label="`FiO<sub>2</sub> ${i}`"
          v-model="form[`FIO2_${i}`]"
        ></input-group>
      </b-col>
      <hr class="d-block d-md-none" />
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import FileInputGroup from "../../form/FileInputGroup.vue";
import InputGroup from "../../form/InputGroup.vue";
export default {
  components: { Expandable, FileInputGroup, InputGroup },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
      console.log(this.form);
    },
    formattedLabel(label, rules) {
      if (!rules) return label;
      if (rules.indexOf("required") != -1)
        return label + " <span style='color:red'>*</span>";
      return label;
    },
  },
  beforeMount() {
    this.form = this.value;
    // if(!this.form['FIO2_1'])  this.form['FIO2_1'] = "100%"
  },
};
</script>

<style></style>
