export default {
    HOSPITAL_MANAGEMENT: 1,
    RECIPIENT_MANAGEMENT: 2,
    DONOR_MANAGEMENT: 3,
    WAITING_LIST: 4,
    ACUTE_LIVER_FAILURE: 5,
    TRANSTAN_MANAGEMENT: 6,
    REPORTS: 7,
    MASTERS: 8,
    USER_MANAGEMENT: 9,
    MANAGE_HOSPITAL: 10,
    MANAGE_HOSPITAL_LICENCE: 11,
    HOSPITAL_ROTA_ORDER: 40,
    MANAGE_RECIPIENT: 12,
    RECIPIENT_TRANSFER: 13,
    PENDING_APPROVAL: 39,
    MANAGE_DONOR: 14,
    DONOR_PlEDGE: 42,
    TNOS_WAITING_LIST: 15,
    HOSPITAL_WAITING_LIST: 16,
    CM_INSURANCE_LIST: 17,
    IN_HOUSE_WAITING_LIST: 18,
    BEREADY_LIST: 19,
    LIVE_TRANSPLANT: 20,
    LIVER_URGENT_LIST: 21,
    TRANSTAN_USER_LIST: 22,
    HOSPITAL_LIST: 23,
    RECIPIENT_LIST: 24,
    DONOR_LIST: 25,
    CAUSE_OF_DEATH: "26",
    TERMINATION_REASON: "27",
    ZONE: "28",
    ORGAN: "29",
    CONTACT: "30",
    BLOOD_GROUP: "31",
    FILE_UPLOAD: "32",
    QUALIFICATION: "33",
    DEPARTMENT: "34",
    USER: 35,
    USER_RIGHTS: 36,
    ALF_APPROVER: 37,
    FINAl_DONOR: 38,
    MULTIPLE_ORGAN: "multi_29",
    USER_CREATE: "user_create",
    USER_EDIT: "user_edit",
    USER_VIEW: "user_view",
    USER_DELETE: "user_delete",
}