<template>
  <div>
    <allocation-recipient-table
      title="Priority List"
      :fields="fields"
      :item="item"
      :isLast="isLast"
      :isBusy="isLoading"
      :items="list"
    >
      <template v-slot:covid_result_fileupload="{ value }">
        <b-button
          v-if="value"
          @click="openFile(value)"
          variant="outline-primary"
          size="sm"
        >
          View
        </b-button>
      </template>
      <template v-slot:action="doc">
        <div class="bg-primary bg-soft text-primary px-2 py-2 text-center">
          <template v-if="isProvision">
            {{ getAllocationStatus(doc.index + 1) }}
          </template>
          <template v-else>
            {{ getAllocationStatus(doc.item.recepient_allocation_status) }}
          </template>
        </div>
      </template>
      <b-modal
        tabindex="0"
        v-model="dialog"
        hide-footer
        hide-header
        ignore-enforce-focus-selector=".select2-search__field"
        size="xl"
        body-class="px-0 py-0"
        centered
      >
        <generate-rota-waiting-list
          @close="dialog = false"
          v-if="item.type_of_allocation == 3"
          type="popup"
          :waitlistType="waitlistType"
          :item="item"
        ></generate-rota-waiting-list>
        <generate-waiting-list
          @close="dialog = false"
          type="popup"
          :waitlistType="waitlistType"
          v-else
          :item="item"
        ></generate-waiting-list>
      </b-modal>
      <template v-if="isAdmins && !isAllocationReport">
        <div class="d-flex mt-3" v-if="isAction">
          <allocate-to-other-state
            v-if="isAllocateToOtherState"
            :item="item"
          ></allocate-to-other-state>
          <div class="flex-grow-1"></div>
          <b-button @click="dialog = true" variant="success" v-if="isNotaker">
            {{ noTakerTitle }}
          </b-button>
          <b-button
            @click="submit()"
            class="ms-3"
            v-if="list.length > 0"
            variant="primary"
            >Confirm</b-button
          >
        </div>
        <div class="d-flex mt-3 flex-column flex-sm-row" v-if="isLogistics">
          <allocate-to-other-state
            v-if="isAllocateToOtherState"
            :item="item"
          ></allocate-to-other-state>
          <re-allocate-list
            :item="item"
            :status="status"
            @fetch="init()"
            :list="list"
            v-if="isReallocate"
          ></re-allocate-list>
          <re-assign-list
            :item="item"
            :status="status"
            @fetch="init()"
            :list="list"
            v-if="isReassign"
          ></re-assign-list>
          <b-button
            @click="dialog = true"
            class="ms-sm-3 mb-3 mb-sm-0"
            variant="success"
            v-if="isNotaker"
          >
            {{ noTakerTitle }}
          </b-button>
          <div class="flex-grow-1"></div>
          <b-button
            variant="success"
            v-if="item.type_of_allocation != 1"
            @click="initiateLogistics()"
            >Initiate Logistics</b-button
          >
          <b-button
            variant="primary"
            v-else-if="list.length > 0 && isAdmins"
            @click="updateOrganInfectionDetails()"
            >Update Organ Assessment Details</b-button
          >
        </div>
      </template>
    </allocation-recipient-table>
  </div>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import {
  formatDate,
  HEART_ID,
  HEART_LUNG_ID,
  LUNG_ID,
  openFile,
} from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
import GenerateRotaWaitingList from "./GenerateRotaWaitingList.vue";
import GenerateWaitingList from "./GenerateWaitingList.vue";
import AllocateToOtherState from "./provisionalwaitlist/AllocateToOtherState.vue";
import ReAllocateList from "./provisionalwaitlist/ReAllocateList.vue";
import ReAssignList from "./provisionalwaitlist/ReAssignList.vue";
export default {
  components: {
    ReAllocateList,
    GenerateRotaWaitingList,
    GenerateWaitingList,
    ReAssignList,
    AllocationRecipientTable,
    AllocateToOtherState,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Number,
      default: 2,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: false,
      list: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isProvision() {
      let list = [6, 20, 21, 22, 23, 24, 34, 30];
      return list.indexOf(this.item.allocation_status) != -1;
    },
    isReallocate() {
      return (
        this.isAdmins &&
        this.list.length > 0 &&
        this.item.allocation_status != 32
      );
    },
    isReassign() {
      return (
        this.isAdmins &&
        this.item.type_of_allocation == 2 &&
        this.item.allocation_status == 17
      );
    },
    noTakerTitle() {
      let { allocation_status } = this.item;
      let noTaker2 = [36, 20];
      if (allocation_status == 22 || allocation_status == 38)
        return "Initiate Rota 2";
      if (allocation_status == 23 || allocation_status == 39)
        return "Initiate Rota 3";
      if (noTaker2.indexOf(allocation_status) != -1)
        return "Initiate Notaker 2";
      return "Initiate Notaker 1";
    },
    waitlistType() {
      let { allocation_status } = this.item;
      let noTaker2 = [36, 20];
      if (allocation_status == 22 || allocation_status == 38) return "R2";
      if (allocation_status == 23 || allocation_status == 39) return "R3";
      if (noTaker2.indexOf(allocation_status) != -1) return "SNT2";
      return "SNT1";
    },
    isAction() {
      let { allocation_status } = this.item;
      let { status } = this;
      return (
        (status == 6 && allocation_status == 6) ||
        (status == 20 && allocation_status == 20) ||
        (status == 21 && allocation_status == 21) ||
        (status == 22 && allocation_status == 22) ||
        (status == 23 && allocation_status == 23) ||
        (status == 24 && allocation_status == 24) ||
        (status == 34 && allocation_status == 34) ||
        (status == 30 && allocation_status == 30)
      );
    },
    isNotaker() {
      let { allocation_status } = this.item;
      let isListAvailableStatus = [35, 36, 38, 39];
      if (isListAvailableStatus.indexOf(allocation_status) != -1) return true;
      let status = [6, 22, 23, 20, 34];
      return (
        !this.isLoading &&
        this.list.length == 0 &&
        status.indexOf(allocation_status) != -1
      );
    },
    isLogistics() {
      let status = [17, 32, 35, 36, 37, 38, 39, 40];
      return status.indexOf(this.item.allocation_status) != -1 && this.isAdmins;
    },
    isAllocateToOtherState() {
      let organs = [HEART_LUNG_ID, HEART_ID, LUNG_ID];
      let { allocation_status, organ_id_org } = this.item;
      if (
        [6, 17].indexOf(allocation_status) != -1 &&
        organs.indexOf(organ_id_org.toString()) != -1 &&
        this.isAdmins
      ) {
        return true;
      }
      let status = [37, 30, 24, 40];
      return status.indexOf(this.item.allocation_status) != -1 && this.isAdmins;
    },
    fields() {
      let doc = {
        recepient_organ_allocation_status: "Accept/Decline Status",
        covid_result_fileupload: "Covid Report",
        action: "Allocation Status",
      };
      return doc;
    },
  },
  watch: {
    status() {
      this.init();
    },
  },
  methods: {
    openFile(v) {
      openFile(v);
    },
    updateOrganInfectionDetails() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to update organ inspection list?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let list = [];
            let { organ_allocation_id } = vm.item;
            vm.list.forEach((item) => {
              let { recepeint_allocation_id } = item;
              let doc = {
                recepeint_allocation_id,
                organ_allocation_id,
                organ_inspection_update_by: vm.created_by,
                dml_indicator: "LSU",
              };
              list.push(doc);
            });
            let { data } =
              await donorService.organAllocationWaitingListStatusUpdate({
                recepient_organ_allocation_proc: list,
              });
            let { status, msg } = data;
            vm.isLoading = false;
            vm.$loader.hide();
            event.$emit("refresh");
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let list = [];
        let { organ_allocation_id, allocation_status } = vm.item;
        vm.list.forEach((item, i) => {
          let { recipient_registration_id, recepeint_allocation_id } = item;
          let doc = {
            recepient_allocation_status: i + 1,
            recipient_registration_id,
            recepeint_allocation_id,
            allocation_status,
            organ_allocation_id,
            dml_indicator: "AU",
          };
          list.push(doc);
        });
        let { data } =
          await donorService.organAllocationWaitingListStatusUpdate({
            recepient_organ_allocation_proc: list,
          });
        let { status, msg } = data;
        vm.isLoading = false;
        vm.$loader.hide();
        event.$emit("refresh");
        if (!status) {
          vm.$alert.show(msg);
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async initiateLogistics() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to intiate logistics?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let list = [];
            let { organ_allocation_id } = vm.item;
            vm.list.forEach((item) => {
              let { recepeint_allocation_id } = item;
              let doc = {
                logistics_update_status: 1,
                recepeint_allocation_id,
                organ_allocation_id,
                logistics_update_by: vm.created_by,
                dml_indicator: "LOU",
              };
              list.push(doc);
            });
            let { data } =
              await donorService.organAllocationWaitingListStatusUpdate({
                recepient_organ_allocation_proc: list,
              });
            let { status, msg } = data;
            vm.isLoading = false;
            vm.$loader.hide();
            event.$emit("refresh");
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    getAllocationStatus(status) {
      if (status == "1") return "Priority Allocation";
      return `Standby ${status - 1}`;
    },
    async init() {
      const vm = this;
      let {
        organ_id_org,
        is_multi,
        organ_id,
        organ_allocation_id,
        type_of_allocation,
      } = vm.item;
      if (is_multi) organ_id = organ_id_org;

      const doc = {
        dml_indicator: "SS1",
        organ_id,
        recepient_type_of_allocation: 1,
        organ_allocation_id,
      };
      if (type_of_allocation == 3) doc.dml_indicator = "RSS1";
      if (!vm.isProvision) {
        doc.dml_indicator = "SS2";
      }
      if (vm.status == 20) doc.recepient_type_of_allocation = 2;
      if (vm.status == 21) doc.recepient_type_of_allocation = 3;
      if (vm.status == 22) doc.recepient_type_of_allocation = 4;
      if (vm.status == 23) doc.recepient_type_of_allocation = 5;
      if (vm.status == 24) doc.recepient_type_of_allocation = 6;
      if (vm.status == 34) doc.recepient_type_of_allocation = 7;
      if (vm.status == 30 || vm.status == 32)
        doc.recepient_type_of_allocation = 8;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, list } = data;
        vm.isLoading = false;
        if (!status) {
          // vm.$alert.show(msg);
          return;
        }
        list = list.sort(
          (a, b) =>
            a.recepient_allocation_status - b.recepient_allocation_status
        );
        vm.list = list.map((item) => {
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
