<template>
  <expandable header="Basic Medical Details" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <input-group
          v-model.trim="form.pulse_rate"
          @input="onInput"
          unit="/minute"
          :disabled="isDisabled"
          label="Pulse Rate"
          type="text"
          rules="required|num-3|dec-0"
        ></input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          v-model.trim="form.systolic"
          @input="onInput"
          :disabled="isDisabled"
          unit="mmhg"
          label="BP - Systolic"
          type="text"
          rules="required|num-3|dec-0"
        ></input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          v-model.trim="form.diastolic"
          @input="onInput"
          :disabled="isDisabled"
          unit="mmhg"
          label="BP - Diastolic"
          type="text"
          rules="required|num-3|dec-0"
        ></input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.MAP"
          @input="onInput"
          label="MAP"
          :disabled="true"
          rules="required"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <text-area
          v-model.trim="form.CVP"
          @input="onInput"
          unit=""
          :disabled="isDisabled"
          label="CVP"
          rules="required|max-255"
        ></text-area>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          v-model.trim="form.urine_output"
          @input="onInput"
          label="Urine Output"
          :disabled="isDisabled"
          type="text"
          rules="required|num-5|dec-0"
        >
          <template v-slot:help>
            <select class="form-select" v-model="form.urine_unit">
              <option value="1">ml/hr</option>
              <option value="2">Litres/24hrs</option>
            </select>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          v-model.trim="form.SPO2"
          @input="onInput"
          unit="%"
          :disabled="isDisabled"
          label="SPO2"
          type="number"
          rules="required|percentage"
        ></input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          v-model.trim="form.temperature"
          @input="onInput"
          unit="Farenhite"
          :disabled="isDisabled"
          label="Temperature"
          type="text"
          rules="required|temperature|dec-1"
        ></input-group>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import InputGroup from "../../form/InputGroup.vue";
import TextArea from "../../form/TextArea.vue";
export default {
  components: { Expandable, InputGroup, TextArea },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      const vm = this;
      let { diastolic, systolic } = vm.form;
      if (isNaN(systolic)) systolic = 0;
      if (isNaN(diastolic)) diastolic = 0;
      diastolic = 2 * diastolic;
      let map = (Number(diastolic) + Number(systolic)) / 3;
      vm.form.MAP = map.toFixed(2);
      vm.$emit("input", vm.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
