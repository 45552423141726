<template>
  <div>
    <table-view
      :isBusy="isLoading"
      :fields="fields"
      :items="list"
      title="Cause Of Death List"
    >
      <template v-slot:add>
        <create-cause-of-death
          v-if="isCreate"
          @init="init"
        ></create-cause-of-death>
      </template>
      <template v-slot:status="{ value }">
        {{ value == 1 ? "Active" : "Deleted" }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex" v-if="isAction(item)">
          <create-cause-of-death
            :item="item"
            @init="init"
          ></create-cause-of-death>
          <delete-button @click="remove(item)"></delete-button>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import masterService from "../../assets/services/master.service";
import DeleteButton from "../buttons/DeleteButton.vue";
import TableView from "../table/TableView.vue";
import CreateCauseOfDeath from "./create/CreateCauseOfDeath.vue";
export default {
  components: { TableView, DeleteButton, CreateCauseOfDeath },
  data() {
    return {
      list: [],
      isLoading: false,
    };
  },
  computed: {
    fields() {
      let doc = {
        cause_of_death: "Cause of Death",
        status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          sortable: true,
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
    isCreate() {
      return this.hasCreateAccess(menuConstants.CAUSE_OF_DEATH);
    },
  },
  methods: {
    isAction({ status }) {
      if (!this.hasStatusAccess(menuConstants.CAUSE_OF_DEATH, 4)) return false;
      return status == 1;
    },
    async remove({ cause_of_death_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to remove this?",
        onConfirm: async () => {
          try {
            let { data } = await masterService.CUDCauseOfDeath({
              cause_of_death_id,
              dml_indicator: "D",
              status: 4,
            });
            let { status, msg } = data;
            vm.init();
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.isLoading = false;
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.isLoading = true;
        let { data } = await masterService.getDonorCauseOFDeath();
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (status) {
          vm.list = list;
        } else {
          vm.$alert.show(msg);
        }
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
