<template>
  <collapse-filter>
    <b-card class="mt-4 mb-4">
      <div class="h4">Filter</div>
      <form-wrapper @submit="submit">
        <b-row>
          <template v-if="isAdmins">
            <b-col cols="12" md="4">
              <autocomplete
                label="Hospital"
                v-model="form.hospitalid"
                :items="hospitals"
              ></autocomplete>
            </b-col>
            <b-col cols="12" md="4">
              <select-field
                v-model.trim="form.hospital_type"
                label="Hospital Type"
                :items="prependAll(masterHospitalTypes)"
              ></select-field>
            </b-col>
          </template>
          <b-col cols="12" md="4">
            <date-range-picker
              v-model.trim="form.date_range"
              label="Date Range (Created ON)"
              :clearable="true"
            ></date-range-picker>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Blood Group"
              :items="prependAll(bloodGroups)"
              v-model="form.bloodid"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              v-model.trim="form.age_type"
              label="Age Type"
              :items="masterAgeTypes"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              v-model.trim="form.gender"
              label="Gender"
              :items="prependAll(genders)"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <autocomplete
              v-model.trim="form.State_id"
              label="State"
              :items="prependAll(states)"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              v-model.trim="form.medical_legal_case"
              label="MLC Status"
              :items="prependAll(mlcStatus)"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              v-model.trim="form.first_apnea_status"
              label="First Apnoea Status"
              :items="prependAll(mlcStatus)"
            ></select-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-btn
            class="width-100 me-3"
            @click="reset(false)"
            variant="outline-secondary"
            >RESET</b-btn
          >
          <b-btn class="width-100" type="submit" variant="primary"
            >SUBMIT</b-btn
          >
        </div>
      </form-wrapper>
    </b-card>
  </collapse-filter>
</template>
  
  <script>
import hospitalService from "../../assets/services/hospital.service";
import Autocomplete from "../form/Autocomplete.vue";
import DateRangePicker from "../form/DateRangePicker.vue";
import CollapseFilter from "../ui/CollapseFilter.vue";
import { GENDERS } from "../../assets/constants/hospital.contants";
import masterService from "../../assets/services/master.service";
export default {
  components: { Autocomplete, DateRangePicker, CollapseFilter },
  props: {
    value: {},
  },
  data() {
    return {
      genders: GENDERS,
      states: [],
      form: {
        hospitalid: "all",
        date_range: [],
        gender: "all",
        age_type: "all",
        State_id: "all",
        bloodid: "all",
        hospital_type: "all",
        medical_legal_case: "all",
        first_apnea_status: "all",
      },
      hospitals: [],
      mlcStatus: [
        { name: "Yes", value: "1" },
        { name: "No", value: "0" },
      ],
    };
  },
  methods: {
    submit() {
      const vm = this;
      vm.$emit("input", { ...vm.form });
    },
    reset() {
      this.form = {
        hospitalid: "all",
        date_range: [],
        gender: "all",
        age_type: "all",
        State_id: "all",
        bloodid: "all",
        hospital_type: "all",
        first_apnea_status: "all",
        medical_legal_case: "all",
      };
      this.$emit("input", { ...this.form });
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { user_type, hospital_id } = vm.$store.getters.user;
        let doc = { user_type };
        if (user_type == 1) {
          doc.hospital_id = hospital_id;
          vm.form.hospital_id = hospital_id;
        }
        let { data } = await hospitalService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
        vm.hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
        vm.$emit("hospitals", [...vm.hospitals]);
        if (vm.isAdmins) {
          vm.hospitals.unshift({
            name: "All Hospital",
            value: "all",
          });
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async fetchStates() {
      const vm = this;
      let { data } = await masterService.getStateCity({
        country_id: "99",
      });
      let { list, status, msg } = data;
      if (status) {
        vm.states = list.map((item) => {
          return { name: item.name, value: item.id };
        });
      } else {
        vm.$alert.show(msg);
      }
    },
  },
  mounted() {
    this.init();
    this.fetchStates();
    this.reset();
    if (this.isHospitalContact) {
      this.reset(true);
    }
  },
};
</script>
  
  <style></style>
  