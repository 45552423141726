<template>
  <div>
    <b-button @click="openFingerPrint()">
      <i class="mdi mdi-fingerprint"></i>
    </b-button>
    <b-button @click="generateOtp()" variant="primary">Verify OTP</b-button>
    <b-modal
      v-model="dialog"
      hide-footer
      centered
      title="Verify Aadhar OTP"
      size="sm"
    >
      <form-wrapper @submit="verify()">
        <input-field
          label="Enter your OTP"
          type="number"
          rules="required"
          v-model="otp"
        >
        </input-field>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button
            variant="text"
            class="text-danger mx-3"
            @click="dialog = false"
          >
            Cancel
          </b-button>
          <b-button variant="text" class="text-success" type="submit">
            Verify
          </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import event from "../../../assets/js/event";
import recipientService from "../../../assets/services/recipient.service";
import { AADHAR_ERROR_MESSAGES } from "../../../assets/constants/recipient.constants";
export default {
  props: {
    number: {},
  },
  data() {
    return {
      dialog: false,
      otp: "",
      txn: "",
      aadhaar_verify_id: "",
    };
  },
  watch: {
    dialog() {
      this.otp = "";
    },
  },
  methods: {
    openFingerPrint() {
      const vm = this;
      if (!vm.number || vm.number.toString().length != 12) {
        return vm.$alert.show("This aadhar ID must be 12 digit number");
      }
      var urlStr = "";
      urlStr = "http://localhost:11100/rd/capture";
      var getJSONCapture = function (url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.open("CAPTURE", url, true);
        xhr.responseType = "text";
        var InputXml =
          '<PidOptions ver="1.0"  ><Opts fCount="1" fType="2" iCount="0"  pCount="0" format="0" pidVer="2.0" timeout="20000" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" env="P"  posh="RIGHT_INDEX" /></PidOptions>';
        console.log(InputXml);
        xhr.onload = function () {
          var status = xhr.status;
          if (status == 200) {
            callback(null, xhr.response);
          } else {
            callback(status);
          }
        };
        xhr.send(InputXml);
      };
      vm.$loader.show();
      getJSONCapture(urlStr, function (err, data) {
        vm.$loader.hide();
        console.log(err);
        if (err != null) {
          vm.$alert.show(err.message);
        } else {
          var convert = require("xml-js");
          var json = JSON.parse(convert.xml2json(data, { compact: true }));
          let message = json?.PidData?.Resp?._attributes?.errInfo;
          if (message != "Success.") {
            vm.$alert.show(message);
            return;
          }
          vm.verifyFingerPrint(data);
        }
      });
    },
    async verifyFingerPrint(text) {
      const vm = this;
      var body = new FormData();
      var strblob = new Blob([text], { type: "text/plain" });
      body.append("file", strblob, "file.txt");
      body.append("aadhar_number", vm.number);
      try {
        vm.$loader.show();
        let { list, status, msg } = (
          await recipientService.verifyFingerPrint(body)
        ).data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        let { errdesc, ret, responseXML } = list;
        errdesc = AADHAR_ERROR_MESSAGES[errdesc] || errdesc;
        if (ret == "y") {
          vm.dialog = false;
          vm.$emit("onVerified");
          vm.initAadharAddress(responseXML);
        } else {
          vm.$alert.show(errdesc);
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async generateOtp() {
      const vm = this;
      if (!vm.number || vm.number.toString().length != 12) {
        return vm.$alert.show("This aadhar ID must be 12 digit number");
      }
      try {
        vm.$loader.show();
        let { list, aadhaar_verify_id, status, msg } = (
          await recipientService.generateOTP(vm.number)
        ).data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
          return;
        }

        let { errdesc, ret, txn } = list;
        errdesc = AADHAR_ERROR_MESSAGES[errdesc] || errdesc;
        vm.txn = txn;
        vm.aadhaar_verify_id = aadhaar_verify_id;
        if (ret == "y") {
          vm.dialog = true;
        } else {
          vm.$alert.show(errdesc);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async verify() {
      const vm = this;
      if (!vm.number || vm.number.toString().length != 12) {
        return vm.$alert.show("This aadhar ID must be 12 digit number");
      }
      let doc = {
        TxnID: vm.txn,
        aadhaarno: vm.number,
        OTPValue: vm.otp,
        aadhaar_verify_id: vm.aadhaar_verify_id,
      };
      try {
        vm.$loader.show();
        let { list, status, msg } = (
          await recipientService.verifyAadharOTP(doc)
        ).data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        let { errdesc, ret, responseXML } = list;
        errdesc = AADHAR_ERROR_MESSAGES[errdesc] || errdesc;
        if (ret == "y") {
          vm.dialog = false;
          vm.$emit("onVerified");
          vm.initAadharAddress(responseXML);
        } else {
          vm.$alert.show(errdesc);
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    initAadharAddress(xml) {
      // xml = xml ? xml : require("../../../assets/js/xml.json").xml;
      xml = atob(xml);
      var convert = require("xml-js");
      var json = JSON.parse(convert.xml2json(xml, { compact: true }));
      console.log(json);
      try {
        let { vtc, house, street, pc, state, dist, co } =
          json.KycRes.UidData.Poa._attributes;
        let photo = json.KycRes.UidData.Pht._text;
        let { name, gender, dob } = json.KycRes.UidData.Poi._attributes;
        let address = {
          aadhar_name: name,
          address_line_1: house,
          father_name: co,
          address_line_2: street,
          town_village: vtc,
          aadhar_photo: `data:image/png;base64,${photo}`,
          pincode: pc,
        };
        let details = {
          first_name: name,
          gender: gender == "M" ? 1 : 2,
          DOB: moment(dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
        };
        let doc = {
          address,
          state,
          details,
          city: dist,
        };
        event.$emit("initAadhar", doc);
        event.$emit("initAadharDetails", doc);
      } catch (error) {
        console.log("errors 13s", error.message);
      }
    },
  },
  mounted() {
    // this.initAadharAddress();
  },
};
</script>

<style></style>
