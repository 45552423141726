<template>
  <div>
    <b-button
      variant="danger"
      v-if="isTerminatable"
      @click="dialog = true"
      style="width: 100%"
      >Terminate List</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Termination Reason"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12" class="mt-2">
            <input-field label="Reason" rules="required" v-model="reason">
            </input-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import event from "../../../../assets/js/event";
import donorService from "../../../../assets/services/donor.service";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isAction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      reason: "",
      dialog: false,
    };
  },
  computed: {
    isTerminatable() {
      let notAllowed = [28, 13, 44];
      return (
        this.isAction && notAllowed.indexOf(this.item.allocation_status) == -1
      );
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let { organ_allocation_id } = vm.item;
        let doc = {
          organ_allocation_id,
          dml_indicator: "TS",
          termination_reason: vm.reason,
        };
        let { status, msg } = (await donorService.allocationStatusUpdate(doc))
          .data;
        event.$emit("refresh");
        if (status) {
          vm.dialog = false;
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
