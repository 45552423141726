<template>
  <div>
    <add-button @click="dialog = true"></add-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Select Hospital"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <autocomplete
              v-model="hospital_id"
              label="Hospital"
              :items="hospitals"
              :multiple="false"
              :max="1"
              rules="required"
            >
            </autocomplete>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { encode } from "../../assets/js/common";
import AddButton from "../buttons/AddButton.vue";
import Autocomplete from "../form/Autocomplete.vue";
export default {
  props: {
    hospitals: Array,
  },
  components: { AddButton, Autocomplete },
  data() {
    return {
      dialog: false,
      hospital_id: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      let hospital_id = vm.hospital_id;
      let id = encode(hospital_id);
      let hospital =
        vm.hospitals.find((item) => {
          return item.value == hospital_id;
        }) || {};
      let hospital_type = hospital.hospital_type || 1;
      this.$router.push({
        path: `/transplant-registration`,
        query: { hospital_id: id, hospital_type },
      });
    },
  },
};
</script>

<style></style>
