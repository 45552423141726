<template>
  <div class="d-flex recipient-footer">
    <div class="flex-grow-1"></div>
    <b-button
      variant="outline-danger mx-3"
      @click="$router.push('/manage-donor')"
      >Cancel
    </b-button>
    <template v-if="isSubmit">
      <b-button
        v-if="isDraft"
        @click="$emit('draft')"
        variant="primary"
        class="me-3"
        >Draft
      </b-button>
      <b-button variant="success" type="submit">Submit </b-button>
    </template>
  </div>
</template>

<script>
import menuConstants from "../../../assets/constants/menu.constants";
import donorMixins from "../../../assets/mixins/donor.mixins";
export default {
  props: {
    status: [Number, String],
  },
  mixins: [donorMixins],
  computed: {
    isDraft() {
      return this.status == 8;
    },
    isSubmit() {
      let hasCreateAccess = this.hasCreateAccess(menuConstants.MANAGE_DONOR);
      if (this.isCreate) return hasCreateAccess;
      let hasEditAccess = this.hasEditAccess(menuConstants.MANAGE_DONOR);
      if (!hasEditAccess) return false;
      let { status } = this;
      return status != 2 && status != 9 && status != 7;
    },
  },
};
</script>

<style lang="scss">
.recipient-footer {
  button {
    width: 100px;
  }
}
</style>
