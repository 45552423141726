<template>
  <div>
    <Header :hospitals="hospitals" @fetch="$emit('fetch')"></Header>
    <List :isLoading="isLoading" @fetch="fetch" :list="list"></List>
  </div>
</template>

<script>
import event from "../../assets/js/event";
import userMixins from "../../assets/mixins/user.mixins";
import userService from "../../assets/services/user.service";
import Header from "../../components/manageuser/Header.vue";
import List from "../../components/manageuser/List.vue";
export default {
  components: { List, Header },
  data() {
    return {
      list: [],
      hospitals: [],
      filter: {},
      isLoading: false,
    };
  },
  mixins: [userMixins],
  methods: {
    async fetch() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await userService.userList({
          dml_indicator: "DS",
        });
        let { status, msg, user_list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = user_list;
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetch();
    event.$on("refreshUser", () => {
      this.fetch();
    });
  },
};
</script>

<style></style>
