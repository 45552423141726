<template>
  <div class="d-flex mb-4">
    <div class="flex-grow-1"></div>
    <select-hospital
      v-if="hasAccess && isAdmin"
      :hospitals="hospitals"
    ></select-hospital>
    <Add v-else-if="isAdd" @click="open()"></Add>
  </div>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import commonHelper from "../../assets/helper/common.helper";
import { encode } from "../../assets/js/common";
import commonMixins from "../../assets/mixins/common.mixins";
import Add from "../buttons/AddButton.vue";
import SelectHospital from "./SelectHospital.vue";
export default {
  components: { Add, SelectHospital },
  mixins: [commonMixins],
  data() {
    return {
      hospitals: [],
    };
  },
  computed: {
    hasAccess() {
      let hasCreateAccess = this.hasCreateAccess(
        menuConstants.MANAGE_HOSPITAL_LICENCE
      );
      return hasCreateAccess;
    },
    isAdd() {
      let { hospital_id } = this.user;
      if (this.isHospitalAdmin && hospital_id && this.hasAccess) return true;
      return false;
    },
  },
  methods: {
    open() {
      const vm = this;
      let { hospital_type, hospital_id } = vm.user;
      let id = encode(hospital_id);
      this.$router.push({
        path: `/transplant-registration`,
        query: { hospital_id: id, hospital_type },
      });
    },
  },
  mounted() {
    if (this.isAdmin) {
      commonHelper.fetchHospital(this);
    }
  },
};
</script>

<style></style>
