var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Expandable',{staticClass:"mb-10",attrs:{"header":"Vaccination Status"},scopedSlots:_vm._u([{key:"action",fn:function(){return [(!_vm.isDisabled && _vm.isAdd)?_c('create',{attrs:{"list":_vm.list},on:{"onCreate":_vm.onCreate}}):_vm._e()]},proxy:true}])},[_c('b-table',{staticClass:"mt-3",attrs:{"fields":_vm.headers,"responsive":"","items":_vm.list,"head-variant":'dark',"thead-class":"table-light"},scopedSlots:_vm._u([{key:"cell(master_vaccination_id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getVaccinationName(item))+" ")]}},{key:"cell(vaccination_date)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.isDisabled)?[(item.status != 4)?_c('div',{staticClass:"d-flex"},[_c('create',{attrs:{"list":_vm.list,"item":item,"index":index},on:{"onUpdate":_vm.onUpdate}}),_c('delete-button',{on:{"click":function($event){return _vm.remove(index)}}})],1):_c('div',{staticClass:"d-flex"},[_c('b-button',{attrs:{"pill":"","variant":"danger","size":"sm"},on:{"click":function($event){return _vm.undo(index)}}},[_vm._v("undo remove")])],1)]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }