<template>
  <expandable header="Resuscitative measures" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <yes-no
          v-model="form.CPR_given"
          label="CPR Given"
          @input="onInput"
          :disabled="isDisabled"
          rules="required"
        ></yes-no>
      </b-col>

      <b-col cols="12" md="4" v-if="form.CPR_given == 1">
        <file-input-group
          v-model.trim="form.CPR_notes"
          @input="onInput"
          :disabled="isDisabled"
          label="CPR File"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          rules="required|file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          v-model="form.hypotensive_episodes"
          label="Hypotensive Episodes"
          @input="onInput"
          :disabled="isDisabled"
          rules="required"
        ></yes-no>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import FileInputGroup from "../../form/FileInputGroup.vue";
import YesNo from "../../form/YesNo.vue";
export default {
  components: { YesNo, Expandable, FileInputGroup },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
