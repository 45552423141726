<template>
  <div class="ms-sm-3 mb-sm-0 mb-3">
    <b-button style="width: 100%" @click="dialog = true"
      >Reallocate list</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      hide-footer
      title="Reallocate List"
      size="xl"
      centered
    >
      <div class="d-flex align-item-center">
        <div class="flex-grow-1"></div>
        <input-group
          rules=""
          v-if="false"
          placeholder="Search Recipient"
          style="width: 350px"
          type="number"
          v-model="id"
        >
          <template v-slot:help>
            <b-button @click="search()">Search</b-button>
          </template>
        </input-group>
      </div>
      <div class="table-responsive-sm">
        <table class="table align-middle">
          <thead class="table-light">
            <tr>
              <th v-for="item in fields" :key="item.key" class="align-middle">
                {{ item.label }}
              </th>
            </tr>
          </thead>
          <draggable v-model="recipientList" tag="tbody">
            <tr v-for="(item, i) in recipientList" :key="i">
              <td>
                <internal-recipient-icon
                  :item="item"
                  :value="item.recipient_registration_id"
                ></internal-recipient-icon>
              </td>
              <td>{{ item.zone_rank_id }}</td>
              <td>{{ item.created_on }}</td>
              <!-- <td>{{ item.first_name }}</td> -->
              <td>{{ item.age }}</td>
              <td>{{ item.gender }}</td>
              <td>{{ item.height }}</td>
              <td>{{ item.weight }}</td>
              <td>{{ item.group_desc }}</td>
              <td>{{ getBMI(item) }}</td>
              <td v-if="isLung">
                {{ getCauseLung(item) }}
              </td>
              <td>{{ item.hospital_name }}</td>
              <td>{{ item.organ_name }}</td>
              <!-- <td>{{ item.payment_status }}</td> -->
              <!-- <td>{{ item.transtan_status }}</td>
              <td>{{ item.CM_insurance }}</td>
              <td>{{ item.phone_number_1 }}</td> -->
              <td>{{ item.recepient_organ_allocation_status }}</td>
              <td>
                <div
                  class="bg-primary bg-soft text-primary px-2 py-2 text-center"
                >
                  {{ getAllocationStatus(i + 1) }}
                </div>
              </td>
              <td>
                <delete-button @click="remove(i)"></delete-button>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
      <template v-if="recipientList.length != 3">
        <h5 class="mb-3">Other Recipient</h5>
        <b-table
          outlined
          :fields="fields"
          responsive="sm"
          :items="filteredOtherList"
          :head-variant="'dark'"
          thead-class="table-light table-bordered"
          tbody-class="table-bordered"
          :busy="isLoading"
        >
          <template #table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="mx-2">Loading...</strong>
            </div>
          </template>
          <template #cell(recipient_registration_id)="data">
            <internal-recipient-icon
              :item="data.item"
              :value="data.item.recipient_registration_id"
            ></internal-recipient-icon>
          </template>
          <template #cell(action)="data">
            <b-button variant="primary" class="me-3" @click="select(data.item)"
              >Select</b-button
            >
          </template>
          <template #cell(BMI)="data">
            {{ getBMI(data.item) }}
          </template>
          <template #cell(cause_of_lung_disease)="data">
            {{ getCauseLung(data.item) }}
          </template>
        </b-table>
      </template>

      <div class="d-flex mt-3">
        <div class="flex-grow-1"></div>
        <b-button variant="outline-danger" class="mx-3" @click="dialog = false"
          >Close</b-button
        >
        <b-button variant="success" @click="generate()">Submit</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { RECIPIENT_ALLOCATION_STATUS } from "../../../../../assets/constants/donor.constants";
import { CAUSE_OF_LUNG_DISEASE } from "../../../../../assets/constants/recipient.constants";
import {
  formatDate,
  LEFT_LUNG_ID,
  LUNG_ID,
  RIGHT_LUNG_ID,
} from "../../../../../assets/js/common";
import donorService from "../../../../../assets/services/donor.service";
import DeleteButton from "../../../../buttons/DeleteButton.vue";
import InputGroup from "../../../../form/InputGroup.vue";
import InternalRecipientIcon from "../../../../managerecipient/InternalRecipientIcon.vue";
export default {
  components: { InputGroup, draggable, DeleteButton, InternalRecipientIcon },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Number,
      default: 2,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      id: "",
      otherList: [],
      isLoading: false,
      recipientList: [],
    };
  },
  computed: {
    filteredOtherList() {
      return this.otherList.filter((item) => {
        let doc = this.recipientList.find((i) => {
          return i.recipient_registration_id == item.recipient_registration_id;
        });
        return doc == null;
      });
    },
    fields() {
      let doc = {
        recipient_registration_id: "UID",
        zone_rank_id: "Zonal Rank",
        created_on: "Date of Registration",
        // first_name: "Name",
        age: "Age",
        gender: "Gender",
        height: "Height",
        weight: "Weight",
        group_desc: "Blood Group",
        BMI: "BMI",
        cause_of_lung_disease: "Cause of Lung Disease",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        // payment_status: "Payment Status",
        // transtan_status: "Patient Status",
        // CM_insurance: "CM Insurance",
        // phone_number_1: "Phone Number",
        recepient_organ_allocation_status: "Status",
        allocation_status: "Allocation Status",
        action: "Action",
      };
      if (!this.isLung) delete doc.cause_of_lung_disease;
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
    isLung() {
      const vm = this;
      let lungs = [LUNG_ID, LEFT_LUNG_ID, RIGHT_LUNG_ID];
      return lungs.indexOf(vm.item.organ_id) != -1;
    },
  },
  watch: {
    dialog() {
      this.init();
    },
  },
  methods: {
    getCauseLung({ cause_of_lung_disease }) {
      let doc = CAUSE_OF_LUNG_DISEASE.find((item) => {
        return item.value == cause_of_lung_disease;
      });
      return doc != null ? doc.name : "";
    },
    getBMI(item) {
      let { height, weight } = item;
      if (!height) return 0;
      if (!weight) return 0;
      height = height / 100;
      let bmi = weight / (height * height);
      if (bmi < 1000) {
        bmi = bmi.toFixed(2);
      }
      return bmi;
    },
    select(item) {
      this.recipientList.push(item);
    },
    async search() {
      const vm = this;
      if (!vm.id) {
        vm.$alert.show("Please enter recipient UHI");
        return;
      }
      if (vm.recipientList.length > 2) {
        vm.$alert.show("Only 3 allocation allowed");
        return;
      }
      try {
        vm.$loader.show();
        let { organ_allocation_id, organ_id } = vm.item;
        let { data } = await donorService.reAllocate({
          dml_indicator: "RC",
          recepient_registration_id: vm.id,
          organ_id,
          organ_allocation_id,
        });
        let { status, list } = data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show("Recipient not found");
          return;
        }
        vm.recipientList.push(list[0]);
        vm.id = "";
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    remove(i) {
      this.$confirm.show({
        message: "Are you sure, Do you want remove this recipient?",
        onConfirm: () => {
          this.otherList.push({ ...this.recipientList[i] });
          this.recipientList.splice(i, 1);
        },
      });
    },
    getAllocationStatus(status) {
      if (status == "1") return "Priority Allocation";
      return `Standby ${status - 1}`;
    },
    getStatus(val) {
      if (!val) return "";
      let item = RECIPIENT_ALLOCATION_STATUS.find((item) => {
        return item.value == val;
      });
      return item ? item.name : "";
    },
    async generate() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want reallocate?",
        onConfirm: async () => {
          vm.dialog = false;
          try {
            let list = [];
            let {
              organ_allocation_id,
              donor,
              organ_id,
              final_donor_id,
              type_of_allocation,
              allocation_status,
            } = vm.item;
            let { case_officer, donor_temp_registration_id } = donor;
            vm.recipientList.forEach((item, i) => {
              let {
                recipient_registration_id,
                hospital_id,
                recepeint_allocation_id,
              } = item;

              let doc = {
                final_donor_id,
                organ_allocation_id,
                donor_temp_registration_id,
                recepient_allocation_status: i + 1,
                case_officer,
                hospital_id,
                organ_id,
                status: 1,
                recepient_registration_id: recipient_registration_id,
                created_by: vm.created_by,
                updated_by: vm.created_by,
                type_of_allocation,
                allocation_status,
                user_type: 2,
                reject_reason: "",
                dml_indicator: "RAU",
              };
              if (recepeint_allocation_id) {
                doc.recepeint_allocation_id = recepeint_allocation_id;
              } else {
                let recipient = list.find((item) => {
                  return (
                    item.recipient_registration_id == recipient_registration_id
                  );
                });
                if (recipient != null) {
                  doc.recepeint_allocation_id =
                    recipient.recepeint_allocation_id;
                }
              }
              list.push(doc);
            });
            vm.list.forEach((item) => {
              let { recipient_registration_id, recepeint_allocation_id } = item;
              let doc = list.find((i) => {
                return i.recepeint_allocation_id == recepeint_allocation_id;
              });
              if (doc == null) {
                list.push({
                  recepeint_allocation_id,
                  dml_indicator: "RAU",
                  donor_temp_registration_id,
                  recepient_allocation_status: 0,
                  user_type: 2,
                  recepient_registration_id: recipient_registration_id,
                });
              }
            });
            vm.$loader.show();
            let { data } = await donorService.reAllocateInsert({
              recepient_organ_allocation_proc: list,
            });
            let { status, msg } = data;
            vm.$loader.hide();
            vm.$emit("fetch");
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },

    async fetchOtherList() {
      const vm = this;
      let { organ_id, organ_allocation_id, type_of_allocation } = vm.item;
      const doc = {
        dml_indicator: type_of_allocation == 3 ? "RRA" : "RA",
        organ_id,
        recepient_type_of_allocation: 1,
        organ_allocation_id,
      };
      if (vm.status == 20) doc.recepient_type_of_allocation = 2;
      if (vm.status == 21) doc.recepient_type_of_allocation = 3;
      if (vm.status == 22) doc.recepient_type_of_allocation = 4;
      if (vm.status == 23) doc.recepient_type_of_allocation = 5;
      if (vm.status == 24) doc.recepient_type_of_allocation = 6;
      if (vm.status == 34) doc.recepient_type_of_allocation = 7;
      if (vm.status == 30) doc.recepient_type_of_allocation = 8;
      try {
        vm.isLoading = true;
        vm.otherList = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, list } = data;
        vm.isLoading = false;
        if (!status) {
          // vm.$alert.show(msg);
          return;
        }
        vm.otherList = list.map((item) => {
          item.payment_status = vm.getPaymentStatus(item.payment_status);
          item.transtan_status = vm.getPatientStatus(item.transtan_status);
          item.CM_insurance = vm.getCMInsuranceStatus(item.CM_insurance);
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          item.recepient_organ_allocation_status = vm.getStatus(
            item.recepient_organ_allocation_status
          );
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
    init() {
      const vm = this;
      vm.recipientList = vm.list.map((doc) => {
        let item = {};
        Object.keys(doc).forEach((key) => {
          item[key] = doc[key];
        });
        item.payment_status = vm.getPaymentStatus(item.payment_status);
        item.transtan_status = vm.getPatientStatus(item.transtan_status);
        item.CM_insurance = vm.getCMInsuranceStatus(item.CM_insurance);
        if (item.created_on && item.created_on.indexOf("/") == -1) {
          item.created_on = formatDate(item.created_on);
        }
        item.recepient_organ_allocation_status = vm.getStatus(
          item.recepient_organ_allocation_status
        );
        return item;
      });
      vm.fetchOtherList();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
