<template>
  <b-container class="login">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5 mt-5">
        <b-card overflow-hidden>
          <b-card-title>
            <h2 class="text-center">Forgot Password</h2>
          </b-card-title>
          <b-card-body>
            <form-wrapper @submit="submit">
              <InputField
                type="email"
                rules="required|email"
                v-model="form.email_id"
                label="Email address"
                invalid="Enter the Registered Email Address"
                required
              >
              </InputField>
              <b-button block class="mt-3" variant="primary" type="submit"
                >Submit</b-button
              >
            </form-wrapper>
            <!-- <p class="subtitle-1 text-center mt-5">
              Don’t have an account yet?
              <a class="primary-text" @click="$router.push('/registration')">
                Click here to Sign Up
              </a>
            </p> -->
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script>
import { EMAIL, PASSWORD } from "../../assets/js/rules";
import { USER_TYPES } from "../../assets/constants/hospital.contants";
import userService from "../../assets/services/user.service";
export default {
  data() {
    return {
      password: PASSWORD,
      form: {
        email_id: "",
      },
      email: EMAIL,
      isValid: false,
      token: "",
    };
  },
  computed: {
    types() {
      return USER_TYPES;
    },
  },
  methods: {
    async submit() {
      const vm = this;
      let doc = {};
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      vm.$loader.show();
      try {
        let { data } = await userService.forgot(doc);
        vm.$loader.hide();
        let { status } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show("Please enter your registered mail id!");
          return;
        }
        vm.$alert.show("Password reset mail sent successfully!");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
      console.log(JSON.stringify(doc));
    },
  },
  mounted() {},
};
</script>

<style scoped>
.login {
  height: calc(100vh - 70px);
}
</style>
