<template>
  <Expandable header="Recipient Details" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.recipient_name"
          label="Recipient Name"
          :disabled="isDisabled"
          @input="onInput"
          rules="required|alpha_spaces"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.recipient_age"
          type="number"
          label="Recipient Age"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          :items="genders"
          v-model="form.recipient_sex"
          label="Recipient Gender"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        >
        </select-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          @input="onInput"
          label="Recipient Blood Group"
          :items="bloodGroups"
          :disabled="isDisabled"
          v-model="form.recipient_blood_group"
          rules="required"
        ></select-field>
      </b-col>
      <b-col cols="12" md="4">
        <text-area
          v-model="form.recipient_address"
          label="Recipient Address"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        >
        </text-area>
      </b-col>
      <b-col cols="12" md="4" v-if="false">
        <phone-field
          v-model.trim="form.recipient_phone_number"
          label="Recipient Phone Number"
          rules="required"
        ></phone-field>
      </b-col>
      <b-col cols="12" md="4" v-if="false">
        <input-field
          v-model.trim="form.recipient_email_id"
          label="Recipient Email"
          rules="email"
        ></input-field>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import Expandable from "../../../Expandable.vue";
import SelectField from "../../../form/SelectField.vue";
import InputField from "../../../form/InputField.vue";
import { GENDERS } from "../../../../assets/constants/hospital.contants";
import PhoneField from "../../../form/PhoneField.vue";
import TextArea from "../../../form/TextArea.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { Expandable, SelectField, InputField, PhoneField, TextArea },
  data() {
    return {
      form: {},
      genders: GENDERS,
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
