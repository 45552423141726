var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.tables),function(item,i){return _c('table-view',{key:i,attrs:{"title":item.title,"fields":_vm.fields,"isBusy":_vm.isLoading,"items":item.list},scopedSlots:_vm._u([{key:"payment_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getPaymentStatus(value))+" ")]}},{key:"transtan_status",fn:function(ref){
var value = ref.value;
return [_c('div',{class:_vm.getBg(_vm.getPatientStatus(value))},[_vm._v(" "+_vm._s(_vm.getPatientStatus(value))+" ")])]}},{key:"hospital_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getBeReadyStatus(value))+" ")]}},{key:"CM_insurance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCMInsuranceStatus(value))+" ")]}},{key:"gender",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getGender(value))+" ")]}},{key:"status",fn:function(ref){
var item = ref.item;
return [(item.current_town)?_c('div',{staticClass:"badge bg-success font-size-13"},[_vm._v(" Updated ")]):_vm._e()]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('be-ready-status-update',{attrs:{"item":item},on:{"fetch":function($event){return _vm.$emit('fetch')}}})]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }