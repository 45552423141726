<template>
  <div id="layout-wrapper" :class="background">
    <NavBar v-if="isAuth" />
    <login-header v-else></login-header>
    <SideBar v-if="isAuth" :is-condensed="false" type="dark" />
    <div class="container-fluid px-4" v-if="isWaitingList">
      <router-view></router-view>
    </div>
    <div v-else :class="isAuth ? 'main-content' : ''">
      <div class="page-content">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <login-footer v-if="!isAuth"></login-footer>
  </div>
</template>
<script>
import NavBar from "@/components/layout/nav-bar.vue";
import SideBar from "@/components/layout/side-bar.vue";
import LoginHeader from "./layout/login-header.vue";
import LoginFooter from "./layout/login-footer.vue";
export default {
  components: { NavBar, SideBar, LoginHeader, LoginFooter },
  data() {
    return {
      isMenuCondensed: true,
    };
  },
  computed: {
    background() {
      let pages = ["login", "registration", "forgot-password"];
      return pages.indexOf(this.$route.name) != -1 ? "tamilnadu__bg" : "";
    },
    isWaitingList() {
      return this.$route.name == "commong-waiting-list";
    },
  },
  created: () => {
    // document.body.removeAttribute("data-layout", "horizontal");
    // document.body.removeAttribute("data-topbar", "dark");
    // document.body.removeAttribute("data-layout-size", "boxed");
    // document.body.classList.remove("auth-body-bg");
  },
  watch: {
    $route() {
      document.body.classList.remove("vertical-collpsed");
      document.body.classList.remove("sidebar-enable");
    },
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");
      if (window.screen.width >= 992) {
        document.body.classList.toggle("vertical-collpsed");
      } else {
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
  },
};
</script>
<style>
.tamilnadu__bg {
  background-image: url("../assets/tamilnadu.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
