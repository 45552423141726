<template>
  <b-button variant="primary" depressed @click="$emit('click')" tile>
    <slot></slot>
  </b-button>
</template>

<script>
export default {};
</script>

<style></style>
