<template>
  <div>
    <template v-if="!isPendingApprovel">
      <b-button v-b-toggle.collapse-2 variant="primary"> Drafts </b-button>
      <b-collapse id="collapse-2">
        <table-view
          class="mt-4"
          :items="drafts"
          :fields="fields(true)"
          title="Drafts"
          :isBusy="isDraftLoading"
        >
          <template v-slot:sno="{ item }">
            <internal-recipient-icon
              :item="item"
              :value="item.sno"
            ></internal-recipient-icon>
          </template>
          <template v-slot:age="{ value }">
            <div :class="value > 60 ? 'badge bg-danger  font-size-13' : ''">
              {{ value }}
            </div>
          </template>

          <template v-slot:payment_status="{ value }">
            {{ getPaymentStatus(value) }}
          </template>
          <template v-slot:transtan_status="{ value }">
            <div :class="getBg(getPatientStatus(value))">
              {{ getPatientStatus(value) }}
            </div>
          </template>
          <template v-slot:hospital_status="{ value }">
            {{ getBeReadyStatus(value) }}
          </template>
          <template v-slot:CM_insurance="{ value }">
            {{ getCMInsuranceStatus(value) }}
          </template>
          <template v-slot:gender="{ value }">
            {{ getGender(value) }}
          </template>
          <template v-slot:action="{ item }">
            <div class="d-flex">
              <recipient-list-menu
                :item="item"
                @onChangeStatus="changeStatus"
              ></recipient-list-menu>
              <view-button @click="openView(item)"></view-button>
              <edit-button
                v-if="isEditable(item)"
                @click="open(item)"
              ></edit-button>
              <delete-button
                v-if="isDeleteAble(item)"
                @click="remove(item)"
              ></delete-button>
              <approve-button
                v-if="isApprovable(item)"
                @click="approve(item)"
              ></approve-button>
              <print-icon-button @click="print(item)"></print-icon-button>
              <download-icon-button
                @click="download(item)"
              ></download-icon-button>
            </div>
          </template>
        </table-view>
      </b-collapse>
    </template>
    <table-view
      class="mt-4"
      :fields="fields(false)"
      :isDynamic="!isPendingApprovel"
      @export="$emit('export')"
      :total="total"
      ref="table"
      :isSearch="isPendingApprovel"
      :items="live"
      @onPageChange="$emit('onPageChange', $event)"
      :isBusy="isLoading"
      title="Waiting List"
    >
      <template v-slot:filter>
        <recipient-search
          v-if="!isPendingApprovel"
          @select="$emit('select', $event)"
        ></recipient-search>
      </template>
      <template v-slot:sno="{ item }">
        <div
          class="badge bg-warning font-size-13"
          v-if="item.patient_nationlity == 1"
        >
          {{ item.sno }}
          <i class="mdi mdi-airplane-takeoff"></i>
        </div>
        <span v-else>
          {{ item.sno }}
        </span>
      </template>
      <template v-slot:age="{ value }">
        <div :class="value > 60 ? 'badge bg-danger  font-size-13' : ''">
          {{ value }}
        </div>
      </template>

      <template v-slot:payment_status="{ value }">
        {{ getPaymentStatus(value) }}
      </template>
      <template v-slot:transtan_status="{ value, item }">
        <div class="d-flex">
          <div :class="getBg(getPatientStatus(value))">
            {{ getPatientStatus(value) }}
          </div>
          <timer
            class="ms-2"
            v-if="item.transtan_status == 11"
            :config="getTimer(item.status_update_on, 2)"
          ></timer>
          <timer
            class="ms-2"
            v-if="item.transtan_status == 2"
            :config="getTimer(item.created_on, 1)"
          ></timer>
          <timer
            class="ms-2"
            v-if="item.transtan_status == 9"
            :config="getTimer(item.transfer_date, 5)"
          ></timer>
        </div>
      </template>
      <template v-slot:hospital_status="{ value }">
        {{ getBeReadyStatus(value) }}
      </template>
      <template v-slot:CM_insurance="{ value }">
        {{ getCMInsuranceStatus(value) }}
      </template>
      <template v-slot:gender="{ value }">
        {{ getGender(value) }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex">
          <recipient-list-menu
            :item="item"
            @fetch="$emit('fetch')"
            @onChangeStatus="changeStatus"
          ></recipient-list-menu>
          <view-button @click="openView(item)"></view-button>
          <edit-button
            v-if="isEditable(item)"
            @click="open(item)"
          ></edit-button>
          <recipient-delete-reason
            @fetch="$emit('fetch')"
            v-if="isDeleteAble(item)"
            :reasons="reasons"
            :item="item"
          ></recipient-delete-reason>
          <undo-icon-button
            @click="undoRemove(item)"
            v-if="isUndoAble(item)"
          ></undo-icon-button>
          <approve-button
            v-if="isApprovable(item)"
            @click="approve(item)"
          ></approve-button>
          <print-icon-button @click="print(item)"></print-icon-button>
          <download-icon-button @click="download(item)"></download-icon-button>
          <payment-icon-button
            v-if="isPayment(item)"
            @click="initPayment(item)"
          ></payment-icon-button>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import {
  // BLOOD_GROUP_VALUES,
  encode,
  initPayment,
} from "../../assets/js/common";
import DeleteButton from "../buttons/DeleteButton.vue";
import EditButton from "../buttons/EditButton.vue";
import TableView from "../table/TableView.vue";
import ApproveButton from "../buttons/ApproveButton.vue";
import recipientService from "../../assets/services/recipient.service";
import menuConstants from "../../assets/constants/menu.constants";
import ViewButton from "../buttons/ViewButton.vue";
import RecipientListMenu from "./RecipientListMenu.vue";
import RecipientDeleteReason from "./RecipientDeleteReason.vue";
import PrintIconButton from "../buttons/PrintIconButton.vue";
import DownloadIconButton from "../buttons/DownloadIconButton.vue";
import UndoIconButton from "../buttons/UndoIconButton.vue";
import InternalRecipientIcon from "./InternalRecipientIcon.vue";
import Timer from "../ui/Timer.vue";
import moment from "moment";
import hospitalService from "../../assets/services/hospital.service";
import PaymentIconButton from "../buttons/PaymentIconButton.vue";
import RecipientSearch from "../../pages/managerecipient/RecipientSearch.vue";
export default {
  components: {
    TableView,
    EditButton,
    DeleteButton,
    ApproveButton,
    ViewButton,
    RecipientListMenu,
    DownloadIconButton,
    RecipientDeleteReason,
    PrintIconButton,
    UndoIconButton,
    InternalRecipientIcon,
    Timer,
    PaymentIconButton,
    RecipientSearch,
  },
  props: {
    total: {},
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDraftLoading: {
      type: Boolean,
      default: false,
    },
    drafts: Array,
    list: Array,
  },
  data() {
    return {
      organ: null,
      reasons: [],
      sort: null,
      sorts: ["Desc Order"],
      bloodGroup: null,
    };
  },
  computed: {
    organs() {
      if (this.master.master_organ instanceof Array == false) return [];
      let list = this.master.master_organ.filter(
        ({ status, tissue_bank, license_available }) => {
          return status != 4 && (tissue_bank == 1 || license_available == 1);
        }
      );
      return list.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
    isPendingApprovel() {
      return this.$route.name == "recipient-pending-approval";
    },
    live() {
      return this.list;
    },
  },
  methods: {
    async initPayment(item) {
      let { recipient_registrationid, payment_flag, organ_type } = item;
      const vm = this;
      try {
        let doc = {
          dml_indicator: payment_flag == 0 ? "NRS" : "NRT",
          recipient_registration_id: recipient_registrationid,
        };
        if (organ_type == 2 && doc.dml_indicator == "NRS") {
          doc.dml_indicator = "NRM";
        }
        if (organ_type == 2 && doc.dml_indicator == "NRT") {
          doc.dml_indicator = "NRTM";
        }
        if (payment_flag == 3) doc.dml_indicator = "STM";
        vm.$loader.show();
        let data = (await hospitalService.initPayment(doc)).data;
        initPayment(data);
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    isPayment({ payment_flag, hospital_type }) {
      return hospital_type == 2 && [0, 2, 3].indexOf(payment_flag) != -1;
    },
    getTimer(created_on, days) {
      if (!created_on) return { start: null, end: null };
      let date = moment(created_on);
      return { start: date.unix(), end: date.add(24 * days, "hours").unix() };
    },
    fields(isDraft) {
      let doc = {
        sno: "S.No",
        recipient_registration_id: "UID",
        zone_rank: "Zonal Rank",
        date: "Date of Registration",
        first_name: "Name",
        transtan_status: "Patient Status",
        group_desc: "Blood Group",
        age: "Age",
        gender: "Gender",
        aadhar_number: "Aadhar",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        payment_status: "Payment Status",
        CM_insurance: "CM Insurance",
        phone_number_1: "Phone Number",
        payment_reference_no: "Payment Reference No",
        // hospital_status: "Status",
        action: "Action",
      };
      if (isDraft) delete doc.recipient_registration_id;
      if (this.isSubAdmin) {
        delete doc.payment_status;
        delete doc.phone_number_1;
      }
      let list = [];
      Object.keys(doc).forEach((key) => {
        if (!isDraft && this.isAdmin && key == "action") {
          list.push({
            key: "delete_reason",
            label: "Delete / Inactive Reason",
          });
        }
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
    getBg(val) {
      switch (val) {
        case "Active":
          return "badge bg-success font-size-13";
        case "Pending Approval":
          return "badge bg-warning font-size-13";
        case "Inactive":
          return "badge bg-danger font-size-13";
        case "Deleted":
          return "badge bg-danger font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
    isDeleteAble({ transtan_status }) {
      let statusList = [3, 10];
      let deletedStatus = [5, 7];
      if (deletedStatus.indexOf(transtan_status) != -1) return false;
      if (transtan_status == 1) {
        return this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 7);
      }
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 5);
      if (this.isAdmin && hasAccess) return true;
      return hasAccess && statusList.indexOf(transtan_status) != -1;
    },
    isUndoAble({ transtan_status }) {
      return transtan_status == 5 && this.isAdmin;
    },
    isEditable({ transtan_status }) {
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_RECIPIENT);
      let status = [1, 2, 3, 6, 9];
      return hasAccess && status.indexOf(transtan_status) != -1;
    },
    isApprovable({ transtan_status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 3);
      return hasAccess && (transtan_status == 2 || transtan_status == 9);
    },
    open({ recipient_registrationid }) {
      let id = encode(recipient_registrationid);
      this.$router.push(`/update-recipient?id=${id}`);
    },
    openView({ recipient_registrationid }) {
      let id = encode(recipient_registrationid);
      let path = this.$router.resolve(
        `/update-recipient?id=${id}&isView=true`
      ).href;
      window.open(path);
      console.log(path);
    },
    print({ recipient_registrationid }) {
      let id = encode(recipient_registrationid);
      let path = this.$router.resolve(
        `/update-recipient?id=${id}&isPrint=true`
      ).href;
      window.open(path);
      console.log(path);
    },
    download({ recipient_registrationid }) {
      let id = encode(recipient_registrationid);
      let path = this.$router.resolve(
        `/update-recipient?id=${id}&isDownload=true`
      ).href;
      window.open(path);
      console.log(path);
    },
    remove({ recipient_registrationid, transtan_status }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id: recipient_registrationid,
            transtan_status: transtan_status == 1 ? 7 : 5,
            dml_indicator: "A",
          });
        },
      });
    },
    undoRemove({ recipient_registrationid }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to undo the delete for this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id: recipient_registrationid,
            transtan_status: 3,
            dml_indicator: "A",
          });
        },
      });
    },
    approve({ recipient_registrationid }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to approve this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id: recipient_registrationid,
            transtan_status: 3,
            dml_indicator: "A",
          });
        },
      });
    },
    async getDeleteReasonMaster() {
      const vm = this;
      try {
        let { data } = await recipientService.getDeleteReasonMaster();
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.reasons = list.map((item) => {
          let { delete_reason } = item;
          return { value: delete_reason, name: delete_reason };
        });
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await recipientService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        // vm.fetch();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.getDeleteReasonMaster();
  },
};
</script>

<style></style>
