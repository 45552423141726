import { decode } from "../js/common";

export default {

    computed: {
        isCreate() {
            return this.$route.name == "donor-registration";
        },
        isUpdate() {
            return ["update-donor", "organ-allocation", "organ-allocation-overall-report"].indexOf(this.$route.name) != -1;
        },
        isOrganAllocation() {
            return ["organ-allocation"].indexOf(this.$route.name) != -1;
        },
        isAllocationReport() {
            return this.$route.name == "organ-allocation-overall-report"
        },
        id() {
            return decode(this.$route.query.id)
        },
        final_donor_id() {
            return decode(this.$route.query.final_donor_id)
        }
    }
}