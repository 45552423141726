<template>
  <div class="d-flex recipient-footer">
    <download-button @click="$emit('download')"></download-button>
    <print-button @click="$emit('print')"></print-button>
    <div class="flex-grow-1"></div>
    <b-button
      variant="outline-danger mx-3"
      @click="$router.push('/manage-recipient')"
      >Cancel
    </b-button>
    <template v-if="isEdit">
      <b-button
        v-if="isDraft"
        @click="$emit('draft')"
        variant="primary"
        class="me-3"
        >Draft
      </b-button>
      <b-button v-if="isAadharVerified" variant="success" type="submit" style="width:130px">
        {{isPay ? "Pay & Submit": "Submit"}}
      </b-button>
    </template>
  </div>
</template>

<script>
import menuConstants from "../../../assets/constants/menu.constants";
import recipientMixins from "../../../assets/mixins/recipient.mixins";
import DownloadButton from '../../buttons/DownloadButton.vue';
import PrintButton from "../../buttons/PrintButton.vue";
export default {
  props: {
    status: [Number, String],
    isAadharVerified: {
      type: Boolean,
      default: false,
    },
    payment_flag: {

    }
  },
  components: {
    PrintButton,
    DownloadButton,
  },
  mixins: [recipientMixins],
  computed: {
    isPay() {
      console.log("payment_flag",this.payment_flag);
      return [0, 2].indexOf(this.payment_flag) != -1;
    },
    isCreate() {
      return this.$route.name == "recipient-registration";
    },
    isDraft() {
      return this.status == 1 || this.status == 8;
    },
    isView() {
      return this.$route.query.isView == "true";
    },
    isEdit() {
      if(this.isView) return false;
      let hasCreateAccess = this.hasCreateAccess(
        menuConstants.MANAGE_RECIPIENT
      );
      if (this.isCreate) return hasCreateAccess;
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_RECIPIENT);
      return hasAccess && this.status != 7 && this.status != 5;
    },
  },
};
</script>

<style lang="scss">
.recipient-footer {
  button {
    width: 100px;
  }
}
</style>
