<template>
  <div class="d-flex align-center">
    <b-button icon="x-octagon-fill" @click="init()" variant="primary">
      View</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Retrieval Details"
      scrollable
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="md"
      centered
    >
      <b-row>
        <template v-if="recipeint.organ_retrieval_status == '1'">
          <b-col cols="12" md="6">
            <date-time-picker
              label="Organ Retrieval Date and Time"
              :disabled="true"
              rules="required"
              v-model="form.retrieval_on"
            ></date-time-picker>
          </b-col>
          <b-col cols="12" md="6" v-if="false">
            <input-field
              label="Organ Retrieval By"
              rules="required"
              :disabled="true"
              v-model="form.retrieval_by"
            ></input-field>
          </b-col>
        </template>
        <b-col cols="12" md="6">
          <input-field
            label="Notes"
            :disabled="true"
            v-model="form.notes"
          ></input-field>
        </b-col>
        <b-col cols="12" md="6" v-if="form.file_path">
          <file-input-group
            label="File Upload"
            :disabled="true"
            v-model="form.file_path"
          ></file-input-group>
        </b-col>
        <b-col cols="12" md="6" v-if="form.video_path">
          <file-input-group
            label="Video Upload"
            :disabled="true"
            v-model="form.video_path"
          ></file-input-group>
        </b-col>
      </b-row>
      <div class="d-flex mt-3">
        <div class="flex-grow-1"></div>
        <b-button variant="outline-danger" @click="dialog = false"
          >Cancel
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isDocObject, openFile } from "../../../../../assets/js/common";
import donorService from "../../../../../assets/services/donor.service";
import DateTimePicker from "../../../../form/DateTimePicker.vue";
import FileInputGroup from "../../../../form/FileInputGroup.vue";
import InputField from "../../../../form/InputField.vue";
export default {
  components: { InputField, DateTimePicker, FileInputGroup },
  props: {
    recipeint: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      form: {
        retrieval_by: "",
        retrieval_on: "",
        notes: "",
        retrieval_status: "",
        video_path: "",
        file_path: "",
      },
      list: [],
    };
  },
  computed: {
    fields() {
      let doc = {
        first_name: "Name",
        phone_number_1: "Phone Number",
        photo: "View Transport Details",
        action: "Allocation Status",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  methods: {
    open(file) {
      openFile(file);
    },
    async init() {
      const vm = this;
      let { recepeint_allocation_id } = vm.recipeint;
      const doc = {
        dml_indicator: "S",
        recepeint_allocation_id,
      };
      try {
        vm.list = [];
        vm.$loader.show();
        let { data } = await donorService.retrievalDetailsList(doc);
        let { list, status, msg } = data;
        vm.isLoading = false;
        vm.$loader.hide();
        if (!status) return vm.$alert.show(msg);
        if (isDocObject(list)) {
          let doc = list[0];
          Object.keys(vm.form).forEach((key) => {
            if (doc[key]) vm.form[key] = doc[key];
          });
        }
        vm.dialog = true;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
