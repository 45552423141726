import {
  getAge,
  getDateOnly,
  isArray,
  isDocObject,
} from "../../assets/js/common";
import event from "../../assets/js/event";
import hospitalService from "../../assets/services/hospital.service";
import userService from "../../assets/services/user.service";

class Helper {
  async get(vm) {
    try {
      vm.isLoading = true;
      vm.$loader.show();
      let { hospital_contact_id } = vm.item;
      let { data } = await userService.get(hospital_contact_id);
      vm.updateDoc = data;
      let {
        status,
        msg,
        contact_details,
        contact_organ_mapping,
        qualification_mapping,
      } = data;
      vm.$loader.hide();
      if (!status) {
        vm.$alert.show(msg);
        return;
      }
      if (isDocObject(contact_details)) {
        let doc = contact_details[0];
        Object.keys(vm.form).forEach((key) => {
          if (doc[key] != undefined) vm.form[key] = doc[key];
        });
        if(doc.ALF_approver) {
          vm.form.ALF_approver = doc.ALF_approver.toString();
        }
      }
      if (isArray(qualification_mapping)) {
        let list = qualification_mapping.filter((item) => {
          return item.status != 4;
        });
        vm.form.qualification_details = list.map((item) => {
          return item.qualification_id;
        });
      }
      if (isArray(contact_organ_mapping)) {
        let list = contact_organ_mapping.filter((item) => {
          return item.contact_organ_mapping_status != 4;
        });
        vm.form.contact_organ_mapping = list.map((item) => {
          return item.organ_id;
        });
      }
      vm.isLoading = false;
      vm.dialog = true;
    } catch (error) {
      vm.$alert.show(error.message);
      vm.$loader.hide();
    }
  }
  async createUser(vm) {
    let doc = {};
    let { hospital_id } = vm;
    Object.keys(vm.form).forEach((key) => {
      doc[key] = vm.form[key];
    });
    let { email_id, phone_number_1, hospital_contact_id } = doc;
    let isEmail = true;
    let isPhone1 = true;
    let isPhone2 = true;
    try {
      vm.$loader.show();
      let { data } = await hospitalService.validateEmailPhone({
        email_id,
        hospital_id,
        dml_indicator: hospital_id ? "" : "TC",
        hospital_contact_id,
      });
      isEmail = data.status.toString() == "true";
      let res = await hospitalService.validateEmailPhone({
        phone_number_1,
        hospital_id,
        dml_indicator: hospital_id ? "" : "TC",
        hospital_contact_id,
      });
      isPhone1 = res.data.status.toString() == "true";
      vm.$loader.hide();
    } catch (error) {
      vm.$alert.show(error.message);
      vm.$loader.hide();
    }

    if (!isEmail) {
      vm.$alert.show(
        "Another contact already exists with the same <b>Email</b>. Please enter a unique email."
      );
      return;
    }
    if (!isPhone1) {
      vm.$alert.show(
        "Another contact already exists with the same <b>Phone Number 1</b>. Please enter a unique phone number 1."
      );
      return;
    }
    if (!isPhone2) {
      vm.$alert.show(
        "Another contact already exists with the same <b>Phone Number 2</b>. Please enter a unique phone number 2."
      );
      return;
    }
    doc.age = getAge(doc.DOB);
    doc.age_registered_date = getDateOnly();
    doc.qualification_details = this.getQualificationArray(vm);
    doc.contact_organ_mapping = this.getOrganArray(vm);
    try {
      vm.$loader.show();
      let { photo } = doc;
      if (photo instanceof File) {
        doc.photo = await this.uploadContact(photo);
      }
      let { data } = await userService.createUser(doc);
      let { status, msg } = data;
      console.log(status, msg);
      vm.$loader.hide();
      if (!status) {
        vm.$alert.show(msg);
        return;
      }
      event.$emit("refreshUser");
      vm.dialog = false;
    } catch (error) {
      vm.$loader.hide();
      vm.$alert.show(error.message);
    }
  }
  getQualificationArray = (vm) => {
    let qualification_details = vm.form.qualification_details;
    let { hospital_contact_id } = vm.form;
    let { qualification_mapping } = vm.updateDoc;
    if (qualification_mapping == undefined) qualification_mapping = [];
    qualification_mapping = qualification_mapping.map((item) => {
      item.status = "4";
      return item;
    });
    qualification_details.forEach((id) => {
      let doc = {
        hospital_id: vm.form.hospital_id,
        qualification_id: id,
        hospital_contact_id,
        created_by: vm.created_by,
        status: 1,
      };
      let index = -1;
      qualification_mapping.forEach((item, i) => {
        if (item.qualification_id == id) index = i;
      });
      if (index == -1) {
        qualification_mapping.push(doc);
      } else {
        qualification_mapping[index].status = 1;
      }
    });
    return qualification_mapping;
  };
  getOrganArray = (vm) => {
    let organ_id = vm.form.contact_organ_mapping;
    let { hospital_contact_id, contact_id } = vm.form;

    let { contact_organ_mapping } = vm.updateDoc;
    if (contact_organ_mapping == undefined) contact_organ_mapping = [];
    contact_organ_mapping = contact_organ_mapping.map((item) => {
      item.contact_organ_mapping_status = "4";
      return item;
    });
    organ_id.forEach((id) => {
      let organ = vm.organs.find((item) => {
        return item.value == id;
      });
      let organ_license_id = organ != null ? organ.organ_license_id : "";
      let doc = {
        hospital_id: vm.form.hospital_id,
        organ_id: id,
        hospital_contact_id,
        contact_organ_mapping_status: "",
        contact_organ_mapping_id: "",
        created_by: vm.created_by,
        organ_license_id,
        contact_id,
      };
      let index = -1;
      contact_organ_mapping.forEach((item, i) => {
        if (item.organ_id == id) index = i;
      });
      if (index == -1) {
        contact_organ_mapping.push(doc);
      } else {
        contact_organ_mapping[index].contact_organ_mapping_status = 1;
        contact_organ_mapping[index].contact_id = contact_id;
        contact_organ_mapping[index].organ_license_id = organ_license_id;
      }
    });
    return contact_organ_mapping;
  };

  uploadContact(file) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        var body = new FormData();
        body.append("file", file);
        let { data } = await hospitalService.uploadContact(body);
        let { status, msg, filePath_url } = data;
        if (status) {
          resolve(filePath_url);
        } else {
          reject({
            message: `Error while uploading image : ${msg}`,
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  uploadVideo(file) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        var body = new FormData();
        body.append("file", file);
        let { data } = await hospitalService.uploadVideo(body);
        let { status, msg, filePath_url } = data;
        if (status) {
          resolve(filePath_url);
        } else {
          reject({
            message: `Error while uploading image : ${msg}`,
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new Helper();
