<template>
  <b-card no-body class="overflow-hidden">
    <b-card-header
      @click="collapse = !collapse"
      class="p-3 bg-white h4 cursor-pointer d-flex"
      :class="collapse ? 'pb-0' : 'pb-3'"
    >
      <div>Confirmed Donor List</div>
      <div class="flex-grow-1"></div>
      <b-icon :icon="!collapse ? 'chevron-down' : 'chevron-up'"></b-icon>
    </b-card-header>
    <b-collapse v-model="collapse">
      <b-row class="mx-2 pt-3 pb-0">
        <b-col cols="12" md="4" class="mb-m-3">
          <autocomplete
            v-model="hospital_id"
            :items="hospitals"
            placeholder="Select Hospital"
            :multiple="false"
            :max="1"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-m-3">
          <date-range-picker
            v-model.trim="date_range"
            placeholder="Date Range (Created ON)"
            :clearable="true"
          ></date-range-picker>
        </b-col>
        <b-col cols="12" md="4">
          <b-row>
            <b-col cols="12" md="6" class="mb-m-3">
              <filter-select
                :items="statusList"
                v-model="status"
                width="100%"
                placeholder="All Status Except Terminated"
              ></filter-select>
            </b-col>
            <b-col cols="12" md="6">
              <filter-select
                width="100%"
                :items="masterHospitalTypes"
                v-model="hospital_type"
                placeholder="All Hospital Type"
              ></filter-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <table-view
        :fields="fields"
        :isBusy="isLoading"
        :items="filteredList"
        ref="table"
      >
        <template v-slot:name="{ item }">
          {{ item.first_name }} {{ item.last_name }}
        </template>
        <template v-slot:status="{ item }">
          <div :class="getBg(getStatus(item))">
            {{ getStatus(item) }}
          </div>
        </template>
        <template v-slot:action="{ item }">
          <div class="d-flex" v-if="isAccessAble(item)">
            <final-donor-list-menu
              :item="item"
              @fetch="$emit('fetch')"
              @changeStatus="changeStatus"
            ></final-donor-list-menu>
            <a :href="getAllocationLink(item)">
              <b-button
                class="mt-2"
                size="sm"
                icon
                variant="link"
                @click="$emit('click')"
              >
                <b-icon
                  variant="secondary"
                  icon="card-list"
                  class="font-size-16"
                ></b-icon>
              </b-button>
            </a>
            <icon-button
              @click="$refs.report.fetch(item)"
              icon="printer"
              v-if="isPrint(item)"
            ></icon-button>
            <icon-button
              v-if="isPrint(item)"
              @click="openOverallPrint(item)"
              icon="card-heading"
            ></icon-button>
          </div>
          <div v-else>
            <a :href="getAllocationLink(item)">
              <view-button></view-button>
            </a>
            <icon-button
              v-if="isPrint(item)"
              @click="$refs.report.fetch(item)"
              icon="printer"
            ></icon-button>
            <icon-button
              v-if="isPrint(item)"
              @click="openOverallPrint(item)"
              icon="card-heading"
            ></icon-button>
          </div>
        </template>
      </table-view>
      <organ-allocation-report ref="report"></organ-allocation-report>
    </b-collapse>
  </b-card>
</template>

<script>
import { encode } from "../../assets/js/common";
import { FINAL_DONOR_STATUS } from "../../assets/constants/donor.constants";
import TableView from "../table/TableView.vue";
import donorService from "../../assets/services/donor.service";
import FinalDonorListMenu from "./FinalDonorListMenu.vue";
import ViewButton from "../buttons/ViewButton.vue";
import menuConstants from "../../assets/constants/menu.constants";
import OrganAllocationReport from "../../pages/organAllocation/OrganAllocationReport.vue";
import IconButton from "../buttons/IconButton.vue";
import DateRangePicker from "../form/DateRangePicker.vue";
import moment from "moment";
import FilterSelect from "../form/FilterSelect.vue";
export default {
  components: {
    TableView,
    FinalDonorListMenu,
    ViewButton,
    OrganAllocationReport,
    IconButton,
    DateRangePicker,
    FilterSelect,
  },
  props: {
    hospitals: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
      hospital_id: "",
      hospital_type: null,
      date_range: [],
      collapse: true,
      status: null,
      statusList: FINAL_DONOR_STATUS,
    };
  },
  computed: {
    filteredList() {
      let { hospital_id, date_range, status, hospital_type } = this;
      let list = [...this.list];
      return list
        .filter((item) => {
          if (hospital_id) return item.hospital_id == hospital_id;
          return item;
        })
        .filter((i) => {
          if (hospital_type) return i.hospital_type == hospital_type;
          return true;
        })
        .filter((item) => {
          let date = moment(item.created_on, "DD/MM/YY HH:mm")
            .toDate()
            .getTime();
          if (date_range.length > 0) {
            let start = moment(date_range[0], "DD-MM-YYYY")
              .startOf("day")
              .toDate()
              .getTime();
            let end = moment(date_range[1], "DD-MM-YYYY")
              .endOf("day")
              .toDate()
              .getTime();
            return date >= start && date <= end;
          }
          return item;
        })
        .filter((item) => {
          if (status) return item.final_donor_status == status;
          return item.final_donor_status != 5;
        });
    },
    fields() {
      let doc = {
        sno: "S.No",
        created_on: "Date of Registration",
        donor_temp_registration_id: "Donor ID",
        donor_name: "Donor Name",
        group_desc: "Blood Group",
        hospital_name: "Hospital Name",
        organ_consented: "Organ Consented",
        status: "Status",
        caseofficer: "Case Officer",
        caseofficer_phoneno: "Case Officer Phone",
        // caseofficer_email: "Case Officer Email",
        donor_register_name: "Donor Case Officer",
        donor_register_phone_number: "Donor Case Officer Phone",
        // donor_register_email_id: "Donor Case Officer Email",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
  },
  watch: {
    collapse(val) {
      if (val) {
        this.$refs.table.resetScroll();
      }
    },
  },
  methods: {
    getBg(val) {
      switch (val) {
        case "Local Organ Requested":
          return "badge bg-warning font-size-13";
        case "Local Organ Chosen":
          return "badge bg-warning font-size-13";
        case "Case Completed":
          return "badge bg-success font-size-13";
        case "Case Officer Allocated":
          return "badge bg-info font-size-13";
        case "Case Officer Allocation Pending":
          return "badge bg-info font-size-13";
        case "Terminated":
          return "badge bg-danger font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
    isPrint(item) {
      return item.final_donor_status == 8;
    },
    isAccessAble(item) {
      let { isHospitalContact } = this;
      let { hospital_id } = this.user;
      if (!isHospitalContact) return true;
      return isHospitalContact && hospital_id == item.hospital_id;
    },
    isTerminateAble({ status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.FINAl_DONOR, 7);
      return hasAccess && status != 6 && status != 5;
    },
    openAllocation({ donor_temp_registration_id, final_donor_id }) {
      let id = encode(donor_temp_registration_id);
      final_donor_id = encode(final_donor_id);
      this.$router.push(
        `/organ-allocation?id=${id}&final_donor_id=${final_donor_id}`
      );
    },
    openOverallPrint({ donor_temp_registration_id, final_donor_id }) {
      let id = encode(donor_temp_registration_id);
      final_donor_id = encode(final_donor_id);
      this.$router.push({
        path: `/organ-allocation-overall-report?id=${id}&final_donor_id=${final_donor_id}`,
      }).href;
    },
    getAllocationLink({ donor_temp_registration_id, final_donor_id }) {
      let id = encode(donor_temp_registration_id);
      final_donor_id = encode(final_donor_id);
      return this.$router.resolve({
        path: `/organ-allocation?id=${id}&final_donor_id=${final_donor_id}`,
      }).href;
    },
    open({ donor_temp_registration_id }) {
      let id = encode(donor_temp_registration_id);
      this.$router.push(`/update-donor?id=${id}`);
    },
    getStatus({ final_donor_status }) {
      let item = FINAL_DONOR_STATUS.find((item) => {
        return item.value == final_donor_status;
      });
      return item != null ? item.name : "";
    },
    remove({ donor_temp_registration_id, status }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this donor?",
        onConfirm: () => {
          vm.changeStatus({
            donor_temp_registration_id,
            status: status == 1 ? 9 : 2,
            dml_indicator: "A",
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await donorService.completeDraft(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
