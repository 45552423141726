<template>
  <Expandable header="Declaration Attachments" class="mb-10">
    <div v-if="loading">
      <b-row class="mobile-table-header d-none d-md-flex px-2 py-2 mx-1">
        <b-col cols="12" md="6"> Name </b-col>
        <b-col cols="12" md="6"> File </b-col>
      </b-row>
      <b-row
        class="px-md-4 py-2 d-flex align-items-center"
        v-for="(item, i) in files"
        :key="i"
      >
        <b-col
          cols="12"
          md="6"
          v-html="formattedLabel(item.name, getRules(item))"
        >
        </b-col>
        <b-col cols="12" md="6">
          <div v-if="item.url" class="d-flex mt-2">
            <b-button @click="open(item)" variant="outline-primary" size="sm">
              View
            </b-button>
            <b-button
              class="mx-2"
              :disabled="isDisabled"
              @click="remove(item)"
              variant="outline-danger"
              size="sm"
            >
              Delete
            </b-button>
          </div>
          <file-input
            v-else
            :disabled="isDisabled"
            @input="input(i)"
            :rules="getRules(item)"
            v-model="item.file"
            accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          ></file-input>
        </b-col>
      </b-row>
    </div>
  </Expandable>
</template>

<script>
import {
  openFile,
  RECIEPIENT_DECLARATION_MASTER_TYPE,
} from "../../../assets/js/common";
import { FIELD } from "../../../assets/js/rules";
import hospitalCreateMixins from "../../../assets/mixins/hospital.create.mixins";
import recipientService from "../../../assets/services/recipient.service";
import Expandable from "../../Expandable.vue";
export default {
  props: {
    value: Array,
    filesList: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInternational: Boolean,
  },
  components: { Expandable },
  mixins: [hospitalCreateMixins],
  data() {
    return {
      rules: FIELD,
      files: [],
      loading: true,
    };
  },
  computed: {
    master() {
      return this.$store.getters.master;
    },
    uploads() {
      if (this.master.master_fileupload instanceof Array == false) return [];
      return this.master.master_fileupload.filter((item) => {
        return item.file_upload_type_id == RECIEPIENT_DECLARATION_MASTER_TYPE;
      });
    },
  },
  watch: {
    uploads() {
      this.init();
    },
  },
  methods: {
    formattedLabel(label, rules) {
      if (!rules) return label;
      if (rules.indexOf("required") != -1)
        return label + " <span style='color:red'>*</span>";
      return label;
    },
    getRules({ file_upload_id, rules }) {
      if (this.isInternational && file_upload_id == 109)
        return "file-image-pdf|file-size";
      return rules;
    },
    remove({ recipent_file_upload_mapping_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this attachment?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let { data } = await recipientService.removeFile(
              recipent_file_upload_mapping_id
            );
            let { status, msg } = data;
            if (status == false) {
              vm.$alert.show(msg);
            } else {
              vm.$emit("onFileRemoved", recipent_file_upload_mapping_id);
            }
            vm.$loader.hide();
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    open({ url }) {
      openFile(url);
    },
    init() {
      const vm = this;
      vm.files = vm.uploads.map((item) => {
        let { file_upload_name, file_upload_id, is_required } = item;
        let rules =
          is_required == 1
            ? "required|file-image-pdf|file-size"
            : "file-image-pdf|file-size";
        let file = vm.filesList.find((item) => {
          return item.file_upload_id == file_upload_id;
        });
        let url = null;
        let recipent_file_upload_mapping_id = "";
        if (file instanceof Object) {
          url = file.file_name;
          recipent_file_upload_mapping_id =
            file.recipent_file_upload_mapping_id;
        }
        return {
          name: file_upload_name,
          file_upload_id,
          file: null,
          url,
          recipent_file_upload_mapping_id,
          rules,
        };
      });
    },
    input() {
      const vm = this;
      this.$emit("input", vm.files);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
