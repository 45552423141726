<template>
  <div>
    <edit-button v-if="isUpdate" @click="dialog = true"></edit-button>
    <add-button v-else @click="dialog = true"></add-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      :title="title"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <input-field
              label="Department Name"
              rules="required"
              v-model="form.department_name"
            ></input-field>
          </b-col>
          <!-- <b-col cols="12">
            <input-field
              label="Department Classification"
              rules="required"
              v-model="form.department_classification"
            ></input-field>
          </b-col> -->
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import masterService from "../../../assets/services/master.service";
import AddButton from "../../buttons/AddButton.vue";
import EditButton from "../../buttons/EditButton.vue";
import InputField from "../../form/InputField.vue";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  components: { AddButton, InputField, EditButton },
  data() {
    return {
      dialog: false,
      form: {},
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init();
      }
    },
  },
  computed: {
    isUpdate() {
      return this.item != undefined;
    },
    title() {
      return this.isUpdate ? "Update Department" : "Add Department";
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await masterService.CUDDepartment(vm.form);
        let { status, msg } = data;
        vm.$emit("init");
        if (status) {
          vm.dialog = false;
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    init() {
      const vm = this;
      vm.form = {
        department_name: "",
        department_classification: "",
        status: 1,
        created_by: vm.created_by,
        department_id: 0,
        dml_indicator: "I",
      };
      if (vm.isUpdate) {
        delete vm.form.created_by;
        vm.form.dml_indicator = "U";
        Object.keys(vm.form).forEach((key) => {
          if (vm.item[key] != undefined) {
            vm.form[key] = vm.item[key];
          }
        });
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
