<template>
  <expandable header="Consent Details" class="mb-10">
    <b-row class="pb-0">
      <b-col cols="12" md="4">
        <yes-no
          label="Consent Given"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.conset_given"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="form.conset_given == 1">
        <radio-group
          v-model.trim="form.type_of_consent"
          label="Type of Consent"
          :options="consents"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        ></radio-group>
      </b-col>
      <b-col cols="12" md="4" v-if="isWritten">
        <file-input-group
          v-model.trim="form.type_of_consent_upload"
          label="Written Consent"
          :isDeletable="isAdmins"
          :disabled="isDisabled"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          @input="onInput"
          rules="required|file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          v-model.trim="form.contact_id"
          multiple
          label="Donor Relationship"
          :items="contactTypes"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        ></autocomplete>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.first_name"
          @input="onInput"
          :disabled="isDisabled"
          label="First Name"
          rules="required|max-50"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          @input="onInput"
          v-model.trim="form.last_name"
          label="Last Name"
          :disabled="isDisabled"
          rules="required|max-50"
        ></input-field>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <select-field
          @input="onInput"
          v-model.trim="form.gender"
          label="Gender"
          outlined
          :items="gender"
          rules="required|max-50"
        ></select-field>
      </b-col> -->
      <template v-if="isOwnHospital">
        <b-col cols="12" md="4">
          <input-field
            v-model.trim="form.email_id"
            label="Email"
            :disabled="isDisabled"
            @input="onInput"
            rules="email|max-100"
          ></input-field>
        </b-col>
        <b-col cols="12" md="4">
          <phone-field
            v-model.trim="form.phone_number_1"
            :defaultCountry="form.country_code_1"
            :disabled="isDisabled"
            @input="onInput"
            label="Phone Number 1"
            @onCountryCode="form.country_code_1 = $event"
            rules="required|integer"
          ></phone-field>
        </b-col>
        <b-col cols="12" md="4">
          <phone-field
            v-model.trim="form.phone_number_2"
            :defaultCountry="form.country_code_2"
            @input="onInput"
            label="Phone Number 2"
            :disabled="isDisabled"
            @onCountryCode="form.country_code_2 = $event"
          ></phone-field>
        </b-col>
      </template>
    </b-row>
  </expandable>
</template>

<script>
import { TYPE_OF_CONSENTS } from "../../../assets/constants/donor.constants";
import { GENDERS } from "../../../assets/constants/hospital.contants";
import { DONOR_MASTER_TYPE } from "../../../assets/js/common";
import Expandable from "../../Expandable.vue";
import Autocomplete from "../../form/Autocomplete.vue";
import FileInputGroup from "../../form/FileInputGroup.vue";
import PhoneField from "../../form/PhoneField.vue";
import RadioGroup from "../../form/RadioGroup.vue";
import YesNo from "../../form/YesNo.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
    donor: {},
  },
  components: {
    PhoneField,
    Expandable,
    YesNo,
    RadioGroup,
    FileInputGroup,
    Autocomplete,
  },
  data() {
    return {
      form: {},
      consents: TYPE_OF_CONSENTS,
    };
  },
  computed: {
    isOrganAllocation() {
      let { name } = this.$route;
      return name == "organ-allocation";
    },
    isWritten() {
      return this.form.conset_given == 1 && this.form.type_of_consent == 2;
    },
    gender() {
      return GENDERS;
    },
    contactTypes() {
      return this.masterContactTypes.filter((item) => {
        return item.contact_type == DONOR_MASTER_TYPE;
      });
    },
    master() {
      return this.$store.getters.master;
    },
    isOwnHospital() {
      console.log(this.donor);
      if (!this.isOrganAllocation) return true;
      let { hospital_id } = this.user;
      console.log("donor", hospital_id == this.donor.hospital_id);
      return this.isAdmins || hospital_id == this.donor.hospital_id;
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
