var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isPendingApprovel)?[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],attrs:{"variant":"primary"}},[_vm._v(" Drafts ")]),_c('b-collapse',{attrs:{"id":"collapse-2"}},[_c('table-view',{staticClass:"mt-4",attrs:{"items":_vm.drafts,"fields":_vm.fields(true),"title":"Drafts","isBusy":_vm.isDraftLoading},scopedSlots:_vm._u([{key:"sno",fn:function(ref){
var item = ref.item;
return [_c('internal-recipient-icon',{attrs:{"item":item,"value":item.sno}})]}},{key:"age",fn:function(ref){
var value = ref.value;
return [_c('div',{class:value > 60 ? 'badge bg-danger  font-size-13' : ''},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"payment_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getPaymentStatus(value))+" ")]}},{key:"transtan_status",fn:function(ref){
var value = ref.value;
return [_c('div',{class:_vm.getBg(_vm.getPatientStatus(value))},[_vm._v(" "+_vm._s(_vm.getPatientStatus(value))+" ")])]}},{key:"hospital_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getBeReadyStatus(value))+" ")]}},{key:"CM_insurance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCMInsuranceStatus(value))+" ")]}},{key:"gender",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getGender(value))+" ")]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('recipient-list-menu',{attrs:{"item":item},on:{"onChangeStatus":_vm.changeStatus}}),_c('view-button',{on:{"click":function($event){return _vm.openView(item)}}}),(_vm.isEditable(item))?_c('edit-button',{on:{"click":function($event){return _vm.open(item)}}}):_vm._e(),(_vm.isDeleteAble(item))?_c('delete-button',{on:{"click":function($event){return _vm.remove(item)}}}):_vm._e(),(_vm.isApprovable(item))?_c('approve-button',{on:{"click":function($event){return _vm.approve(item)}}}):_vm._e(),_c('print-icon-button',{on:{"click":function($event){return _vm.print(item)}}}),_c('download-icon-button',{on:{"click":function($event){return _vm.download(item)}}})],1)]}}],null,false,252517551)})],1)]:_vm._e(),_c('table-view',{ref:"table",staticClass:"mt-4",attrs:{"fields":_vm.fields(false),"isDynamic":!_vm.isPendingApprovel,"total":_vm.total,"isSearch":_vm.isPendingApprovel,"items":_vm.live,"isBusy":_vm.isLoading,"title":"Waiting List"},on:{"export":function($event){return _vm.$emit('export')},"onPageChange":function($event){return _vm.$emit('onPageChange', $event)}},scopedSlots:_vm._u([{key:"filter",fn:function(){return [(!_vm.isPendingApprovel)?_c('recipient-search',{on:{"select":function($event){return _vm.$emit('select', $event)}}}):_vm._e()]},proxy:true},{key:"sno",fn:function(ref){
var item = ref.item;
return [(item.patient_nationlity == 1)?_c('div',{staticClass:"badge bg-warning font-size-13"},[_vm._v(" "+_vm._s(item.sno)+" "),_c('i',{staticClass:"mdi mdi-airplane-takeoff"})]):_c('span',[_vm._v(" "+_vm._s(item.sno)+" ")])]}},{key:"age",fn:function(ref){
var value = ref.value;
return [_c('div',{class:value > 60 ? 'badge bg-danger  font-size-13' : ''},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"payment_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getPaymentStatus(value))+" ")]}},{key:"transtan_status",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{class:_vm.getBg(_vm.getPatientStatus(value))},[_vm._v(" "+_vm._s(_vm.getPatientStatus(value))+" ")]),(item.transtan_status == 11)?_c('timer',{staticClass:"ms-2",attrs:{"config":_vm.getTimer(item.status_update_on, 2)}}):_vm._e(),(item.transtan_status == 2)?_c('timer',{staticClass:"ms-2",attrs:{"config":_vm.getTimer(item.created_on, 1)}}):_vm._e(),(item.transtan_status == 9)?_c('timer',{staticClass:"ms-2",attrs:{"config":_vm.getTimer(item.transfer_date, 5)}}):_vm._e()],1)]}},{key:"hospital_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getBeReadyStatus(value))+" ")]}},{key:"CM_insurance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCMInsuranceStatus(value))+" ")]}},{key:"gender",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getGender(value))+" ")]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('recipient-list-menu',{attrs:{"item":item},on:{"fetch":function($event){return _vm.$emit('fetch')},"onChangeStatus":_vm.changeStatus}}),_c('view-button',{on:{"click":function($event){return _vm.openView(item)}}}),(_vm.isEditable(item))?_c('edit-button',{on:{"click":function($event){return _vm.open(item)}}}):_vm._e(),(_vm.isDeleteAble(item))?_c('recipient-delete-reason',{attrs:{"reasons":_vm.reasons,"item":item},on:{"fetch":function($event){return _vm.$emit('fetch')}}}):_vm._e(),(_vm.isUndoAble(item))?_c('undo-icon-button',{on:{"click":function($event){return _vm.undoRemove(item)}}}):_vm._e(),(_vm.isApprovable(item))?_c('approve-button',{on:{"click":function($event){return _vm.approve(item)}}}):_vm._e(),_c('print-icon-button',{on:{"click":function($event){return _vm.print(item)}}}),_c('download-icon-button',{on:{"click":function($event){return _vm.download(item)}}}),(_vm.isPayment(item))?_c('payment-icon-button',{on:{"click":function($event){return _vm.initPayment(item)}}}):_vm._e()],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }