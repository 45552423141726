<template>
  <b-modal title="Message" hide-footer size="sm" centered v-model="dialog">
    <p v-html="message"></p>
  </b-modal>
</template>

<script>
import AlertModal from "./index";
export default {
  data() {
    return {
      message: "",
      dialog: false,
    };
  },
  methods: {
    show(message) {
      const vm = this;
      vm.message = message;
      vm.dialog = true;
    },
  },
  beforeMount() {
    AlertModal.EventBus.$on("show", (params) => {
      this.show(params);
    });
    AlertModal.EventBus.$on("hide", () => {
      this.dialog = false;
    });
  },
};
</script>

<style></style>
