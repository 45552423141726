<template>
  <Expandable header="Recipient Aadhar Details" class="mb-10">
    <b-row class="pb-0">
      <b-col cols="12" md="4" class="pb-3">
        <img :src="photo" class="aadhar_image" @error="onError" />
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.aadhar_name"
          label="Aadhar Name"
          @input="input"
          :disabled="isDisabled"
          rules="max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.father_name"
          label="Father Name/Spouse"
          @input="input"
          :disabled="isDisabled"
          rules="max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.address_line_1"
          label="Address Line 1"
          @input="input"
          :disabled="isDisabled"
          rules="max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.address_line_2"
          label="Address Line 2"
          :disabled="isDisabled"
          rules="max-100"
          @input="input"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          :disabled="isDisabled"
          v-model.trim="form.town_village"
          label="Town Village"
          @input="input"
          rules="max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.landmark"
          :disabled="isDisabled"
          label="Landmark"
          @input="input"
          rules="max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          v-model.trim="form.country"
          :disabled="isDisabled"
          @input="onCountryChange"
          label="Country"
          :items="countries"
          rules=""
        ></autocomplete>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          v-model.trim="form.state"
          label="State"
          @input="onStateChange"
          :items="states"
          :disabled="isDisabled"
          rules=""
        ></autocomplete>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          v-model.trim="form.city"
          :items="citys"
          @input="input"
          :disabled="isDisabled"
          label="City"
          rules=""
        ></autocomplete>
      </b-col>

      <b-col cols="12" md="4">
        <input-field
          @input="input"
          v-model.trim="form.pincode"
          label="Pincode"
          :disabled="isDisabled"
          type="number"
          invalid="Enter a valid pincode"
          rules="zipcode|integer"
        ></input-field>
      </b-col>
      <b-col v-if="false">
        <yes-no
          :disabled="isDisabled"
          v-model="form.is_permanent_address"
          label="Whether this is permanent address?"
        ></yes-no>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import {
  FIELD,
  getMaxNotRules,
  getMaxRules,
  PINCODE,
} from "../../../assets/js/rules";
import { HOSPITAL_TYPES } from "../../../assets/constants/hospital.contants";
import masterService from "../../../assets/services/master.service";
import Expandable from "../../Expandable.vue";
import YesNo from "../../form/YesNo.vue";
import event from "../../../assets/js/event";
import { BASE_URL } from "../../../assets/js/common";
export default {
  components: { Expandable, YesNo },
  props: {
    value: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: FIELD,
      pincode: PINCODE,
      form: {},
      citys: [],
      states: [],
    };
  },
  computed: {
    photo() {
      let photo = this.form.aadhar_photo;
      if (photo?.indexOf("contact_person") != -1) {
        return `${BASE_URL}${photo}`;
      }
      return photo;
    },
    types() {
      return HOSPITAL_TYPES;
    },
    master() {
      return this.$store.getters.master;
    },
    countries() {
      if (this.master.country instanceof Array == false) return [];
      return this.master.country.map((item) => {
        return { value: item.id, name: item.name };
      });
    },
    years() {
      let year = 1899;
      let list = [];
      while (year < new Date().getFullYear()) {
        year++;
        list.push(year);
      }
      return list.reverse();
    },
  },
  methods: {
    onError(e) {
      e.target.src = require("../../../assets/images/account.png");
      console.log(e);
    },
    getMaxRules(max) {
      return getMaxRules(max);
    },
    getMaxNotRules(max) {
      return getMaxNotRules(max);
    },
    async onCountryChange(copy) {
      const vm = this;
      if (copy == undefined) {
        vm.form.state = "";
        vm.form.city = "";
      }

      vm.states = [];
      vm.citys = [];
      vm.$emit("input", vm.form);
      if (!vm.form.country) return;
      try {
        vm.$loader.show();
        let { data } = await masterService.getStateCity({
          country_id: vm.form.country,
        });
        let { list, status, msg } = data;
        if (status) {
          vm.states = list.map((item) => {
            return { name: item.name, value: item.id };
          });
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
      }
    },
    async onStateChange(copy) {
      const vm = this;
      if (copy == undefined) {
        vm.form.city = "";
      }
      vm.citys = [];
      if (!vm.form.state) return;
      vm.$emit("input", vm.form);
      try {
        vm.$loader.show();
        let { data } = await masterService.getStateCity({
          state_id: vm.form.state,
        });
        let { list, status } = data;
        if (status) {
          vm.citys = list.map((item) => {
            return { name: item.name, value: item.id };
          });
        } else {
          // vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
      }
    },
    input() {
      this.$emit("input", this.form);
    },
    getState(state) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let state_doc = vm.states.find((item) => {
            return (
              item.name.toLowerCase().split(" ").join() ==
              state.toLowerCase().split(" ").join()
            );
          });
          let state_id = state_doc != null ? state_doc.value : "";
          if (state_id == "") {
            let states = (await masterService.createState(state)).data.list;
            console.log(states);
            if (states instanceof Array) {
              vm.states = states.map((item) => {
                item.value = item.id;
                return item;
              });
              state_id = states.find((item) => {
                return item.name == state;
              }).id;
            }
          }
          resolve(state_id);
        } catch (error) {
          reject(error);
        }
      });
    },
    getCity({ city, state_id }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let citys = (
            await masterService.getStateCity({
              state_id,
            })
          ).data.list;
          let city_id = "";
          let city_doc = citys.find((item) => {
            return (
              item.name.toLowerCase().split(" ").join() ==
              city.toLowerCase().split(" ").join()
            );
          });
          vm.citys = citys.map((item) => {
            item.value = item.id;
            return item;
          });
          console.log(vm.citys);
          console.log(citys);
          city_id = city_doc != null ? city_doc.id : "";
          if (city_id == "") {
            let cities = (await masterService.createCity({ city, state_id }))
              .data.list;
            console.log(cities);
            if (cities instanceof Array) {
              vm.citys = cities.map((item) => {
                item.value = item.id;
                return item;
              });
              city_id = cities.find((item) => {
                return item.name == city;
              }).id;
            }
          }
          resolve(city_id);
          console.log("City id", city_id);
        } catch (error) {
          reject(error);
        }
      });
    },
    async initAddress({ state, city, address }) {
      const vm = this;
      let state_id = "";
      let city_id = "";
      try {
        vm.$loader.show();
        state_id = await vm.getState(state);
        city_id = await vm.getCity({ city, state_id });
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
      vm.form.state = state_id;
      vm.form.city = city_id;
      Object.keys(address).forEach((key) => {
        vm.form[key] = address[key];
      });
    },
  },
  beforeMount() {
    this.form = this.value;
    this.onCountryChange(true);
    this.onStateChange(true);
    event.$off("initAadhar");
  },
  mounted() {
    event.$on("initAadhar", this.initAddress);
    this.input();
  },
};
</script>

<style>
.aadhar_image {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
</style>
