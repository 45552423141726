<template>
  <b-progress
    :value="100"
    v-if="value"
    variant="info"
    striped
    class="mt-3"
    :animated="true"
  ></b-progress>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
};
</script>

<style></style>
