import {
  getDate,
  getDocObject,
  isArray,
  isObject,
} from "../../assets/js/common";
import hospitalService from "../../assets/services/hospital.service";
import recipientPrint from "../../assets/print/recipient.print";

import recipientService from "../../assets/services/recipient.service";
class ReceipienHelper {
  getDoc = (vm) => {
    let doc = {};
    if (!vm.heart.recipent_heart_id) delete vm.heart.recipent_heart_id;
    if (!vm.liver.recipent_liver_id) delete vm.liver.recipent_liver_id;
    if (!vm.kidney.recipent_kidney_id) delete vm.kidney.recipent_kidney_id;
    if (!vm.lung.recipent_lungs_id) delete vm.lung.recipent_lungs_id;
    doc = getDocObject(vm.heart, doc);
    doc = getDocObject(vm.lung, doc);
    doc = getDocObject(vm.kidney, doc);
    doc = getDocObject(vm.liver, doc);
    doc = getDocObject(vm.virology_status, doc);
    doc = getDocObject(vm.details, doc);
    doc = getDocObject(vm.consultant, doc);
    doc = getDocObject(vm.recipient_medical_details, doc);
    if (vm.isCreate) {
      delete vm.address.recipient_address_mapping_id;
      delete vm.address.recipient_registration_id;
      delete vm.aadhar_address.recipient_address_mapping_id;
      delete vm.aadhar_address.recipient_registration_id;
    }
    if (doc.history_of_complications) {
      doc.history_of_complications = doc.history_of_complications.toString();
    }
    vm.address.aadhar_flag = 0;
    vm.aadhar_address.aadhar_flag = 1;
    let addressList = [];
    addressList.push(vm.address);
    if (vm.details.aadhar_flag == 1) {
      addressList.push(vm.aadhar_address);
    }
    doc.patient_register_date = getDate();
    doc.aadhar_name = vm.aadhar_address.aadhar_name;
    doc.father_name = vm.aadhar_address.father_name;
    doc.aadhar_photo = vm.aadhar_address.aadhar_photo;
    doc.recipient_vaccination_mapping = vm.vaccination_status;
    doc.recipient_address_mapping = addressList;
    doc.recipient_organ_mapping = this.getOrganArray(vm);
    doc.organ_type = vm.organ_requested.organ_id.length > 1 ? 2 : 1;
    doc.multi_organ = vm.organ_requested.organ_id
      .sort(function (a, b) {
        return a - b;
      })
      .toString();
    doc.recipient_contact_mapping = vm.contacts;
    doc.payment_status = doc.payment_reference_no ? 1 : 0;
    Object.keys(doc).forEach((key) => {
      if (doc[key] === "") {
        doc[key] = null;
      }
    });
    return doc;
  };
  getOrganArray = (vm) => {
    let { organ_id } = vm.organ_requested;
    let { recipient_organ_mapping } = vm.updateDoc;
    if (recipient_organ_mapping == undefined) recipient_organ_mapping = [];
    recipient_organ_mapping = recipient_organ_mapping.map((item) => {
      item.dml_indicator = "U";
      item.status = "4";
      return item;
    });
    organ_id.forEach((id) => {
      let doc = {
        hospital_id: vm.details.hospital_id,
        organ_id: id,
        created_by: vm.created_by,
        status: 1,
        dml_indicator: "I",
      };
      let index = -1;
      recipient_organ_mapping.forEach((item, i) => {
        if (item.organ_id == id) index = i;
      });
      if (index == -1) {
        recipient_organ_mapping.push(doc);
      } else {
        recipient_organ_mapping[index].status = 1;
      }
    });
    return recipient_organ_mapping;
  };
  initUpdate(data, vm) {
    let {
      recipient_list,
      recipient_address_mapping,
      recipient_vaccination_mapping,
      recipient_contact_mapping,
      recipient_organ_mapping,
      file_uploaded_list,
    } = data;
    let aadhar_flag = 0;
    if (isArray(recipient_list)) {
      let doc = recipient_list[0];
      vm.payment_flag = doc.payment_flag;
      vm.organ_type = recipient_organ_mapping.length > 1 ? 2 : 1;
      this.initDoc(vm, doc, "heart");
      this.initDoc(vm, doc, "lung");
      this.initDoc(vm, doc, "kidney");
      this.initDoc(vm, doc, "liver");
      this.initDoc(vm, doc, "virology_status");
      this.initDoc(vm, doc, "details");
      this.initDoc(vm, doc, "consultant");
      this.initDoc(vm, doc, "recipient_medical_details");
      this.initDoc(vm, doc, "aadhar_address");
      aadhar_flag = doc.aadhar_flag;
      let aadhar_number = doc.aadhar_number;
      if (aadhar_flag == 1 && aadhar_number?.toString().length == 12) {
        vm.isAadharVerified = true;
      }
      vm.created_on = doc.created_on;
      vm.hospitalType = doc.hospital_type;
      vm.details.dml_indicator = "U";
      vm.doc = doc;
    }
    let { history_of_complications } = vm.liver;
    if (history_of_complications) {
      vm.liver.history_of_complications = history_of_complications
        ?.toString()
        .split(",");
    }
    if (isArray(recipient_address_mapping)) {
      let aadhar_address = recipient_address_mapping.find((item) => {
        return item.aadhar_flag == 1;
      });
      let addtional_address = recipient_address_mapping.find((item) => {
        return item.aadhar_flag == 0;
      });
      if (isObject(aadhar_address)) {
        this.initDoc(vm, aadhar_address, "aadhar_address");
        vm.aadhar_address.dml_indicator = "U";
        vm.aadhar_address.country = vm.aadhar_address.country?.toString();
        vm.aadhar_address.state = vm.aadhar_address.state?.toString();
        vm.aadhar_address.city = vm.aadhar_address.city?.toString();
      }
      if (isObject(addtional_address)) {
        this.initDoc(vm, addtional_address, "address");
        vm.address.dml_indicator = "U";
        if (vm.address.country)
          vm.address.country = vm.address.country?.toString();
        if (vm.address.state) vm.address.state = vm.address.state?.toString();
        if (vm.address.city) vm.address.city = vm.address.city?.toString();
      }
    }
    if (isArray(recipient_vaccination_mapping)) {
      vm.vaccination_status = recipient_vaccination_mapping
        .map((item) => {
          item.dml_indicator = "U";
          return item;
        })
        .filter((item) => {
          return item.status != 4;
        });
    }
    if (isArray(recipient_contact_mapping)) {
      vm.contacts = recipient_contact_mapping.map((item) => {
        item.dml_indicator = "U";
        return item;
      });
    }
    if (isArray(recipient_organ_mapping)) {
      let organs = [];
      recipient_organ_mapping.forEach((item) => {
        if (item.status == 1) organs.push(item.organ_id?.toString());
      });
      vm.organ_requested.organ_id = organs;
    }
    if (isArray(file_uploaded_list)) {
      vm.filesList = file_uploaded_list;
    }
    vm.aadhar_address.is_permanent_address = aadhar_flag;
    vm.isLoading = false;
    setTimeout(() => {
      this.initPrint(vm);
    }, 200);
    console.log("aadhar", vm.isLoading);
  }
  initPrint(vm) {
    if (vm.isView) {
      recipientPrint.print(vm, "preview");
    }
    if (vm.isPrint) {
      recipientPrint.print(vm, "print");
    }
    if (vm.isDownload) {
      recipientPrint.print(vm, "openDownload");
    }
  }
  initDoc(vm, doc, key) {
    Object.keys(vm[key]).forEach((i) => {
      if (doc[i] !== undefined) vm[key][i] = doc[i];
    });
  }
  async getRecipient(vm, id) {
    try {
      vm.$loader.show();
      vm.details.recipient_registration_id = id;
      let { status, msg, data } = await recipientService.get(id);
      if (status) {
        vm.updateDoc = data;
        this.initUpdate(data, vm);
      } else {
        vm.$alert.show(msg);
        vm.$router.replace("/manage-recipient");
      }
      vm.$loader.hide();
    } catch (error) {
      console.log(error.message);
      vm.$loader.hide();
      vm.$alert.show(error.message);
    }
  }
  upload(doc, id, vm) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        var body = new FormData();
        let { file, file_upload_id } = doc;
        body.append("file_upload_id", file_upload_id);
        body.append("recipient_registration_id", id);
        body.append("created_by", vm.created_by);
        body.append("file", file);
        let { data } = await recipientService.upload(body);
        let { status, msg } = data;
        let state = vm.isUpdate ? "updated" : "created";
        if (status) {
          resolve();
        } else {
          reject({
            message: `Recipient ${state} successfully. <br/>Error while uploading document : ${msg}`,
          });
          vm.$router.push("/manage-recipient");
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  uploadImage(file) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        var body = new FormData();
        body.append("file", file);
        let { data } = await hospitalService.uploadContact(body);
        let { status, msg, filePath_url } = data;
        if (status) {
          resolve(filePath_url);
        } else {
          reject({
            message: `Error while uploading image : ${msg}`,
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new ReceipienHelper();
