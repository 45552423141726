<template>
  <div class="border-bottom py-2">
    <div class="d-flex align-items-center">
      <h5 class="mb-0 flex-grow-1 text-capitalize">{{ item.hospital_name }}</h5>
      <b-dropdown variant="link" no-caret menu-class="mobile-menu">
        <template #button-content>
          <b-icon icon="three-dots" class="font-size-16"></b-icon>
        </template>
        <contacts :hospital="item"></contacts>
        <licenses :hospital="item"></licenses>
        <organs :hospital="item"></organs>
        <b-dropdown-item-button @click="$emit('open')">
          {{ isView ? "View" : "Edit" }}
        </b-dropdown-item-button>
        <b-dropdown-item-button v-if="isDeleteAble" @click="$emit('remove')"
          >Delete</b-dropdown-item-button
        >
        <b-dropdown-item-button v-if="isApprovable" @click="$emit('approve')"
          >Approve</b-dropdown-item-button
        >
        <b-dropdown-item-button v-if="isPayment" @click="$emit('payment')"
          >Pay Now</b-dropdown-item-button
        >
      </b-dropdown>
    </div>
    <p class="mb-0 text-capitalize">
      {{ item.type }}
      <span v-if="item.zone"> / {{ item.zone }}</span>
    </p>
    <div class="d-flex">
      {{ item.hospital_registration_no || "-" }}
      <div class="flex-grow-1"></div>
    </div>
    <div class="d-flex align-center">
      <p class="mb-0 flex-grow-1 font-size-11">{{ item.expiry_date }}</p>
      <div :class="getBg(item)">
        {{ getStatus(item) }}
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS } from "../../assets/constants/hospital.contants";
import Contacts from "./list/Contacts.vue";
import Licenses from "./list/Licenses.vue";
import Organs from "./list/Organs.vue";

export default {
  props: {
    item: {},
    isView: {},
    isPayment: {},
    isApprovable: {},
    isDeleteAble: {},
  },
  components: { Contacts, Licenses, Organs },
  methods: {
    getStatus({ status }) {
      return STATUS[status] || "";
    },
    getBg(item) {
      let val = this.getStatus(item);
      switch (val) {
        case "Active":
          return "badge bg-success font-size-13";
        case "Pending Approval":
          return "badge bg-warning font-size-13";
        case "Inactive":
          return "badge bg-danger font-size-13";
        case "Deleted":
          return "badge bg-danger font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
  },
};
</script>

<style>
.mobile-menu {
  right: 0px !important;
  left: unset !important;
}
</style>
