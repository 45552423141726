<template>
  <div>
    <TNOSOrganFilter
      v-model="organs"
      @onOrganChange="onOrganChange"
      @input="fetch({})"
    ></TNOSOrganFilter>
    <TNOSStatus :status="res"></TNOSStatus>
    <TNOSFilter
      v-model="filter"
      :title="title"
      @onSeachUid="searchUID"
      ref="filter"
      @hospitals="hospitals = $event"
      @fetch="fetch({})"
      @select="onRecipientSelect"
    ></TNOSFilter>
    <TNOSList
      :title="title"
      :total="totalRows"
      ref="list"
      @export="exportCSV"
      @onPageChange="onPageChange"
      :isLoading="isLoading"
      :list="list"
      @fetch="fetch({})"
    ></TNOSList>
    <JsonCSV
      :name="`Transtan Waiting list.csv`"
      v-show="false"
      ref="csv"
      :data="json"
    ></JsonCSV>
  </div>
</template>

<script>
import { formatDate } from "../../assets/js/common";
import recipientService from "../../assets/services/recipient.service";
import TNOSOrganFilter from "../../components/waitingList/tnosWaitingList/TNOSOrganFilter.vue";
import TNOSStatus from "../../components/waitingList/tnosWaitingList/TNOSStatus.vue";
import TNOSFilter from "../../components/waitingList/tnosWaitingList/TNOSWaitingFilter.vue";
import TNOSList from "../../components/waitingList/tnosWaitingList/TNOSWaitingTable.vue";
import JsonCSV from "vue-json-csv";
export default {
  components: { TNOSFilter, TNOSList, TNOSStatus, TNOSOrganFilter, JsonCSV },
  data() {
    return {
      isLoading: false,
      filter: {},
      res: {},
      totalRows: 0,
      title: "Waiting List",
      organs: [],
      json: [],
      list: [],
      hospitals: [],
    };
  },
  computed: {
    isHeartLungs() {
      return this.title.endsWith("Heart & lungs");
    },
    doc() {
      const vm = this;
      let doc = {};
      Object.keys(vm.filter).forEach((key) => {
        doc[key] = vm.filter[key];
      });
      let organs = [...vm.organs];
      if (vm.isHeartLungs && vm.filter.heart) {
        organs = vm.filter.heart.split(",").map((organ_id) => {
          return { organ_id };
        });
        doc.filter_type = 1;
        doc.multi_organ = organs
          .map((item) => {
            return item.organ_id;
          })
          .sort(function (a, b) {
            return a - b;
          })
          .toString();
      }
      if (vm.isHeartLungs) doc.transtan_status = "3";
      if (!vm.isHeartLungs && vm.organs.length > 0) {
        doc.filter_type = 1;
        doc.multi_organ = vm.organs
          .map((item) => {
            return item.organ_id;
          })
          .sort(function (a, b) {
            return a - b;
          })
          .toString();
      }
      doc.organ = organs;
      return doc;
    },
  },
  methods: {
    onOrganChange(name) {
      const vm = this;
      vm.title = "Waiting List - " + name;
      vm.$refs.filter.initHeartLung();
      let status = "all";
      if (vm.isHeartLungs) status = "3";
      vm.$refs.filter.form.transtan_status = status;
    },
    onPageChange(event) {
      this.fetch(event);
    },
    onRecipientSelect(event) {
      const vm = this;
      if (event.id) {
        vm.list = [event];
        vm.totalRows = vm.list.length;
      }
    },
    async searchUID(uid) {
      const vm = this;
      if (!uid) return;
      try {
        vm.list = [];
        vm.isLoading = true;
        let { hospital_id } = vm.$store.getters.user;
        let doc = {
          multi_organ: uid,
        };
        if (vm.isHospitalContact) {
          doc.hospital_id = hospital_id;
        }
        let { data } = await recipientService.searchUid(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          console.log(item.created_on);
          item.created_on = formatDate(item.created_on);
          return item;
        });
        vm.totalRows = list.length;
        vm.isLoading = false;
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
    async exportCSV() {
      const vm = this;
      try {
        vm.$loader.show();
        let doc = { ...vm.doc };
        doc.Limit_Start = 0;
        doc.Limit_End = vm.totalRows;
        let { data } = await recipientService.getTNOSWaitingList(doc);
        let { status, msg, list } = data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        list = list.map((item) => {
          item.date = formatDate(item.created_on);
          item.payment_status = vm.getPaymentStatus(item.payment_status);
          item.transtan_status = vm.getPatientStatus(item.transtan_status);
          item.hospital_status = vm.getBeReadyStatus(item.hospital_status);
          item.CM_insurance = vm.getCMInsuranceStatus(item.CM_insurance);
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
        let fields = vm.$refs.list.fields;
        vm.json = list.map((item, i) => {
          let doc = {};
          fields.forEach((field) => {
            if (field.key != "action") {
              doc[field.label] = item[field.key];
            }
          });
          doc["S.No"] = i + 1;
          return doc;
        });
        setTimeout(() => {
          vm.$refs.csv.$el.click();
        }, 500);
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    async fetch({ size, page }) {
      const vm = this;
      size = size || 50;
      page = page || 0;
      if (page) page = page - 1;
      if (page == 0) {
        vm.$refs.list.$refs.table.currentPage = 1;
      }
      try {
        let doc = { ...vm.doc };
        vm.isLoading = true;
        vm.list = [];
        doc.Limit_Start = size * page;
        doc.Limit_End = size;
        let { data } = await recipientService.getTNOSWaitingList(doc);
        let { status, msg, list, Total_row_count } = data;
        vm.res = data;
        vm.isLoading = false;
        vm.totalRows = Total_row_count;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetch({});
  },
};
</script>

<style></style>
