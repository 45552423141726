<template>
  <div>
    <add-button v-if="!item.master_vaccination_id" @click="dialog = true">
    </add-button>
    <edit-button @click="dialog = true" v-else></edit-button>
    <b-modal v-model="dialog" :title="title" hide-footer size="sm" centered>
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <select-field
              v-model.trim="form.master_vaccination_id"
              label="Vaccination Name"
              :items="masterVaccinations"
              rules="required"
            ></select-field>
          </b-col>
          <b-col cols="12">
            <date-picker
              v-model.trim="form.vaccination_date"
              label="Vaccination Date"
              rules="required"
              :max="new Date()"
            ></date-picker>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import AddButton from "../../../buttons/AddButton.vue";
import EditButton from "../../../buttons/EditButton.vue";
import DatePicker from "../../../form/DatePicker.vue";
import SelectField from "../../../form/SelectField.vue";
export default {
  props: {
    list: Array,
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: null,
    },
  },
  components: { DatePicker, AddButton, EditButton, SelectField },
  data() {
    return {
      dialog: false,
      isValid: false,
      form: {},
      loading: true,
    };
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.init();
      }
    },
  },
  computed: {
    title() {
      return this.item.first_name
        ? "Update Vaccination Status"
        : "Add Vaccination Status";
    },
  },
  methods: {
    reset() {
      this.form = {
        recipient_vaccination_mapping_id: "",
        recipient_registration_id: "",
        master_vaccination_id: "",
        vaccination_date: "",
        status: 1,
        created_by: this.created_by,
        dml_indicator: "I",
      };
    },
    init() {
      const vm = this;
      vm.reset();
      Object.keys(vm.form).forEach((key) => {
        if (vm.item[key] !== undefined) {
          vm.$set(vm.form, key, vm.item[key]);
        }
      });
    },
    async submit() {
      const vm = this;
      let doc = {};
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      let isExits = false;
      vm.list.forEach((item, i) => {
        if (
          item.status == 1 &&
          i != vm.index &&
          item.master_vaccination_id == vm.form.master_vaccination_id
        ) {
          isExits = true;
        }
      });
      if (isExits) {
        vm.$alert.show(
          "Another vaccination status already exists with the same <b>Vaccination</b>. Please enter a unique vaccination."
        );
        return;
      }
      if (vm.item.master_vaccination_id) {
        console.log("welcome");
        vm.$emit("onUpdate", { doc, index: vm.index });
      } else {
        vm.$emit("onCreate", doc);
      }
      vm.dialog = false;
    },
  },
};
</script>

<style></style>
