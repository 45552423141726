export const DONOR_PLEDGE_ORGANS = [
  {
    text: "All Organs",
    value: "1",
  },
  {
    text: "Corneas (eyes)",
    value: "2",
  },
  {
    text: "Liver",
    value: "3",
  },
  {
    text: "Heart",
    value: "4",
  },
  {
    text: "Lungs",
    value: "5",
  },
  {
    text: "Skin",
    value: "6",
  },
  {
    text: "Kidney",
    value: "7",
  },
  {
    text: "Pancreas",
    value: "8",
  },
  {
    text: "Other",
    value: "9",
  },
];

export const DONOR_PLEDGE_DISTRICT = [
  { name: " Ariyalur", value: "Ariyalur" },
  { name: " Karur", value: "Karur" },
  { name: " Nagapattinam", value: "Nagapattinam" },
  { name: " Perambalur", value: "Perambalur" },
  { name: " Pudukkottai", value: "Pudukkottai" },
  { name: " Thanjavur", value: "Thanjavur" },
  { name: " Tiruchirappalli", value: "Tiruchirappalli" },
  { name: " Tiruvarur", value: "Tiruvarur" },
  { name: " Dharmapuri", value: "Dharmapuri" },
  { name: " Coimbatore", value: "Coimbatore" },
  { name: " Erode", value: "Erode" },
  { name: " Krishnagiri", value: "Krishnagiri" },
  { name: " Namakkal", value: "Namakkal" },
  { name: " The Nilgiris", value: "TheNilgiris" },
  { name: " Salem", value: "Salem" },
  { name: " Tiruppur", value: "Tiruppur" },
  { name: " Dindigul", value: "Dindigul" },
  { name: " Kanyakumari", value: "Kanyakumari" },
  { name: " Madurai", value: "Madurai" },
  { name: " Ramanathapuram", value: "Ramanathapuram" },
  { name: " Sivaganga", value: "Sivaganga" },
  { name: " Theni", value: "Theni" },
  { name: " Thoothukudi", value: "Thoothukudi" },
  { name: " Tirunelveli", value: "Tirunelveli" },
  { name: " Virudhunagar", value: "Virudhunagar" },
  { name: " Chennai", value: "Chennai" },
  { name: " Cuddalore", value: "Cuddalore" },
  { name: " Kanchipuram", value: "Kanchipuram" },
  { name: " Tiruvallur", value: "Tiruvallur" },
  { name: " Tiruvannamalai", value: "Tiruvannamalai" },
  { name: " Vellore", value: "Vellore" },
  { name: " Viluppuram", value: "Viluppuram" },
];
