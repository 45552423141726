import http from "../js/axios";
class HospitalSerive {
  create(data) {
    return http.post("/donor_registration/donor_registration", data);
  }
  list(data) {
    return http.post("/donor_registration/donor_list", data);
  }
  finalDonorList(data) {
    return http.post("/donor_registration/final_donor_list", data);
  }
  get(id) {
    return http.post("/donor_registration/donor_list", {
      dml_indicator: "E",
      donor_temp_registration_id: id,
    });
  }
  updateStatus(data) {
    return http.post("/donor_registration/donor_status", data);
  }

  consultatantList(data) {
    return http.post("/donor_registration/consultant_list", data);
  }

  upload(data) {
    return http.post("/ByteArrayAPI/donor_files_upload", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  removeFile(id) {
    return http.post("/donor_registration/donor_files_upload_delete", {
      status: "4",
      donor_investigations_fileupload_mapping_id: id,
    });
  }
  completeDraft(doc) {
    return http.post("/donor_registration/final_donor", doc);
  }
  getCaseOfficer() {
    return http.post("/donor_registration/case_officer_list", {
      dml_indicator: "CS",
    });
  }
  updateFinalDonorStatus(data) {
    return http.post("/donor_registration/final_donor_status_update", data);
  }
  updateOTStatus(data) {
    return http.post("/donor_registration/donor_ot_status", data);
  }
  getAllocatedOrgans(data) {
    return http.post("/donor_registration/organ_allocation_list", data);
  }
  organAllocation(data) {
    return http.post("/donor_registration/organ_allocation", data);
  }
  generateWL(data) {
    return http.post(
      "/donor_registration/recepient_organ_allocation_proc",
      data
    );
  }
  getAllocatedWL(data) {
    return http.post(
      "/donor_registration/recepient_organ_allocation_proc_list",
      data
    );
  }
  callNow(data) {
    return http.post("/donor_registration/hospital_calling_table", data);
  }
  fetchAllocationContacts(data) {
    return http.post("/donor_registration/hospital_caller_detail", data);
  }
  organAllocationWaitingListStatusUpdate(data) {
    return http.post(
      "/donor_registration/recepient_organ_allocation_proc_allocation_status",
      data
    );
  }
  createLogistics(data) {
    return http.post("/donor_registration/logistics_table", data);
  }
  updateLogisticsStatus(data) {
    return http.post("/donor_registration/logistics_status_update", data);
  }
  logisticsList(data) {
    return http.post("/donor_registration/logistics_table_list", data);
  }
  reAllocate(data) {
    return http.post("/donor_registration/reallocate_recepient", data);
  }
  reAllocateInsert(data) {
    return http.post("/donor_registration/reallocate_recepient_insert", data);
  }
  getRota(data) {
    return http.post("/donor_registration/rota_filter", data);
  }
  organInspection(data) {
    return http.post("/donor_registration/organ_inspection", data);
  }
  organInspectionList(data) {
    return http.post("/donor_registration/organ_inspection_list", data);
  }
  retrievalDetails(data) {
    return http.post("/donor_registration/retrieval_details", data);
  }
  retrievalDetailsList(data) {
    return http.post("/donor_registration/retrieval_details_list", data);
  }
  recipientSurgeryDetail(data) {
    return http.post("/donor_registration/recipient_surgery_detail", data);
  }
  recipientSurgeryDetailList(data) {
    return http.post("/donor_registration/recipient_surgery_detail_list", data);
  }
  selectALF(doc) {
    return http.post("/donor_registration/alf_generate_insert", doc);
  }
  allocationStatusUpdate(doc) {
    return http.post("/donor_registration/organ_allocation_status_update", doc);
  }
  allocateToOtherState(doc) {
    return http.post("/donor_registration/organ_allocation_other_state", doc);
  }
  pledgeOrgan(doc) {
    return http.post("/DonorPledge/donor_pledge_details", doc);
  }
  uploadPledgeDonorImage(doc) {
    return http.post("/ByteArrayAPI/UploadDonorPhotoFiles", doc, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  organAllocationTissue(doc) {
    return http.post("/donor_registration/organ_allocation_tissue_bank", doc);
  }
  getAllocationTissue(doc) {
    return http.post("/donor_registration/organ_allocation_tissue_bank", doc);
  }
  updateCrossClamp(doc) {
    return http.post("/donor_registration/crossclamp", doc);
  }
  uploadChatFile(data) {
    return http.post("/ByteArrayAPI/Uploadorgan_chat_photo", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  organAllocationReport(data) {
    return http.post("/donor_registration/organ_allocation_report", data);
  }
  donorIdentification(data) {
    return http.post("/donor_registration/Donor_Identification", data);
  }
}
export default new HospitalSerive();
