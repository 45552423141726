<template>
  <div class="ms-sm-3 mb-sm-0 mb-3">
    <b-button style="width: 100%" @click="dialog = true"
      >Reassign list</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      hide-footer
      scrollable
      title="Reassign List"
      size="xl"
      centered
    >
      <collapse-filter>
        <form-wrapper @submit="fetch">
          <b-row class="mt-2">
            <template>
              <b-col cols="12" md="3">
                <select-field
                  v-model.trim="form.hospital_type"
                  label="Hospital Type"
                  :items="getSelect(masterHospitalTypes)"
                ></select-field>
              </b-col>
              <b-col cols="12" md="3">
                <autocomplete
                  v-model.trim="zone"
                  label="Zone"
                  :multiple="true"
                  :items="masterZones"
                ></autocomplete>
              </b-col>
            </template>
            <b-col cols="12" md="3">
              <select-field
                v-model="form.patient_nationlity"
                label="Nationality"
                :items="getSelect(masterNationality)"
              ></select-field>
            </b-col>
            <b-col cols="12" md="3">
              <autocomplete
                label="Blood Group"
                :items="filterBloodGroups"
                :multiple="true"
                v-model="blood_group"
              ></autocomplete>
            </b-col>
            <b-col cols="12" md="3">
              <select-field
                label="Age Type"
                :items="getSelect(masterAgeTypes)"
                v-model="form.age"
              ></select-field>
            </b-col>
            <b-col cols="6">
              <radio-group
                label="Limit"
                :options="radioGroup"
                v-model="form.recordlimit"
              ></radio-group>
            </b-col>
            <b-col cols="12" md="3" class="pt-4">
              <div class="d-flex">
                <div class="flex-grow-1"></div>
                <b-button variant="success" class="mx-3" type="submit">
                  Submit
                </b-button>
              </div>
            </b-col>
          </b-row>
        </form-wrapper>
      </collapse-filter>

      <table-view
        :fields="fields"
        :isFilter="false"
        :isBusy="isLoading"
        :items="list"
      >
        <template v-slot:recipient_registration_id="{ item, value }">
          <internal-recipient-icon
            :item="item"
            :value="value"
          ></internal-recipient-icon>
        </template>
      </table-view>
      <div class="d-flex mt-3">
        <div class="flex-grow-1"></div>
        <b-button variant="outline-danger" class="mx-3" @click="dialog = false"
          >Close</b-button
        >
        <b-button variant="success" v-if="list.length" @click="generate()"
          >Submit</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../../assets/constants/recipient.constants";
import {
  BLOOD_GROUP_VALUES,
  formatDate,
} from "../../../../../assets/js/common";
import event from "../../../../../assets/js/event";
import donorMixins from "../../../../../assets/mixins/donor.mixins";
import donorService from "../../../../../assets/services/donor.service";
import recipientService from "../../../../../assets/services/recipient.service";
import RadioGroup from "../../../../form/RadioGroup.vue";
import InternalRecipientIcon from "../../../../managerecipient/InternalRecipientIcon.vue";
import TableView from "../../../../table/TableView.vue";
import CollapseFilter from "../../../../ui/CollapseFilter.vue";
export default {
  props: {
    status: {
      type: Number,
      default: 2,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    RadioGroup,
    TableView,
    InternalRecipientIcon,
    CollapseFilter,
  },
  mixins: [donorMixins],
  data() {
    return {
      dialog: false,
      list: [],
      radioGroup: [
        {
          name: "Next 50",
          value: "2",
        },
        {
          name: "All",
          value: "3",
        },
      ],
      form: {
        hospital_id: "",
        hospital_type: "",
        patient_nationlity: "",
        age: "",
        recordlimit: "2",
        hospital_status: "",
      },
      zone: [],
      blood_group: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,

      isLoading: false,
    };
  },
  computed: {
    isInhouse() {
      return this.item.type_of_allocation == 1;
    },

    fields() {
      let doc = {
        recipient_registration_id: "UID",
        zone_rank_id: "Zonal Rank",
        created_on: "Date of Registration",
        // first_name: "Name",
        age: "Age",
        gender: "Gender",
        height: "Height",
        weight: "Weight",
        group_desc: "Blood Group",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        // payment_status: "Payment Status",
        // transtan_status: "Patient Status",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  methods: {
    async generate() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want reassign?",
        onConfirm: async () => {
          const vm = this;
          if (vm.list.length == 0) return;
          try {
            vm.$loader.show();
            let list = [];
            let { final_donor_id, id } = vm;
            let {
              organ_allocation_id,
              donor,
              organ_id,
              type_of_allocation,
              allocation_status,
            } = vm.item;
            let { case_officer } = donor;

            vm.list.forEach((item) => {
              let { recipient_registrationid, hospital_id } = item;
              let doc = {
                final_donor_id,
                organ_allocation_id,
                donor_temp_registration_id: id,
                case_officer,
                hospital_id,
                organ_id,
                status: 0,
                organ_allocated_step: 2,
                recepient_registration_id: recipient_registrationid,
                created_by: vm.created_by,
                updated_by: vm.created_by,
                type_of_allocation,
                allocation_status,
                reject_reason: "",
                dml_indicator: "I",
                flag: "RA",
              };
              list.push(doc);
            });
            let { data } = await donorService.generateWL({
              recepient_organ_allocation_proc: list,
            });
            let { status, msg } = data;
            vm.isLoading = false;
            vm.$loader.hide();
            event.$emit("refresh");
            vm.$emit("close");

            if (!status) {
              vm.$alert.show(msg);
            } else {
              vm.dialog = false;
            }
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async fetch() {
      const vm = this;
      const doc = {
        dml_indicator: "S",
      };
      let { allocated_organ } = vm.item;
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.blood_group = [];
      vm.blood_group.forEach((id) => {
        BLOOD_GROUP_VALUES[id].forEach((blood_group) => {
          doc.blood_group.push({ blood_group });
        });
      });
      doc.zone_list = vm.zone.map((zone) => {
        return { zone };
      });
      doc.organ = allocated_organ.split(",").map((organ_id) => {
        return { organ_id };
      });
      doc.filter_type = 1;
      doc.multi_organ = allocated_organ;

      try {
        vm.isLoading = true;
        vm.list = [];
        let data = {};
        if (vm.isInhouse) {
          data = (await recipientService.getLocalWaitingList(doc)).data;
        } else {
          data = (await recipientService.getSharedWaitingList(doc)).data;
        }
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.payment_status = vm.getPaymentStatus(item.payment_status);
          item.transtan_status = vm.getPatientStatus(item.transtan_status);
          item.CM_insurance = vm.getCMInsuranceStatus(item.CM_insurance);
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
