<template>
  <div>
    <div class="h3 px-3 mb-0">Donor Summary</div>
    <div class="d-flex justify-content-between">
      <div>
        <div class="px-3">{{ details.donor_hospital_name }}</div>
        <div class="px-3 pb-3">{{ details.donor_hospital_name_village }}</div>
      </div>
      <div class="px-5">
        <div>Donor ID : {{ details.donor_temp_registration_id }}</div>
        <div>{{ formatDate(details.dateofregistration) }}</div>
      </div>
    </div>
    <donor-details-print v-model="details"></donor-details-print>
    <donor-cause-of-death-print
      :details="details"
      v-model="cause_of_death"
    ></donor-cause-of-death-print>
    <donor-apnea-test-details-print
      v-model="apnea_test_details"
    ></donor-apnea-test-details-print>
    <template v-if="false">
      <donor-contact-print v-model="contact"></donor-contact-print>
      <donor-consent-attachments-print
        v-model="consentFiles"
        :filesList="filesList"
        ref="consentAttachments"
      ></donor-consent-attachments-print>
      <donor-organ-requested-print v-model="organ_requested" />
      <donor-co-morbidities-print
        v-model="co_morbidities"
        :covid_free_period="covid_free_period"
      />
      <donor-injuries-print v-model="injuries" />
      <donor-medical-details-print v-model="medical_details" />
      <donor-ventilator-settings-print
        v-model="ventilator_settings"
      ></donor-ventilator-settings-print>
      <donor-apg-test-print v-model="apg_test" />
      <donor-legal-form-print
        v-model="formFiles"
        :filesList="filesList"
        ref="formAttachments"
      />
      <donor-resistance-measure-print v-model="resistance_measure" />
      <donor-medications-inotrope-print v-model="medication_inotrope" />
      <medication v-if="false" v-model="medications" />
      <donor-attachments-print
        v-model="files"
        :filesList="filesList"
        ref="attachments"
      ></donor-attachments-print>
    </template>
  </div>
</template>

<script>
import { formatDate, isArray } from "../../assets/js/common";
import DonorApgTestPrint from "./donorSummary/DonorApgTestPrint.vue";
import DonorApneaTestDetailsPrint from "./donorSummary/DonorApneaTestDetailsPrint.vue";
import DonorAttachmentsPrint from "./donorSummary/DonorAttachmentsPrint.vue";
import DonorCauseOfDeathPrint from "./donorSummary/DonorCauseOfDeathPrint.vue";
import DonorCoMorbiditiesPrint from "./donorSummary/DonorCoMorbiditiesPrint.vue";
import DonorConsentAttachmentsPrint from "./donorSummary/DonorConsentAttachmentsPrint.vue";
import DonorContactPrint from "./donorSummary/DonorContactPrint.vue";
import DonorDetailsPrint from "./donorSummary/DonorDetailsPrint.vue";
import DonorInjuriesPrint from "./donorSummary/DonorInjuriesPrint.vue";
import DonorLegalFormPrint from "./donorSummary/DonorLegalFormPrint.vue";
import DonorMedicalDetailsPrint from "./donorSummary/DonorMedicalDetailsPrint.vue";
import DonorMedicationsInotropePrint from "./donorSummary/DonorMedicationsInotropePrint.vue";
import DonorOrganRequestedPrint from "./donorSummary/DonorOrganRequestedPrint.vue";
import DonorResistanceMeasurePrint from "./donorSummary/DonorResistanceMeasurePrint.vue";
import DonorVentilatorSettingsPrint from "./donorSummary/DonorVentilatorSettingsPrint.vue";
export default {
  components: {
    DonorDetailsPrint,
    DonorCauseOfDeathPrint,
    DonorApgTestPrint,
    DonorContactPrint,
    DonorConsentAttachmentsPrint,
    DonorOrganRequestedPrint,
    DonorCoMorbiditiesPrint,
    DonorInjuriesPrint,
    DonorMedicalDetailsPrint,
    DonorVentilatorSettingsPrint,
    DonorApneaTestDetailsPrint,
    DonorLegalFormPrint,
    DonorResistanceMeasurePrint,
    DonorMedicationsInotropePrint,
    DonorAttachmentsPrint,
  },
  props: {
    donor: {},
  },
  data() {
    return {
      organ_requested: {},
      consentFiles: [],
      files: [],
      formFiles: [],
      covid_free_period: "",
      filesList: [],
      details: {
        donor_name: "",
        donor_hospital_name: "",
        donor_hospital_name_village: "",
        organ_harvesting_date: "",
        age: "",
        DOB: "",
        sex: "",
        blood_group: "",
        height: "",
        weight: "",
        BMI: "",
        medical_legal_case: "",
        MLC_AR_No: "",
        status: 8,
        created_by: "",
        dml_indicator: "I",
        hospital_id: "",
        individual_id: "",
        donor_temp_registration_id: "",
        dateofregistration: "",
      },
      cause_of_death: {
        cause_of_death_id: "",
        cause_of_death_notes: "",
        date_of_admisssion: "",
        date_of_accident: "",
        No_of_days_ventilator: "",
        tentative_retreival_date: "",
        assessment_datetime: "",
      },
      contact: {
        first_name: "",
        last_name: "",
        email_id: "",
        country_code_1: "",
        phone_number_1: "",
        country_code_2: "",
        phone_number_2: "",
        contact_id: "",
        type_of_consent: "",
        conset_given: "",
        type_of_consent_upload: "",
      },
      medical_details: {
        pulse_rate: "",
        systolic: "",
        diastolic: "",
        MAP: "",
        CVP: "",
        urine_output: "",
        urine_unit: 1,
        SPO2: "",
        temperature: "",
      },
      ventilator_settings: {
        FIO2: "",
        PEEP: "",
        PIP: "",
        TV: "",
        RR: "",
        pressure_support: "",
        VCV: "",
        PCV: "",
        others: "",
        ventilation: "",
      },
      injuries: {
        chest_injuries: "",
        abdomen_injuries: "",
        notes: "",
      },
      resistance_measure: {
        CPR_given: "",
        CPR_notes: "",
        hypotensive_episodes: "",
      },
      co_morbidities: {},
      apg_test: {
        ABG_file_upload_1: "",
        ABG_file_upload_2: "",
        ABG_file_upload_3: "",
        ABG_file_upload_4: "",
        FIO2_1: "",
        FIO2_2: "",
        FIO2_3: "",
        FIO2_4: "",
      },
      medication_inotrope: {
        inotrope_1: "",
        inotrope_2: "",
        inotrope_3: "",
        inotrope_4: "",
        harmone_replacement_theraphy: "",
        steroids: "",
        vasopressin: "",
        thyroxine: "",
      },
      apnea_test_details: {
        first_apnea_date: "",
        first_apnea_status: "",
        first_apnea_file_upload_1: null,
        first_apnea_file_upload_2: null,
        second_apnea_date: "",
        second_apnea_status: "",
        second_apnea_file_upload_1: "",
        second_apnea_file_upload_2: "",
      },
    };
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    initDoc(vm, doc, key) {
      Object.keys(vm[key]).forEach((i) => {
        if (doc[i] !== undefined) vm[key][i] = doc[i];
      });
    },
    initDetails() {
      const vm = this;
      let {
        donor_consented_organ,
        donor_medical_detail,
        donor_medication,
        donor_investigations_fileupload_mapping,
        list,
      } = vm.donor;
      console.log(vm.donor);
      let doc = list[0] || {};
      vm.covid_free_period = doc.covid_free_period;
      vm.initDoc(vm, doc, "details");
      vm.initDoc(vm, doc, "cause_of_death");
      vm.initDoc(vm, doc, "contact");
      vm.initDoc(vm, doc, "ventilator_settings");
      vm.initDoc(vm, doc, "injuries");
      vm.initDoc(vm, doc, "resistance_measure");
      vm.initDoc(vm, doc, "medical_details");
      vm.initDoc(vm, doc, "medication_inotrope");
      vm.initDoc(vm, doc, "apg_test");
      vm.initDoc(vm, doc, "apnea_test_details");
      if (isArray(donor_consented_organ)) {
        donor_consented_organ.forEach((item) => {
          let { organ_id, status } = item;
          vm.organ_requested[organ_id] = status == 1 ? "1" : "0";
        });
      }
      if (isArray(donor_medical_detail)) {
        donor_medical_detail.forEach((item) => {
          let { master_donor_medical_detail_id, status } = item;
          vm.co_morbidities[master_donor_medical_detail_id] =
            status == 1 ? "1" : "0";
        });
      }
      if (isArray(donor_medication)) {
        vm.medications = donor_medication.map((item) => {
          return item;
        });
      }
      if (isArray(donor_investigations_fileupload_mapping)) {
        vm.filesList = donor_investigations_fileupload_mapping;
      }
    },
  },
  beforeMount() {
    this.initDetails();
  },
};
</script>

<style>
</style>