<template>
  <div>
    <div v-for="(doc, i) in tabs" :key="i">
      <b-card class="px-3 py-2 bg-primary bg-soft" no-body>
        <div class="d-flex align-items-center">
          <h5 class="mb-0 text-primary">{{ doc.name }} : {{ doc.subtitle }}</h5>
          <div class="flex-grow-1"></div>
          <div class="font-size-12">
            {{ doc.created_on || "-" }}
          </div>
        </div>
      </b-card>
      <div class="mb-4">
        <allocating-waiting-list
          :hospitals="hospitals"
          :item="item"
          :isLast="isLast"
          :status="doc.status"
          v-if="isAlocate(doc)"
        ></allocating-waiting-list>
        <generate-provisional-wait-list
          :hospitals="hospitals"
          :status="doc.status"
          :isLast="isLast"
          :item="item"
          v-if="isPriority(doc)"
        ></generate-provisional-wait-list>
        <update-logistics-list
          :item="item"
          :isLast="isLast"
          v-if="doc.status == 7"
        ></update-logistics-list>
        <organ-infection-list
          :item="item"
          v-if="doc.status == 25"
          :isLast="isLast"
        >
        </organ-infection-list>
        <retrieval-list
          :status="doc.status"
          :item="item"
          :isLast="isLast"
          :history="history"
          v-if="doc.status == 26"
        ></retrieval-list>
        <transplant-surgery
          :item="item"
          v-if="doc.status == 28"
          :isLast="isLast"
        ></transplant-surgery>
        <allocation-list
          :item="item"
          :isLast="isLast"
          v-if="doc.status == 18"
        ></allocation-list>
        <allocate-to-other-state-view
          :item="item"
          v-if="doc.status == 41"
        ></allocate-to-other-state-view>
        <final-allocation-list
          :item="item"
          :isLast="isLast"
          v-if="doc.status == 43"
        ></final-allocation-list>
      </div>
    </div>
  </div>
</template>

<script>
import AllocatingWaitingList from "../create/steps/AllocatingWaitingList.vue";
import GenerateProvisionalWaitList from "../create/steps/GenerateProvisionalWaitList.vue";
import OrganInfectionList from "../create/steps/OrganInfectionList.vue";
import UpdateLogisticsList from "../create/steps/UpdateLogisticsList.vue";
import RetrievalList from "../create/steps/RetrievalList.vue";
import TransplantSurgery from "../create/steps/TransplantSurgery.vue";
import { formatDate } from "../../../assets/js/common";
import AllocateToOtherStateView from "../create/steps/provisionalwaitlist/AllocateToOtherStateView.vue";
import AllocationList from "../create/steps/AllocationList.vue";
import FinalAllocationList from "../create/steps/FinalAllocationList.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    history: {
      type: Array,
      default: () => [],
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AllocatingWaitingList,
    GenerateProvisionalWaitList,
    UpdateLogisticsList,
    OrganInfectionList,
    RetrievalList,
    TransplantSurgery,
    AllocateToOtherStateView,
    AllocationList,
    FinalAllocationList,
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    isLast() {
      return false;
    },
    tabItem() {
      return this.tabs[this.tab] || {};
    },
    tabs() {
      let { organ_allocation_id } = this.item;
      let list = [
        // {
        //   subtitle: "Wait List",
        //   disabled: allocation_status != 1,
        //   status: 1,
        // },
        {
          subtitle: "Generated Wait List",
          status: 2,
        },
        {
          subtitle: "Priority List",
          status: 6,
        },
        {
          subtitle: "Generated Wait List",
          status: 33,
        },
        {
          subtitle: "Priority List",
          status: 34,
        },
        {
          subtitle: "No Taker 1 List",
          status: 4,
        },
        {
          subtitle: "Priority List",
          status: 20,
        },
        {
          subtitle: "No Taker 2 List",
          status: 29,
        },
        {
          subtitle: "Priority List",
          status: 30,
        },
        {
          subtitle: "Logistics",
          status: 7,
        },
        {
          subtitle: "Assessment Status",
          status: 25,
        },
        {
          subtitle: "Allocation",
          status: 18,
        },
        {
          subtitle: "Organ Retrieval",
          status: 26,
        },
        {
          subtitle: "Other State",
          status: 41,
        },
        {
          subtitle: "Final Allocation",
          status: 43,
        },
        {
          subtitle: "Transplant Surgery",
          status: 28,
        },
      ];
      // if (this.isAdmins) {
      //   list.splice(2, 1);
      //   list.splice(4, 1);
      //   list.splice(6, 1);
      //   list.splice(8, 1);
      // }
      list = list.filter((item, i) => {
        let history = this.history.find((doc) => {
          let status = item.status;
          if (status == 2.1) status = 2;
          if (status == 33.1) status = 33;
          if (status == 4.1) status = 4;
          if (status == 29.1) status = 29;
          return (
            doc.status == status &&
            doc.organ_allocation_id == organ_allocation_id
          );
        });
        if (item.status == 1) return true;
        if (history != null) {
          list[i].created_on = formatDate(history.created_on);
        }
        return history != null;
      });
      return list.map((item, i) => {
        item.name = `Step ${i + 1}`;
        return item;
      });
    },
    fields() {
      let doc = {
        name: "Name",
        email_id: "Email ID / User ID",
        contact_name: "Contact Type",
        phone_number_1: "Phone Number",
        status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });

      return list;
    },
  },
  methods: {
    isAlocate({ status }) {
      let allowed = [2, 4, 33, 29];
      return allowed.indexOf(status) != -1;
    },
    isPriority({ status }) {
      let allowed = [6, 20, 34, 30];
      return allowed.indexOf(status) != -1;
    },
    isMyUpdate(status) {
      let allowed = [2.1, 4.1, 33.1, 29.1];
      return allowed.indexOf(status) != -1;
    },
  },
  mounted() {
    if (this.isMobile) {
      this.tab = this.tabs.length - 1;
      return;
    }
    setTimeout(() => {
      this.tab = this.tabs.length - 1;
    }, 1000);
  },
};
</script>
