<template>
  <b-card class="mt-4" v-show="!isHospitalContact">
    <div class="h4">Filter</div>
    <form-wrapper @submit="submit">
      <b-row>
        <b-col cols="12">
          <autocomplete
            label="Hospital"
            v-model="form.hospital_id"
            rules="required"
            :items="hospitals"
          ></autocomplete>
        </b-col>
      </b-row>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-btn
          class="width-100 me-3"
          @click="reset(false)"
          variant="outline-secondary"
          >RESET</b-btn
        >
        <b-btn class="width-100" type="submit" variant="primary">SUBMIT</b-btn>
      </div>
    </form-wrapper>
  </b-card>
</template>

<script>
import commonHelper from "../../assets/helper/common.helper";
import Autocomplete from "../form/Autocomplete.vue";
export default {
  components: { Autocomplete },
  props: {
    value: {},
  },
  data() {
    return {
      form: { hospital_id: "" },
      hospitals: [],
    };
  },
  methods: {
    submit() {
      const vm = this;
      let { hospital_id } = vm.form;
      const doc = {
        dml_indicator: "S",
        hospital_id,
      };
      vm.$store.commit("SET_QUERY", { key: "donor", query: vm.form });
      vm.$emit("input", doc);
      vm.$emit("fetch");
    },
    reset(isSubmit) {
      this.form.hospital_id = "";
      if (isSubmit) {
        this.submit();
      }
    },
  },
  mounted() {
    const vm = this;
    if (vm.query.donor) {
      vm.form = vm.query.donor;
      this.submit();
    } else {
      this.reset();
    }
    commonHelper.fetchHospital(this);
  },
};
</script>

<style></style>
