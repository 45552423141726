<template>
  <table-view
    title="Waiting List"
    :isMobileView="isMobile"
    :fields="fields"
    :items="formmattedList"
    :isBusy="isLoading"
  >
    <template v-slot:transtan_status="{ value, item }">
      <div :class="item.bg">
        {{ value }}
      </div>
    </template>
    <template v-slot:mobile_list="rows">
      <div class="border-bottom"></div>
      <waiting-list-mobile-item
        v-for="(item, i) of rows"
        :key="i"
        :item="item"
      />
    </template>
  </table-view>
</template>

<script>
import { encode, formatDate } from "../../../assets/js/common";
import TableView from "../../table/TableView.vue";
import recipientService from "../../../assets/services/recipient.service";
import WaitingListMobileItem from "../WaitingListMobileItem.vue";
export default {
  components: { TableView, WaitingListMobileItem },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
    };
  },
  computed: {
    formmattedList() {
      const vm = this;
      return [...vm.list].map((item) => {
        let doc = { ...item };
        doc.CM_insurance = vm.getCMInsuranceStatus(doc.CM_insurance);
        doc.created_on = formatDate(item.created_on);
        doc.gender = vm.getGender(doc.gender);
        doc.hospital_status = vm.getBeReadyStatus(doc.hospital_status);
        doc.payment_status = vm.getPaymentStatus(doc.payment_status);
        doc.transtan_status = vm.getPatientStatus(doc.transtan_status);
        doc.bg = vm.getBg(doc.transtan_status);
        return doc;
      });
    },
    fields() {
      let doc = {
        sno: "S.No",
        recipient_registration_id: "UID",
        first_name: "Name",
        transtan_status: "Patient Status",
        group_desc: "Blood Group",
        age: "Age",
        gender: "Gender",
        created_on: "Date of Registration",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        // payment_status: "Payment Status",
        CM_insurance: "CM Insurance",
        phone_number_1: "Phone Number",
        // hospital_status: "Status",
        // /        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  methods: {
    getBg(val) {
      switch (val) {
        case "Active":
          return "badge bg-success font-size-13";
        case "Pending Approval":
          return "badge bg-warning font-size-13";
        case "Inactive":
          return "badge bg-danger font-size-13";
        case "Deleted":
          return "badge bg-danger font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
    isDelete(item) {
      return item.transtan_status == 4 || item.transtan_status == 1;
    },
    isApproved(item) {
      return item.transtan_status == 4;
    },
    open({ recipient_registration_id }) {
      let id = encode(recipient_registration_id);
      this.$router.push(`/update-recipient?id=${id}`);
    },
    remove({ recipient_registration_id, transtan_status }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id,
            transtan_status: transtan_status == 1 ? 7 : 5,
            dml_indicator: "A",
          });
        },
      });
    },
    approve({ recipient_registration_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to approve this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id,
            transtan_status: 3,
            dml_indicator: "A",
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await recipientService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        // vm.fetch();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
