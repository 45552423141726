<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <b-form @submit.stop.prevent="onSubmit(invalid)">
      <slot></slot>
    </b-form>
  </validation-observer>
</template>

<script>
export default {
  data() {
    return {
      wasValidated: false,
    };
  },

  methods: {
    reset() {
      this.$refs.observer.reset();
    },
    async validate() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          reject({ message: "Please enter all the mandatory fields" });
          return;
        }
        resolve(true);
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$alert.show("Please enter all the mandatory fields");
        return;
      }
      this.$emit("submit");
    },
  },
};
</script>

<style></style>
