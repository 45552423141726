<template>
  <expandable header="Ventilator Settings">
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
      Type of Ventilation
    </div>

    <b-row>
      <b-col cols="12" md="4">
        <print-yes-no
          v-model="form.ventilation"
          @input="onInput"
          label="Is on Ventilation?"
          rules="required"
        ></print-yes-no>
      </b-col>

      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.VCV"
          :disabled="isDisabled"
          @input="onInput"
          label="Volume Control Ventilation (VCV)"
          rules="required|max-100"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.PCV"
          @input="onInput"
          :disabled="isDisabled"
          label="Pressure Control Ventilation (PCV)"
          rules="required|max-100"
        ></print-input>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">Other Settings</div>
    <b-row>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.FIO2"
          @input="onInput"
          label="FiO<sub>2</sub>"
          :disabled="isDisabled"
          rules="required|max-100"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.PEEP"
          @input="onInput"
          :disabled="isDisabled"
          label="PEEP"
          rules="required|max-100"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.PIP"
          @input="onInput"
          :disabled="isDisabled"
          label="PIP"
          rules="required|max-100"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          :disabled="isDisabled"
          v-model.trim="form.TV"
          @input="onInput"
          label="TV"
          rules="required|max-100"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          :disabled="isDisabled"
          v-model.trim="form.RR"
          @input="onInput"
          label="Respiratory Rate"
          rules="required|max-100"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          :disabled="isDisabled"
          v-model.trim="form.pressure_support"
          @input="onInput"
          label="Pressure Support"
          rules="required|max-100"
        ></print-input>
      </b-col>

      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.others"
          @input="onInput"
          label="Others"
          :disabled="isDisabled"
          rules="max-100"
        ></print-input>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import PrintInput from "../../ui/print/PrintInput.vue";
import PrintYesNo from "../../ui/print/PrintYesNo.vue";
export default {
  components: { Expandable, PrintInput, PrintYesNo },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
