<template>
  <expandable
    header="Evaluation Specific to Liver / Pancreas"
    class="mb-10 liver-parameter"
  >
    <b-row>
      <b-col cols="12">
        <autocomplete
          :items="complications"
          @input="onInput"
          :disabled="isDisabled"
          label="History of Complications"
          v-model="form.history_of_complications"
          :multiple="true"
          rules="required"
        ></autocomplete>
      </b-col>
      <b-col cols="12" md="4">
        <text-area
          @input="onInput"
          :disabled="isDisabled"
          label="Complication Description"
          v-model="form.complication_description"
        ></text-area>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          @input="onInput"
          :items="cancerScreening"
          :disabled="isDisabled"
          label="Cancer Screening"
          v-model="form.cancer_screening"
          rules="max-255"
        ></select-field>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">Liver Function</div>
    <b-row>
      <b-col cols="12" md="4">
        <input-group
          label="MELD Score"
          @input="onInput"
          v-model="form.MELD_score"
          :disabled="true"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Bilirubin"
          type="text"
          @input="onInput"
          :disabled="isDisabled"
          rules="required|num-3|dec-2"
          v-model="form.bilirubin"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <input-group
          label="Total Protein"
          type="number"
          v-model="form.total_protein"
          rules="required|max-255"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> g/dL </b-button>
          </template>
        </input-group>
      </b-col>-->
      <b-col cols="12" md="4">
        <input-group
          label="Albumin"
          :disabled="isDisabled"
          type="text"
          @input="onInput"
          v-model="form.albumin"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> g/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Globulin"
          @input="onInput"
          type="text"
          v-model="form.globulin"
          :disabled="isDisabled"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> g/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="GGT"
          @input="onInput"
          type="text"
          :disabled="isDisabled"
          v-model="form.GGT"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> IU/L </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          type="text"
          label="AST"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.AST"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> IU/L </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="ALT"
          type="text"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.ALT"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> IU/L </b-button>
          </template>
        </input-group>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
      Cardiac Evaluation
    </div>
    <b-row>
      <b-col cols="12" md="4">
        <file-input-group
          label="Coronary Angiogram"
          :disabled="isDisabled"
          @input="onInput"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          v-model="form.coronary_angiogram"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          label="Stress Test"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          v-model="form.stress_test"
          :disabled="isDisabled"
          @input="onInput"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
      Pulmonary Evaluation
    </div>
    <b-row>
      <b-col cols="12" md="4">
        <file-input-group
          label="Room air - ABG"
          :disabled="isDisabled"
          @input="onInput"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          v-model="form.room_air_ABG"
          rules="file-image-pdf|file-size"
        />
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          label="PFT"
          v-model="form.liver_PFT"
          :disabled="isDisabled"
          @input="onInput"
          rules="file-image-pdf|file-size"
        />
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">Renal Evaluation</div>
    <b-row>
      <b-col cols="12" md="4">
        <input-group
          label="Urea"
          type="text"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.urea"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          @input="onInput"
          label="Creatinine"
          :disabled="isDisabled"
          type="text"
          v-model="form.creatinine"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Uric Acid"
          :disabled="isDisabled"
          type="text"
          @input="onInput"
          v-model="form.uric_acid"
          rules="num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Serum Sodium"
          :disabled="isDisabled"
          @input="onInput"
          type="text"
          v-model="form.serum_sodium"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Serum Potassium"
          :disabled="isDisabled"
          type="text"
          v-model="form.serum_potassium"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Serum Chloride"
          type="text"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.serum_chloride"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Serum Bicarbonate"
          :disabled="isDisabled"
          @input="onInput"
          type="text"
          v-model="form.serum_bicarbonate"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          :disabled="isDisabled"
          label="Serum Magnesium"
          @input="onInput"
          type="text"
          v-model="form.serum_magnesium"
          rules="num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Serum Phosphate"
          @input="onInput"
          :disabled="isDisabled"
          type="text"
          v-model="form.serum_phosphorus"
          rules="num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL </b-button>
          </template>
        </input-group>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
      Coagulation Profile
    </div>
    <b-row>
      <b-col cols="12" md="4">
        <input-field
          label="INR"
          @input="onInput"
          v-model="form.INR"
          :disabled="isDisabled"
          rules="required|num-3|dec-2"
          type="text"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          label="APTT"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.APTT"
          rules="num-3|dec-2"
          type="text"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          label="Platelets"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.platelets"
          rules="num-3|dec-2"
          type="text"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          label="Fibrinogen"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.fibrinogen"
          rules="num-3|dec-2"
          type="text"
        >
        </input-field>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import {
  CANCER_SCREENINGS,
  HISTORY_COMPLICATIONS,
} from "../../../assets/constants/recipient.constants";
import Expandable from "../../Expandable.vue";
import FileInputGroup from "../../form/FileInputGroup.vue";
import InputField from "../../form/InputField.vue";
import SelectField from "../../form/SelectField.vue";
import TextArea from "../../form/TextArea.vue";

export default {
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { Expandable, SelectField, InputField, TextArea, FileInputGroup },
  data() {
    return {
      form: {},
      complications: HISTORY_COMPLICATIONS,
      cancerScreening: CANCER_SCREENINGS,
    };
  },
  methods: {
    onInput() {
      let { bilirubin, creatinine, INR } = this.form;
      if (isNaN(bilirubin)) bilirubin = 0;
      if (isNaN(creatinine)) creatinine = 0;
      if (isNaN(INR)) INR = 0;
      let meld = "";
      if (bilirubin && creatinine && INR) {
        bilirubin = 3.78 * Math.log(bilirubin);
        INR = 11.2 * Math.log(INR);
        creatinine = 9.57 * Math.log(creatinine);
        meld = bilirubin + INR + creatinine + 6.43;
        meld = Math.round(meld);
      }

      this.form.MELD_score = meld;
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    if (this.value != undefined) this.form = this.value;
  },
};
</script>

<style lang="scss">
.liver-parameter {
  .btn-outline-info {
    min-width: 85px;
  }
}
</style>
