<template>
  <div>
    <h3>Group Chat</h3>
    <div class="chat-messages chat-report" id="chat-messages" ref="messages">
      <ul class="list-unstyled">
        <li v-for="(item, i) in messages" :key="i" :class="isRight(item)">
          <div class="ctext-wrap">
            <div class="conversation-name" v-if="isUser(item)">
              {{ users[item.u] || "-" }}
            </div>
            <a
              class="mb-1 text-decoration-underline"
              v-if="isFile(item)"
              :href="getChatUrl(item)"
              target="_blank"
            >
              View
            </a>
            <p v-else class="mb-1">{{ item.m }}</p>
            <div class="chat-time mb-0 d-flex align-items-center">
              <i class="bx bx-time-five me-1"></i>
              <span>
                {{ item.time }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "simplebar/dist/simplebar.min.css";
import { DB } from "../../../assets/js/firebase";
import {
  limitToLast,
  off,
  onChildAdded,
  onValue,
  orderByKey,
  query,
  ref,
  set,
} from "@firebase/database";
import { chatLink, formatDate } from "../../../assets/js/common";
import donorMixins from "../../../assets/mixins/donor.mixins";
import donorService from "../../../assets/services/donor.service";
export default {
  data() {
    return {
      imageModel: false,
      show: false,
      messages: [],
      message: "",
      src: "",
      file: null,
      users: {},
    };
  },
  mixins: [donorMixins],
  computed: {
    uid() {
      return this.user.fcm_uid;
    },
    ref() {
      console.log(this.id);
      return `/chat_rooms/${this.id}/messages`;
    },
    isAllocation() {
      return this.$route.name == "organ-allocation";
    },
    chatWindowClass() {
      if (!this.isMobile) {
        return this.show ? "show-chat" : "hide-chat";
      }
      return "popup-layer--chat";
    },
  },
  beforeDestroy() {
    off(ref(DB, this.ref));
    off(ref(DB, "user_names"));
  },
  watch: {
    $route() {
      if (this.isAllocation) {
        this.reset();
      }
    },
    show() {
      this.scrollToBottom();
    },
  },
  methods: {
    reset() {
      off(ref(DB, this.ref));
      this.listenChat();
    },
    getChatUrl({ m }) {
      return chatLink(m);
    },
    isFile({ m }) {
      return m.indexOf("photos/chat") != -1;
    },
    isUser(item) {
      if (item.u == this.uid) return false;
      return true;
    },
    isFileImage(file) {
      return file && file["type"].split("/")[0] === "image";
    },
    isRight(item) {
      return item.u == this.uid ? "right" : "";
    },
    async sendMessage() {
      const vm = this;
      if (!vm.message) return;
      let ts = new Date().getTime();
      let chatRef = ref(DB, `${vm.ref}/${ts}`);
      try {
        await set(chatRef, {
          m: vm.message,
          u: vm.uid,
        });
        vm.message = "";
        if (vm.messages.length == 0) {
          this.reset();
        }
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },
    onFileChange(e) {
      const vm = this;
      let file = e.target.files[0];
      if (!file) return;
      vm.file = file;
      if (!vm.isFileImage(file)) return vm.uploadFile(vm.false);
      vm.src = URL.createObjectURL(vm.file);
      vm.imageModel = true;
    },
    async uploadFile() {
      const vm = this;
      vm.imageModel = false;
      try {
        vm.$loader.show();
        let form = new FormData();
        form.append("file", vm.file);
        let { Message } = (await donorService.uploadChatFile(form)).data;
        vm.message = Message;
        vm.sendMessage();
        console.log(Message);
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    listenChat() {
      const vm = this;
      let chatRef = ref(DB, vm.ref);
      let q = query(chatRef, orderByKey(), limitToLast(100));
      vm.messages = [];
      onChildAdded(q, (data) => {
        console.log(data.key);
        vm.messages.push({
          time: formatDate(new Date(Number(data.key))),
          ...data.val(),
        });
        vm.scrollToBottom();
      });
      let userRef = ref(DB, "user_names");
      onValue(userRef, (snap) => {
        vm.users = snap.val();
      });
    },
    scrollToBottom() {
      setTimeout(() => {
        let $ = window.$;
        let chat = $("#chat-messages .simplebar-content-wrapper");
        chat.scrollTop(chat.prop("scrollHeight"));
      }, 1000);
    },
    setName() {
      const vm = this;
      if (!vm.uid) return false;
      let { first_name, last_name } = vm.user;
      let name = `${first_name} ${last_name}`;
      let nameRef = ref(DB, `user_names/${vm.uid}`);
      set(nameRef, name);
    },
  },
  mounted() {
    this.setName();
    this.listenChat();
  },
};
</script>

<style lang="scss">
.chat {
  &-input {
    background: #eff2f7 !important;
    border-color: #eff2f7 !important;
  }
  &-btn {
    cursor: pointer;
    .avatar-btn {
      align-items: center;
      color: #fff;
      display: flex;
      font-weight: 500;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }
  &-window {
    position: fixed;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    top: 70px;
    width: 320px;
    height: 400px;
    border-radius: 10px;
    border: 1px solid #eff2f7;
    z-index: 100;
    background: white;
    right: 10px;
  }
  &-header {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eff2f7;
  }
  &-messages,
  &-messages--mobile {
    height: 258px;
    padding: 10px;
    overflow-y: auto;
    li {
      clear: both;
    }
    .ctext-wrap {
      margin-bottom: 10px;
      padding: 12px;
      float: left;
      max-width: 280px;
      background-color: rgba(85, 110, 230, 0.1);
      border-radius: 8px 8px 8px 0;
      overflow: hidden;
      .conversation-name {
        font-weight: 600;
        color: #556ee6;
        margin-bottom: 4px;
      }
      .chat-time {
        font-size: 8px;
      }
    }
    .right {
      .ctext-wrap {
        float: right;
        background-color: #eff2f7;
        text-align: right;
        border-radius: 8px 8px 0 8px;
      }
      .chat-time {
        justify-content: end;
      }
    }
  }

  @keyframes showhide {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
  }
  &-footer {
    border-top: 1px solid #eff2f7;
    padding: 10px;
    display: flex;
    // height: 50px;
    button {
      min-width: 35px !important;
      min-height: 30px !important;
    }
  }
}
.hide-chat {
  display: none;
  animation-name: showhide;
  animation-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
}
.show-chat {
  display: block;
  animation-name: showhide;
  animation-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
}
.chat-image-preview {
  width: 100%;
  height: 400px;
  object-fit: contain;
}
.popup-layer--chat {
  margin-top: 70px !important;
  height: calc(100vh - 70px);
  overflow-y: auto;
}
.chat-messages--mobile {
  height: calc(100vh - 130px - 50px - 95px);
}
.chat-report {
  height: auto !important;
}
</style>
