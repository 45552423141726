import Loader from "./Loader.vue";

const LoaderModal = {
  install(Vue) {
    this.EventBus = new Vue();
    Vue.component("Loader", Loader);
    Vue.prototype.$loader = {
      show(params) {
        LoaderModal.EventBus.$emit("show", params);
      },
      hide() {
        LoaderModal.EventBus.$emit("hide");
      },
    };
  },
};

export default LoaderModal;
