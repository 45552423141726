<template>
  <div>
    <ValidationProvider :rules="rules" v-slot="validationContext">
      <b-form-group class="pb-3">
        <template v-slot:label>
          <span v-html="formattedLabel"></span>
        </template>
        <b-form-radio-group
          v-model="input"
          :disabled="disabled"
          class="horizondal-radiogroup"
          :options="options"
          :state="getValidationState(validationContext)"
          @change="$emit('input', input)"
        >
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
            >{{ message(validationContext) }}</b-form-invalid-feedback
          >
        </b-form-radio-group>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input: "",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    };
  },
  computed: {
    formattedLabel() {
      if(!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  methods: {
    message(validationContext) {
      return validationContext.errors[0];
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    if (this.value != undefined) {
      this.input = this.value.toString();
    }
  },
};
</script>

<style lang="scss">
.horizondal-radiogroup {
  display: block;
  .custom-radio {
    margin-right: 10px;
    float: left;
    .custom-control-label {
      margin-left: 10px;
    }
  }
  .invalid-feedback {
    width: 100%;
    float: left;
  }
}
</style>
