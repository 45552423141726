<template>
  <b-button icon variant="link"  depressed @click="$emit('click')" tile>
    <b-icon icon="credit-card" variant="primary" aria-hidden="true"></b-icon>
  </b-button>
</template>

<script>
export default {
  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style></style>
