<template>
  <Expandable header="Hospital" class="mb-10">
    <b-row class="pb-0">
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.hospital_name"
          label="Hospital Name"
          @input="input"
          :disabled="!isAdmin || isDisabled"
          dense
          rules="required|max-150"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          v-model.trim="form.hospital_type"
          label="Hospital Type"
          :disabled="isHospitalTypeDisabled"
          @input="input"
          :items="masterHospitalTypes"
          rules="required"
        ></select-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.website_url"
          :disabled="isDisabled"
          label="Website URL"
          @input="input"
          rules="required|url"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          @input="input"
          v-model="form.year_of_establishment"
          label="Year Of Establishment"
          :disabled="isDisabled"
          :items="years"
          rules="required"
        ></autocomplete>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          v-model.trim="form.hospital_registration_no"
          @input="input"
          label="Hospital License No"
          counter="100"
          rules="required|max-100"
          :disabled="isDisabled"
          outlined
          dense
        >
          <template v-slot:help>
            <help text="Hospital Registration/License Number"></help>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          @input="input"
          v-model.trim="form.zone_id"
          :disabled="isDisabled"
          label="Zone"
          :items="masterZones"
          rules="required"
        ></select-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.hospital_email_id"
          label="Email"
          @input="input"
          :disabled="isDisabled"
          rules="required|email|max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <phone-field
          v-model.trim="form.hospital_phone_number_1"
          :defaultCountry="form.hospital_country_code_1"
          label="Phone Number 1"
          :disabled="isDisabled"
          @input="input"
          @onCountryCode="form.hospital_country_code_1 = $event"
          rules="required|integer"
        ></phone-field>
      </b-col>
      <b-col cols="12" md="4">
        <phone-field
          v-model.trim="form.hospital_phone_number_2"
          :defaultCountry="form.hospital_country_code_2"
          label="Phone Number 1"
          :disabled="isDisabled"
          @input="input"
          @onCountryCode="form.hospital_country_code_2 = $event"
          rules="integer"
        ></phone-field>
      </b-col>
      <b-col cols="12" md="4" v-if="form.hospital_type != 1">
        <input-field
          v-model.trim="form.payment_reference_no"
          label="Payment Reference No"
          @input="input"
          :disabled="true"
          rules=""
        >
          <!-- <template v-slot:help>
            <b-button @click="openPayment()" variant="primary"
              >Payment Link</b-button
            >
          </template> -->
        </input-field>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import {
  FIELD,
  getMaxEmailRules,
  getMaxRules,
  URL_RULES,
} from "../../../assets/js/rules";
import hospitalCreateMixins from "../../../assets/mixins/hospital.create.mixins";
import Expandable from "../../Expandable.vue";
import SelectField from "../../form/SelectField.vue";
import Autocomplete from "../../form/Autocomplete.vue";
import Help from "../../Help.vue";
import PhoneField from "../../form/PhoneField.vue";
import { PAYMENT_LINK } from "../../../assets/js/common";
export default {
  props: {
    value: Object,
    status: Number,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [hospitalCreateMixins],
  components: {
    Expandable,
    SelectField,
    Autocomplete,
    PhoneField,
    Help,
  },
  data() {
    return {
      rules: FIELD,
      phone: FIELD,
      phone2: [],
      urlRules: URL_RULES,
      form: {
        hospital_name: "",
        hospital_type: "",
        hospital_email_id: "",
        hospital_country_code_1: "91",
        hospital_phone_number_1: "",
        hospital_country_code_2: "91",
        hospital_phone_number_2: "",
        hospital_registration_no: "",
        year_of_establishment: "",
        website_url: "",
        zone_id: "",
      },
    };
  },
  computed: {
    master() {
      return this.$store.getters.master;
    },
    years() {
      let year = 1899;
      let list = [];
      while (year < new Date().getFullYear()) {
        year++;
        list.push({
          name: year.toString(),
          value: year.toString(),
        });
      }
      return list.reverse();
    },
    isHospitalTypeDisabled() {
      return this.isDisabled || this.status == 2;
    },
  },
  methods: {
    openPayment() {
      window.open(PAYMENT_LINK, "_blank");
    },
    getMaxRules(max) {
      return getMaxRules(max);
    },
    getMaxEmailRules(max) {
      return getMaxEmailRules(max);
    },
    input() {
      this.$emit("input", this.form);
    },
    init() {
      const vm = this;
      Object.keys(vm.value).forEach((key) => {
        vm.$set(vm.form, key, vm.value[key]);
      });
      vm.form.year_of_establishment = vm.form.year_of_establishment.toString();
      if (vm.$route.name == "hospital-registration" && vm.user.user_type == 1) {
        vm.form.hospital_name = vm.user.hospital_name;
      }
    },
    onPhoneInput1(item, doc) {
      const vm = this;
      vm.phone = [
        (v) => !!v || "This field is required",
        doc.isValid || "Enter valid phone number",
      ];
      if (doc.country.dialCode !== undefined) {
        vm.form.hospital_country_code_1 = doc.country.dialCode;
      }
      if (doc.number.significant !== undefined) {
        vm.form.hospital_phone_number_1 = doc.number.significant;
      }
      vm.input();
    },
    onPhoneInput2(item, doc) {
      const vm = this;
      if (doc.number.significant) {
        vm.phone2 = [doc.isValid || "Enter valid phone number"];
      } else {
        vm.phone2 = [];
      }
      if (doc.country.dialCode !== undefined) {
        vm.form.hospital_country_code_2 = doc.country.dialCode;
      }
      if (doc.number.significant !== undefined) {
        vm.form.hospital_phone_number_2 = doc.number.significant;
      }
      vm.input();
    },
  },

  beforeMount() {
    this.init();
    this.input();
  },
};
</script>

<style></style>
