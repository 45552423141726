<template>
  <div class="d-flex" v-if="isCreateAble">
    <div class="flex-grow-1"></div>
    <select-hospital
      :hospitals="hospitals"
    ></select-hospital>
  </div>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import commonMixins from "../../assets/mixins/common.mixins";
import SelectHospital from "./SelectHospital.vue";
export default {
  props: {
    hospitals: Array,
  },
  components: { SelectHospital },
  mixins: [commonMixins],
  computed: {
    isUser() {
      return this.$route.name == "user-management";
    },
    isCreateAble() {
      if (this.isUser) {
        return this.hasCreateAccess(menuConstants.USER);
      }
      return this.hasCreateAccess(menuConstants.TRANSTAN_USER_LIST);
    },
  },
};
</script>

<style></style>
