<template>
  <div class="d-flex align-center">
    <b-button
      icon="x-octagon-fill"
      variant="danger"
      @click="open()"
      class="me-2"
    >
      Reject</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Reject Reason"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <input-field
              v-model="retrieval_detail_reject"
              label="Reason"
              rules="required"
            />
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import donorService from "../../../../../assets/services/donor.service";
export default {
  props: {
    item: {},
  },
  data() {
    return {
      dialog: false,
      retrieval_detail_reject: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        let { retrieval_detail_reject } = vm;
        let { recepeint_allocation_id } = vm.item;
        let doc = {
          recepeint_allocation_id,
          dml_indicator: "U",
          retrieval_detail_reject,
          organ_retrieval_status: 3,
        };
        vm.$loader.show();
        let { data } = await donorService.retrievalDetails(doc);
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    open() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to reject this?",
        onConfirm: () => {
          vm.dialog = true;
        },
      });
    },
  },
};
</script>

<style></style>
