import http from '../js/axios'
class Serive {
  create(data) {
    return http.post('/recipient_registration/live_transplant_detail', data)
  }
  list(data) {
    return http.post('/recipient_registration/live_transplant_detail_list', data)
  }
  get(id) {
    return http.post('/recipient_registration/live_transplant_detail_list', {
      "dml_indicator": "E",
      "live_transplant_detail_id": id
    })
  }
  updateStatus(data) {
    return http.post("/recipient_registration/live_transplant_detail_list", data);
  }
}
export default new Serive()
