<template>
  <b-dropdown variant="link" dropleft class="dropleft" v-if="isDelistable">
    <template #button-content>
      <b-icon icon="three-dots" class="font-size-16"></b-icon>
    </template>
    <b-dropdown-item @click="markDelisted()">Mark as Delisted </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import alfHelper from "./alf.helper";
export default {
  props: {
    item: Object,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    isDelistable() {
      let hasPermission = this.hasStatusAccess(
        menuConstants.LIVER_URGENT_LIST,
        6
      );
      return hasPermission && this.item.status == 3;
    },
  },
  methods: {
    markDelisted() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, You want to delist this ALF?",
        onConfirm: () => {
          alfHelper.updateStatus(6, vm.item.alf_id, vm);
        },
      });
    },
  },
};
</script>
<style>
.dropleft .dropdown-menu {
  left: -100% !important;
}
</style>
