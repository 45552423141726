<template>
  <div>
    <Expandable header="Organ Consented" class="mb-10">
      <b-row>
        <b-col cols="12" md="4" v-for="(item, i) in organs" :key="i">
          <button-yes-no
            :label="item.name"
            @input="onInput"
            :disabled="isOrganDisabled(item)"
            v-model="form[item.value]"
          ></button-yes-no>
        </b-col>
      </b-row>
    </Expandable>
    <Expandable header="Tissue Consented" class="mb-10">
      <b-row>
        <b-col cols="12" md="4" v-for="(item, i) in tissues" :key="i">
          <button-yes-no
            :label="item.name"
            @input="onInput"
            :disabled="isOrganDisabled(item)"
            v-model="form[item.value]"
          ></button-yes-no>
        </b-col>
      </b-row>
    </Expandable>
  </div>
</template>

<script>
import {
  HEART_ID,
  HEART_LUNG_ID,
  KIDNEY_ID,
  KIDNEY_LEFT_ID,
  KIDNEY_RIGHT_ID,
  LIVER_ID,
  LUNG_ID,
  SPLIT_LIVER_1_ID,
  SPLIT_LIVER_2_ID,
} from "../../../assets/js/common";
import Expandable from "../../Expandable.vue";
import ButtonYesNo from "../../form/ButtonYesNo.vue";
export default {
  props: {
    value: {},
    organAllocation: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  components: { Expandable, ButtonYesNo },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    organs() {
      if (this.master.master_organ instanceof Array == false) return [];
      let master = this.master.master_organ.filter((item) => {
        let { status, tissue_bank, consent_organ_id } = item;
        return status != 4 && consent_organ_id == 1 && tissue_bank == 0;
      });
      return master.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
    tissues() {
      if (this.master.master_organ instanceof Array == false) return [];
      let master = this.master.master_organ.filter((item) => {
        let { status, tissue_bank, consent_organ_id } = item;
        return status != 4 && consent_organ_id == 1 && tissue_bank == 1;
      });
      return master.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
  },
  methods: {
    isOrganDisabled({ value }) {
      if (this.isDisabled) return true;
      let organ = this.organAllocation.find((item) => {
        if (value == KIDNEY_ID) {
          let ids = [KIDNEY_LEFT_ID, KIDNEY_RIGHT_ID];
          return ids.indexOf(item.organ_id.toString()) != -1;
        }
        if (value == LIVER_ID) {
          let ids = [SPLIT_LIVER_1_ID, SPLIT_LIVER_2_ID, LIVER_ID];
          return ids.indexOf(item.organ_id.toString()) != -1;
        }
        if (value == HEART_ID) {
          let ids = [HEART_ID, HEART_LUNG_ID];
          return ids.indexOf(item.organ_id.toString()) != -1;
        }
        if (value == LUNG_ID) {
          let ids = [LUNG_ID, HEART_LUNG_ID];
          return ids.indexOf(item.organ_id.toString()) != -1;
        }
        return item.organ_id == value;
      });
      return organ != null;
    },

    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    if (this.value != undefined) this.form = this.value;
  },
  mounted() {},
};
</script>

<style></style>
