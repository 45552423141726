<template>
  <b-card class="oa">
    <div class="oa-bg"></div>
    <div
      class="
        oa-donor-details
        d-flex
        justify-content-between
        flex-column flex-sm-row
      "
    >
      <div class="oa-donor-left d-flex flex-column">
        <div class="avatar-md mb-2">
          <img
            :src="icon"
            @error="onError"
            class="img-thumbnail rounded-circle"
          />
        </div>
        <h4>{{ details.donor_name }}</h4>
        <p>{{ gender || "-" }} | {{ details.age || "-" }} Years</p>
        <b-row>
          <b-col class="bg-primary bg-soft">
            <h5 class="font-size-15 text-primary">
              {{ details.height || "-" }} cm
            </h5>
            <p class="font-size-11 text-primary mb-0">Height</p>
          </b-col>
          <b-col class="bg-primary bg-soft">
            <h5 class="font-size-15 text-primary">
              {{ details.weight || "-" }} kg
            </h5>
            <p class="font-size-11 text-primary mb-0">Weight</p>
          </b-col>
          <b-col class="bg-primary bg-soft">
            <h5 class="font-size-15 text-primary">
              {{ bloodGroup || "-" }}
            </h5>
            <p class="font-size-11 text-primary mb-0">Blood Group</p>
          </b-col>
        </b-row>
      </div>
      <div class="oa-donor-right d-flex flex-column justify-content-end">
        <h4>{{ details.hospital_name }}</h4>
        <b-row no-gutters class="ps-0">
          <b-col md="6" cols="12">
            <p class="mb-2">Medical Legal Case</p></b-col
          >
          <b-col md="6" cols="12"
            ><span class="text-muted">
              {{ details.medical_legal_case == 1 ? "Yes" : "No" }}</span
            ></b-col
          >
          <template v-if="details.medical_legal_case == 1">
            <b-col md="6" cols="12"><p class="mb-2">AR Case Number</p></b-col>
            <b-col md="6" cols="12"
              ><span class="text-muted"> {{ details.MLC_AR_No }}</span></b-col
            >
          </template>
          <b-col md="6" cols="12"> <p class="mb-2">Cause Of Death</p></b-col>
          <b-col md="6" cols="12"
            ><span class="text-muted"
              >{{ cause_of_death }}
              <template v-if="details.cause_of_death_notes">
                - {{ details.cause_of_death_notes }}
              </template>
            </span></b-col
          >
          <b-col md="6" cols="12"> <p class="mb-2">Date Of Admission</p></b-col>
          <b-col md="6" cols="12"
            ><span class="text-muted">
              {{ format(details.date_of_admisssion) }}</span
            ></b-col
          >
          <b-col md="6" cols="12">
            <p class="mb-0">Tentative Retrieval Date & Time</p></b-col
          >
          <b-col md="6" cols="12"
            ><span class="text-muted">
              {{ formatDate(details.tentative_retreival_date) }}</span
            ></b-col
          >
          <b-col md="6" cols="12">
            <p class="mb-0">Assessment Date & Time</p></b-col
          >
          <b-col md="6" cols="12"
            ><span class="text-muted">
              {{ formatDate(details.assessment_datetime) }}</span
            ></b-col
          >
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import { GENDERS } from "../../../assets/constants/hospital.contants";
import {
  formatDate,
  formatDateOnly,
  isDocObject,
} from "../../../assets/js/common";
export default {
  props: {
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      details: {},
    };
  },
  watch: {
    doc() {
      console.log(this.doc);
      this.init();
    },
  },
  computed: {
    cause_of_death() {
      let item = this.masterCauseOfDeath.find((item) => {
        return this.details.cause_of_death_id == item.value;
      });
      return item ? item.name : "";
    },
    icon() {
      switch (this.details.sex) {
        case 1:
          return require("../../../assets/images/man.png");
        case 2:
          return require("../../../assets/images/woman.png");
        default:
          return require("../../../assets/images/man.png");
      }
    },
    gender() {
      let item = GENDERS.find((item) => {
        return this.details.sex == item.value;
      });
      return item ? item.name : "";
    },
    bloodGroup() {
      let item = this.bloodGroups.find((item) => {
        return this.details.blood_group == item.value;
      });
      return item ? item.name : "";
    },
    photo() {
      return (
        this.details.photo || require("../../../assets/images/account.png")
      );
    },
  },
  methods: {
    init() {
      const vm = this;
      if (isDocObject(vm.doc.donor_list)) {
        vm.details = vm.doc.donor_list[0];
      }
    },
    format(date) {
      return formatDateOnly(date);
    },
    formatDate(date) {
      return formatDate(date);
    },
    onError(e) {
      e.target.src = require("../../../assets/images/account.png");
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
.oa {
  overflow: hidden;
  .card-body {
    padding: 0px;
  }
  &-bg {
    width: 100%;
    height: 150px;
    background-image: url("../../../assets/images/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &-donor-left {
    width: 350px;
  }
  &-donor-right {
    width: 400px;
    padding-top: 15px;
    p {
      font-weight: 600;
    }
    span {
      font-weight: normal;
      white-space: nowrap;
    }
    .col-6 {
      overflow: hidden;
      padding-left: 4px;
    }
  }
  &-donor-details {
    padding: 0px 20px;
    padding-bottom: 20px;
    .col {
      padding: 10px;
      border-radius: 5px;
      margin-right: 10px;
    }
    .avatar-md {
      margin-top: -36px;
    }
    .row {
      width: 100%;
      padding-left: 10px;
    }
  }
}
</style>
