<template>
  <div>
    <allocation-recipient-table
      title="Allocation"
      :fields="fields"
      :isBusy="isLoading"
      :isLast="isLast"
      :item="item"
      :items="list"
    >
      <template v-slot:action="{ index }">
        <div class="bg-primary bg-soft text-primary px-2 py-2 text-center">
          <!-- {{ getAllocationStatus(item.recepient_allocation_status) }} -->
          {{ getAllocationStatus(index + 1) }}
        </div>
      </template>
      <div
        class="d-flex mt-3"
        v-if="item.allocation_status == 43 && isAdmins && !isAllocationReport"
      >
        <div class="flex-grow-1"></div>
        <b-button
          variant="primary"
          v-if="isUpdateTransplantSugery"
          @click="updateTransplantSugery()"
        >
          Request Transplant Surgery Details
        </b-button>
      </div>
    </allocation-recipient-table>
  </div>
</template>

<script>
import {
  LOGISTICS_STATUS,
  RECIPIENT_ORGAN_ALLOCATION_STATUS,
} from "../../../../assets/constants/donor.constants";
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { formatDate } from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
export default {
  components: { AllocationRecipientTable },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
    status: {},
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: false,
      list: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isUpdateTransplantSugery() {
      let { list } = this;
      let updated = list.filter((item) => {
        return item.organ_retrieval_status == 1;
      });
      if (list.length == 0 || updated.length == 0) return false;
      return true;
    },
    isGenerateFinal() {
      let { list } = this;
      let updated = list.filter((item) => {
        return item.logistics_update_status != 2;
      });
      if (list.length == 0) return false;
      if (list.length != updated.length) return false;
      return true;
    },
    fields() {
      // let doc = {
      //   action: "Allocation Status",
      // };
      return {};
    },
  },
  methods: {
    getAllocationStatus(status) {
      let item = RECIPIENT_ORGAN_ALLOCATION_STATUS.find((item) => {
        return item.value == status;
      });
      return item != null ? item.name : "";
    },

    submit() {},
    getStatus(val) {
      let item = LOGISTICS_STATUS.find((item) => {
        return item.value == val;
      });
      return item ? item.name : "";
    },
    updateTransplantSugery() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to update transplant surgery?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let list = [];
            let { organ_allocation_id } = vm.item;
            vm.list.forEach((item) => {
              let { recepeint_allocation_id } = item;
              let doc = {
                recepeint_allocation_id,
                organ_allocation_id,
                organ_surgery_by: vm.created_by,
                dml_indicator: "OSS",
              };
              list.push(doc);
            });
            let { data } =
              await donorService.organAllocationWaitingListStatusUpdate({
                recepient_organ_allocation_proc: list,
              });
            let { status, msg } = data;
            vm.isLoading = false;
            vm.$loader.hide();
            event.$emit("refresh");
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async init() {
      const vm = this;
      let {
        organ_id,
        organ_allocation_id,
        type_of_allocation,
        organ_id_org,
        is_multi,
      } = vm.item;
      if (is_multi) organ_id = organ_id_org;

      const doc = {
        dml_indicator: "LOSFT",
        organ_id,
        organ_allocation_id,
      };
      if (type_of_allocation == 1) doc.dml_indicator = "LOSLT";
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
