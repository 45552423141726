<template>
  <Expandable header="Details" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <radio-group
          v-model="form.patient_nationlity"
          label="Recipient Nationality"
          :options="masterNationality"
          @input="onInput"
          :disabled="isAadharVerified || isDisabled"
          rules="required"
        ></radio-group>
      </b-col>
      <template v-if="isIndian">
        <b-col cols="12" md="4">
          <input-group
            label="Aadhar ID"
            :disabled="isAadharVerified || isDisabled"
            type="text"
            name="aadhar_number"
            @input="onInput"
            rules="required|aadhar|dec-0"
            v-model="form.aadhar_number"
          >
            <template v-slot:help>
              <verify-aadhar-otp
                v-if="!isAadharVerified"
                :number="form.aadhar_number"
              ></verify-aadhar-otp>
            </template>
          </input-group>
        </b-col>
        <!-- VISHWA NATH GUPTA  -->
      </template>
      <template v-else>
        <b-col cols="12" md="4">
          <input-field
            v-model.trim="form.passport"
            :disabled="isDisabled"
            label="Passport"
            @input="onInput"
            rules="required|max-100"
          ></input-field>
        </b-col>
        <b-col cols="12" md="4">
          <file-input-group
            v-model.trim="form.passport_document"
            label="Passport Document"
            :disabled="isDisabled"
            accept=".jpg,capture=camera,.jpeg,.png,.pdf"
            @input="onInput"
            rules="required|file-image-pdf|file-size"
          ></file-input-group>
        </b-col>

        <b-col cols="12" md="4">
          <file-input-group
            v-model.trim="form.embassy_documents"
            label="Embassy Document"
            :disabled="isDisabled"
            accept=".jpg,capture=camera,.jpeg,.png,.pdf"
            @input="onInput"
            rules="required|file-image-pdf|file-size"
          ></file-input-group>
        </b-col>
      </template>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.first_name"
          label="Recipient Name"
          :disabled="isAadharVerified || isDisabled"
          @input="onInput"
          rules="required|max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <date-picker
          v-model="form.DOB"
          :disabled="isDisabled"
          label="Recipient Date of Birth"
          @input="onInput"
          outlined
          :max="new Date()"
          rules="required"
        ></date-picker>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.age"
          label="Recipient Age"
          @input="onInput"
          :disabled="true"
          type="number"
          outlined
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          v-model="form.gender"
          label="Gender"
          @input="onInput"
          rules="required"
          :items="genders"
          :disabled="isDisabled"
          outlined
        ></select-field>
      </b-col>
      <b-col cols="12" md="4">
        <phone-field
          v-model.trim="form.phone_number_1"
          :defaultCountry="form.country_code_1"
          label="Phone Number"
          @input="onInput"
          :disabled="isDisabled"
          @onCountryCode="form.country_code_1 = $event"
          rules="required|integer"
        ></phone-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          @input="onInput"
          v-model.trim="form.email_id"
          label="Email"
          :disabled="isDisabled"
          invalid="Enter a valid email address"
          rules="email"
        ></input-field>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.last_name"
          label="Recipient Last Name"
          @input="onInput"
          rules="max-100"
        ></input-field>
      </b-col> -->
      <b-col cols="12" md="4">
        <select-field
          @input="onInput"
          label="Blood Group"
          :disabled="isDisabled"
          :items="bloodGroups"
          v-model="form.blood_group"
          rules="required"
        ></select-field>
      </b-col>
      <template v-if="isIndian">
        <b-col cols="12" md="4">
          <select-field
            @input="onInput"
            label="CM Insurance"
            :items="masterCMInsurance"
            :disabled="isDisabled"
            v-model="form.CM_insurance"
            rules="required"
          ></select-field>
        </b-col>
        <b-col cols="12" md="4" v-if="isCMFile">
          <file-input-group
            v-model.trim="form.CM_insurance_notes"
            label="Insurance Document"
            :disabled="isDisabled"
            accept=".jpg,capture=camera,.jpeg,.png,.pdf"
            @input="onInput"
            rules="required|file-image-pdf|file-size"
          ></file-input-group>
        </b-col>
      </template>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.notto_id"
          label="NOTTO Registration No"
          @input="onInput"
          :disabled="isDisabled"
          rules=""
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.payment_reference_no"
          label="Payment Reference No"
          @input="onInput"
          :disabled="true"
        >
          <!-- <template v-slot:help>
            <b-button @click="openPayment()" variant="primary"
              >Payment Link</b-button
            >
          </template> -->
        </input-field>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import RadioGroup from "../../form/RadioGroup.vue";
import SelectField from "../../form/SelectField.vue";
import FileInputGroup from "../../form/FileInputGroup.vue";
import PhoneField from "../../form/PhoneField.vue";
import DatePicker from "../../form/DatePicker.vue";
import { getAge, PAYMENT_LINK } from "../../../assets/js/common";
import { GENDERS } from "../../../assets/constants/hospital.contants";
import VerifyAadharOtp from "./VerifyAadharOtp.vue";

export default {
  props: {
    value: {},
    hospitalType: {},
    isAadharVerified: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Expandable,
    RadioGroup,
    SelectField,
    FileInputGroup,
    PhoneField,
    DatePicker,
    VerifyAadharOtp,
  },
  data() {
    return {
      form: {},
      genders: GENDERS,
    };
  },
  computed: {
    isIndian() {
      return this.form.patient_nationlity == 0;
    },
    isGoverMentHospital() {
      return this.hospitalType == 1;
    },
    isCMFile() {
      let insurance = this.form.CM_insurance;
      return insurance == 1 || insurance == 3;
    },
  },
  methods: {
    setAadharDetails(doc) {
      Object.keys(doc).forEach((key) => {
        this.form[key] = doc[key];
      });
      this.onInput();
    },
    openPayment() {
      window.open(PAYMENT_LINK, "_blank");
    },
    onInput() {
      let { DOB } = this.form;
      if (DOB) {
        this.form.age = getAge(DOB);
      }
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
    this.onInput();
  },
};
</script>

<style></style>
