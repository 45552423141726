<template>
  <div>
    <b-button
      icon="x-octagon-fill"
      @click="dialog = true"
      variant="primary"
      style="width: 100%"
      v-if="isAdmins"
    >
      Allocate To Other State</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Allocate To Other State"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="md"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row>
          <b-col cols="6">
            <select-field
              :items="states"
              label="Other State"
              rules="required"
              v-model="form.other_state"
            ></select-field>
          </b-col>
          <b-col cols="6">
            <input-field
              label="Hospital Name"
              rules="required"
              v-model="form.hospital_name"
            ></input-field>
          </b-col>
          <b-col cols="12">
            <text-area
              label="Recipient Details"
              rules="required"
              v-model="form.recipient_details"
            ></text-area>
          </b-col>
          <b-col cols="12">
            <text-area label="Notes" rules="" v-model="form.notes"></text-area>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" class="ms-3" type="submit"
            >Submit
          </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import event from "../../../../../assets/js/event";
import donorService from "../../../../../assets/services/donor.service";
import masterService from "../../../../../assets/services/master.service";
import InputField from "../../../../form/InputField.vue";
import SelectField from "../../../../form/SelectField.vue";
import TextArea from "../../../../form/TextArea.vue";
export default {
  components: { InputField, SelectField, TextArea },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      states: [],
      form: {
        other_state: "",
        recipient_details: "",
        notes: "",
        hospital_name: "",
      },
    };
  },
  computed: {},
  methods: {
    reset() {
      this.form = {};
    },
    async fetchStates() {
      const vm = this;
      vm.states = [];
      try {
        let { data } = await masterService.getStateCity({
          country_id: "99",
        });
        let { list, status, msg } = data;
        if (status) {
          vm.states = list.map((item) => {
            return { name: item.name, value: item.id };
          });
        } else {
          console.log("Error while fetching the states", msg);
        }
      } catch (error) {
        console.log("Error while fetching the states", error.message);
      }
    },
    async submit() {
      const vm = this;
      let { organ_allocation_id } = vm.item;
      let doc = {
        dml_indicator: "I",
        organ_allocation_id,
        organ_allocation_other_state_id: null,
        created_by: vm.created_by,
      };
      doc = { ...doc, ...vm.form };
      try {
        vm.$loader.show();
        let { data } = await donorService.allocateToOtherState(doc);
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
        event.$emit("refresh");
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
  mounted() {
    this.fetchStates();
  },
};
</script>

<style></style>
