<template>
  <form-wrapper @submit="submit" v-if="!isLoading">
    <organ-requested
      v-if="isOrganAllocation"
      :isDisabled="isDisabled"
      v-model="organ_requested"
    ></organ-requested>
    <Details v-model="details" :isDisabled="isDisabled"></Details>
    <cause-of-death
      :details="details"
      v-model="cause_of_death"
      :isDisabled="isDisabled"
    ></cause-of-death>
    <apnea-test-details
      v-model="apnea_test_details"
      :isDisabled="isDisabled"
    ></apnea-test-details>
    <donor-contact
      :donor="details"
      v-model="contact"
      :isDisabled="isDisabled"
    ></donor-contact>
    <consent-attachments
      v-model="consentFiles"
      :filesList="filesList"
      ref="consentAttachments"
      :isDisabled="isDisabled"
      @onFileRemoved="onFileRemoved($event, 'consentAttachments')"
    ></consent-attachments>
    <organ-requested
      v-if="!isOrganAllocation"
      :isDisabled="isDisabled"
      :organAllocation="organ_allocation"
      v-model="organ_requested"
    ></organ-requested>
    <co-morbidities
      v-model="co_morbidities"
      :isDisabled="isDisabled"
      @onCovideChange="covid_free_period = $event"
      :covid_free_period="covid_free_period"
    ></co-morbidities>
    <injuries v-model="injuries" :isDisabled="isDisabled"></injuries>
    <medical-details v-model="medical_details" :isDisabled="isDisabled">
    </medical-details>
    <ventilator-settings
      v-model="ventilator_settings"
      :isDisabled="isDisabled"
    ></ventilator-settings>
    <APGTest v-model="apg_test" :isDisabled="isDisabled"></APGTest>
    <donor-legal-form
      v-model="formFiles"
      :filesList="filesList"
      ref="formAttachments"
      :isDisabled="isDisabled"
      @onFileRemoved="onFileRemoved($event, 'formAttachments')"
    ></donor-legal-form>
    <resistance-measure
      v-model="resistance_measure"
      :isDisabled="isDisabled"
    ></resistance-measure>
    <medications-inotrope
      v-model="medication_inotrope"
      :isDisabled="isDisabled"
    ></medications-inotrope>
    <medication
      v-if="false"
      v-model="medications"
      :isDisabled="isDisabled"
    ></medication>
    <attachments
      v-model="files"
      :filesList="filesList"
      ref="attachments"
      :isDisabled="isDisabled"
      @onFileRemoved="onFileRemoved($event, 'attachments')"
    ></attachments>
    <Footer
      v-if="!isOrganAllocation && !isAllocationReport"
      :status="details.status"
      :isDisabled="isDisabled"
      @draft="submit(true)"
    ></Footer>
  </form-wrapper>
</template>

<script>
import Details from "../../components/managedonor/create/Details.vue";
import MedicalDetails from "../../components/managedonor/create/MedicalDetails.vue";
import OrganRequested from "../../components/managedonor/create/OrganRequested.vue";
import Footer from "../../components/managedonor/create/Footer.vue";
import DonorHelper from "../../components/managedonor/donor.helper.js";
import DonorService from "../../assets/services/donor.service";
import { decode } from "../../assets/js/common";
import donorMixins from "../../assets/mixins/donor.mixins";
import Attachments from "../../components/managedonor/create/Attachments.vue";
import DonorContact from "../../components/managedonor/create/DonorContact.vue";
import VentilatorSettings from "../../components/managedonor/create/VentilatorSettings.vue";
import Injuries from "../../components/managedonor/create/Injuries.vue";
import CoMorbidities from "../../components/managedonor/create/CoMorbidities.vue";
import APGTest from "../../components/managedonor/create/APGTest.vue";
import ApneaTestDetails from "../../components/managedonor/create/ApneaTestDetails.vue";
import Medication from "../../components/managedonor/create/Medication.vue";
import CauseOfDeath from "../../components/managedonor/create/CauseOfDeath.vue";
import ResistanceMeasure from "../../components/managedonor/create/ResistanceMeasure.vue";
import MedicationsInotrope from "../../components/managedonor/create/MedicationsInotrope.vue";
import ConsentAttachments from "../../components/managedonor/create/ConsentAttachments.vue";
import userHelper from "../../components/manageuser/user.helper";
import DonorLegalForm from "../../components/managedonor/create/DonorLegalForm.vue";
import menuConstants from "../../assets/constants/menu.constants";
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Details,
    OrganRequested,
    MedicalDetails,
    Footer,
    Attachments,
    DonorContact,
    VentilatorSettings,
    Injuries,
    CoMorbidities,
    APGTest,
    ApneaTestDetails,
    Medication,
    CauseOfDeath,
    ResistanceMeasure,
    MedicationsInotrope,
    ConsentAttachments,
    DonorLegalForm,
  },
  data() {
    return {
      contacts: [],
      files: [],
      consentFiles: [],
      formFiles: [],
      filesList: [],
      isLoading: true,
      covid_free_period: "",
      organ_requested: {},
      organ_allocation: [],
      details: {
        donor_name: "",
        organ_harvesting_date: "",
        age: "",
        DOB: "",
        sex: "",
        blood_group: "",
        height: "",
        weight: "",
        BMI: "",
        medical_legal_case: "",
        MLC_AR_No: "",
        status: 8,
        created_by: "",
        dml_indicator: "I",
        hospital_id: "",
        individual_id: "",
        donor_temp_registration_id: "",
      },
      cause_of_death: {
        cause_of_death_id: "",
        cause_of_death_notes: "",
        date_of_admisssion: "",
        date_of_accident: "",
        No_of_days_ventilator: "",
        tentative_retreival_date: "",
        assessment_datetime: "",
      },
      contact: {
        first_name: "",
        last_name: "",
        email_id: "",
        country_code_1: "",
        phone_number_1: "",
        country_code_2: "",
        phone_number_2: "",
        contact_id: "",
        type_of_consent: "",
        conset_given: "",
        type_of_consent_upload: "",
      },
      medical_details: {
        pulse_rate: "",
        systolic: "",
        diastolic: "",
        MAP: "",
        CVP: "",
        urine_output: "",
        urine_unit: 1,
        SPO2: "",
        temperature: "",
      },
      ventilator_settings: {
        ventilation: "",
        FIO2: "",
        PEEP: "",
        PIP: "",
        TV: "",
        RR: "",
        pressure_support: "",
        VCV: "",
        PCV: "",
        others: "",
      },
      injuries: {
        chest_injuries: "",
        abdomen_injuries: "",
        notes: "",
      },
      resistance_measure: {
        CPR_given: "",
        CPR_notes: "",
        hypotensive_episodes: "",
      },
      co_morbidities: {},
      apg_test: {
        ABG_file_upload_1: "",
        ABG_file_upload_2: "",
        ABG_file_upload_3: "",
        ABG_file_upload_4: "",
        FIO2_1: "",
        FIO2_2: "",
        FIO2_3: "",
        FIO2_4: "",
      },
      medication_inotrope: {
        inotrope_1: "",
        inotrope_2: "",
        inotrope_3: "",
        inotrope_4: "",
        harmone_replacement_theraphy: "",
        steroids: "",
        vasopressin: "",
        thyroxine: "",
      },
      apnea_test_details: {
        first_apnea_date: "",
        first_apnea_status: "",
        first_apnea_file_upload_1: null,
        first_apnea_file_upload_2: null,
        second_apnea_date: "",
        second_apnea_status: "",
        second_apnea_file_upload_1: "",
        second_apnea_file_upload_2: "",
      },
      medications: [],
      updateDoc: {},
    };
  },
  mixins: [donorMixins],
  computed: {
    isDisabled() {
      if (this.isOrganAllocation || this.isAllocationReport) return true;
      let hasEditAccess = this.hasEditAccess(menuConstants.MANAGE_DONOR);
      let statusList = [2, 9, 7];
      return !hasEditAccess || statusList.indexOf(this.details.status) != -1;
    },
    isAadharNotPermanent() {
      return this.aadhar_address.is_permanent_address == "0";
    },
  },
  methods: {
    onFileRemoved(id, ref) {
      const vm = this;
      let list = vm.filesList.filter((item) => {
        return item.donor_investigations_fileupload_mapping_id != id;
      });
      vm.filesList = list;
      setTimeout(() => {
        vm.$refs[ref].init();
      }, 500);
    },
    isParameter(id) {
      return this.organ_requested.organ_id.indexOf(id) != -1;
    },
    async submit(isDraft) {
      const vm = this;
      let reviewStatus = [3, 4];
      try {
        let doc = DonorHelper.getDoc(vm);
        if (!isDraft) {
          if (doc.status == 8) {
            doc.status = 1;
          }
          if (reviewStatus.indexOf(doc.status) != -1) {
            doc.status = 5;
          }
          if (doc.conset_given == 0) {
            doc.status = 7;
          }
        }
        let details = [];
        Object.keys(doc).forEach((key) => {
          details.push({ key, value: doc[key] });
        });
        if (isDraft) {
          let draftFields = {
            blood_group: "Blood Group",
            cause_of_death_id: "Cause of Brain Death",
            contact_id: "Donor Relationship",
          };
          let list = [];
          Object.keys(draftFields).forEach((key) => {
            if (!doc[key]) {
              list.push(draftFields[key]);
            }
          });
          if (list.length > 0) {
            console.log(list);
            vm.$alert.show(
              `Please update the following fields to save as draft <b>${list.toString()}</b>`
            );
            return;
          }
        }
        vm.$loader.show();

        for (let item of details) {
          let { key, value } = item;
          if (value instanceof File) {
            doc[key] = await userHelper.uploadContact(value);
          } else {
            doc[key] = value;
          }
        }

        let { data } = await DonorService.create(doc);
        let { msg, status, donor_temp_registration_id } = data;
        if (status == false) {
          vm.$alert.show(msg);
          vm.$loader.hide();
          return;
        }
        let files = vm.files.concat(vm.consentFiles);
        files = files.concat(vm.formFiles);
        for (let item of files) {
          if (item.file !== null) {
            await DonorHelper.upload(item, donor_temp_registration_id, vm);
          }
        }
        if (vm.isUpdate) {
          vm.$alert.show("Donor Updated successfully");
        } else {
          vm.$alert.show("Donor created successfully");
        }
        vm.$router.push("/manage-donor");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    init() {
      const vm = this;
      let { created_by } = vm;
      vm.details.created_by = created_by;
      if (vm.isCreate) {
        vm.details.hospital_id = decode(vm.$route.query.hospital_id);
      }
      if (vm.isUpdate) {
        DonorHelper.getRecipient(vm);
      } else {
        vm.isLoading = false;
      }
      // window.$("form").each(function () {
      //   console.log(window.$(this).find(":input"));
      // });
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {},
};
</script>

<style></style>
