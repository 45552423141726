import { GENDERS, HOSPITAL_TYPES } from "../constants/hospital.contants";
import { isMobile } from "../js/common.js";
import {
  BE_READY_STATUS,
  CM_INSURANCE_STATUS,
  NATIONALITY,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../constants/recipient.constants";
import { AGE_TYPES } from "../constants/waiting.list.constants";
import { getSelect, LEFT_LUNG_ID, RIGHT_LUNG_ID } from "../js/common.js";
import menuConstants from "../constants/menu.constants.js";
export default {
  computed: {
    isMobile() {
      return isMobile();
    },
    user() {
      return this.$store.getters.user;
    },
    userHospital() {
      return this.user.hospital_id;
    },
    created_by() {
      return this.$store.getters.user.registration_id;
    },
    isAdmin() {
      return this.user.user_type == 2;
    },
    isSubAdmin() {
      return this.user.user_type == 3;
    },
    isCaseOfficer() {
      return this.user.contact_id == 32;
    },
    isAdmins() {
      return this.isAdmin || this.isSubAdmin;
    },
    isHospitalAdmin() {
      let { user_type, contact_type } = this.user;
      return user_type == 1 && contact_type == 1;
    },
    isHospitalContact() {
      let { user_type, contact_type } = this.user;
      return user_type == 1 && (contact_type == 1 || contact_type == 2);
    },
    isHospitalOrgan() {
      let { user_type, contact_type } = this.user;
      return user_type == 1 && contact_type == 2;
    },
    isHospitalOrganCoordinate() {
      let { user_type, contact_type, contact_id } = this.user;
      return (
        user_type == 1 &&
        (contact_type == 1 || (contact_type == 2 && contact_id == 3))
      );
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
    master() {
      return this.$store.getters.master;
    },
    bloodGroups() {
      if (this.master.master_bloodgroup instanceof Array == false) return [];
      let master = this.master.master_bloodgroup.filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        return { value: item.blood_id, name: item.group_desc };
      });
    },
    filterBloodGroups() {
      let filters = ["O", "A", "B", "AB"];
      return filters.map((name) => {
        return { name, value: name };
      });
    },
    masterOrgans() {
      if (this.master.master_organ instanceof Array == false) return [];
      let master = [...this.master.master_organ].filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        item.value = item.organ_id;
        item.name = item.organ_name;
        return item;
      });
    },
    filterMasterOrgans() {
      if (this.master.master_organ instanceof Array == false) return [];
      let master = [...this.master.master_organ].filter((item) => {
        let {
          status,
          allocation_available,
          tissue_bank,
          license_available,
          organ_id,
        } = item;
        let isLung =
          [LEFT_LUNG_ID, RIGHT_LUNG_ID].indexOf(organ_id.toString()) != -1;

        return (
          status != 4 &&
          allocation_available == 1 &&
          (license_available == 1 || isLung) &&
          tissue_bank == 0
        );
      });
      return master.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
    masterFileUploads() {
      if (this.master.master_fileupload instanceof Array == false) return [];
      return this.master.master_fileupload.filter((item) => {
        return item.status != 4;
      });
    },
    masterContactTypes() {
      if (this.master.master_contact instanceof Array == false) return [];
      let master = this.master.master_contact.filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        return {
          value: item.contact_id,
          name: item.contact_name,
          contact_type: item.contact_type,
        };
      });
    },
    masterVaccinations() {
      return this.$store.getters.vaccinations.map((item) => {
        return {
          value: item.master_vaccination_id,
          name: item.vaccination_name,
        };
      });
    },
    masterDonorTermination() {
      let master = this.$store.getters.masterDonorTermination.filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        return { value: item.termination_id, name: item.termination_name };
      });
    },
    masterCauseOfDeath() {
      let master = this.$store.getters.masterCauseOfDeath.filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        return { value: item.cause_of_death_id, name: item.cause_of_death };
      });
    },
    masterFileUploadTypes() {
      let master = this.$store.getters.masterfileUploadTypes.filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        return {
          value: item.file_upload_type_id,
          name: item.file_upload_type_name,
        };
      });
    },
    masterDonorMedicalDetails() {
      let master = this.$store.getters.masterDonorMedicalDetails.filter(
        (item) => {
          return item.status != 4;
        }
      );
      return master.map((item) => {
        return {
          value: item.donor_medical_detail_id,
          name: item.donor_medical_detail,
        };
      });
    },
    masterHospitalTypes() {
      return HOSPITAL_TYPES;
    },
    masterZones() {
      if (this.master.master_zone instanceof Array == false) return [];
      let master = this.master.master_zone.filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        return { value: item.zone_id, name: item.zone_name };
      });
    },
    masterDepartments() {
      if (this.master.department instanceof Array == false) return [];
      let master = this.master.department.filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        return { value: item.department_id, name: item.department_name };
      });
    },
    masterQualifications() {
      if (this.master.master_qualification instanceof Array == false) return [];
      let master = this.master.master_qualification.filter((item) => {
        return item.status != 4;
      });
      return master.map((item) => {
        return { value: item.qualification_id, name: item.qualification_name };
      });
    },

    masterNationality() {
      return NATIONALITY;
    },
    masterAgeTypes() {
      return AGE_TYPES;
    },
    masterALF() {
      return this.$store.getters.masterALF.map((item) => {
        return { value: item.master_alf_id, name: item.alf_name };
      });
    },
    masterCaseOffer() {
      return this.$store.getters.masterCaseOffer.map((item) => {
        return { value: item.registration_id, name: item.first_name };
      });
    },
    masterCMInsurance() {
      return CM_INSURANCE_STATUS;
    },
    query() {
      return this.$store.getters.query;
    },
  },
  methods: {
    getSelect(list) {
      return getSelect(list);
    },
    hasStatusAccess(id, status) {
      let { userRights } = this.$store.getters;
      let item = userRights.find((item) => {
        return item.module_id == id;
      });
      if (item == null) return false;
      if (item.permit != 1) return false;
      if (!item.user_rights_status) return false;
      let statusList = item.user_rights_status.split(",");
      return statusList.indexOf(status.toString()) != -1;
    },
    hasEditAccess(id) {
      let { userRights } = this.$store.getters;
      let item = userRights.find((item) => {
        return item.module_id == id;
      });
      if (item == null) return false;
      if (item.permit != 1) return false;
      return item[menuConstants.USER_EDIT] == 1;
    },
    hasViewAccess(id) {
      let { userRights } = this.$store.getters;
      let item = userRights.find((item) => {
        return item.module_id == id;
      });
      if (item == null) return false;
      if (item.permit != 1) return false;
      return item[menuConstants.USER_VIEW] == 1;
    },
    hasCreateAccess(id) {
      let { userRights } = this.$store.getters;
      let item = userRights.find((item) => {
        return item.module_id == id;
      });
      if (item == null) return false;
      if (item.permit != 1) return false;
      return item[menuConstants.USER_CREATE] == 1;
    },
    getCMInsuranceStatus(val) {
      if (!val) return "";
      let item = CM_INSURANCE_STATUS.find((item) => {
        return item.value == val.toString();
      });
      return item ? item.name : "";
    },
    getPaymentStatus(val) {
      if (!val) return "Unpaid";
      let item = PAYMENT_STATUS.find((item) => {
        return item.value == val.toString();
      });
      return item ? item.name : "Unpaid";
    },
    getPatientStatus(val) {
      if (!val) return "";
      let item = PATIENT_STATUS.find((item) => {
        return item.value == val.toString();
      });
      return item ? item.name : "";
    },
    getBeReadyStatus(val) {
      if (!val) return "";
      let item = BE_READY_STATUS.find((item) => {
        return item.value == val.toString();
      });
      return item ? item.name : "";
    },
    getGender(val) {
      if (!val) return "";
      let item = GENDERS.find((item) => {
        return item.value == val.toString();
      });
      return item ? item.name : "";
    },
    getBloodGroup(val) {
      if (!val) return "";
      let item = this.bloodGroups.find((item) => {
        return item.value == val;
      });
      return item ? item.name : "";
    },
    prependAll(list) {
      list = [...list];
      list.unshift({ name: "All", value: "all" });
      return list;
    },
  },
  // mounted() {
  //   document.body.scrollTop = 0;
  //   document.documentElement.scrollTop = 0;
  // }
};
