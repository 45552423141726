<template>
  <div v-if="!loading">
    <organ-allocation-report-html-print
      :show-layout="false"
      :float-layout="false"
      :enable-download="enableDownload"
      :preview-modal="previewMode"
      @beforeDownload="$loader.show()"
      @hasDownloaded="$loader.hide()"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      ref="print"
    >
      <section slot="pdf-content">
        <template v-if="isVisiable">
          <donor-summary :donor="donor"></donor-summary>
          <organ-allocation-summary
            :list="donor.list"
          ></organ-allocation-summary>
        </template>
      </section>
    </organ-allocation-report-html-print>
  </div>
</template>

<script>
import donorService from "../../assets/services/donor.service";
import DonorSummary from "../../components/organAllocationReport/DonorSummary.vue";
import OrganAllocationSummary from "../../components/organAllocationReport/OrganAllocationSummary.vue";
import { decode, sleep } from "../../assets/js/common";
import OrganAllocationReportHtmlPrint from "./OrganAllocationReportHtmlPrint.vue";
export default {
  data() {
    return {
      donor: {},
      enableDownload: false,
      isVisiable: false,
      loading: true,
      previewMode: true,
    };
  },
  components: {
    DonorSummary,
    OrganAllocationSummary,
    OrganAllocationReportHtmlPrint,
  },
  methods: {
    async fetch({ donor_temp_registration_id }) {
      const vm = this;
      try {
        if (vm.isMobile) {
          vm.enableDownload = true;
          vm.previewMode = false;
        }
        vm.$loader.show();
        vm.loading = true;
        let doc = {
          donor_temp_registration_id,
        };
        let donor = (await donorService.organAllocationReport(doc)).data;
        let { status, msg } = donor;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.loading = false;
        vm.donor = donor;
        vm.$loader.hide();
        vm.isVisiable = true;
        await sleep(1000);
        this.$refs.print.generatePdf();
        await sleep(1000);
        // vm.isVisiable = false;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    if (this.$route.name == "organ-allocation-report") {
      let id = decode(this.$route.query.id);
      this.fetch({ donor_temp_registration_id: id });
    }
  },
};
</script>

<style>
@media print {
  div {
    break-inside: avoid;
  }
  @page {
    margin-left: 17mm;
    margin-top: 10mm;
  }
}
</style>