<template>
  <ValidationProvider :rules="rules" :vid="vid" v-slot="validationContext">
    <b-form-group :invalid-feedback="invalid" class="mb-3">
      <template v-slot:label>
        <span v-html="formattedLabel"></span>
      </template>

      <b-form-select
        class="form-select"
        v-model="model"
        :type="type"
        :disabled="disabled"
        :options="items"
        :text-field="textField"
        :value-field="valueField"
        :state="getValidationState(validationContext)"
        @input="$emit('input', model)"
      ></b-form-select>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
export default {
  components: {},
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    textField: {
      type: String,
      default: "name",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    valueField: {
      type: String,
      default: "value",
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: {
      type: String,
      default: "This field is required",
    },
    value: {},
  },
  data() {
    return {
      model: "",
    };
  },
  computed: {
    formattedLabel() {
      if(!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  watch: {
    value(val) {
      if (val != undefined) {
        this.model = val.toString();
      } else {
        this.model = "";
      }
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    if (this.value != undefined) {
      this.model = this.value.toString();
    }
  },
};
</script>
