<template>
  <Expandable header="Donor Details" class="mb-10">
    <b-row>
      <!-- <b-col cols="12" md="4">
        <date-picker
          v-model="form.organ_harvesting_date"
          label="Organ Harvesting Date"
          outlined
          @input="onInput"
          rules="required|max-50"
        ></date-picker>
      </b-col> -->
      <b-col cols="12" md="4">
        <input-field
          v-model="form.donor_name"
          label="Name of Donor"
          @input="onInput"
          :disabled="isDisabled"
          rules="required"
        >
        </input-field>
      </b-col>

      <b-col cols="12" md="4">
        <date-picker
          v-model="form.DOB"
          label="Date of Birth"
          @input="onInput"
          outlined
          :disabled="isDisabled"
          :max="new Date()"
          rules="required"
        ></date-picker>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.age"
          label="Age"
          @input="onInput"
          :disabled="true"
          type="number"
          outlined
          rules="max_num_120"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          @input="onInput"
          label="Gender"
          :disabled="isDisabled"
          :items="gender"
          v-model="form.sex"
          rules="required"
        ></select-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          @input="onInput"
          label="Blood Group"
          :items="bloodGroups"
          :disabled="isDisabled"
          v-model="form.blood_group"
          rules="required"
        ></select-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Height"
          v-model="form.height"
          @input="onInput"
          rules="required|height|dec-0"
          :disabled="isDisabled"
          unit="cm"
          type="text"
        >
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Weight"
          :disabled="isDisabled"
          v-model="form.weight"
          rules="required|weight|dec-2"
          unit="kg"
          @input="onInput"
          type="text"
        >
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="BMI"
          @input="onInput"
          v-model="form.BMI"
          rules="required"
          unit="Kg/m<sup>2</sup>"
          :disabled="true"
          type="number"
        >
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.medical_legal_case"
          label="Is MLC Case?"
          rules="required"
        >
        </yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          :disabled="isDisabled"
          @input="onInput"
          label="AR Case Number"
          v-model="form.MLC_AR_No"
          :rules="form.medical_legal_case == 1 ? 'required' : ''"
        >
        </input-field>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import SelectField from "../../form/SelectField.vue";
import DatePicker from "../../form/DatePicker.vue";
import InputField from "../../form/InputField.vue";
import YesNo from "../../form/YesNo.vue";
import { GENDERS } from "../../../assets/constants/hospital.contants";
import { getAge } from "../../../assets/js/common";
export default {
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  components: { Expandable, SelectField, DatePicker, InputField, YesNo },
  data() {
    return {
      form: {},
      gender: GENDERS,
    };
  },
  methods: {
    onInput() {
      const vm = this;
      let { height, weight } = vm.form;
      height = height / 100;
      let bmi = weight / (height * height);
      vm.form.BMI = bmi.toFixed(2);
      let { DOB } = vm.form;
      if (DOB) {
        this.form.age = getAge(DOB);
      }
      vm.$emit("input", vm.form);
    },
  },
  beforeMount() {
    this.form = this.value;
    this.onInput();
  },
};
</script>

<style></style>
