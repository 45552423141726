var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-view',{attrs:{"isBusy":_vm.isLoading,"fields":_vm.fields,"items":_vm.list,"title":"Organ"},scopedSlots:_vm._u([{key:"add",fn:function(){return [(_vm.isCreate)?_c('create-organ',{on:{"init":_vm.init}}):_vm._e()]},proxy:true},{key:"license_available",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value == 1 ? "Yes" : "No")+" ")]}},{key:"allocation_available",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value == 1 ? "Yes" : "No")+" ")]}},{key:"status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value == 1 ? "Active" : "Deleted")+" ")]}},{key:"action",fn:function(ref){
var item = ref.item;
return [(_vm.isAction(item))?_c('div',{staticClass:"d-flex"},[_c('create-organ',{attrs:{"item":item},on:{"init":_vm.init}}),_c('delete-button',{on:{"click":function($event){return _vm.remove(item)}}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }