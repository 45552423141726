<template>
  <collapse-filter>
    <b-card class="mt-4 mb-4">
      <div class="h4">Filter</div>
      <form-wrapper @submit="submit">
        <b-row>
          <template v-if="!isHospitalContact">
            <b-col cols="12" md="4">
              <autocomplete
                label="Hospital"
                v-model="form.hospital_id"
                :items="hospitals"
              ></autocomplete>
            </b-col>
          </template>
          <b-col cols="12" md="4">
            <select-field
              label="Payment Status"
              v-model="form.payment_status"
              :items="paymentStatus"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Patient Status"
              :items="patientStatus"
              v-model="form.transtan_status"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4" v-if="false">
            <select-field
              label="Be Ready Status"
              :items="beReadyStatus"
              v-model="form.hospital_status"
            ></select-field>
          </b-col>
          <b-col cols="12">
            <autocomplete
              label="Organs"
              :items="organList"
              rules="required"
              v-model="organs"
              :multiple="true"
            ></autocomplete>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-btn
            class="width-100 me-3"
            @click="reset(false)"
            variant="outline-secondary"
            >RESET</b-btn
          >
          <b-btn class="width-100" type="submit" variant="primary"
            >SUBMIT</b-btn
          >
        </div>
      </form-wrapper>
    </b-card>
  </collapse-filter>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../assets/constants/recipient.constants";
import hospitalService from "../../../assets/services/hospital.service";
import licenseService from "../../../assets/services/license.service";
import CollapseFilter from "../../ui/CollapseFilter.vue";
export default {
  components: { CollapseFilter },
  props: {
    value: {},
  },
  data() {
    return {
      form: {},
      hospitals: [],
      organs: [],
      organList: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  methods: {
    submit() {
      const vm = this;
      const doc = {
        dml_indicator: "S",
      };
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.organ = vm.organs.map((organ_id) => {
        return { organ_id };
      });
      vm.$emit("input", doc);
      vm.$emit("fetch");
    },
    reset(isSubmit) {
      this.form = {
        hospital_id: "0",
        payment_status: "all",
        transtan_status: "all",
        hospital_status: "all",
      };
      if (this.isHospitalContact) {
        this.form.hospital_id = this.user.hospital_id;
      }
      this.organs = [];
      if (isSubmit) {
        this.submit();
      }
    },
    async fetchOrgans() {
      const vm = this;
      try {
        let { hospital_id } = vm.user;
        let doc = { hospital_id, dml_indicator: "UL" };
        vm.hospital_id = hospital_id;
        let { data } = await licenseService.list(doc);
        let { status, list } = data;
        if (status) {
          list = list.filter((item) => {
            return item.status != 4;
          });
          vm.organList = list.map((item) => {
            return { value: item.organ_id, name: item.organ_name };
          });
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
        return;
      }
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { user_type, hospital_id } = vm.$store.getters.user;
        let doc = { user_type };
        if (user_type == 1) {
          doc.hospital_id = hospital_id;
          vm.form.hospital_id = hospital_id;
        }
        let { data } = await hospitalService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        if (vm.isAdmins) {
          vm.organList = vm.filterMasterOrgans;
          vm.$loader.hide();
        } else {
          vm.fetchOrgans();
        }
        let hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
        vm.$emit("hospitals", hospitals);
        hospitals.unshift({
          name: "All Hospital",
          value: "0",
        });
        vm.hospitals = hospitals;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
    this.reset();
  },
};
</script>

<style></style>
