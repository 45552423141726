<template>
  <i :style="{ fontSize: size }" :class="`mdi ${icon}`"></i>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "24px",
    },
  },
};
</script>

<style></style>
