<template>
  <div>
    <add-button v-if="!item.medication_name" @click="dialog = true">
    </add-button>
    <edit-button @click="dialog = true" v-else></edit-button>
    <b-modal v-model="dialog" :title="title" hide-footer size="sm" centered>
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <input-field
              v-model.trim="form.medication_name"
              label="Medications Used"
              rules="required"
            ></input-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import AddButton from "../../../buttons/AddButton.vue";
import EditButton from "../../../buttons/EditButton.vue";
export default {
  props: {
    list: Array,
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: null,
    },
  },
  components: { AddButton, EditButton },
  data() {
    return {
      dialog: false,
      isValid: false,
      form: {},
      loading: true,
    };
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.init();
      }
    },
  },
  computed: {
    title() {
      return this.item.first_name ? "Update Medication" : "Add Medication";
    },
  },
  methods: {
    reset() {
      let doc = {
        medication_name: "",
        status: 1,
        donor_medication_id: 0,
        donor_temp_registration_id: 0,
        created_by: this.created_by,
      };
      this.form = doc;
    },
    init() {
      const vm = this;
      vm.reset();
      Object.keys(vm.form).forEach((key) => {
        if (vm.item[key] !== undefined) {
          vm.$set(vm.form, key, vm.item[key]);
        }
      });
    },
    async submit() {
      const vm = this;
      let doc = {};
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      if (vm.item.medication_name) {
        vm.$emit("onUpdate", { doc, index: vm.index });
      } else {
        vm.$emit("onCreate", doc);
      }
      vm.dialog = false;
    },
  },
};
</script>

<style></style>
