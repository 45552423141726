<template>
  <div class="pa-10">
    <form-wrapper v-if="!isLoading" @submit="submit">
      <Details
        :orderList="orderList"
        :isDisabled="!isEditable"
        v-model="details"
      ></Details>
      <attachments
        v-if="isEdit"
        :isDisabled="!isEditable"
        v-model="files"
        ref="attachments"
        @onFileRemoved="onFileRemoved"
        :filesList="filesList"
      ></attachments>
      <contacts
        :count="3"
        v-if="isUpdate"
        :hospital_id="hospital_id"
        v-model="contacts"
      ></contacts>
      <Footer :status="status"></Footer>
    </form-wrapper>
  </div>
</template>

<script>
import { decode, initPayment, isArray } from "../../assets/js/common";
import licenseService from "../../assets/services/license.service";
import Attachments from "../../components/transplant/create/Attachments.vue";
import Contacts from "../../components/hospitals/create/Contacts.vue";
import Details from "../../components/transplant/create/Details.vue";
import Footer from "../../components/transplant/create/Footer.vue";
import hospitalService from "../../assets/services/hospital.service";
export default {
  components: {
    Details,
    Attachments,
    Contacts,
    Footer,
  },
  data() {
    return {
      isValid: false,
      details: {
        cadaver: "",
        live: "",
        // corneal: "",
        license_active: "",
        expiry_date: "",
        organ_first_level_registration_date: "",
        organ_id: "",
        certificate_number: "",
        NOTTO_Registration_ID: "",
        payment_reference_no: "",
      },
      files: [],
      isLoading: true,
      contacts: [],
      contact: {
        first_name: "",
        last_name: "",
        gender: "",
        status: 1,
        photo: null,
        contact_name: "",
        organ_name: "",
        email_id: "",
        contact_id: "",
        country_code_1: "91",
        phone_number_1: "",
        country_code_2: "91",
        phone_number_2: "",
        hospital_contact_id: "",
        individual_id: "",
        qualification_details: [],
      },
      hospital_id: "",
      status: 1,
      orderList: [],
      updateItem: {},
      filesList: [],
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth;
    },
    licenseId() {
      return decode(this.$route.query.id);
    },
    isUpdate() {
      return this.$route.name == "update-transplant-license";
    },
    isAdmin() {
      return this.$store.getters.user.user_type == 2;
    },
    master() {
      return this.$store.getters.master;
    },
    organs() {
      if (this.master.master_organ instanceof Array == false) return [];
      return this.master.master_organ.map((item) => {
        return {
          value: item.organ_id,
          name: item.organ_name,
          license_available: item.license_available,
        };
      });
    },
    isEdit() {
      let type = this.details.organ_id;
      let item = this.organs.find((item) => {
        return item.value == type;
      });
      return item != null && item.license_available == 1 ? true : false;
    },
    isEditable() {
      const { status, isHospitalAdmin, isHospitalOrganCoordinate, isAdmin } =
        this;
      if (status == 4) return false;
      if (isAdmin) return true;
      if (
        (isHospitalAdmin || isHospitalOrganCoordinate) &&
        (status == 1 || status == 3)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onFileRemoved(id) {
      const vm = this;
      let list = vm.filesList.filter((item) => {
        return item.organ_license_fileupload_mapping_id != id;
      });
      vm.filesList = list;
      setTimeout(() => {
        vm.$refs.attachments.init();
      }, 500);
    },
    async init() {
      const vm = this;
      let { name } = vm.$route;
      let hospital_id = decode(vm.$route.query.hospital_id);
      if (name !== "update-transplant-license") {
        try {
          let doc = { hospital_id, dml_indicator: "UL" };
          vm.hospital_id = hospital_id;
          vm.$loader.show();
          let { data } = await licenseService.list(doc);
          let { status, list } = data;
          if (status) {
            list = list.filter((item) => {
              return item.status != 4;
            });
            vm.orderList = list.map((item) => {
              return item.organ_id;
            });
          }
          vm.$loader.hide();
        } catch (error) {
          vm.$alert.show(error.message);
          vm.$loader.hide();
          vm.$router.replace("/transplant-list");
          return;
        }
        vm.isLoading = false;
        return;
      }
      try {
        vm.$loader.show();
        let { data } = await licenseService.get(vm.licenseId);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          vm.$router.replace("/hospital-list");
          return;
        }
        vm.initForm(data);
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    async initPayment(item) {
      const vm = this;
      let { organ_license_id, organ_id } = item;
      try {
        let doc = {
          dml_indicator: "NO",
          organ_license_id,
          multi_organ_id: organ_id,
        };
        vm.$loader.show();
        let data = (await hospitalService.initPayment(doc)).data;
        initPayment(data);
        vm.$loader.hide();
      } catch (error) {
        console.log("error", error.message);
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    initForm(data) {
      const vm = this;
      vm.updateItem = data;
      let {
        contact_details,
        organ_license,
        qualification,
        file_uploaded_list,
      } = data;
      if (isArray(contact_details)) {
        contact_details.forEach((doc) => {
          let item = {};
          Object.keys(vm.contact).forEach((key) => {
            item[key] = doc[key];
          });
          let qualification_details = qualification.filter((qual) => {
            return item.hospital_contact_id == qual.hospital_contact_id;
          });
          item.qualification_details = qualification_details.map(
            ({ qualification_id }) => {
              return qualification_id;
            }
          );
          vm.contacts.push(item);
        });
      }
      if (isArray(file_uploaded_list)) {
        vm.filesList = file_uploaded_list;
      }
      if (isArray(organ_license) && organ_license[0]) {
        let item = organ_license[0];
        Object.keys(vm.details).forEach((key) => {
          if (item[key] !== null) {
            vm.details[key] = item[key];
          }
        });
        vm.details.organ_id = vm.details.organ_id.toString();
        vm.hospital_id = item.hospital_id;
        vm.status = item.status;
      }
      // vm.isDisabled = vm.getDisable("certificate_number");
      vm.isLoading = false;
    },
    uploadContact(file) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          var body = new FormData();
          body.append("file", file);
          let { data } = await licenseService.uploadContact(body);
          let { status, msg, filePath_url } = data;
          if (status) {
            resolve(filePath_url);
          } else {
            reject({
              message: `Error while uploading image : ${msg}`,
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    validateContact() {
      return new Promise((resolve, reject) => {
        const vm = this;
        let surgeons = vm.contacts.filter((item) => {
          return item.contact_id == 2 && item.status == 1;
        });
        let coordinators = vm.contacts.filter((item) => {
          return item.contact_id == 3 && item.status == 1;
        });
        if (surgeons.length == 0) {
          reject({ message: "Please provide at least one surgeon contact" });
          return;
        }
        if (coordinators.length == 0) {
          reject({
            message: "Please provide at least one coordinator contact",
          });
          return;
        }
        resolve();
      });
    },
    async submit() {
      const vm = this;
      if (!vm.isAuth) {
        vm.$alert.show("Authentication required");
        vm.$router.replace("/login");
        return;
      }
      // if (!vm.isValid) {
      //   vm.$alert.show("Please fill all required fields");
      //   return;
      // }
      let doc = vm.getForm();
      vm.$loader.show();

      try {
        // await vm.validateContact();
        for (let i = 0; i < doc.contact_detail.length; i++) {
          let { photo } = doc.contact_detail[i];
          if (photo instanceof File) {
            doc.contact_detail[i].photo = await this.uploadContact(photo);
          }
        }
        Object.keys(doc).forEach((key) => {
          if (!doc[key]) doc[key] = null;
        });

        let { data } = await licenseService.create(doc);
        let { status, msg, organ_license_id } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        for (let item of vm.files) {
          if (item.file !== null) {
            await this.upload(item, organ_license_id);
          }
        }
        let hospital_type = vm.$route.query.hospital_type;
        vm.$loader.hide();
        if (vm.isUpdate) {
          vm.$alert.show("License updated successfully");
        } else {
          if (hospital_type == 2) {
            vm.initPayment({ organ_license_id, organ_id: doc.organ_id });
          }
          vm.$alert.show("License created successfully");
        }
        vm.$router.replace("/transplant-list");
      } catch ({ message }) {
        vm.$alert.show(message);
        vm.$loader.hide();
      }
    },
    upload(doc, id) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          var body = new FormData();
          let { file, file_upload_id } = doc;
          body.append("file_upload_id", file_upload_id);
          body.append("organ_license_id", id);
          body.append("created_by", 1);
          body.append("file", file);
          let { data } = await licenseService.upload(body);
          let { status, msg } = data;
          if (status) {
            resolve();
          } else {
            reject({
              message: `License created successfully. <br/>Error while uploading image : ${msg}`,
            });
            this.$router.replace("/transplant-list");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getForm() {
      const vm = this;
      let { details, contacts } = vm;
      // let { registration_id, user_type } = user;
      // let created_by = registration_id;

      let doc = {
        contact_detail: [],
      };
      Object.keys(details).forEach((key) => {
        doc[key] = details[key];
      });
      doc.payment_status = doc.payment_reference_no ? 1 : 0;
      let contactlist = [];
      for (let contact of contacts) {
        let doc = {};
        Object.keys(contact).forEach((key) => {
          doc[key] = contact[key];
        });
        contactlist.push(doc);
      }
      // let contact_detail = contactlist.map((item) => {
      //   item.qualification_details = item.qualification_details.map(
      //     (qualification_id) => {
      //       return { qualification_id };
      //     }
      //   );
      //   item.hospital_id = vm.hospital_id;
      //   item.organ_license_id = vm.licenseId;
      //   item.organ_id = doc.organ_id;
      //   return item;
      // });
      // doc.contact_detail = contact_detail;
      doc.organ_license_id = vm.licenseId;
      doc.hospital_id = vm.hospital_id;

      if (vm.isUpdate) {
        // let { qualification } = vm.updateItem;
        // if (isArray(qualification)) {
        //   qualification.forEach((item) => {
        //     doc.contact_detail.forEach((contact, c) => {
        //       if (contact.hospital_contact_id == item.hospital_contact_id) {
        //         let check = false;
        //         contact.qualification_details.forEach((d, i) => {
        //           if (d.qualification_id == item.qualification_id) {
        //             doc.contact_detail[c].qualification_details[
        //               i
        //             ].qualification_mapping_id = item.qualification_mapping_id;
        //             check = true;
        //           }
        //         });
        //         if (!check) {
        //           doc.contact_detail[c].qualification_details.push({
        //             qualification_id: item.qualification_id,
        //             qualification_mapping_id: item.qualification_mapping_id,
        //             status: 4,
        //           });
        //         }
        //       }
        //     });
        //   });
        // }
      }
      return doc;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
