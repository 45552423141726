<template>
  <div class="mb-2">
    <div class="font-size-10 text-muted">{{ label }}</div>
    <div class="font-size-14">
      {{ selected }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    items: {},
  },
  computed: {
    selected() {
      let item = this.items.find((item) => {
        return item.value == this.value;
      });
      return item ? item.name : "";
    },
  },
};
</script>

<style>
</style>