<template>
  <div>
    <div class="d-flex flex-wrap">
      <b-card
        v-for="(item, i) in organs"
        @click="openOrgan(item)"
        :key="i"
        style="width: 120px"
        :class="type == item.id ? 'bg-success bg-soft' : ''"
        class="px-3 py-3 text-center me-3 mt-1"
        no-body
      >
        <img
          :src="item.src"
          width="88px"
          height="50px"
          style="object-fit: contain"
        />
        <p class="text-center mb-0 mt-1">
          {{ item.name }}
        </p>
      </b-card>
    </div>
    <select-heart-lungs
      ref="heartlungs"
      @onOrganSelect="$emit('input', $event)"
    ></select-heart-lungs>
  </div>
</template>
<script>
import SelectHeartLungs from '../waitingList/tnosWaitingList/SelectHeartLungs.vue';
export default {
  components: { SelectHeartLungs },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      type: null,
      organs: [
        {
          src: require("../../assets/images/organs/kidney.png"),
          id: 1,
          name: "Kidney",
        },
        {
          src: require("../../assets/images/organs/heart-lung.png"),
          id: "hl",
          name: "Heart & lungs",
        },
      ],
    };
  },
  methods: {
    openOrgan({ id, name }) {
      const vm = this;
      vm.type = id;
      vm.$emit("onOrganChange", name);
      if (id == "hl") {
        let list = [11, 12, 19, 20].map((organ_id) => {
          return { organ_id };
        });
        vm.$emit("input", list);
        return;
      }
      vm.$emit("input", [
        {
          organ_id: id,
        },
      ]);
    },
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
</style>
