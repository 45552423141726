<template>
  <b-dropdown variant="link">
    <template #button-content>
      <b-icon icon="three-dots" class="font-size-16"></b-icon>
    </template>
    <contacts :hospital="hospital"></contacts>
    <licenses :hospital="hospital"></licenses>
    <organs :hospital="hospital"></organs>
  </b-dropdown>
</template>

<script>
import Contacts from "./Contacts.vue";
import Licenses from "./Licenses.vue";
import Organs from "./Organs.vue";
export default {
  props: {
    hospital: Object,
  },
  components: { Contacts, Licenses, Organs },
  data: () => ({
    menu: false,
  }),
};
</script>
<style></style>
