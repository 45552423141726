<template>
  <b-card class="allocation__tissue">
    <h4>Allocated To Other State</h4>
    <b-row>
      <b-col cols="6">
        <select-field
          :items="states"
          label="Other State"
          rules="required"
          :disabled="true"
          v-model="form.other_state"
        ></select-field>
      </b-col>
      <b-col cols="6">
        <input-field
          :disabled="true"
          label="Hospital Name"
          rules="required"
          v-model="form.hospital_name"
        ></input-field>
      </b-col>
      <b-col cols="12">
        <text-area
          label="Recipient Details"
          rules="required"
          :disabled="true"
          v-model="form.recipient_details"
        ></text-area>
      </b-col>
      <b-col cols="12">
        <text-area
          :disabled="true"
          label="Notes"
          rules=""
          v-model="form.notes"
        ></text-area>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import donorService from "../../../../../assets/services/donor.service";
import masterService from "../../../../../assets/services/master.service";
import InputField from "../../../../form/InputField.vue";
import SelectField from "../../../../form/SelectField.vue";
import TextArea from "../../../../form/TextArea.vue";
export default {
  components: { InputField, SelectField, TextArea },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      states: [],
      form: {
        other_state: "",
        recipient_details: "",
        notes: "",
        hospital_name: "",
      },
    };
  },
  computed: {},
  methods: {
    reset() {
      this.form = {};
    },
    async fetchStates() {
      const vm = this;
      vm.states = [];
      try {
        let { data } = await masterService.getStateCity({
          country_id: "99",
        });
        let { list, status, msg } = data;
        if (status) {
          vm.states = list.map((item) => {
            return { name: item.name, value: item.id };
          });
        } else {
          console.log("Error while fetching the states", msg);
        }
      } catch (error) {
        console.log("Error while fetching the states", error.message);
      }
    },
    async init() {
      const vm = this;
      let { organ_allocation_id } = vm.item;
      let doc = {
        dml_indicator: "S",
        organ_allocation_id,
      };
      try {
        vm.$loader.show();
        let { data } = await donorService.allocateToOtherState(doc);
        let { status, list, msg } = data;
        if (!status) {
          vm.$alert.show(msg);
        }
        if (list[0] instanceof Object) {
          Object.keys(vm.form).forEach((key) => {
            vm.form[key] = list[0][key];
          });
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
  mounted() {
    this.fetchStates();
    this.init();
  },
};
</script>

<style></style>
