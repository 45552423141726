import http from "../js/axios";
class HospitalSerive {
  create(data) {
    return http.post("/recipient_registration/recipient_registration", data);
  }
  list(data) {
    return http.post("/recipient_registration/recipient_list", data);
  }
  getPendingList(data) {
    return http.post("/recipient_registration/Pending_Approval", data);
  }
  get(id) {
    return http.post("/recipient_registration/recipient_list", {
      dml_indicator: "E",
      recipient_registration_id: id,
    });
  }
  updateStatus(data) {
    return http.post("/recipient_registration/recipient_status", data);
  }

  consultatantList(data) {
    return http.post("/recipient_registration/consultant_list", data);
  }

  upload(data) {
    return http.post("/ByteArrayAPI/recipient_files_upload", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  removeFile(id) {
    return http.post("/recipient_registration/recipient_files_upload_delete", {
      status: "4",
      recipent_file_upload_mapping_id: id,
    });
  }
  getTNOSWaitingList(doc) {
    return http.post("/recipient_registration/tnos_filter", doc);
  }
  getLocalWaitingList(doc) {
    return http.post("/recipient_registration/local_generate_list", doc);
  }
  getSharedWaitingList(doc) {
    return http.post("/recipient_registration/shared_generate_list", doc);
  }
  getCMInsuranceWaitingList(doc) {
    return http.post("/recipient_registration/hospital_cm_list_fiter", doc);
  }
  getHospitalWaitingList(doc) {
    return http.post("/recipient_registration/hospital_waitinglist_fiter", doc);
  }
  getInhouseWaitList(doc) {
    return http.post(
      "/recipient_registration/in_hospital_organ_waiting_list",
      doc
    );
  }
  updateInhouseWaitList(doc) {
    return http.post(
      "/recipient_registration/recipient_upcomingrank_update",
      doc
    );
  }
  getBeReadyList(doc) {
    return http.post(
      "/recipient_registration/recipient_beready_status_list",
      doc
    );
  }
  updateBeReadyStatus(doc) {
    return http.post("/recipient_registration/recipient_beready_status", doc);
  }
  transfer(doc) {
    return http.post("/recipient_registration/recipient_transfer", doc);
  }
  searchUid(doc) {
    return http.post("/recipient_registration/uhid_search", doc);
  }
  deleteReason(doc) {
    return http.post("/recipient_registration/recipient_delete", doc);
  }
  updateFinalInhouseList(doc) {
    return http.post("/recipient_registration/upcomingtofinalrank", doc);
  }
  getDeleteReasonMaster() {
    return http.post("/recipient_registration/recipient_list", {
      dml_indicator: "DR",
    });
  }
  generateOTP(aadhaarno) {
    return http.get("/recipient_registration/KYCOTP_GenerateOTP", {
      params: {
        aadhaarno,
      },
    });
  }
  verifyAadharOTP(params) {
    return http.get("/recipient_registration/KYCOTP_KYCWithOTP", {
      params,
    });
  }
  verifyFingerPrint(data) {
    return http.post("/ByteArrayAPI/Uploadaadhar_photo", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
export default new HospitalSerive();
