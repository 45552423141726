<template>
  <div>
    <b-card class="pa-0 tabs">
      <Tabs :theme="theme" v-model="tab">
        <TabItem v-for="(item, i) in tabs" :key="i" :name="item">
          <template #name>
            {{ item }}
          </template>
        </TabItem>
      </Tabs>
    </b-card>
    <hospital-rota-order v-show="tab == 'Zone Rota'"> </hospital-rota-order>
    <government-rota-order v-show="tab == 'Government Rota'">
    </government-rota-order>
  </div>
</template>

<script>
import GovernmentRotaOrder from "../../components/hospitals/hospitalRotaOrder/GovernmentRotaOrder.vue";
import HospitalRotaOrder from "../../components/hospitals/hospitalRotaOrder/HospitalRotaOrder.vue";
export default {
  components: { HospitalRotaOrder, GovernmentRotaOrder },
  data() {
    return {
      theme: {
        nav: "white",
        navItem: "#BDBDBD",
        navActiveItem: "#556ee6",
        slider: "#556ee6",
        arrow: "#556ee6",
      },
      tabs: ["Zone Rota", "Government Rota"],
      tab: "Zone Rota",
    };
  },
};
</script>

<style>
</style>