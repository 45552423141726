<template>
  <div class="mb-2">
    <div class="font-size-10 text-muted" v-html="label"></div>
    <div class="font-size-14">{{ value }} <span v-html="unit"></span></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    unit: {},
  },
};
</script>

<style>
</style>