<template>
  <div>
    <Header :hospitals="hospitals"></Header>
      <donor-filter
        v-model="filter"
        @hospitals="hospitals = $event"
        @fetch="fetch"
      ></donor-filter>
      <List
        @onDraftStateCompleted="fetchFinalDonor"
        :isLoading="isLoading"
        @fetch="fetch"
        :list="list"
      ></List>
    <final-donor-list
      :hospitals="hospitals"
      :isLoading="isFinalDonorLoading"
      @fetch="fetchFinalDonor"
      :list="final_donor_list"
    ></final-donor-list>
  </div>
</template>

<script>
import donorService from "../../assets/services/donor.service";
import FinalDonorList from "../../components/managedonor/FinalDonorList.vue";
import Header from "../../components/managedonor/Header.vue";
import List from "../../components/managedonor/List.vue";
import DonorFilter from "../../components/managedonor/DonorFilter.vue";
import { formatDate } from "../../assets/js/common";
export default {
  components: { List, Header, DonorFilter, FinalDonorList },
  data() {
    return {
      list: [],
      final_donor_list: [],
      hospitals: [],
      isFinalDonorLoading: false,
      filter: {},
      isLoading: false,
    };
  },
  methods: {
    async fetch() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.list(vm.filter);
        let { status, msg, donor_list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = donor_list;
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
    async fetchFinalDonor() {
      const vm = this;
      try {
        vm.isFinalDonorLoading = true;
        vm.final_donor_list = [];
        let { data } = await donorService.finalDonorList({
          dml_indicator: "FS",
        });
        let { status, msg, final_donor_list } = data;
        vm.isFinalDonorLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.final_donor_list = final_donor_list.map((item) => {
          return {
            ...item,
            created_on: formatDate(item.created_on),
          };
        });
      } catch (error) {
        vm.isFinalDonorLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetchFinalDonor();
  },
};
</script>

<style></style>
