<template>
  <expandable header="Apnoea Test Details">
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
      First Apnoea Details
    </div>
    <b-row>
      <b-col cols="12" md="4">
        <yes-no
          v-model.trim="form.first_apnea_status"
          @input="onInput"
          label="First Apnoea Status"
          :disabled="isDisabled"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <date-time-picker
          v-model.trim="form.first_apnea_date"
          @input="onInput"
          label="First Apnoea Date/Time"
          :disabled="isDisabled"
        ></date-time-picker>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <file-input-group
          v-model.trim="form.first_apnea_file_upload_1"
          @input="onInput"
          :disabled="isDisabled"
          label="Pre Apnoea ABG"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          v-model.trim="form.first_apnea_file_upload_2"
          @input="onInput"
          :disabled="isDisabled"
          label="Post Apnoea ABG"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
      2nd Apnoea Details
    </div>
    <b-row>
      <b-col cols="12" md="4">
        <yes-no
          v-model.trim="form.second_apnea_status"
          @input="onInput"
          :disabled="isDisabled"
          label="Second Apnoea Status"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <date-time-picker
          v-model.trim="form.second_apnea_date"
          @input="onInput"
          :rules="secondApneaRules"
          label="Second Apnoea Date/Time"
          :disabled="isDisabled"
        ></date-time-picker>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <file-input-group
          v-model.trim="form.second_apnea_file_upload_1"
          @input="onInput"
          :disabled="isDisabled"
          label="Pre Apnoea ABG"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          v-model.trim="form.second_apnea_file_upload_2"
          @input="onInput"
          :disabled="isDisabled"
          accept=".jpg,capture=camera,.jpeg,.png,.pdf"
          label="Post Apnoea ABG"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import moment from "moment";
import Expandable from "../../Expandable.vue";
import DateTimePicker from "../../form/DateTimePicker.vue";
import FileInputGroup from "../../form/FileInputGroup.vue";
import YesNo from "../../form/YesNo.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Expandable,
    YesNo,
    DateTimePicker,
    FileInputGroup,
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    secondApneaRules() {
      let isBefore = false;
      let { first_apnea_date, second_apnea_date } = this.form;
      let first = moment(first_apnea_date, "YYYY-MM-DD HH:mm");
      if (first_apnea_date) {
        isBefore = moment(second_apnea_date, "YYYY-MM-DD HH:mm").isBefore(
          first
        );
      }
      console.log(isBefore);
      return isBefore ? "second_apnea_date" : "";
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
