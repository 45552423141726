<template>
  <b-row dense class="mt-4" v-if="isAdmin || isSubAdmin">
    <div
      class="col-md-3 col-6"
      v-for="(item, i) in items"
      :key="i"
      :class="i == 4 ? 'col-md-12 col-12' : 'col-md-3'"
    >
      <div class="card mini-stats-wid">
        <div class="card-body">
          <div class="media">
            <div class="media-body">
              <p class="text-muted fw-medium mb-2">{{ item.subtitle }}</p>
              <h4 class="mb-0">{{ item.title }}</h4>
            </div>
            <div
              class="avatar-sm align-self-center mini-stat-icon rounded-circle"
            >
              <span :class="`avatar-title bg-${item.color}`"
                ><i :class="`mdi ${item.icon} font-size-24`"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>
import commonMixins from "../assets/mixins/common.mixins";
export default {
  props: {
    status: Object,
  },
  mixins: [commonMixins],
  computed: {
    items() {
      let { deleted, expired, live, pending, hospital_all } = this.status;
      let list = [
        {
          color: "primary",
          icon: "mdi-hospital-building",
          title: live ? live : 0,
          subtitle: "Active",
        },
        {
          color: "success",
          icon: "mdi-clock-outline",
          title: pending ? pending : "0",
          subtitle: "Pending",
        },
        {
          color: "warning",
          icon: "mdi-alert",
          title: expired ? expired : "0",
          subtitle: "Expired",
        },
        {
          color: "danger",
          icon: "mdi-delete",
          title: deleted ? deleted : "0",
          subtitle: "Deleted",
        },
      ];
      if (hospital_all) {
        list.push({
          color: "primary",
          icon: "mdi-hospital-building",
          title: hospital_all ? hospital_all : 0,
          subtitle: "All Hospitals",
        });
      }
      return list;
    },
  },
};
</script>

<style></style>
