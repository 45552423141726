<template>
  <expandable header="Basic Medical Details" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.pulse_rate"
          @input="onInput"
          unit="/minute"
          :disabled="isDisabled"
          label="Pulse Rate"
          type="text"
          rules="required|num-3|dec-0"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.systolic"
          @input="onInput"
          :disabled="isDisabled"
          unit="mmhg"
          label="BP - Systolic"
          type="text"
          rules="required|num-3|dec-0"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.diastolic"
          @input="onInput"
          :disabled="isDisabled"
          unit="mmhg"
          label="BP - Diastolic"
          type="text"
          rules="required|num-3|dec-0"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.MAP"
          @input="onInput"
          label="MAP"
          :disabled="true"
          rules="required"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.CVP"
          @input="onInput"
          unit=""
          :disabled="isDisabled"
          label="CVP"
          rules="required|max-255"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.urine_output"
          @input="onInput"
          label="Urine Output"
          :disabled="isDisabled"
          type="text"
          :unit="unit"
          rules="required|num-5|dec-0"
        >
        </print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.SPO2"
          @input="onInput"
          unit="%"
          :disabled="isDisabled"
          label="SPO2"
          type="number"
          rules="required|percentage"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model.trim="form.temperature"
          @input="onInput"
          unit="Farenhite"
          :disabled="isDisabled"
          label="Temperature"
          type="text"
          rules="required|temperature|dec-1"
        ></print-input>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import PrintInput from "../../ui/print/PrintInput.vue";
export default {
  components: { Expandable, PrintInput },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    unit() {
      let unit = this.form.urine_unit;
      if (unit == 1) return "ml/hr";
      if (unit == 2) return "Litres/24hrs";
      return "";
    },
  },
  methods: {
    onInput() {
      const vm = this;
      let { diastolic, systolic } = vm.form;
      if (isNaN(systolic)) systolic = 0;
      if (isNaN(diastolic)) diastolic = 0;
      diastolic = 2 * diastolic;
      let map = (Number(diastolic) + Number(systolic)) / 3;
      vm.form.MAP = map.toFixed(2);
      vm.$emit("input", vm.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
