<template>
  <b-button variant="danger" depressed @click="$emit('click')" tile>
    <slot>Close</slot>
  </b-button>
</template>

<script>
export default {};
</script>

<style></style>
