<template>
  <div>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">General Details</div>
    <b-row>
      <b-col cols="12" md="4">
        <input-group
          label="Height"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.height"
          rules="required|height"
          type="number"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> CM </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          :disabled="isDisabled"
          label="Weight"
          @input="onInput"
          v-model="form.weight"
          rules="required|weight"
          type="number"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> kg </b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="BMI"
          @input="onInput"
          v-model="form.BMI"
          rules="required"
          unit="Kg/m<sup>2</sup>"
          :disabled="true"
          type="number"
        >
        </input-group>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">History</div>
    <b-row>
      <b-col cols="12" md="4">
        <yes-no
          label="Smoker"
          @input="onInput"
          :disabled="isDisabled"
          rules="required"
          v-model="form.smoker"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4" class="">
        <yes-no
          label="Alcohol"
          @input="onInput"
          rules="required"
          :disabled="isDisabled"
          v-model="form.alcohol"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="form.alcohol == 1">
        <select-field
          label="Period of Abstinence"
          :items="periods"
          @input="onInput"
          rules="required"
          :disabled="isDisabled"
          v-model="form.period_of_abstinence"
        ></select-field>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          @input="onInput"
          label="Drugs"
          :disabled="isDisabled"
          v-model="form.drugs"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          @input="onInput"
          label="Hypertension"
          rules="required"
          :disabled="isDisabled"
          v-model="form.hypertension"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="Diabetes"
          @input="onInput"
          rules="required"
          :disabled="isDisabled"
          v-model="form.diabetes"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="CAD"
          @input="onInput"
          rules="required"
          :disabled="isDisabled"
          v-model="form.CAD"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="Bronchial Asthma/COPD/Other Lung Disease"
          rules="required"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.bronchial_asthma_COPD"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="Epilepsy"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.epilepsy"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <radio-group
          label="Hepatitis B"
          rules="required"
          :disabled="isDisabled"
          :options="hepatitis"
          @input="onInput"
          v-model="form.hepatitis_B"
        ></radio-group>
      </b-col>
      <b-col cols="12" md="4">
        <radio-group
          label="Hepatitis C"
          :disabled="isDisabled"
          :options="hepatitis"
          @input="onInput"
          rules="required"
          v-model="form.hepatitis_C"
        ></radio-group>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="History of TB"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
          v-model="form.history_of_TB"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="History of Peripheral Vascular Disease"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.history_of_peripheral_vascular_disease"
        ></yes-no>
      </b-col>

      <b-col cols="12" md="4">
        <yes-no
          @input="onInput"
          label="History of Previous Transplant"
          v-model="form.history_of_previous_transplants"
          :disabled="isDisabled"
          rules=""
        ></yes-no>
      </b-col>
      <!-- <b-col cols="12" md="4" v-if="isPrevious">
        <date-picker
          @input="onInput"
          label="Previous Transplant Date"
          rules="required"
          v-model="form.history_of_previous_transplants_date"
        >
        </date-picker>
      </b-col> -->
      <b-col cols="12" md="4" v-if="isPrevious">
        <text-area
          label="Organ Transplanted"
          :disabled="isDisabled"
          @input="onInput"
          rules="max-150"
          v-model="form.organ_transplanted"
        ></text-area>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="History of Covid"
          @input="onInput"
          rules=""
          :disabled="isDisabled"
          v-model="form.history_of_covid"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="form.history_of_covid == 1">
        <select-field
          label="Covid Free Period"
          :items="periods"
          @input="onInput"
          rules=""
          :disabled="isDisabled"
          v-model="form.covid_free_period"
        ></select-field>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">Malignancy Status</div>
    <b-row>
      <b-col cols="12" md="4">
        <yes-no
          label="History of Malignancy"
          rules=""
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.history_of_malignancy"
        ></yes-no>
      </b-col>
      <template v-if="form.history_of_malignancy == 1">
        <b-col cols="12" md="4">
          <text-area
            label="Type of Malignancy"
            @input="onInput"
            :disabled="isDisabled"
            rules=""
            v-model="form.type_of_malignancy"
          ></text-area>
        </b-col>
        <b-col cols="12" md="4" class="">
          <select-field
            label="Duration of remission"
            :items="periods"
            @input="onInput"
            rules=""
            :disabled="isDisabled"
            v-model="form.duration_of_remission"
          ></select-field>
        </b-col>
      </template>

      <!-- <b-col cols="12" md="4">
        <input-field
          label="Hemogram"
          @input="onInput"
          rules="required|max-150"
          v-model="form.hemogram"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          label="Chest X Ray"
          @input="onInput"
          rules="required|max-150"
          v-model="form.ChestXRay"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          @input="onInput"
          label="ECG"
          rules="required|max-150"
          v-model="form.ECG"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          @input="onInput"
          label="ECHO"
          rules="required|max-150"
          v-model="form.Echo"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          @input="onInput"
          label="Relevant Remarks"
          rules="required|max-150"
          v-model="form.relevant_remarks"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          @input="onInput"
          label="Tissue Typing"
          rules="required|max-150"
          v-model="form.tissue_typing"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <radio-group
          @input="onInput"
          label="Live or Cadaver"
          :options="cadaverOptions"
          rules="required|max-150"
          v-model="form.Live_cadaver"
        ></radio-group>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import {
  HEPATITIS,
  PREIODS,
} from "../../../assets/constants/recipient.constants";
import InputGroup from "../../form/InputGroup.vue";
import RadioGroup from "../../form/RadioGroup.vue";
import SelectField from "../../form/SelectField.vue";
import TextArea from '../../form/TextArea.vue';
import YesNo from "../../form/YesNo.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    YesNo,
    InputGroup,
    RadioGroup,
    SelectField,
    TextArea
  },
  data() {
    return {
      form: {},
      hepatitis: HEPATITIS,
      periods: PREIODS,
    };
  },
  watch: {},
  computed: {
    isPrevious() {
      return this.form.history_of_previous_transplants == 1;
    },
    cadaverOptions() {
      return [
        {
          name: "Live",
          value: "1",
        },
        {
          name: "Cadaveor",
          value: "2",
        },
      ];
    },
  },
  methods: {
    onInput() {
      let { height, weight } = this.form;
      height = height / 100;
      let bmi = weight / (height * height);
      if (bmi < 1000) {
        this.form.BMI = bmi.toFixed(2);
      }
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
    this.onInput();
  },
};
</script>

<style></style>
