<template>
  <filter-collapse>
    <template v-slot:left>
      <recipient-search @select="$emit('select', $event)"></recipient-search>
    </template>
    <b-card class="mt-4">
      <form-wrapper @submit="submit(true)">
        <b-row>
          <template v-if="!isHospitalContact">
            <b-col cols="12" md="4">
              <autocomplete
                label="Hospital"
                v-model="form.hospital_id"
                :items="hospitals"
              ></autocomplete>
            </b-col>
            <b-col cols="12" md="4">
              <select-field
                v-model.trim="form.hospital_type"
                label="Hospital Type"
                :items="masterHospitalTypes"
              ></select-field>
            </b-col>
            <b-col cols="12" md="4">
              <autocomplete
                v-model.trim="zone"
                label="Zone"
                :multiple="true"
                :items="masterZones"
              ></autocomplete>
            </b-col>
          </template>
          <b-col cols="12" md="4">
            <select-field
              v-model="form.patient_nationlity"
              label="Nationality"
              :items="masterNationality"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <autocomplete
              label="Blood Group"
              :items="filterBloodGroups"
              :multiple="true"
              v-model="blood_group"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4" v-if="false">
            <select-field
              label="Be Ready Status"
              :items="beReadyStatus"
              v-model="form.hospital_status"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Age Type"
              :items="masterAgeTypes"
              v-model="form.age"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Patient Status"
              :items="patientStatus"
              :disabled="isHeartLungs"
              v-model="form.transtan_status"
            ></select-field>
          </b-col>

          <b-col cols="12" md="4">
            <select-field
              label="Heart & Lung"
              :items="heartLungsList"
              v-if="isHeartLungs"
              v-model="form.heart"
            ></select-field>
          </b-col>
          <!-- <b-col cols="12">
            <autocomplete
              label="Organs"
              :items="filterMasterOrgans"
              v-model="organs"
              :multiple="true"
              rules="required"
            ></autocomplete>
          </b-col> -->
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-btn
            class="width-100 me-3"
            @click="reset()"
            variant="outline-secondary"
            >RESET</b-btn
          >
          <b-btn class="width-100" type="submit" variant="primary"
            >SUBMIT</b-btn
          >
        </div>
      </form-wrapper>
    </b-card>
  </filter-collapse>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../assets/constants/recipient.constants";
import { HEART_LUNGS } from "../../../assets/constants/waiting.list.constants";
import { BLOOD_GROUP_VALUES } from "../../../assets/js/common";
import hospitalService from "../../../assets/services/hospital.service";
import RecipientSearch from "../../../pages/managerecipient/RecipientSearch.vue";
import FilterCollapse from "../../FilterCollapse.vue";
import Autocomplete from "../../form/Autocomplete.vue";
import SelectField from "../../form/SelectField.vue";
export default {
  components: { SelectField, Autocomplete, FilterCollapse, RecipientSearch },
  props: {
    value: {},
    title: {},
  },
  data() {
    return {
      form: {},
      organs: [],
      uid: "",
      zone: [],
      hospitals: [],
      blood_group: [],
      heart: "",
      heartLungsList: HEART_LUNGS,
      paymentStatus: PAYMENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    patientStatus() {
      let status = ["all", "3", "10"];
      return PATIENT_STATUS.filter((item) => {
        return status.indexOf(item.value) != -1;
      });
    },
    isHeartLungs() {
      return this.title.endsWith("Heart & lungs");
    },
  },
  methods: {
    initHeartLung() {
      const vm = this;
      vm.form.heart = "";
    },
    submit(isSubmit) {
      const vm = this;
      const doc = {
        dml_indicator: "S",
      };
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.blood_group = [];
      vm.blood_group.forEach((id) => {
        BLOOD_GROUP_VALUES[id].forEach((blood_group) => {
          doc.blood_group.push({ blood_group });
        });
      });
      doc.zone_list = vm.zone.map((zone) => {
        return { zone };
      });
      vm.$emit("input", doc);
      if (isSubmit) {
        vm.$emit("fetch");
      }
    },
    reset() {
      this.form = {
        hospital_id: "0",
        hospital_type: "",
        patient_nationlity: "",
        age: "",
        heart: "",
        hospital_status: "",
      };
      this.zone = [];
      this.blood_group = [];
      this.organs = [];
      this.submit(false);
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { user_type, hospital_id } = vm.$store.getters.user;
        let doc = { user_type };
        if (user_type == 1) {
          doc.hospital_id = hospital_id;
          vm.form.hospital_id = hospital_id;
        }
        let { data } = await hospitalService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
        let hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
        vm.$emit("hospitals", hospitals);
        hospitals.unshift({
          name: "All Hospital",
          value: "0",
        });
        vm.hospitals = hospitals;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    clear() {
      this.uid = "";
      this.$emit("fetch");
    },
  },
  mounted() {
    this.init();
    this.reset();
  },
};
</script>

<style></style>
