<template>
  <b-row dense class="mt-4" v-if="isAdmin">
    <div
      v-for="(item, i) in items"
      :class="i == 4 ? 'col-md-12 mt-4' : 'col-md-3  mb-4 mb-md-0'"
      :key="i"
    >
      <div class="card mini-stats-wid mb-0" style="height: 100%">
        <div class="card-body">
          <div class="media" style="height: 100%">
            <div class="media-body">
              <p class="text-muted fw-medium mb-2">{{ item.subtitle }}</p>
              <h4 class="mb-0">{{ item.title }}</h4>
            </div>
            <div
              class="avatar-sm align-self-center mini-stat-icon rounded-circle"
            >
              <span :class="`avatar-title bg-${item.color}`"
                ><i :class="`mdi ${item.icon} font-size-24`"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>
import commonMixins from "../../assets/mixins/common.mixins";
export default {
  props: {
    status: Object,
  },
  mixins: [commonMixins],
  computed: {
    items() {
      let {
        Approved,
        Pending_Transtan_Review,
        Rejected,
        Requested_ALF_Review,
        deleted,
      } = this.status;
      return [
        {
          color: "primary",
          icon: "mdi-hospital-building",
          title: Approved || 0,
          subtitle: "Approved",
        },
        {
          color: "success",
          icon: "mdi-clock-outline",
          title: Pending_Transtan_Review || "0",
          subtitle: "Pending Transtan Review",
        },
        {
          color: "secondary",
          icon: "mdi-hospital-building",
          title: Requested_ALF_Review || 0,
          subtitle: "Requested ALF Review",
        },
        {
          color: "warning",
          icon: "mdi-alert",
          title: Rejected || "0",
          subtitle: "Rejected",
        },
        {
          color: "danger",
          icon: "mdi-delete",
          title: deleted || "0",
          subtitle: "Deleted",
        },
      ];
    },
  },
};
</script>

<style></style>
