<template>
  <div class="mb-2">
    <div class="font-size-10 text-muted">{{ label }}</div>
    <div class="font-size-14">
      {{ value == 1 ? "Yes" : "No" }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
  },
};
</script>

<style>
</style>