<template>
  <div>
    <!-- <div class="html2pdf__page-break"></div> -->
    <div class="h3 py-3 px-3 pb-0 mb-3">Organ Allocation Summary</div>
    <b-card
      v-for="(item, i) in filteredList"
      :key="i"
      class="mb-0 px-3 mb-3"
      no-body
    >
      <div class="font-size-15 d-flex align-items-center">
        {{ item.organ_name }}
        <b-badge
          pill
          class="float-end mx-2 font-weight-normal"
          :class="getBg(item)"
          style="height: 16px"
          >{{ getTypeOfAllocation(item) }}</b-badge
        >
        <div v-if="item.back_up_reason">Reason : {{ item.back_up_reason }}</div>
      </div>
      <div class="font-size-12" :style="{ color: getStatus(item).color }">
        {{ getStatus(item).name }} <br />
      </div>
      <div class="font-size-12">
        Created on : {{ format(item.allocation_created_on) }} | Created by :
        {{ item.allocation_created_by }}
      </div>
      <div class="h6 mt-1 mb-0" v-if="item.allocation_status == 13">
        Termination Reason : {{ item.termination_reason }}
      </div>
      <div class="h6 mt-1 mb-0" v-else-if="item.allocation_status == 44">
        Process Closed Reason : {{ item.termination_reason }}
      </div>
      <template v-else-if="item.allocation_status == 41">
        <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
          Allocate to other state
        </div>
        <b-row>
          <b-col v-for="(doc, i) in getOtherState(item)" :key="i" cols="4">
            <print-input :label="doc.label" :value="doc.value"></print-input>
          </b-col>
        </b-row>
      </template>
      <template v-else-if="item.allocation_status == 42">
        <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
          Tissue Bank Details
        </div>
        <b-row>
          <b-col v-for="(doc, i) in getTissueBank(item)" :key="i" cols="4">
            <print-input :label="doc.label" :value="doc.value"></print-input>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
          Recipient Details
        </div>
        <b-row
          class="print-avoid-break"
          v-for="(items, i) of getChunks(recipientDetails(item))"
          :key="i"
        >
          <b-col v-for="(doc, i) in items" :key="i" cols="4">
            <print-input :label="doc.label" :value="doc.value"></print-input>
          </b-col>
        </b-row>
        <template v-if="false">
          <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
            Surgery Details
          </div>
          <b-row>
            <b-col v-for="(doc, i) in surgeryDetails(item)" :key="i" cols="4">
              <print-input :label="doc.label" :value="doc.value"></print-input>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import {
  ALLOCATION_STATUS_LIST,
  TYPE_OF_ALLOCATION,
} from "../../assets/constants/donor.constants";
import { NATIONALITY } from "../../assets/constants/recipient.constants";
import { formatDate, formatDateOnly } from "../../assets/js/common";
import PrintInput from "../ui/print/PrintInput.vue";
export default {
  components: { PrintInput },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredList() {
      return [...this.list]
        .map((item) => {
          return { ...item };
        })
        .filter(
          (item) => [28, 13, 41, 42, 44].indexOf(item.allocation_status) != -1
        );
    },
  },
  methods: {
    getChunks(items) {
      let _ = require("lodash");
      return _.chunk(items, 3);
    },
    getTissueBank(doc) {
      let item = { ...doc };
      let details = {
        tissue_hospital_name: "Hospital Name",
        state_name: "State",
        details_of_tissue_retrieved: "Details of Tissue Retrieved",
        retrieval_date_time: "Retrieval Date and Time",
        retrievedby: "Retrieved By",
      };
      item.retrieval_date_time = formatDate(
        moment(item.retrieval_date_time, "YYYY-MM-DD HH:mm").toDate()
      );
      let list = [];
      Object.keys(details).forEach((key) => {
        let value = item[key];
        let doc = {
          label: details[key],
          value,
        };
        list.push(doc);
      });
      return list;
    },
    getOtherState(item) {
      const vm = this;
      let details = {
        other_state_name: "Other State",
        other_state_hospital_name: "Hospital Name",
        other_state_recipient_details: "Recipient Details",
        other_state_notes: "Notes",
      };
      let list = [];
      Object.keys(details).forEach((key) => {
        let value = item[key];
        if (key == "recipient_created_on") value = formatDateOnly(value);
        if (key == "recipient_blood_group") value = vm.getBloodGroup(value);
        if (key == "recipient_gender") value = vm.getGender(value);
        if (key == "patient_nationlity") {
          let item = NATIONALITY.find((item) => item.value == value);
          if (item) value = item.name;
        }
        let doc = {
          label: details[key],
          value,
        };
        list.push(doc);
      });
      return list;
    },
    recipientDetails(item) {
      const vm = this;
      let details = {
        UID: "UID",
        zone_rank_id: "Priority Rank",
        recipient_name: "Name",
        recipient_age: "Age",
        recipient_gender: "Gender",
        recipient_height: "Height",
        recipient_weight: "Weight",
        recipient_blood_group: "Blood Group",
        patient_nationlity: "Nationality",
        recipient_created_on: "Date of Registration",
        recipient_hospital_id: "Hospital Name",
        aadhar_number: "Aadhar",
        recipient_address_line_1: "Address Line 1",
        recipient_address_line_2: "Address Line 2",
        recipient_city: "City",
        recipient_country: "Country",
      };
      let list = [];
      Object.keys(details).forEach((key) => {
        let value = item[key];
        if (key == "recipient_created_on") value = formatDateOnly(value);
        if (key == "recipient_blood_group") value = vm.getBloodGroup(value);
        if (key == "recipient_gender") value = vm.getGender(value);
        if (key == "patient_nationlity") {
          let item = NATIONALITY.find((item) => item.value == value);
          if (item) value = item.name;
        }
        let doc = {
          label: details[key],
          value,
        };
        list.push(doc);
      });
      return list;
    },
    surgeryDetails(item) {
      let details = {
        inpatient_number: "Inpatient Number",
        date_of_surgery: "Surgery Start Date",
        time_of_surgery: "Surgery Start Time",
        status_after_surgery: "Status After Surgery",
        remarks: "Remarks",
        ischaemia_warm: "Warm Ischaemia Time",
        ischaemia_cold: "Cold Ischaemia Time",
        end_date: "Surgery Completed Date",
        end_time: "Surgery Completed Time",
      };
      let list = [];
      Object.keys(details).forEach((key) => {
        let doc = {
          label: details[key],
          value: item[key],
        };
        if (["date_of_surgery", "end_date"].indexOf(key) != -1) {
          doc.value = formatDateOnly(doc.value);
        }
        list.push(doc);
      });
      console.log(item);
      return list;
    },
    format(date) {
      if (!date) return "";
      return formatDate(date);
    },
    getBg({ type_of_allocation }) {
      let item = TYPE_OF_ALLOCATION.find((item) => {
        return item.value == type_of_allocation;
      });
      return item != null ? item.color : "bg-primary";
    },
    getTypeOfAllocation({ type_of_allocation }) {
      let item = TYPE_OF_ALLOCATION.find((item) => {
        return item.value == type_of_allocation;
      });
      return item != null ? item.name : "";
    },
    getStatus(doc) {
      let item = ALLOCATION_STATUS_LIST.find((item) => {
        return item.value == doc.allocation_status;
      });
      return item || {};
    },
  },
  mounted() {
    console.log(
      this.filteredList.filter((item) => item.allocation_status == 41)
    );
  },
};
</script>

<style>
.print-avoid-break {
  break-inside: avoid;
}
</style>