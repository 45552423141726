<template>
  <div>
    <allocation-recipient-table
      title="Logistics"
      :fields="fields"
      :isBusy="isLoading"
      :item="item"
      :isLast="isLast"
      :items="list"
    >
      <template v-slot:action="doc">
        <update-logistics
          :recipeint="doc.item"
          @onUpdate="init()"
          :item="item"
        ></update-logistics>
      </template>
      <div
        class="d-flex mt-3"
        v-if="item.allocation_status == 7 && isAdmins && !isAllocationReport"
      >
        <div class="flex-grow-1"></div>
        <b-button
          variant="primary"
          v-if="isAllLogisticsUpdated"
          @click="updateOrganInfectionDetails()"
          >Update Organ Assessment Details</b-button
        >
      </div>
    </allocation-recipient-table>
  </div>
</template>

<script>
import { LOGISTICS_STATUS } from "../../../../assets/constants/donor.constants";
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { formatDate } from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
import UpdateLogistics from "./logistics/UpdateLogistics.vue";
export default {
  components: {
    UpdateLogistics,
    AllocationRecipientTable,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
    isLast: {
      type: Boolean,
      default: false,
    },

    status: {},
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: true,
      list: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isAllLogisticsUpdated() {
      let { list } = this;
      let updated = list.filter((item) => {
        return item.logistics_update_status != 1;
      });
      if (list.length == 0) return false;
      if (updated.length == 0) return false;
      return true;
    },
    fields() {
      let doc = {
        logistics_update_reason: "Reject Note",
        logistics_update_status: "Status",
        action: "Action",
      };
      if (this.isAllocationReport) delete doc.action;
      return doc;
    },
  },
  methods: {
    isLogisticViewable() {
      return this.item.allocation_status == 7;
    },
    getBg(val) {
      switch (val) {
        case "Updated":
          return "badge bg-success font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
    getStatus(val) {
      let item = LOGISTICS_STATUS.find((item) => {
        return item.value == val.toString();
      });
      return item ? item.name : "";
    },
    updateOrganInfectionDetails() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to update organ inspection list?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let list = [];
            let { organ_allocation_id } = vm.item;
            vm.list.forEach((item) => {
              let { recepeint_allocation_id } = item;
              let doc = {
                recepeint_allocation_id,
                organ_allocation_id,
                organ_inspection_update_by: vm.created_by,
                dml_indicator: "LSU",
              };
              list.push(doc);
            });
            let { data } =
              await donorService.organAllocationWaitingListStatusUpdate({
                recepient_organ_allocation_proc: list,
              });
            let { status, msg } = data;
            vm.isLoading = false;
            vm.$loader.hide();
            event.$emit("refresh");
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async init() {
      const vm = this;
      vm.dialog = false;
      let { organ_id_org, is_multi, organ_id, organ_allocation_id } = vm.item;
      if (is_multi) organ_id = organ_id_org;
      const doc = {
        dml_indicator: "LOS",
        organ_id,
        organ_allocation_id,
      };
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
