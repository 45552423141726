<template>
  <b-card class="mt-4">
    <div class="h4">Filter</div>
    <form-wrapper @submit="submit">
      <b-row>
        <b-col cols="12">
          <autocomplete
            label="Hospital"
            v-model="form.hospital_id"
            rules="required"
            :items="hospitals"
          ></autocomplete>
        </b-col>
      </b-row>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-btn
          class="width-100 me-3"
          @click="reset(false)"
          variant="outline-secondary"
          >RESET</b-btn
        >
        <b-btn class="width-100" type="submit" variant="primary">SUBMIT</b-btn>
      </div>
    </form-wrapper>
  </b-card>
</template>

<script>
import hospitalService from "../../assets/services/hospital.service";
import Autocomplete from "../form/Autocomplete.vue";
export default {
  components: { Autocomplete },
  props: {
    value: {},
  },
  data() {
    return {
      form: {
        hospital_id: "",
      },
      hospitals: [],
    };
  },
  methods: {
    submit() {
      const vm = this;
      let { hospital_id } = vm.form;
      const doc = {
        dml_indicator: "DS",
        hospital_id,
      };
      vm.$emit("input", doc);
      vm.$emit("fetch");
    },
    reset(isSubmit) {
      this.form.hospital_id = "";
      if (isSubmit) {
        this.submit();
      }
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { user_type, hospital_id } = vm.$store.getters.user;
        let doc = { user_type };
        if (user_type == 1) {
          doc.hospital_id = hospital_id;
          vm.form.hospital_id = hospital_id;
        }
        let { data } = await hospitalService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
        vm.hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
        vm.$emit("hospitals", vm.hospitals);
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
    this.reset();
  },
};
</script>

<style></style>
