<template>
  <div class="d-flex align-center">
    <b-button icon="x-octagon-fill" @click="dialog = true" variant="primary">
      Update</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Update Status"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <radio-group
              label="Status"
              rules="required"
              :options="options"
              v-model="form.status"
            ></radio-group>
          </b-col>
          <b-col cols="12" v-if="form.status == '2'">
            <input-field
              v-model.trim="form.reject_reason"
              label="Reason"
              :rules="form.status == '2' ? 'required' : ''"
            ></input-field>
          </b-col>
          <b-col cols="12" v-if="form.status == '1'">
            <file-input-group
              label="Covid Report"
              accept=".jpg,capture=camera,.jpeg,.png,.pdf"
              rules="file-image-pdf|file-size"
              v-model="form.file_path"
            ></file-input-group>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { ACCEPT_DECLINE } from "../../../../../assets/constants/recipient.constants";
import donorService from "../../../../../assets/services/donor.service";
import FileInputGroup from "../../../../form/FileInputGroup.vue";
import RadioGroup from "../../../../form/RadioGroup.vue";
import userHelper from "../../../../manageuser/user.helper";
export default {
  components: { RadioGroup, FileInputGroup },
  props: {
    id: {},
  },

  data() {
    return {
      dialog: false,
      form: {
        file_path: null,
        reject_reason: "",
        status: "",
      },
      options: ACCEPT_DECLINE,
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        let doc = {
          dml_indicator: "U",
          status: vm.form.status,
          updated_by: vm.created_by,
          recepeint_allocation_id: vm.id,
          reject_reason: vm.form.reject_reason,
        };

        vm.$loader.show();
        if (vm.form.file_path instanceof File) {
          doc.covid_result_fileupload = await userHelper.uploadContact(
            vm.form.file_path
          );
        }

        let { data } =
          await donorService.organAllocationWaitingListStatusUpdate({
            recepient_organ_allocation_proc: [doc],
          });
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    open() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to terminate this donor?",
        onConfirm: () => {
          vm.dialog = true;
        },
      });
    },
  },
};
</script>

<style></style>
