<template>
  <b-card class="py-2 px-2 bg-white" no-body @click="$emit('click')">
    <div class="d-flex">
      <div class="align-self-center me-3">
        <img :src="icon" @error="onError" class="avatar-xs rounded-circle" />
      </div>
      <div class="flex-grow-1">
        <h5 class="font-size-15 mt-0 mb-1">{{ details.donor_name }}</h5>
        <p class="text-muted mb-0">
          {{ gender || "-" }} | {{ details.age || "-" }} Years |
          {{ bloodGroup || "-" }}
        </p>
        <p class="text-muted mb-0">{{ details.hospital_name }}</p>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  formatDate,
  formatDateOnly,
  isDocObject,
} from "../../../assets/js/common";
import { GENDERS } from "../../../assets/constants/hospital.contants";
export default {
  props: {
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      details: {},
    };
  },
  watch: {
    doc() {
      this.init();
    },
  },
  computed: {
    icon() {
      switch (this.details.sex) {
        case 1:
          return require("../../../assets/images/man.png");
        case 2:
          return require("../../../assets/images/woman.png");
        default:
          return require("../../../assets/images/man.png");
      }
    },
    gender() {
      let item = GENDERS.find((item) => {
        return this.details.sex == item.value;
      });
      return item ? item.name : "";
    },
    bloodGroup() {
      let item = this.bloodGroups.find((item) => {
        return this.details.blood_group == item.value;
      });
      return item ? item.name : "";
    },
    photo() {
      return (
        this.details.photo || require("../../../assets/images/account.png")
      );
    },
  },
  methods: {
    init() {
      const vm = this;
      if (isDocObject(vm.doc.donor_list)) {
        vm.details = vm.doc.donor_list[0];
      }
    },
    format(date) {
      return formatDateOnly(date);
    },
    formatDate(date) {
      return formatDate(date);
    },
    onError(e) {
      e.target.src = require("../../../assets/images/account.png");
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
