import { decode } from "../js/common";

export default {

    computed: {
        isCreate() {
            return this.$route.name == "live-transplant-registration";
        },
        isUpdate() {
            return this.$route.name == "update-live-transplant"
        },
        id() {
            return decode(this.$route.query.id)
        }
    }
}