<template>
  <Expandable header="Medical Details" class="mb-0 px-3" :noBody="true">
    <b-row>
      <b-col cols="12" md="4">
        <print-select
          @input="onInput"
          v-model="form.cause_of_death_id"
          :items="masterCauseOfDeath"
          :disabled="isDisabled"
          label="Cause of Brain Death"
          rules="required"
        >
        </print-select>
      </b-col>
      <b-col cols="12" md="4" v-if="form.cause_of_death_id == 1003">
        <print-input
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.cause_of_death_notes"
          label="Cause of Brain Death - If others"
          rules="required|max-255"
        >
        </print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          :disabled="isDisabled"
          label="No of Days on Ventilator"
          type="text"
          @input="onInput"
          unit="Days"
          v-model="form.No_of_days_ventilator"
          rules="required|dec-0"
        >
        </print-input>
      </b-col>

      <b-col cols="12" md="4">
        <print-date
          :label="
            details.medical_legal_case == '0'
              ? 'Date of Onset of illness'
              : 'Date of Accident'
          "
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.date_of_accident"
          rules="required"
        >
        </print-date>
      </b-col>
      <b-col cols="12" md="4">
        <print-date
          :disabled="isDisabled"
          label="Date of Admission"
          @input="onInput"
          v-model="form.date_of_admisssion"
          :rules="dateOfAdmin"
        >
        </print-date>
      </b-col>
      <b-col cols="12" md="4">
        <print-date-time
          :disabled="isDisabled"
          v-model.trim="form.tentative_retreival_date"
          @input="onInput"
          :min="fromToday"
          label="Tentative Retrieval Date & Time"
          rules=""
        ></print-date-time>
      </b-col>
      <b-col cols="12" md="4">
        <print-date-time
          :disabled="isDisabled"
          v-model.trim="form.assessment_datetime"
          @input="onInput"
          :min="fromToday"
          label="Assessment Date & Time"
          rules=""
        ></print-date-time>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import moment from "moment";
import Expandable from "../../Expandable.vue";
import { GENDERS } from "../../../assets/constants/hospital.contants";
import PrintSelect from "../../ui/print/PrintSelect.vue";
import PrintInput from "../../ui/print/PrintInput.vue";
import PrintDate from "../../ui/print/PrintDate.vue";
import PrintDateTime from "../../ui/print/PrintDateTime.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
    details: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Expandable,
    PrintSelect,
    PrintInput,
    PrintDate,
    PrintDateTime,
  },
  data() {
    return {
      form: {},
      gender: GENDERS,
    };
  },
  computed: {
    fromToday() {
      return moment().add(-1, "day").toDate();
    },
    dateOfAdmin() {
      let isBefore = false;
      let { date_of_accident, date_of_admisssion } = this.form;
      let first = moment(date_of_accident, "YYYY-MM-DD");
      if (date_of_accident) {
        isBefore = moment(date_of_admisssion, "YYYY-MM-DD").isBefore(first);
      }
      return isBefore ? "required|date_of_admission" : "required";
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
