<template>
  <Expandable header="Organ License Details" class="mb-10">
    <b-row class="pb-0">
      <!-- Hidden for pharse 1 -->
      <b-col cols="12" v-if="false">
        <yes-no
          @input="input"
          label="Is your Transplant Registration License Active?"
          v-model="form.license_active"
          :disabled="isDisabled"
        ></yes-no>
      </b-col>
      <b-col cols="12" v-if="isEdit">
        <input-group
          @input="input"
          :disabled="isDisabled"
          v-model.trim="form.certificate_number"
          label="TRANSTAN Certificate Number"
          rules="required"
        >
          <template v-slot:help>
            <image-help
              tip="License issued by DMS for Organ level transplant"
              :src="require('../../../assets/organ_license.png')"
            ></image-help>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          v-model.trim="form.organ_id"
          label="Organ Type"
          :items="organs"
          :disabled="isTypeDisabled"
          @input="input"
          rules="required"
        ></autocomplete>
      </b-col>

      <b-col cols="12" md="4" v-if="isEdit">
        <date-picker
          @input="input"
          :disabled="isDisabled"
          :max="max"
          rules=""
          label="Organ First Level Registration Date"
          v-model="form.organ_first_level_registration_date"
        ></date-picker>
      </b-col>
      <b-col cols="12" md="4" v-if="isEdit">
        <date-picker
          :disabled="isDisabled"
          @input="input"
          :rules="expiryRules"
          label="License Expiry Date"
          v-model="form.expiry_date"
        ></date-picker>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.payment_reference_no"
          label="Payment Reference No"
          @input="input"
          :disabled="true"
          rules=""
        >
          <!-- <template v-slot:help>
          <b-button @click="openPayment()" variant="primary">Payment Link</b-button>
        </template> -->
        </input-field>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import { FIELD } from "../../../assets/js/rules";
import { HOSPITAL_TYPES } from "../../../assets/constants/hospital.contants";
import Expandable from "../../Expandable.vue";
import YesNo from "../../form/YesNo.vue";
import DatePicker from "../../form/DatePicker.vue";
import moment from "moment-timezone";
import hospitalCreateMixins from "../../../assets/mixins/hospital.create.mixins";
import ImageHelp from "../../ImageHelp.vue";
import { PAYMENT_LINK } from "../../../assets/js/common";
export default {
  props: {
    isDisabled: Boolean,
    value: Object,
    orderList: Array,
  },
  mixins: [hospitalCreateMixins],
  components: { Expandable, YesNo, DatePicker, ImageHelp },
  data() {
    return {
      rules: FIELD,
      form: {
        cadaver: "",
        live: "",
        // corneal: "",
        license_active: "",
        expiry_date: "",
        organ_first_level_registration_date: "",
        organ_id: "",
        certificate_number: "",
        NOTTO_Registration_ID: "",
        payment_reference_no: "",
      },
    };
  },
  computed: {
    expiryRules() {
      let isBefore = false;
      let { organ_first_level_registration_date, expiry_date } = this.form;
      let first = moment(organ_first_level_registration_date, "YYYY-MM-DD");
      if (organ_first_level_registration_date) {
        isBefore = moment(expiry_date, "YYYY-MM-DD").isBefore(first);
      }
      return isBefore ? "required|expiry_date" : "required";
    },
    isTypeDisabled() {
      return this.$route.name != "transplant-registration";
    },
    isEdit() {
      let type = this.form.organ_id;
      let item = this.organs.find((item) => {
        return item.value == type;
      });
      return item != null && item.license_available == 1 ? true : false;
    },
    types() {
      return HOSPITAL_TYPES;
    },
    master() {
      return this.$store.getters.master;
    },
    organs() {
      if (this.master.master_organ instanceof Array == false) return [];
      let list = [...this.master.master_organ].filter((item) => {
        return item.license_available == 1;
      });
      list = list.map((item) => {
        return {
          value: item.organ_id,
          name: item.organ_name,
          status: item.status,
          license_available: item.license_available,
        };
      });
      let isCreate = this.$route.name == "transplant-registration";
      list = list.filter((item) => {
        if (isCreate) {
          return this.orderList.indexOf(item.value) == -1 && item.status != 4;
        } else {
          return true;
        }
      });
      return list;
    },

    years() {
      let year = 1899;
      let list = [];
      while (year < new Date().getFullYear()) {
        year++;
        list.push(year);
      }
      return list.reverse();
    },
    max() {
      return moment().format();
    },
  },
  methods: {
    openPayment() {
      window.open(PAYMENT_LINK, "_blank");
    },
    input() {
      this.$emit("input", this.form);
    },
    init() {
      const vm = this;
      Object.keys(vm.value).forEach((key) => {
        vm.$set(vm.form, key, vm.value[key]);
      });
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.input();
  },
};
</script>

<style></style>
