<template>
  <div>
    <b-dropdown-item-button @click="dialog = true">
      Contact List</b-dropdown-item-button
    >
    <b-modal
      centered
      v-model="dialog"
      title="Contact List"
      hide-footer
      size="lg"
    >
      <b-table
        outlined
        :fields="headers"
        :items="list"
        responsive="sm"
        :items-per-page="5"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-button variant="outline-danger" outlined @click="dialog = false">
          Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import hospitalService from "../../../assets/services/hospital.service";
export default {
  props: {
    hospital: Object,
  },
  data() {
    return {
      list: [],
      dialog: false,
      loading: false,
      isLoaded: false,
    };
  },
  computed: {
    formatedList() {
      const vm = this;
      return vm.list.map((item) => {
        item.zone = vm.getZone(item);
        return item;
      });
    },
    master() {
      return this.$store.getters.master;
    },
    zones() {
      if (this.master.master_zone instanceof Array == false) return [];
      return this.master.master_zone.map((item) => {
        return { value: item.zone_id, name: item.zone_name };
      });
    },
    headers() {
      let list = [];
      let headers = {
        sno: "S No",
        name: "Name",
        phone: "Phone Number",
        email_id: "Email",
        organ_name: "Organ",
        contact_name: "Contact Type",
      };
      Object.keys(headers).forEach((key) => {
        let label = headers[key];
        let doc = {
          label,
          key,
        };
        list.push(doc);
      });
      return list;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetch();
      }
    },
  },
  methods: {
    getZone({ zone_id }) {
      let item = this.zones.find((item) => {
        return item.value == zone_id;
      });
      return item != null ? item.name : "";
    },
    async fetch() {
      const vm = this;
      try {
        if (vm.isLoaded == true) return;
        vm.loading = true;
        let id = vm.hospital.hospital_id;
        let { data } = await hospitalService.getHospitalContacts(id);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.loading = false;
        list = list.filter((item) => {
          return item.status != 4;
        });
        vm.list = list.map((item, i) => {
          item.sno = i + 1;
          if (item.phone_number_2) {
            item.phone = `${item.phone_number_1} / ${item.phone_number_2}`;
          } else {
            item.phone = `${item.phone_number_1}`;
          }
          item.name = `${item.first_name} ${item.last_name}`;
          return item;
        });
        vm.isLoaded = true;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
