<template>
  <collapse-filter>
    <b-card class="mt-4 mb-4">
      <div class="h4">Filter</div>
      <form-wrapper @submit="submit">
        <b-row>
          <b-col cols="12" md="4" v-if="!isHospitalContact">
            <autocomplete
              label="Hospital"
              v-model="form.hospital_id"
              :items="hospitals"
              rules=""
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4">
            <autocomplete
              label="Blood Group"
              :items="filterBloodGroups"
              :multiple="true"
              v-model="blood_group"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4">
            <autocomplete
              v-model.trim="zone"
              v-if="isAdmins"
              label="Zone"
              rules="required"
              :multiple="true"
              :items="masterZones"
            ></autocomplete>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-btn
            class="width-100 me-3"
            @click="reset(false)"
            variant="outline-secondary"
            >RESET</b-btn
          >
          <b-btn class="width-100" type="submit" variant="primary"
            >SUBMIT</b-btn
          >
        </div>
      </form-wrapper>
    </b-card>
  </collapse-filter>
</template>

<script>
import { BLOOD_GROUP_VALUES } from "../../../assets/js/common";
import hospitalService from "../../../assets/services/hospital.service";
import CollapseFilter from "../../ui/CollapseFilter.vue";
export default {
  components: { CollapseFilter },
  props: {
    value: {},
  },
  data() {
    return {
      form: {
        hospital_id: "0",
      },
      hospitals: [],
      blood_group: [],
      zone: [],
      organs: [],
    };
  },
  methods: {
    submit() {
      const vm = this;
      const doc = {
        dml_indicator: "S",
      };
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.blood_group = [];
      vm.blood_group.forEach((id) => {
        BLOOD_GROUP_VALUES[id].forEach((blood_group) => {
          doc.blood_group.push({ blood_group });
        });
      });
      doc.zone_list = vm.zone.map((zone) => {
        return { zone };
      });
      vm.$emit("input", doc);
      vm.$emit("fetch");
    },
    reset(isSubmit) {
      this.form = {
        hospital_id: "0",
      };
      if (this.isHospitalContact) {
        this.form.hospital_id = this.user.hospital_id;
      }
      this.blood_group = [];
      this.zone = [];
      this.organs = [];
      if (isSubmit) {
        this.submit();
      }
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { user_type, hospital_id } = vm.$store.getters.user;
        let doc = { user_type };
        if (user_type == 1) {
          doc.hospital_id = hospital_id;
          vm.form.hospital_id = hospital_id;
        }
        let { data } = await hospitalService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
        let hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
        vm.$emit("hospitals", hospitals);
        hospitals.unshift({
          name: "All Hospital",
          value: "0",
        });
        vm.hospitals = hospitals;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
    this.reset();
  },
};
</script>

<style></style>
