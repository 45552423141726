<template>
  <div class="overall-report">
    <donor-details :doc="updateDoc" />
    <donor-registration @input="initOrgans" v-model="updateDoc" />
    <h3>Allocation Information</h3>
    <template v-for="item in tabs">
      <div :key="item.id">
        <h5 class="d-flex mb-0">
          {{ item.name }}
          <b-badge
            pill
            class="float-end mx-2 font-weight-normal"
            :class="getBg(item)"
            style="height: 16px"
            >{{ item.badge }}</b-badge
          >
          <div v-if="item.back_up_reason">
            Reason : {{ item.back_up_reason }}
          </div>
        </h5>
        <div class="font-size-11" :style="getColor(item)">
          {{ item.status }} <br />
        </div>
        <div class="font-size-11" v-if="item.termination_reason">
          {{
            item.status == "Process Closed"
              ? "Process Closed Reason:"
              : "Termination Reason:"
          }}
          {{ item.termination_reason }} <br />
        </div>
        <div class="mt-4">
          <allocation-tissue :item="item" v-if="isTissue(item)" />
          <template v-else>
            <allocation-report-local-steps
              v-if="item.type_of_allocation == 1"
              :history="allocation_list_history"
              :item="item"
            >
            </allocation-report-local-steps>
            <allocation-report-shared-pool-steps
              v-if="item.type_of_allocation == 2"
              :history="allocation_list_history"
              :item="item"
            ></allocation-report-shared-pool-steps>
            <allocation-report-rota-steps
              v-if="item.type_of_allocation == 3"
              :history="allocation_list_history"
              :item="item"
            ></allocation-report-rota-steps>
          </template>
        </div>
      </div>
    </template>
    <chat-report> </chat-report>
  </div>
</template>

<script>
import {
  ALLOCATION_STATUS_LIST,
  TYPE_OF_ALLOCATION,
} from "../../assets/constants/donor.constants";
import { formatDate, isDocObject } from "../../assets/js/common";
import donorMixins from "../../assets/mixins/donor.mixins";
import donorService from "../../assets/services/donor.service";
import AllocationReportSharedPoolSteps from "../../components/organAllocation/OverallReport/AllocationReportSharedPoolSteps.vue";
import DonorRegistration from "../donor/DonorRegistration.vue";
import AllocationTissue from "../../components/organAllocation/create/AllocationTissue.vue";
import AllocationReportLocalSteps from "../../components/organAllocation/OverallReport/AllocationReportLocalSteps.vue";
import AllocationReportRotaSteps from "../../components/organAllocation/OverallReport/AllocationReportRotaSteps.vue";
import DonorDetails from "../../components/organAllocation/create/DonorDetails.vue";
import ChatReport from "../../components/organAllocation/OverallReport/ChatReport.vue";
export default {
  components: {
    DonorRegistration,
    AllocationReportSharedPoolSteps,
    AllocationTissue,
    AllocationReportLocalSteps,
    AllocationReportRotaSteps,
    DonorDetails,
    ChatReport,
  },
  data() {
    return {
      updateDoc: {},
      allocation_list: [],
      list: [],
    };
  },
  mixins: [donorMixins],
  computed: {
    donor() {
      let { donor_list } = this.updateDoc;
      let donor = {};
      if (isDocObject(donor_list)) {
        donor = donor_list[0];
      }
      return donor;
    },
    tabs() {
      let tabs = [];
      const vm = this;
      vm.allocation_list.forEach((item) => {
        item.name = item.organ_name;
        if (item.backup_flow == 1) {
          item.name = `${item.name} (${item.back_up_reason || "Backup"})`;
        }
        item.updated_on = formatDate(item.updated_on);
        item.donor = vm.donor;
        item.status = vm.getAllocationStatus(item);
        item.badge = vm.getType(item);
        item.id = item.organ_allocation_id.toString();
        tabs.push(item);
      });
      return tabs;
    },
  },
  methods: {
    isTissue({ organ_id }) {
      let organ = this.masterOrgans.find((item) => {
        return item.organ_id == organ_id && item.tissue_bank == 1;
      });
      return organ != null;
    },
    getAllocationStatus({ allocation_status }) {
      let item = ALLOCATION_STATUS_LIST.find((item) => {
        return item.value == allocation_status;
      });
      return item != null ? item.name : "";
    },
    getType({ type_of_allocation }) {
      let item = TYPE_OF_ALLOCATION.find((item) => {
        return item.value == type_of_allocation;
      });
      return item != null ? item.name : "";
    },
    getBg({ type_of_allocation }) {
      let item = TYPE_OF_ALLOCATION.find((item) => {
        return item.value == type_of_allocation;
      });
      return item != null ? item.color : "bg-primary";
    },
    getColor(doc) {
      let item = ALLOCATION_STATUS_LIST.find((item) => {
        return item.value == doc.allocation_status;
      });
      if (item != null) {
        return { color: item.color };
      }
      return {};
    },
    async initOrgans() {
      const vm = this;
      let { id } = vm;
      let { hospital_id } = vm.donor;
      vm.list = [];
      vm.allocation_list = [];
      try {
        vm.$loader.show();
        let { data } = await donorService.getAllocatedOrgans({
          dml_indicator: "S",
          hospital_id,
          donor_temp_registration_id: id,
        });
        let { status, msg, list, allocation_list, allocation_list_history } =
          data;
        if (status == true) {
          vm.final_donor_status = data.Final_donor_status;
          vm.dialog = false;
          vm.form = {};
          if (!status) {
            vm.$alert.show(msg);
          }
          vm.allocation_list = allocation_list.map((item) => {
            item.organ_id_org = item.organ_id;
            return item;
          });
          vm.allocation_list_history = allocation_list_history;
          vm.list = list;
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
};
</script>


<style lang="scss">
.overall-report {
  @media print {
    body {
      zoom: 75%;
    }
    @page {
      size: landscape;
    }
  }
}
</style>

