<template>
  <b-card class="mb-0">
    <b-card-title v-if="!isAllocationReport">
      <div
        class="d-flex align-items-md-center h4 mb-3 flex-column flex-md-row"
        v-if="title"
      >
        <div v-html="title" class="flex-grow-1"></div>
        <slot name="add"></slot>
      </div>
      <b-row v-if="isFilter">
        <b-col cols="12" md="9" class="mb-m-3">
          <b-row>
            <b-col cols="12" md="6" class="mb-m-3" v-if="isSearch">
              <b-form-input
                v-model="filter.search"
                type="search"
                placeholder="Search..."
                class="form-control"
              ></b-form-input>
            </b-col>
            <b-col cols="12" md="6" class="d-flex">
              <slot name="filter"></slot>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center justify-content-end">
            <label class="mb-0"> Rows per page:&nbsp; </label>
            <b-form-select
              class="form-select form-select-sm ms-1"
              v-model="filter.perPage"
              style="width: 60px"
              size="sm"
              @change="onPageChange(currentPage)"
              :options="pageOptions"
            ></b-form-select>
          </div>
        </b-col>
      </b-row>
      <div class="row mt-3" v-if="isFooter">
        <div class="col">
          <b-button
            v-if="items.length && !isWaitingList"
            variant="primary"
            @click="exportTable()"
            >Export</b-button
          >
          <div class="dataTables_paginate paging_simple_numbers float-end">
            <ul class="pagination pagination-rounded mb-0">
              <b-pagination
                v-model="currentPage"
                @change="onPageChange"
                :total-rows="totalRows"
                :per-page="filter.perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </b-card-title>
    <div :class="!isAllocationReport ? 'mt-3' : ''">
      <template v-if="isMobileView">
        <div class="text-center text-primary my-2" v-if="isBusy">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="mx-2">Loading...</strong>
        </div>
        <slot name="mobile_list" v-bind="mobileList"></slot>
      </template>
      <b-table
        v-else
        class="double-scroll"
        :fields="fields"
        :items="list"
        :busy="isBusy"
        :sort-icon-left="true"
        :head-variant="'dark'"
        thead-class="table-light table-bordered"
        tbody-class="table-bordered"
        ref="table"
        responsive="sm"
        :id="id"
        :per-page="isDynamic ? undefined : filter.perPage"
        :current-page="isDynamic ? undefined : currentPage"
        :filter="filter.search"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(sno)="data">
          <div style="min-width: 30px">
            <slot name="sno" v-bind="data">
              {{ data.value }}
            </slot>
          </div>
        </template>
        <template #cell()="data">
          <slot :name="data.field.key" v-bind="data">
            {{ data.value }}
          </slot>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">Loading...</strong>
          </div>
        </template>
      </b-table>
    </div>

    <div class="row" v-if="isFooter">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
              v-model="currentPage"
              @change="onPageChange"
              :total-rows="totalRows"
              :per-page="filter.perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <slot></slot>
    <JsonCSV
      :name="`${title}.csv`"
      v-show="false"
      ref="csv"
      :data="json"
    ></JsonCSV>
  </b-card>
</template>
<script>
import JsonCSV from "vue-json-csv";
import "../../assets/js/jquery.doubleScroll.js";
import "../../assets/js/scrollfix.js";

export default {
  props: {
    items: Array,
    fields: Array,
    title: String,
    total: {},
    isSearch: {
      type: Boolean,
      default: true,
    },
    isMobileView: {
      type: Boolean,
      default: false,
    },
    isFilter: {
      type: Boolean,
      default: true,
    },
    isFooter: {
      type: Boolean,
      default: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    isDynamic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    JsonCSV,
  },
  data() {
    return {
      filter: {
        search: "",
        perPage: 50,
      },
      json: [],
      totalRows: 1,
      currentPage: 1,
      id: new Date().getTime().toString(),
      filterOn: [],
      pageOptions: [10, 25, 50, 100],
    };
  },
  computed: {
    mobileList() {
      const searchTerm = this.filter.search.toLowerCase();
      let filteredList = [...this.list].filter((doc) => {
        let keys = Object.keys(doc);
        for (let key of keys) {
          let val = doc[key];
          if (val && val.toString().toLowerCase().startsWith(searchTerm)) {
            return true;
          }
        }
        return false;
      });
      return filteredList;
    },
    list() {
      let sno = (this.currentPage - 1) * this.filter.perPage;
      return this.items.map((item, i) => {
        item.sno = i + 1;
        sno++;
        if (this.isDynamic) item.sno = sno;
        return item;
      });
    },
    isWaitingList() {
      return this.$route.name == "commong-waiting-list";
    },
    isAllocationReport() {
      return this.$route.name == "organ-allocation-overall-report";
    },
  },
  watch: {
    items() {
      if (!this.isAllocationReport) {
        this.resetScroll();
        if (this.isDynamic) {
          this.totalRows = this.total;
          return;
        }
        this.totalRows = this.items.length;
      }
    },
  },
  methods: {
    onPageChange(event) {
      const vm = this;
      if (!vm.isDynamic) return;
      console.log(vm.filter.perPage, event);
      vm.$emit("onPageChange", {
        size: vm.filter.perPage,
        page: event,
      });
    },
    onFiltered(filteredItems) {
      if (this.isDynamic) return;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    exportTable() {
      const vm = this;
      if (vm.isDynamic) return vm.$emit("export");
      let $ = window.$;
      let json = [];
      $(`#${vm.id} > tbody > tr`).each(function () {
        let doc = {};
        $(this)
          .find("td")
          .each((_i, td) => {
            let th = vm.fields[_i];
            if (th.key != "action") {
              if ($(td).text() != undefined) {
                doc[th.label] = $(td).text().toString().trim();
              } else {
                doc[th.label] = "";
              }
            }
          });
        json.push(doc);
      });
      vm.json = json;
      setTimeout(() => {
        vm.$refs.csv.$el.click();
      }, 500);
    },
    resetScroll() {
      let $ = window.$;
      const vm = this;
      $(document).ready(function () {
        $(".double-scroll").doubleScroll();
        setTimeout(() => {
          $(".doubleScroll-scroll-wrapper").scrollFix({
            side: "top",
            topPosition: 65,
          });
        }, 500);
        vm.stickyScroll();
      });
    },
    stickyScroll() {},
  },
  mounted() {
    if (!this.isDynamic) {
      this.totalRows = this.items.length;
    }
    this.resetScroll();
    if (this.isAllocationReport) {
      this.filter.perPage = undefined;
    }
  },
};
</script>

<style>
/* @import "../../../assets/design/mixins"; */

.doubleScroll-scroll {
}
</style>
