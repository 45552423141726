import menuConstants from "../../assets/constants/menu.constants.js";
export const menuItems = [
  {
    id: menuConstants.HOSPITAL_MANAGEMENT,
    label: "Hospital Management",
    icon: "mdi-hospital-marker",
    subItems: [
      {
        id: menuConstants.MANAGE_HOSPITAL,
        label: "Manage Hospitals",
        link: "/hospital-list",
      },
      {
        id: menuConstants.MANAGE_HOSPITAL_LICENCE,
        label: "Manage Organ License",
        link: "/transplant-list",
      },
    ],
  },
  {
    id: menuConstants.RECIPIENT_MANAGEMENT,
    label: "Recipient Management",
    icon: "mdi-account-arrow-left",
    subItems: [
      {
        id: menuConstants.MANAGE_RECIPIENT,
        label: "Manage Recipient",
        link: "/manage-recipient",
      },
      {
        id: menuConstants.RECIPIENT_TRANSFER,
        label: "Recipient Transfer",
        link: "/recipient-transfer",
      },
      {
        id: menuConstants.PENDING_APPROVAL,
        label: "Pending Approval",
        link: "/recipient-pending-approval",
      },
    ],
  },
  {
    id: menuConstants.MANAGE_DONOR,
    label: "Manage Donor",
    icon: "mdi-account-arrow-right",
    subItems: [
      {
        id: menuConstants.MANAGE_DONOR,
        label: "Manage Donor",
        link: "/manage-donor",
      },
      {
        id: menuConstants.MANAGE_DONOR,
        label: "Donor Identification",
        link: "/donor-identification",
      },
    ],
  },
  {
    id: menuConstants.DONOR_PlEDGE,
    label: "Donor Pledge",
    link: "/donor-pledge",
    icon: "mdi-hand-heart-outline",
  },
  {
    id: menuConstants.WAITING_LIST,
    label: "Waiting List",
    icon: "mdi-timer-sand",
    subItems: [
      {
        id: menuConstants.TNOS_WAITING_LIST,
        label: "Transtan Waiting List",
        link: "/tnos-waiting-list",
      },
      {
        id: menuConstants.HOSPITAL_WAITING_LIST,
        label: "Hospital Waiting List",
        link: "/hospital-waiting-list",
      },
      {
        id: menuConstants.CM_INSURANCE_LIST,
        label: "CM Insurance List",
        link: "/cm-insurance-list",
      },
      {
        id: menuConstants.IN_HOUSE_WAITING_LIST,
        label: "Inhouse Waiting List",
        link: "/in-house-waiting-list",
      },
      {
        id: menuConstants.BEREADY_LIST,
        label: "Be Ready List",
        link: "/be-ready-list",
      },
      {
        id: menuConstants.LIVE_TRANSPLANT,
        label: "Live Transplant",
        link: "/live-transplant",
      },
      {
        id: menuConstants.HOSPITAL_ROTA_ORDER,
        label: "Liver Rota Order",
        link: "/hospital-rota-order",
      },
    ],
  },
  {
    id: menuConstants.ACUTE_LIVER_FAILURE,
    label: "Acute Liver Failure",
    icon: "mdi-flash",
    link: "/liver-urgent-listing",
    subItems: [
      {
        id: menuConstants.LIVER_URGENT_LIST,
        label: "Liver Urgent Listing",
        icon: "mdi-progress-alert",
        link: "/manage-alf",
      },
      {
        id: menuConstants.ALF_APPROVER,
        label: "ALF Approver",
        icon: "mdi-check-decagram",
        link: "/manage-alf-approver",
      },
    ],
  },
  {
    id: menuConstants.USER_MANAGEMENT,
    icon: "mdi-account-plus",
    label: "User Management",
    link: "/user-management",
  },
  {
    id: menuConstants.TRANSTAN_USER_LIST,
    label: "Transtan Management",
    icon: "mdi-account-plus",
    link: "/transtan-management",
  },
  {
    id: menuConstants.REPORTS,
    label: "Reports",
    icon: "mdi-poll",
    link: "/reports",
  },
  {
    id: menuConstants.MASTERS,
    label: "Master",
    icon: "mdi-rhombus-split",
    link: "/masters",
  },
];
