<template>
  <div class="d-flex align-center">
    <b-dropdown-item @click="dialog = true">
      Update the case completed status
    </b-dropdown-item>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Update the case completed status"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <date-time-picker
              v-model="datatime"
              label="Surgery Completed Date & Time"
              :items="masterDonorTermination"
              rules="required"
            />
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import donorService from "../../assets/services/donor.service";
import DateTimePicker from "../form/DateTimePicker.vue";
export default {
  components: { DateTimePicker },
  props: {
    item: {},
  },
  data() {
    return {
      dialog: false,
      datatime: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      let { donor_temp_registration_id } = vm.item;
      try {
        vm.$loader.show();
        let { data } = await donorService.updateCrossClamp({
          donor_temp_registration_id,
          dml_indicator: "CL",
          crossclamp_datetime: vm.datatime,
        });
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    open() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to terminate this donor?",
        onConfirm: () => {
          vm.dialog = true;
        },
      });
    },
  },
};
</script>

<style></style>
