<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <select-hospital
        :isDonor="false"
        @onSelect="openAdmin"
        v-if="isAdmins"
        :hospitals="hospitals"
      ></select-hospital>
      <add-button @click="open" v-else></add-button>
    </div>
    <donor-identification-filter
      v-model="filter"
      @hospitals="hospitals = $event"
    ></donor-identification-filter>
    <table-view :fields="fields" :items="filteredList" ref="table">
      <template v-slot:action="{ item }">
        <edit-button @click="openEdit(item)"></edit-button>
      </template>
    </table-view>
  </div>
</template>

<script>
import moment from "moment";
import { encode, formatDate, formatDateOnly } from "../../assets/js/common";
import donorService from "../../assets/services/donor.service";
import EditButton from "../../components/buttons/EditButton.vue";
import DonorIdentificationFilter from "../../components/managedonor/DonorIdentificationFilter.vue";
import SelectHospital from "../../components/managedonor/SelectHospital.vue";
import TableView from "../../components/table/TableView.vue";
import { GENDERS } from "../../assets/constants/hospital.contants";
import AddButton from "../../components/buttons/AddButton.vue";
export default {
  components: {
    SelectHospital,
    DonorIdentificationFilter,
    TableView,
    EditButton,
    AddButton,
  },
  data() {
    return {
      hospitals: [],
      filter: {},
      list: [],
    };
  },
  computed: {
    filteredList() {
      let list = [...this.list];
      let { date_range, age_type } = this.filter;
      if (age_type && age_type != "all") {
        switch (age_type) {
          case "1":
            list = list.filter((i) => i.age < 12);
            break;
          case "2":
            list = list.filter((i) => i.age < 18);
            break;
          case "3":
            list = list.filter((i) => i.age < 60);
            break;
          case "4":
            list = list.filter((i) => i.age >= 60);
            break;
          default:
            break;
        }
      }
      [
        "hospitalid",
        "hospital_type",
        "gender",
        "State_id",
        "bloodid",
        "medical_legal_case",
        "first_apnea_status",
      ].forEach((key) => {
        list = list.filter((item) => {
          let val = this.filter[key];
          if (val != undefined && val != "all") {
            return item[key] == val;
          }
          return true;
        });
      });
      return list.filter((item) => {
        let date = moment(item.created_on, "DD/MM/YY HH:mm").toDate().getTime();
        if (date_range && date_range.length > 0) {
          let start = moment(date_range[0], "DD-MM-YYYY")
            .startOf("day")
            .toDate()
            .getTime();
          let end = moment(date_range[1], "DD-MM-YYYY")
            .endOf("day")
            .toDate()
            .getTime();
          return date >= start && date <= end;
        }
        return item;
      });
    },
    fields() {
      let doc = {
        sno: "S.No",
        created_on: "Created On",
        donor_name: "Donor Name",
        age: "Age",
        gender_text: "Gender",
        bloodgroup: "Blood Group",
        hospital_name: "Hospital Name",
        medical_legal_case_text: "MLC / Non-MLC",
        cause_of_death: "Cause of Death",
        date_of_accident: "Date of Accident",
        date_of_admisssion: "Date of admission",
        No_of_days_ventilator: "No.of days in Ventilator",
        first_apnea_status_text: "First Apnoea Status",
        first_apnea_updated_time: "1st apnea date & time",
        State: "State",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
  },
  methods: {
    openEdit({ donor_identificationid }) {
      let id = encode(donor_identificationid);
      this.$router.push(`/update-donor-identification?id=${id}`);
    },
    openAdmin(id) {
      this.$router.push(`/create-donor-identification?hospital_id=${id}`);
    },
    async fetchDonors() {
      const vm = this;
      try {
        vm.isLoading = true;
        let res = (
          await donorService.donorIdentification({
            Dml_Indicator: "S",
          })
        ).data;
        let { status, msg, data } = res;
        if (status) {
          let list = data.Table || [];
          vm.list = list.map((i) => {
            let gender = GENDERS.find((item) => item.value == i.gender);
            let item = {
              ...i,
              medical_legal_case_text: i.medical_legal_case == 1 ? "Yes" : "No",
              first_apnea_status_text: i.first_apnea_status == 1 ? "Yes" : "No",
              first_apnea_updated_time: formatDate(i.first_apnea_updated_time),
              created_on: formatDate(i.createdon),
              gender_text: gender ? gender.name : "",
              date_of_accident: formatDateOnly(i.date_of_accident),
              date_of_admisssion: formatDateOnly(i.date_of_admisssion),
            };
            return item;
          });
        } else {
          vm.$alert.show(msg);
        }
        vm.isLoading = false;
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
    open() {
      const vm = this;
      let hospital_id = vm.user.hospital_id;
      let id = encode(hospital_id);
      if (hospital_id) {
        vm.$router.push(`/create-donor-identification?hospital_id=${id}`);
      } else {
        vm.$alert.show("Please register the hospital fist");
      }
    },
  },
  mounted() {
    this.fetchDonors();
  },
};
</script>

<style>
</style>