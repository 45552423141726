<template>
  <div>
    <allocation-recipient-table
      :fields="fields"
      :isBusy="isLoading"
      :title="title"
      :item="item"
      :isLast="isLast"
      :items="list"
    >
      <template v-slot:action="doc">
        <allocating-waiting-preview
          :item="{ ...doc.item, organ_allocation_id: item.organ_allocation_id }"
        ></allocating-waiting-preview>
      </template>
      <div class="d-flex mt-3">
        <div class="flex-grow-1"></div>
        <b-button variant="primary" v-if="isFooter" @click="submit"
          >Generate Priority Waiting List</b-button
        >
      </div>
    </allocation-recipient-table>
  </div>
</template>

<script>
import { RECIPIENT_ALLOCATION_STATUS } from "../../../../assets/constants/donor.constants";
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { formatDate } from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import AllocatingWaitingPreview from "./AllocatingWaitingPreview.vue";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
export default {
  components: {
    AllocatingWaitingPreview,
    AllocationRecipientTable,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: 2,
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: false,
      list: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isFooter() {
      let { allocation_status } = this.item;
      let { status, isAllocationReport } = this;
      if (isAllocationReport) return false;
      if (!this.isAdmins) return false;
      return (
        (status == 2 && allocation_status == 2) ||
        (status == 4 && allocation_status == 4) ||
        (status == 19 && allocation_status == 19) ||
        (status == 14 && allocation_status == 14) ||
        (status == 15 && allocation_status == 15) ||
        (status == 16 && allocation_status == 16) ||
        (status == 33 && allocation_status == 33) ||
        (status == 29 && allocation_status == 29)
      );
    },
    title() {
      if (this.status == 4) return "No Takers Wait List";
      return "Generated Wait List";
    },
    fields() {
      let doc = {
        recepient_organ_allocation_status: "Accept/Decline Status",
        reject_reason: "Reject Reason",
      };
      if (this.isAdmins && !this.isAllocationReport) doc.action = "Action";
      return doc;
    },
  },
  watch: {
    status() {
      this.init();
    },
  },
  methods: {
    async submit() {
      const vm = this;
      let { organ_id, organ_allocation_id } = vm.item;
      const doc = {
        dml_indicator: "SS",
        organ_id,
        organ_allocation_id,
        recepient_type_of_allocation: 1,
      };
      if (vm.status == 4) doc.recepient_type_of_allocation = 2;
      if (vm.status == 19) doc.recepient_type_of_allocation = 3;
      if (vm.status == 14) doc.recepient_type_of_allocation = 4;
      if (vm.status == 15) doc.recepient_type_of_allocation = 5;
      if (vm.status == 16) doc.recepient_type_of_allocation = 6;
      if (vm.status == 33) doc.recepient_type_of_allocation = 7;
      if (vm.status == 29) doc.recepient_type_of_allocation = 8;

      try {
        vm.$loader.show();
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, msg } = data;
        vm.$loader.hide();
        event.$emit("refresh");
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    getStatus(val) {
      let item = RECIPIENT_ALLOCATION_STATUS.find((item) => {
        return item.value == val;
      });
      return item ? item.name : "";
    },
    async init() {
      const vm = this;
      vm.list = [];
      let {
        organ_id_org,
        is_multi,
        organ_id,
        organ_allocation_id,
        type_of_allocation,
      } = vm.item;
      let { user_type } = this.user;
      if (is_multi) organ_id = organ_id_org;
      const doc = {
        dml_indicator: type_of_allocation == 3 ? "RGL" : "GL",
        organ_id,
        recepient_type_of_allocation: 1,
        organ_allocation_id,
        user_type,
      };
      if (vm.status == 4) doc.recepient_type_of_allocation = 2;
      if (vm.status == 19) doc.recepient_type_of_allocation = 3;
      if (vm.status == 14) doc.recepient_type_of_allocation = 4;
      if (vm.status == 15) doc.recepient_type_of_allocation = 5;
      if (vm.status == 16) doc.recepient_type_of_allocation = 6;
      if (vm.status == 33) doc.recepient_type_of_allocation = 7;
      if (vm.status == 29 || vm.status == 31)
        doc.recepient_type_of_allocation = 8;

      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }

        vm.list = list.map((item) => {
          item.created_on = formatDate(item.created_on);
          item.gender = vm.getGender(item.gender);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
