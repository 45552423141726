<template>
  <expandable header="Medications - Inotropes" class="mb-10">
    <b-row class="mobile-table-header d-none d-md-flex px-2 py-2 mx-1">
      <b-col cols="12" md="6"> Inotrope </b-col>
      <b-col cols="12" md="6"> Dosage </b-col>
    </b-row>
    <b-row
      class="px-md-4 py-2 d-flex align-items-center"
      v-for="i in 4"
      :key="i"
    >
      <b-col cols="12" md="6"> Inotrope {{ i }} </b-col>
      <b-col cols="12" md="6">
        <input-field
          v-model="form[`inotrope_${i}`]"
          @input="onInput"
          :disabled="isDisabled"
        ></input-field>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
      Hormone Replacement Therapy
    </div>
    <b-row>
      <b-col cols="4">
        <yes-no
          v-model="form.steroids"
          label="Steroids"
          class="ms-1"
          @input="onInput"
          :disabled="isDisabled"
        ></yes-no>
      </b-col>
      <b-col cols="4">
        <yes-no
          v-model="form.vasopressin"
          label="Vasopressin"
          @input="onInput"
          :disabled="isDisabled"
        ></yes-no>
      </b-col>
      <b-col cols="4">
        <yes-no
          v-model="form.thyroxine"
          label="Thyroxine"
          :disabled="isDisabled"
          @input="onInput"
        ></yes-no>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import YesNo from "../../form/YesNo.vue";
export default {
  components: { YesNo, Expandable },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
