<template>
  <div class="d-flex" v-if="hasCreate">
    <div class="flex-grow-1"></div>
    <select-hospital v-if="isAdmin" :hospitals="hospitals"></select-hospital>
    <Add @click="open" v-else-if="isHospitalContact"></Add>
  </div>
</template>

<script>
import menuConstants from "../../../assets/constants/menu.constants";
import { encode } from "../../../assets/js/common";
import commonMixins from "../../../assets/mixins/common.mixins";
import Add from "../../buttons/AddButton.vue";
import SelectHospital from "./SelectHospital.vue";
export default {
  props: {
    hospitals: Array,
  },
  components: { Add, SelectHospital },
  mixins: [commonMixins],
  computed: {
    hasCreate() {
      let hasCreateAccess = this.hasCreateAccess(menuConstants.LIVE_TRANSPLANT);
      return hasCreateAccess;
    },
  },
  methods: {
    open() {
      const vm = this;
      let hospital_id = vm.user.hospital_id;
      let id = encode(hospital_id);
      if (hospital_id) {
        vm.$router.push(`/live-transplant-registration?hospital_id=${id}`);
      } else {
        vm.$alert.show("Please register the hospital fist");
      }
    },
  },
};
</script>

<style></style>
