<template>
  <div class="pa-10">
    <Header ref="header"></Header>
    <Status :status="status"></Status>
    <hospital-list v-model="status"></hospital-list>
  </div>
</template>
<script>
import Header from "../../components/hospitals/Header.vue";
import HospitalList from "../../components/hospitals/HospitalList.vue";
import Status from "../../components/Status.vue";
export default {
  components: {
    Header,
    Status,
    HospitalList,
  },
  data() {
    return {
      status: {},
    };
  },
  mounted() {},
};
</script>

<style></style>
