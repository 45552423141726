<template>
  <expandable header="ABG Test">
    <table class="table align-middle">
      <thead class="table-light">
        <tr>
          <th class="align-middle">Name</th>
          <th class="align-middle">File</th>
          <th class="align-middle">FiO<sub>2</sub></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in 4" :key="i">
          <td
            v-html="formattedLabel(`ABG Test ${i}`, 'file-image-pdf|file-size')"
          ></td>
          <td>
            <print-file-input
              v-model="form[`ABG_file_upload_${i}`]"
              @input="onInput"
              :label="'File ' + i"
              accept=".jpg,capture=camera,.jpeg,.png,.pdf"
              :disabled="isDisabled"
              rules="file-image-pdf|file-size"
            ></print-file-input>
          </td>
          <td>
            <print-input
              :prepend="i == 1 ? '100%' : ''"
              :label="`FiO<sub>2</sub> ${i}`"
              v-model="form[`FIO2_${i}`]"
            ></print-input>
          </td>
        </tr>
      </tbody>
    </table>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import PrintFileInput from "../../ui/print/PrintFileInput.vue";
import PrintInput from "../../ui/print/PrintInput.vue";

export default {
  components: { Expandable, PrintInput, PrintFileInput },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
      console.log(this.form);
    },
    formattedLabel(label, rules) {
      if (!rules) return label;
      if (rules.indexOf("required") != -1)
        return label + " <span style='color:red'>*</span>";
      return label;
    },
  },
  beforeMount() {
    this.form = this.value;
    // if(!this.form['FIO2_1'])  this.form['FIO2_1'] = "100%"
  },
};
</script>

<style></style>
