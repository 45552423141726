<template>
  <Expandable header="Approved Tissue Bank" class="h-100">
    <ul class="list-group list-group-flush">
      <li
        class="
          list-group-item
          d-flex
          justify-content-between
          cursor-pointer
          align-items-center
        "
        v-for="(item, i) in list"
        :key="i"
      >
        <label class="mb-0 flex-grow-1 cursor-pointer" :for="'tissues-' + i">{{
          item.name
        }}</label>

        <b-form-checkbox
          :id="'tissues-' + i"
          v-model="model"
          :disabled="isDisabled"
          class="cursor-pointer"
          @change="input"
          :value="item.value"
        >
        </b-form-checkbox>
      </li>
    </ul>
  </Expandable>
</template>

<script>
import hospitalCreateMixins from "../../../assets/mixins/hospital.create.mixins";
import Expandable from "../../Expandable.vue";
export default {
  props: {
    value: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: [],
    };
  },
  mixins: [hospitalCreateMixins],
  components: { Expandable },
  computed: {
    master() {
      return this.$store.getters.master;
    },
    list() {
      if (this.master.master_organ instanceof Array == false) return [];
      let list = this.master.master_organ.filter(({ status, tissue_bank }) => {
        return status != 4 && tissue_bank == 1;
      });
      return list.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
  },
  methods: {
    input() {
      this.$emit("input", this.model);
    },
  },
  mounted() {
    this.model = this.value;
  },
};
</script>

<style></style>
