/* eslint-disable no-async-promise-executor */
import Vue from "vue";
import Vuex from "vuex";
import alfService from "../assets/services/alf.service";
import donorService from "../assets/services/donor.service";
import masterService from "../assets/services/master.service";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    _master: {},
    _isAuth: false,
    _user: {},
    _vaccinations: [],
    _masterCauseOfDeath: [],
    _masterfileUploadTypes: [],
    _masterDonorTermination: [],
    _masterDonorMedicalDetails: [],
    _masterALF: [],
    _masterCaseOffer: [],
    _userRights: [],
    _query: {},
  },
  getters: {
    master: (state) => state._master,
    query: (state) => state._query,
    userRights: (state) => state._userRights,
    isAuth: (state) => state._isAuth,
    vaccinations: (state) => state._vaccinations,
    masterALF: (state) => state._masterALF,
    masterfileUploadTypes: (state) => state._masterfileUploadTypes,
    masterCaseOffer: (state) => state._masterCaseOffer,
    masterCauseOfDeath: (state) => state._masterCauseOfDeath,
    masterDonorTermination: (state) => state._masterDonorTermination,
    masterDonorMedicalDetails: (state) => state._masterDonorMedicalDetails,
    user() {
      let doc = Vue.$cookies.get("session_details");
      return doc instanceof Object == false ? {} : doc;
    },
  },
  mutations: {
    SET_QUERY(state, { key, query }) {
      state._query[key] = query;
    },
    SET_RIGHTS(state, val) {
      state._userRights = val;
    },
    SET_MASTER_ALF(state, val) {
      state._masterALF = val;
    },
    SET_MASTER(state, val) {
      state._master = val;
    },
    SET_MASTER_CASE_OFFICER(state, val) {
      state._masterCaseOffer = val;
    },
    SET_VACINATIONS(state, val) {
      state._vaccinations = val;
    },
    SET_MASTER_MEDICAL_DETAILS(state, val) {
      state._masterDonorMedicalDetails = val;
    },
    SET_MASTER_CAUSE_OF_DEATH(state, val) {
      state._masterCauseOfDeath = val;
    },
    SET_MASTER_DONOR_TERMINAITON(state, val) {
      state._masterDonorTermination = val;
    },
    SET_AUTH(state) {
      let session = Vue.$cookies.get("session");
      state._isAuth = session;
    },
    SET_FILE_UPLOAD_TYPES(state, val) {
      state._masterfileUploadTypes = val;
    },
  },
  actions: {
    fetchMaster(context) {
      return new Promise(async (resolve, reject) => {
        try {
          context.commit("SET_MASTER", []);
          let { data } = await masterService.get();
          context.commit("SET_MASTER", data);
          resolve();
        } catch (error) {
          reject(error);
          console.log(error.message);
        }
      });
    },
    async fetchCaseOfficer(context) {
      return new Promise(async (resolve, reject) => {
        try {
        context.commit("SET_MASTER_CASE_OFFICER", []);
        let { data } = await donorService.getCaseOfficer();
        context.commit("SET_MASTER_CASE_OFFICER", data.list);
        resolve();
      } catch (error) {
        reject(error)
        console.log(error.message);
      }
    });
    },
    async fetchVaccinations(context) {
      return new Promise(async (resolve,reject) => {
        try {
          let { data } = await masterService.getVaccinationList();
          context.commit("SET_VACINATIONS", data.list);
          resolve();
        } catch (error) {
          reject(error);
          console.log(error.message);
        }
  
      })
    },
    async fetchDonorTermination(context) {
      try {
        let { data } = await masterService.getDonorTermination();
        context.commit("SET_MASTER_DONOR_TERMINAITON", data.list);
      } catch (error) {
        console.log(error.message);
      }
    },
    async fetchFileUploadTypes(context) {
      try {
        let { data } = await masterService.getFileUploadType();
        context.commit("SET_FILE_UPLOAD_TYPES", data.list);
      } catch (error) {
        console.log(error.message);
      }
    },
    async fetchDonorMedicalDetails(context) {
      try {
        let { data } = await masterService.getDonorMedicalDetails();
        context.commit("SET_MASTER_MEDICAL_DETAILS", data.list);
      } catch (error) {
        console.log(error.message);
      }
    },
    async fetchCauseOfDeath(context) {
      try {
        let { data } = await masterService.getDonorCauseOFDeath();
        context.commit("SET_MASTER_CAUSE_OF_DEATH", data.list);
      } catch (error) {
        console.log(error.message);
      }
    },
    async fetchALFMaster(context) {
      try {
        let { data } = await alfService.masterALF();
        context.commit("SET_MASTER_ALF", data.list);
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  modules: {},
});
