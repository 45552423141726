<template>
  <Expandable header="Contacts" class="mb-10">
    <b-table
      class="mt-3"
      :fields="headers"
      :items="list"
      responsive
      :head-variant="'dark'"
      thead-class="table-light"
    >
      <template #cell(first_name)="{ item }">
        {{ `${item.first_name} ${item.last_name}` }}
      </template>
      <template #cell(action)="{ item, index }">
        <div class="d-flex" v-if="item.status != 4">
          <create
            :list="list"
            :item="item"
            :hospital_id="hospital_id"
            :index="index"
            @onUpdate="onUpdate"
          ></create>
          <delete-button @click="remove(index)"> </delete-button>
        </div>
      </template>
    </b-table>
  </Expandable>
</template>

<script>
import { FIELD } from "../../../assets/js/rules";
import DeleteButton from "../../buttons/DeleteButton.vue";
import Expandable from "../../Expandable.vue";
import Create from "./contact/Create.vue";
export default {
  props: {
    value: Array,
    hospital_id: [Number, String],
    count: {
      type: Number,
      default: 3,
    },
  },
  components: { Expandable, Create, DeleteButton },
  data() {
    return {
      rules: FIELD,
      files: [],
      list: [],
    };
  },
  computed: {
    contactType() {
      let way = ["hospital-registration", "update-hospital"];
      let { name } = this.$route;
      return way.indexOf(name) != -1 ? 1 : 2;
    },
    contactTypes() {
      return this.masterContactTypes.filter((item) => {
        return item.contact_type == this.contactType;
      });
    },
    isAdd() {
      let count = this.count;
      const vm = this;
      let list = vm.list.filter((item) => {
        return item.status == 1;
      });
      return list.length != count;
    },
    headers() {
      let doc = {
        first_name: "Name",
        phone_number_1: "Phone",
        contact_name: "Contact Type",
        organ_name: "Organ Access",
        email_id: "Email",
        // action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          label: doc[key],
          key,
        });
      });
      return list;
    },
    isUpdate() {
      let { name } = this.$route;
      return name == "update-hospital" || "update-transplant-license";
    },
  },
  methods: {
    getContactType({ contact_id }) {
      let item = this.contactTypes.find((item) => {
        return item.value == contact_id;
      });
      return item instanceof Object ? item.name : "";
    },
    undo(i) {
      this.list[i].status = 1;
    },
    remove(i) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure?",
        onConfirm: () => {
          if (vm.list[i].hospital_contact_id) {
            vm.list[i].status = 4;
          } else {
            vm.list.splice(i, 1);
          }
        },
      });
      vm.$emit("input", vm.list);
    },
    onCreate(item) {
      const vm = this;
      vm.list.unshift(item);
      vm.$emit("input", vm.list);
    },
    onUpdate({ doc, index }) {
      const vm = this;
      vm.$set(vm.list, index, doc);
      vm.$emit("input", vm.list);
    },
  },
  mounted() {
    this.list = this.value;
  },
};
</script>

<style></style>
