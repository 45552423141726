<template>
  <div class="d-flex align-center">
    <icon-button
      icon="arrow-up-right-circle"
      @click="dialog = true"
      variant="primary"
    ></icon-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Status Update"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <yes-no
              v-model="form.pati_medical_fittransplant"
              label="Patient Medically Fit for Surgery"
              rules="required"
            >
            </yes-no>
          </b-col>
          <b-col cols="12">
            <yes-no
              v-model="form.pati_agreesurgery"
              label="Patient Agreed for Surgery"
              rules="required"
            >
            </yes-no>
          </b-col>
          <b-col cols="12">
            <input-field
              v-model="form.current_town"
              label="Patient Current Town"
              rules="required"
            >
            </input-field>
          </b-col>
          <b-col cols="12">
            <input-field
              v-model="form.anyotherremarks"
              label="Any other Remarks"
              rules=""
            >
            </input-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import recipientService from "../../../assets/services/recipient.service";
import IconButton from "../../buttons/IconButton.vue";
import YesNo from "../../form/YesNo.vue";
export default {
  components: { IconButton, YesNo },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        recipient_beready_id: 0,
        current_town: "",
        pati_medical_fittransplant: "",
        pati_agreesurgery: "",
        anyotherremarks: "",
        update_by: "",
      },
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init();
      }
    },
  },
  methods: {
    init() {
      const vm = this;
      Object.keys(vm.form).forEach((key) => {
        if (vm.item[key]) vm.form[key] = vm.item[key];
      });
      vm.form.update_by = vm.created_by;
    },
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await recipientService.updateBeReadyStatus(vm.form);
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
};
</script>

<style></style>
