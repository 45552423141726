<template>
  <Expandable header="Donor Details" class="mb-0 px-3" :noBody="true">
    <b-row>
      <b-col cols="12" md="4">
        <print-input
          v-model="form.donor_name"
          label="Name of Donor"
          @input="onInput"
          :disabled="isDisabled"
          rules="required"
        >
        </print-input>
      </b-col>

      <b-col cols="12" md="4">
        <print-date
          v-model="form.DOB"
          label="Date of Birth"
          @input="onInput"
          outlined
          :disabled="isDisabled"
          :max="new Date()"
          rules="required"
        ></print-date>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          v-model="form.age"
          label="Age"
          @input="onInput"
          :disabled="true"
          type="number"
          outlined
          rules="max_num_120"
        ></print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-select
          @input="onInput"
          label="Gender"
          :disabled="isDisabled"
          :items="gender"
          v-model="form.sex"
          rules="required"
        ></print-select>
      </b-col>
      <b-col cols="12" md="4">
        <print-select
          @input="onInput"
          label="Blood Group"
          :items="bloodGroups"
          :disabled="isDisabled"
          v-model="form.blood_group"
          rules="required"
        ></print-select>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          label="Height"
          v-model="form.height"
          @input="onInput"
          rules="required|height|dec-0"
          :disabled="isDisabled"
          unit="cm"
          type="text"
        >
        </print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          label="Weight"
          :disabled="isDisabled"
          v-model="form.weight"
          rules="required|weight|dec-2"
          unit="kg"
          @input="onInput"
          type="text"
        >
        </print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          label="BMI"
          @input="onInput"
          v-model="form.BMI"
          rules="required"
          unit="Kg/m<sup>2</sup>"
          :disabled="true"
          type="number"
        >
        </print-input>
      </b-col>
      <b-col cols="12" md="4">
        <print-yes-no
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.medical_legal_case"
          label="Is MLC Case?"
          rules="required"
        >
        </print-yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <print-input
          :disabled="isDisabled"
          @input="onInput"
          label="AR Case Number"
          v-model="form.MLC_AR_No"
          :rules="form.medical_legal_case == 1 ? 'required' : ''"
        >
        </print-input>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import { GENDERS } from "../../../assets/constants/hospital.contants";
import { getAge } from "../../../assets/js/common";
import PrintInput from "../../ui/print/PrintInput.vue";
import PrintYesNo from "../../ui/print/PrintYesNo.vue";
import PrintDate from "../../ui/print/PrintDate.vue";
import PrintSelect from "../../ui/print/PrintSelect.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  components: { Expandable, PrintInput, PrintYesNo, PrintDate, PrintSelect },
  data() {
    return {
      form: {},
      gender: GENDERS,
    };
  },
  methods: {
    onInput() {
      const vm = this;
      let { height, weight } = vm.form;
      height = height / 100;
      let bmi = weight / (height * height);
      vm.form.BMI = bmi.toFixed(2);
      let { DOB } = vm.form;
      if (DOB) {
        this.form.age = getAge(DOB);
      }
      vm.$emit("input", vm.form);
    },
  },
  beforeMount() {
    this.form = this.value;
    this.onInput();
  },
};
</script>

<style></style>
