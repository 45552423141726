<template>
  <b-dropdown variant="link" dropleft class="dropleft">
    <template #button-content>
      <b-icon icon="three-dots" class="font-size-16"></b-icon>
    </template>
    <set-donor-case-officer
      v-if="status == 0 && isStatusChangeAble(1)"
      @fetch="$emit('fetch')"
      :item="item"
    ></set-donor-case-officer>
    <b-dropdown-item
      v-if="status == 1 && isStatusChangeAble(2)"
      @click="updateStatus(2)"
      >Request For Local Organ
    </b-dropdown-item>
    <b-dropdown-item
      v-if="
        status == 2 && isStatusChangeAble(3) && (isDonorHospital || isAdmins)
      "
      @click="updateStatus(3)"
      >Local Organ Chosen
    </b-dropdown-item>
    <b-dropdown-item
      v-if="status == 3 && isStatusChangeAble(7) && isDonorHospital"
      @click="updateOTStatus()"
      >Donor Shift to OT
    </b-dropdown-item>
    <update-cross-clamp-time
      v-if="status == 7 && isStatusChangeAble(8) && isDonorHospital"
      :item="item"
      @fetch="$emit('fetch')"
      @click="updateOTStatus()"
    />
    <b-dropdown-item
      v-if="status == 4 && isStatusChangeAble(6)"
      @click="updateStatus(6)"
      >Organ Assessment Details Updated
    </b-dropdown-item>
    <final-donor-termination
      v-if="status != 5 && isStatusChangeAble(5)"
      @fetch="$emit('fetch')"
      :item="item"
    ></final-donor-termination>
    <b-dropdown-item v-if="isAdmins || isDonorHospital" @click="open()"
      >Edit Donor Details
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import { encode } from "../../assets/js/common";
import donorService from "../../assets/services/donor.service";
import FinalDonorTermination from "./FinalDonorTermination.vue";
import SetDonorCaseOfficer from "./SetDonorCaseOfficer.vue";
import UpdateCrossClampTime from "./UpdateCrossClampTime.vue";
export default {
  props: {
    item: Object,
  },
  components: {
    SetDonorCaseOfficer,
    FinalDonorTermination,
    UpdateCrossClampTime,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    status() {
      return this.item.final_donor_status;
    },
    isDonorHospital() {
      let { hospital_id } = this.user;
      return this.isHospitalContact && hospital_id == this.item.hospital_id;
    },
  },
  methods: {
    open() {
      let { donor_temp_registration_id } = this.item;
      let id = encode(donor_temp_registration_id);
      this.$router.push(`/update-donor?id=${id}`);
    },
    async updateOTStatus() {
      const vm = this;
      let { final_donor_id, donor_temp_registration_id } = vm.item;
      try {
        vm.$loader.show();
        let { data } = await donorService.updateOTStatus({
          dml_indicator: "OT",
          donor_temp_registration_id,
          final_donor_id,
        });
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    isStatusChangeAble(status) {
      return this.hasStatusAccess(menuConstants.FINAl_DONOR, status);
    },
    async updateStatus(final_status) {
      const vm = this;
      let { final_donor_id } = vm.item;
      try {
        vm.$loader.show();
        let { data } = await donorService.updateFinalDonorStatus({
          dml_indicator: "SU",
          status: final_status,
          final_donor_id,
        });
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
};
</script>
<style>
.dropleft .dropdown-menu {
  left: -100% !important;
}
</style>
