<template>
  <div class="d-flex">
    <div class="flex-grow-1"></div>
    <select-hospital v-if="isAdmins" :hospitals="hospitals"></select-hospital>
    <Add class="mb-4" @click="open" v-else></Add>
  </div>
</template>

<script>
import { encode } from "../../assets/js/common";
import commonMixins from "../../assets/mixins/common.mixins";
import Add from "../buttons/AddButton.vue";
import SelectHospital from "./SelectHospital.vue";
export default {
  props: {
    hospitals: Array,
  },
  components: { Add, SelectHospital },
  mixins: [commonMixins],
  methods: {
    open() {
      const vm = this;
      let hospital_id = vm.user.hospital_id;
      let id = encode(hospital_id);
      if (hospital_id) {
        vm.$router.push(`/donor-registration?hospital_id=${id}`);
      } else {
        vm.$alert.show("Please register the hospital fist");
      }
    },
  },
};
</script>

<style></style>
