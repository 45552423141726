<template>
  <expandable header="Payment Details" v-if="false">
    <div class="d-flex align-items-center">
      <h6 class="mb-0">Payment Amount: ₹40000</h6>
      <div class="flex-grow-1"></div>
      <b-button variant="primary" :disabled="isDisabled" @click="paynow"
        >Pay Now</b-button
      >
    </div>
  </expandable>
</template>

<script>
import hospitalService from "../../../assets/services/hospital.service";
import Expandable from "../../Expandable.vue";
export default {
  components: { Expandable },
  props: {
    id: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async paynow() {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await hospitalService.generatePaymentBill(vm.id);
        let { payment_url, status, msg } = data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        location.href = payment_url;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
