<template>
  <form-wrapper @submit="submit" v-if="!isLoading">
    <div class="d-flex mb-2">
      <div class="flex-grow-1"></div>
      <print-button style="width: 170px" @click="printPatient()" class="me-3">
        Print Patient Copy</print-button
      >
      <print-button style="width: 170px" @click="print()">
        Print Hospital Copy</print-button
      >
    </div>
    <recipient-register-alert-message v-if="!isUpdate" />
    <Details
      :hospitalType="hospitalType"
      v-model="details"
      :isAadharVerified="isAadharVerified"
      ref="details"
      :isDisabled="isDisabled"
    ></Details>
    <aadhar-address
      v-if="!isInternational"
      :isDisabled="true"
      v-model="aadhar_address"
    ></aadhar-address>
    <Address
      v-model="address"
      ref="additionAddress"
      :isDisabled="isDisabled"
      :isInternational="isInternational"
      v-if="isAadharNotPermanent"
    ></Address>
    <organ-requested
      :details="details"
      ref="organRequested"
      :isDisabled="isDisabled"
      v-model="organ_requested"
    ></organ-requested>
    <consultant-details
      ref="consultant"
      :isDisabled="isDisabled"
      :organs="organ_requested"
      :hospital_id="details.hospital_id"
      v-model="consultant"
    ></consultant-details>
    <declaration-attachments
      :isDisabled="isDisabled"
      :isInternational="isInternational"
      v-model="declarationFiles"
      ref="declarationAttachments"
      :filesList="filesList"
      @onFileRemoved="onFileRemoved($event, 'declarationAttachments')"
    ></declaration-attachments>

    <medical-details>
      <recipient-medical-details
        :isDisabled="isDisabled"
        v-model="recipient_medical_details"
      ></recipient-medical-details>
      <virology-status
        v-model="virology_status"
        :isDisabled="isDisabled"
      ></virology-status>
    </medical-details>
    <vaccination-status
      ref="vaccination"
      v-model="vaccination_status"
      :isDisabled="isDisabled"
    ></vaccination-status>
    <recipient-medical-attachments
      v-model="medicalFiles"
      v-if="false"
      ref="medicalAttachments"
      :isDisabled="isDisabled"
      :filesList="filesList"
      @onFileRemoved="onFileRemoved($event, 'medicalAttachments')"
    ></recipient-medical-attachments>
    <attachments
      :isDisabled="isDisabled"
      v-model="files"
      ref="attachments"
      :filesList="filesList"
      @onFileRemoved="onFileRemoved($event, 'attachments')"
    ></attachments>
    <lung-medical-details
      v-if="isLung"
      v-model="lung"
      :isDisabled="isDisabled"
    ></lung-medical-details>
    <liver-medical-details
      v-if="
        isParameter(parameter.LIVER_ID) || isParameter(parameter.PANCREAS_ID)
      "
      v-model="liver"
      :isDisabled="isDisabled"
    ></liver-medical-details>
    <kidney-medical-details
      v-if="isParameter(parameter.KIDNEY_ID)"
      :isDisabled="isDisabled"
      v-model="kidney"
    ></kidney-medical-details>
    <heart-medical-details
      v-if="isParameter(parameter.HEART_ID)"
      v-model="heart"
      :isDisabled="isDisabled"
    ></heart-medical-details>
    <contacts
      ref="contacts"
      v-model="contacts"
      :isDisabled="isDisabled"
      :count="3"
    ></contacts>
    <Footer
      @print="print()"
      @download="download()"
      :payment_flag="payment_flag"
      :isAadharVerified="details.aadhar_flag == 1 || isInternational"
      :status="details.transtan_status"
      @draft="submit(true)"
    ></Footer>
  </form-wrapper>
</template>

<script>
import Contacts from "../../components/managerecipient/create/RecipientContacts.vue";
import AadharAddress from "../../components/managerecipient/create/AadharAddress.vue";
import Address from "../../components/managerecipient/create/Address.vue";
import Details from "../../components/managerecipient/create/Details.vue";
import MedicalDetails from "../../components/managerecipient/create/MedicalDetails.vue";
import OrganRequested from "../../components/managerecipient/create/OrganRequested.vue";
import VirologyStatus from "../../components/managerecipient/create/VirologyStatus.vue";
import ConsultantDetails from "../../components/managerecipient/create/ConsultantDetails.vue";
import RecipientMedicalDetails from "../../components/managerecipient/create/HealthDetails.vue";
import VaccinationStatus from "../../components/managerecipient/create/VaccinationStatus.vue";
import HeartMedicalDetails from "../../components/managerecipient/create/HeartMedicalDetails.vue";
import Footer from "../../components/managerecipient/create/Footer.vue";
import KidneyMedicalDetails from "../../components/managerecipient/create/KidneyMedicalDetails.vue";
import LiverMedicalDetails from "../../components/managerecipient/create/LiverMedicalDetails.vue";
import LungMedicalDetails from "../../components/managerecipient/create/LungMedicalDetails.vue";
import RecipientHelper from "../../components/managerecipient/recipient.helper.js";
import RecipientService from "../../assets/services/recipient.service";
import recipientPrint from "../../assets/print/recipient.print";

import {
  decode,
  HEART_ID,
  initPayment,
  KIDNEY_ID,
  LEFT_LUNG_ID,
  LIVER_ID,
  LUNG_ID,
  PANCREAS_ID,
  RIGHT_LUNG_ID,
} from "../../assets/js/common";
import recipientMixins from "../../assets/mixins/recipient.mixins";
import Attachments from "../../components/managerecipient/create/Attachments.vue";
import RecipientMedicalAttachments from "../../components/managerecipient/create/RecipientMedicalAttachments.vue";
import userHelper from "../../components/manageuser/user.helper";
import DeclarationAttachments from "../../components/managerecipient/create/DeclarationAttachments.vue";
import menuConstants from "../../assets/constants/menu.constants";
import PrintButton from "../../components/buttons/PrintButton.vue";
import event from "../../assets/js/event";
import hospitalService from "../../assets/services/hospital.service";
import {
  RECIPIENT_PAYMENT_MESSAGE,
  TRANSFER_PAYMENT_MESSAGE,
  MULTI_ORGAN_PAYMENT_MESSAGE,
} from "../../assets/constants/recipient.constants";
import RecipientRegisterAlertMessage from "../../components/managerecipient/RecipientRegisterAlertMessage.vue";
export default {
  components: {
    Details,
    Address,
    AadharAddress,
    Contacts,
    ConsultantDetails,
    OrganRequested,
    MedicalDetails,
    VirologyStatus,
    RecipientMedicalDetails,
    VaccinationStatus,
    HeartMedicalDetails,
    Footer,
    KidneyMedicalDetails,
    LiverMedicalDetails,
    LungMedicalDetails,
    Attachments,
    RecipientMedicalAttachments,
    DeclarationAttachments,
    PrintButton,
    RecipientRegisterAlertMessage,
  },
  data() {
    return {
      contacts: [],
      files: [],
      medicalFiles: [],
      filesList: [],
      declarationFiles: [],
      isLoading: true,
      created_on: "",
      hospitalType: 2,
      payment_flag: 0,
      organ_type: 1,
      isAadharVerified: false,
      parameter: {
        HEART_ID,
        KIDNEY_ID,
        LIVER_ID,
        LUNG_ID,
        PANCREAS_ID,
      },
      details: {
        patient_nationlity: 0,
        aadhar_flag: 0,
        aadhar_number: "",
        passport: "",
        embassy_documents: null,
        passport_document: null,
        first_name: "",
        last_name: "",
        blood_group: "",
        status: 1,
        created_by: "",
        dml_indicator: "I",
        hospital_id: "",
        hospital_status: 1,
        transtan_status: "1",
        CM_insurance: "",
        CM_insurance_notes: "",
        country_code_1: "",
        phone_number_1: "",
        email_id: "",
        payment_reference_no: "",
        notto_id: "",
        individual_id: "",
        DOB: "",
        age: "",
        gender: "",
      },
      address: {
        address_line_1: "",
        address_line_2: "",
        town_village: "",
        is_permanent_address: 0,
        landmark: "",
        country: "99",
        address_id: "",
        state: "32",
        city: "0",
        pincode: "",
        aadhar_flag: 0,
        created_by: "",
        recipient_address_mapping_id: "",
        recipient_registration_id: "",
        dml_indicator: "I",
      },
      aadhar_address: {
        aadhar_name: "",
        father_name: "",
        aadhar_photo: "",
        address_id: "",
        address_line_1: "",
        address_line_2: "",
        town_village: "",
        is_permanent_address: 1,
        recipient_address_mapping_id: "",
        recipient_registration_id: "",
        landmark: "",
        country: "99",
        state: "32",
        city: "0",
        pincode: "",
        created_by: "",
        aadhar_flag: 1,
        dml_indicator: "I",
      },
      lung: {
        recipent_lungs_id: "",
        cause_of_lung_disease: "",
        cause_of_lung_disease_notes: "",
        FEV1: "",
        FVC: "",
        MVV: "",
        DLCO: "",
        self_on_RA: "",
        supplement_O2: "",
        NIV: "",
        mechanical_ventilation: "",
        ECMO: "",
        room_AIR: "",
        room_air_file_name: null,
        on_oxygen: "",
        on_oxygen_file_name: null,
        non_transplant_heart: "",
        lung_surgery: "",
        lung_walk_test_distance: "",
        lung_walk_test_duration: "",
      },
      kidney: {
        recipent_kidney_id: "",
        kidney_urea: "",
        kidney_failure: "",
        first_dialysis_date: "",
        period_undergoing_dialysis: "",
        se_creatinine: "",
        sodium: "",
        potassium: "",
        chloride: "",
        Bi_carbonate: "",
        lymphocyte_cross_match: "",
        CMV: "",
        IGG: "",
        TB_quantiferon: "",
        C_reative_protein: "",
      },
      heart: {
        recipent_heart_id: "",
        NYHA_grade: "",
        cardiac_index: "",
        PVR_index: "",
        PVRI_unit: "1",
        PVRI: "",
        TPG: "",
        walk_test_duration: "",
        walk_test_distance: "",
        heart_non_transplant_heart: "",
        heart_lung_surgery: "",
        NT_pro_BNP: "",
        PRA_status: "",
      },
      liver: {
        recipent_liver_id: "",
        history_of_complications: "",
        complication_description: "",
        cancer_screening: "",
        coronary_angiogram: "",
        stress_test: "",
        room_air_ABG: "",
        liver_PFT: "",
        urea: "",
        creatinine: "",
        uric_acid: "",
        serum_sodium: "",
        serum_potassium: "",
        serum_chloride: "",
        serum_bicarbonate: "",
        serum_magnesium: "",
        serum_phosphorus: "",
        MELD_score: "",
        bilirubin: "",
        total_protein: "",
        albumin: "",
        globulin: "",
        GGT: "",
        AST: "",
        ALT: "",
        INR: "",
        APTT: "",
        platelets: "",
        fibrinogen: "",
      },
      virology_status: {
        virology_status_HBsAg: "",
        virology_status_HBcAb: "",
        virology_status_HCV: "",
        virology_status_epstein_barr: "",
        virology_status_HIV: "",
        virology_status_CMV: "",
        anti_HBsAg: "",
      },
      organ_requested: {
        organ_id: [],
      },
      consultant: {
        consultant_detail: "",
      },
      recipient_medical_details: {
        height: "",
        weight: "",
        BMI: "",
        smoker: "",
        alcohol: "",
        period_of_abstinence: "",
        drugs: "",
        hypertension: "",
        diabetes: "",
        CAD: "",
        bronchial_asthma_COPD: "",
        epilepsy: "",
        hepatitis_B: "",
        hepatitis_C: "",
        history_of_TB: "",
        history_of_malignancy: "",
        type_of_malignancy: "",
        duration_of_remission: "",
        history_of_peripheral_vascular_disease: "",
        history_of_previous_transplants: "",
        history_of_previous_transplants_date: "",
        organ_transplanted: "",
        history_of_covid: "",
        covid_free_period: "",
        hemogram: "",
        ChestXRay: "",
        ECG: "",
        Echo: "",
        relevant_remarks: "",
        tissue_typing: "",
        Live_cadaver: "",
      },
      vaccination_status: [],
      vaccination_status_item: {
        recipient_vaccination_mapping_id: "",
        recipient_registration_id: "",
        master_vaccination_id: "",
        vaccination_date: "",
        status: 1,
        created_by: "",
        dml_indicator: "I",
      },
      updateDoc: {},
    };
  },
  mixins: [recipientMixins],
  computed: {
    isInternational() {
      return this.details.patient_nationlity == 1;
    },
    isAadharNotPermanent() {
      // if (this.isInternational) return true;
      // return this.aadhar_address.is_permanent_address == "0";
      return true;
    },
    isDownload() {
      return this.$route.query.isDownload == "true";
    },
    isView() {
      return this.$route.query.isView == "true";
    },
    isPrint() {
      return this.$route.query.isPrint == "true";
    },
    isDisabled() {
      if (this.isView) return true;
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_RECIPIENT);
      return (
        !hasAccess ||
        this.details.transtan_status == 5 ||
        this.details.transtan_status == 7
      );
    },
    isLung() {
      let organs = this.organ_requested.organ_id;
      let isLung = false;
      [LEFT_LUNG_ID, RIGHT_LUNG_ID, LUNG_ID].forEach((id) => {
        if (organs.indexOf(id) != -1) isLung = true;
      });
      return isLung;
    },
  },
  methods: {
    print() {
      recipientPrint.print(this, "openPrint");
    },
    printPatient() {
      recipientPrint.print(this, "openPatientPrint");
    },
    download() {
      recipientPrint.print(this, "download");
    },
    onFileRemoved(id, ref) {
      const vm = this;
      let list = vm.filesList.filter((item) => {
        return item.recipent_file_upload_mapping_id != id;
      });
      vm.filesList = list;
      setTimeout(() => {
        vm.$refs[ref].init();
      }, 500);
    },
    dataUrlToFile(dataUrl, filename) {
      const arr = dataUrl.split(",");
      if (arr.length < 2) {
        return undefined;
      }
      const mimeArr = arr[0].match(/:(.*?);/);
      if (!mimeArr || mimeArr.length < 2) {
        return undefined;
      }
      const mime = mimeArr[1];
      const buff = Buffer.from(arr[1], "base64");
      return new File([buff], filename, { type: mime });
    },
    isParameter(id) {
      return this.organ_requested.organ_id.indexOf(id) != -1;
    },
    async submit(isDraft) {
      const vm = this;
      vm.$loader.show();
      let doc = RecipientHelper.getDoc(vm);
      try {
        let details = [];
        Object.keys(doc).forEach((key) => {
          details.push({ key, value: doc[key] });
        });
        for (let item of details) {
          let { key, value } = item;
          if (value instanceof File) {
            doc[key] = await userHelper.uploadContact(value);
          } else {
            doc[key] = value;
          }
        }
        let { aadhar_photo } = doc;
        if (
          typeof aadhar_photo == "string" &&
          aadhar_photo.startsWith("data:image/png;base64,")
        ) {
          doc.aadhar_photo = await userHelper.uploadContact(
            vm.dataUrlToFile(aadhar_photo, new Date().getTime() + ".png")
          );
        }
        if (isDraft != true && doc.transtan_status == 1) {
          doc.transtan_status = 2;
        }
        if (isDraft != true && doc.transtan_status == 8) {
          doc.transtan_status = 9;
        }
        if (isDraft) {
          let draftFields = {
            blood_group: "Blood Group",
            consultant_detail: "Consultant Name",
          };
          let list = [];
          Object.keys(draftFields).forEach((key) => {
            if (!doc[key]) {
              list.push(draftFields[key]);
            }
          });
          if (list.length > 0) {
            vm.$alert.show(
              `Please update the following fields to save as draft: <br/><b>${list.toString()}</b>`
            );
            vm.$loader.hide();
            return;
          }
        }
        let { data } = await RecipientService.create(doc);
        let { msg, status, recipient_registration_id, hospital_type } = data;
        vm.payment_flag = data.payment_flag;
        if (status == false) {
          vm.$alert.show(msg);
          vm.$loader.hide();
          return;
        }
        let files = vm.files.concat(vm.medicalFiles);
        files = files.concat(vm.declarationFiles);
        for (let item of files) {
          if (item.file !== null) {
            await RecipientHelper.upload(item, recipient_registration_id, vm);
          }
        }
        if (vm.isUpdate) {
          vm.$alert.show("Recipient Updated successfully");
        } else {
          vm.$alert.show("Recipient created successfully");
        }
        let isPayment =
          [2, 9].indexOf(doc.transtan_status) != -1 &&
          [0, 2].indexOf(vm.payment_flag) != -1;

        vm.$loader.hide();
        if ((isPayment || vm.payment_flag == 3) && hospital_type != 1) {
          let message = RECIPIENT_PAYMENT_MESSAGE;
          if (doc.transtan_status == 9) {
            message = TRANSFER_PAYMENT_MESSAGE;
          } else if (vm.payment_flag == 3) {
            message = MULTI_ORGAN_PAYMENT_MESSAGE;
          }
          vm.$confirm.show({
            message,
            onConfirm: () => {
              vm.initPayment(recipient_registration_id, doc.organ_type);
            },
            onCancel: () => {
              vm.$router.push("/manage-recipient");
            },
          });
        } else {
          vm.$router.push("/manage-recipient");
        }
      } catch (error) {
        vm.$router.push("/manage-recipient");
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async initPayment(id, organ_type) {
      const vm = this;
      try {
        let doc = {
          dml_indicator: vm.payment_flag == 0 ? "NRS" : "NRT",
          recipient_registration_id: id,
        };
        if (organ_type == 2 && doc.dml_indicator == "NRS") {
          doc.dml_indicator = "NRM";
        }
        if (organ_type == 2 && doc.dml_indicator == "NRT") {
          doc.dml_indicator = "NRTM";
        }
        if (vm.payment_flag == 3) doc.dml_indicator = "STM";
        vm.$loader.show();
        let data = (await hospitalService.initPayment(doc)).data;
        initPayment(data);
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    init() {
      const vm = this;
      let { created_by } = vm;
      vm.details.created_by = created_by;
      vm.address.created_by = created_by;
      vm.aadhar_address.created_by = created_by;
      if (vm.isCreate) {
        vm.details.hospital_id = decode(vm.$route.query.hospital_id);
        vm.hospitalType = vm.$route.query.hospital_type;
      }
      if (vm.isUpdate) {
        RecipientHelper.getRecipient(vm, vm.id);
      } else {
        vm.isLoading = false;
      }
    },
  },
  beforeMount() {
    this.init();
  },
  beforeDestroy() {
    event.$off("initAadharDetails");
  },
  mounted() {
    event.$on("initAadharDetails", ({ details }) => {
      this.details.aadhar_flag = 1;
      this.isAadharVerified = true;
      this.$refs.details.setAadharDetails(details);
    });
  },
};
</script>

<style></style>
