<template>
  <table-view
    :title="title"
    :fields="headers"
    :isBusy="isBusy"
    :items="items"
    :isFooter="!isAllocationReport"
    :isFilter="!isAllocationReport"
  >
    <template v-slot:add v-if="!isAllocationReport">
      <process-closed-button
        :isAction="true"
        class="me-3"
        v-if="isAdmins"
        :item="item"
      ></process-closed-button>
      <terminate-allocation-button
        :isAction="isLast"
        v-if="isAdmins"
        class="mt-3 mt-md-0"
        :item="item"
      ></terminate-allocation-button>
      <slot name="add"> </slot>
    </template>
    <template v-slot:recipient_registration_id="{ item, value }">
      <internal-recipient-icon
        :item="item"
        :value="value"
      ></internal-recipient-icon>
    </template>
    <template v-slot:payment_status="{ value }">
      {{ getPaymentStatus(value) }}
    </template>
    <template v-slot:BMI="{ item }">
      {{ getBMI(item) }}
    </template>
    <template v-slot:transtan_status="{ value }">
      {{ getPatientStatus(value) }}
    </template>
    <template v-slot:CM_insurance="{ value }">
      {{ getCMInsuranceStatus(value) }}
    </template>
    <template v-slot:cause_of_lung_disease="{ value }">
      {{ getCauseLung(value) }}
    </template>

    <template v-slot:recepient_organ_allocation_status="{ value }">
      <div :class="getBg(getStatus(value))">
        {{ getStatus(value) }}
      </div>
    </template>
    <template v-slot:logistics_update_status="{ value }">
      <div v-if="value" :class="getBg(getLogisticsStatus(value))">
        {{ getLogisticsStatus(value) }}
      </div>
      <div v-else></div>
    </template>
    <template v-slot:organ_Inspection_status="{ value }">
      <div :class="getBg(getStatus(value))">
        {{ getStatus(value) }}
      </div>
    </template>
    <template v-slot:organ_retrieval_status="{ value }">
      <div :class="getBg(getStatus(value))">
        {{ getStatus(value) }}
      </div>
    </template>

    <template v-slot:organ_surgery_status="{ value }">
      <div :class="getBg(getLogisticsStatus(value))">
        {{ getLogisticsStatus(value) }}
      </div>
    </template>

    <template v-slot:covid_result_fileupload="data">
      <slot name="covid_result_fileupload" v-bind="data">
        {{ data.value }}
      </slot>
    </template>
    <template v-slot:action="data">
      <slot name="action" v-bind="data">
        {{ data.value }}
      </slot>
    </template>
    <slot></slot>
  </table-view>
</template>

<script>
import {
  LOGISTICS_STATUS,
  RECIPIENT_ALLOCATION_STATUS,
} from "../../../../assets/constants/donor.constants";
import { CAUSE_OF_LUNG_DISEASE } from "../../../../assets/constants/recipient.constants";
import {
  LEFT_LUNG_ID,
  LUNG_ID,
  RIGHT_LUNG_ID,
} from "../../../../assets/js/common";
import InternalRecipientIcon from "../../../managerecipient/InternalRecipientIcon.vue";
import TableView from "../../../table/TableView.vue";
import ProcessClosedButton from "./ProcessClosedButton.vue";
import TerminateAllocationButton from "./TerminateAllocationButton.vue";
export default {
  components: {
    TerminateAllocationButton,
    InternalRecipientIcon,
    TableView,
    ProcessClosedButton,
  },
  props: {
    isLast: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    fields: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      let doc = {
        sno: "S No.",
        recipient_registration_id: "UID",
        zone_rank_id: "Zonal Rank",
        created_on: "Date of Registration",
        // first_name: "Name",
        age: "Age",
        gender: "Gender",
        height: "Height",
        weight: "Weight",
        group_desc: "Blood Group",
        BMI: "BMI",
        cause_of_lung_disease: "Cause of Lung Disease",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        // payment_status: "Payment Status",
        // transtan_status: "Patient Status",
      };
      const vm = this;
      let lungs = [LUNG_ID, LEFT_LUNG_ID, RIGHT_LUNG_ID];

      if (lungs.indexOf(vm.item.organ_id) == -1) {
        delete doc.cause_of_lung_disease;
      }
      let list = [];
      Object.keys(vm.fields).forEach((key) => {
        doc[key] = vm.fields[key];
      });
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
    isAllocationReport() {
      return this.$route.name == "organ-allocation-overall-report";
    },
  },
  methods: {
    getBMI(item) {
      let { height, weight } = item;
      if (!height) return 0;
      if (!weight) return 0;
      height = height / 100;
      let bmi = weight / (height * height);
      if (bmi < 1000) {
        bmi = bmi.toFixed(2);
      }
      return bmi;
    },
    getLogisticsStatus(val) {
      let item = LOGISTICS_STATUS.find((item) => {
        return item.value == val.toString();
      });
      return item ? item.name : "";
    },

    getCauseLung(value) {
      let doc = CAUSE_OF_LUNG_DISEASE.find((item) => {
        return item.value == value;
      });
      return doc != null ? doc.name : "";
    },
    getBg(val) {
      switch (val) {
        case "Accepted":
          return "badge bg-success font-size-13";
        case "Decline":
          return "badge bg-danger font-size-13";
        case "Rejected":
          return "badge bg-danger font-size-13";
        case "Updated":
          return "badge bg-success font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
    getStatus(val) {
      // eslint-disable-next-line no-undef
      let item = RECIPIENT_ALLOCATION_STATUS.find((item) => {
        return item.value == val;
      });
      return item ? item.name : "";
    },
  },
};
</script>

<style></style>
