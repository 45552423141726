<template>
  <b-button icon variant="link" size="sm" @click="$emit('click')">
    <b-icon variant="danger" icon="trash-fill" class="font-size-16"></b-icon>
  </b-button>
</template>

<script>
export default {};
</script>

<style></style>
