<template>
  <b-dropdown variant="link" dropleft class="dropleft">
    <template #button-content>
      <b-icon icon="three-dots" class="font-size-16"></b-icon>
    </template>
    <request-more-details
      title="Requesting apnea details"
      :item="item"
      @fetch="$emit('fetch')"
      v-if="isStatusChangeAble(3)"
      :status="3"
    ></request-more-details>
    <request-more-details
      title="Requesting more details"
      @fetch="$emit('fetch')"
      :item="item"
      v-if="isStatusChangeAble(4)"
      :status="4"
    ></request-more-details>
    <!-- <b-dropdown-item @click="requestApnea()" v-if="isStatusChangeAble(3)"
      >Requesting apnea details</b-dropdown-item
    >
    <b-dropdown-item @click="requestMore(4)" v-if="isStatusChangeAble(4)"
      >Requesting more details
    </b-dropdown-item> -->
    <b-dropdown-item @click="completeDraft()" v-if="isStatusChangeAble(6)"
      >Draft status completed
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import donorService from "../../assets/services/donor.service";
import RequestMoreDetails from "./RequestMoreDetails.vue";
export default {
  props: {
    item: Object,
  },
  components: { RequestMoreDetails },
  data: () => ({
    menu: false,
  }),
  methods: {
    isStatusChangeAble(status) {
      return this.hasStatusAccess(menuConstants.MANAGE_DONOR, status);
    },
    requestApnea() {
      const vm = this;
      let { donor_temp_registration_id } = vm.item;
      vm.$confirm.show({
        message: "Are you sure, Do you want to request apnoea details?",
        onConfirm: () => {
          vm.$emit("changeStatus", {
            donor_temp_registration_id,
            status: 3,
            dml_indicator: "A",
          });
        },
      });
    },
    requestMore(status) {
      const vm = this;
      let { donor_temp_registration_id } = vm.item;
      vm.$confirm.show({
        message: "Are you sure, Do you want to request more details?",
        onConfirm: () => {
          vm.$emit("changeStatus", {
            donor_temp_registration_id,
            status,
            dml_indicator: "A",
          });
        },
      });
    },
    completeDraft() {
      const vm = this;
      let { donor_temp_registration_id, hospital_id } = vm.item;
      let doc = {
        donor_temp_registration_id,
        hospital_id,
        status: 0,
        case_start_time: null,
        case_end_time: null,
        number_of_organs_allocated: 0,
        number_of_organs_transplanted: 0,
        no_of_tissues_recovered: 0,
        termination_reason: null,
        created_by: vm.created_by,
      };
      vm.$confirm.show({
        message: "Are you sure, Do you want to complete this draft?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let { data } = await donorService.completeDraft(doc);
            let { status, msg } = data;
            if (!status) {
              vm.$loader.hide();
              vm.$alert.show(msg);
              return;
            }
            vm.$emit("onDraftStateCompleted");
            vm.$emit("fetch");
            vm.$loader.hide();
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
  },
};
</script>
<style>
.dropleft .dropdown-menu {
  left: -100% !important;
}
</style>
