<template>
  <div class="d-flex align-center">
    <b-button icon="x-octagon-fill" @click="init()" variant="primary">
      View</b-button
    >
    <b-modal
      tabindex="0"
      scrollable
      v-model="dialog"
      title="Transplant Surgery Details"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="lg"
      centered
    >
      <b-row>
        <b-col cols="12" md="4">
          <input-field
            label="Inpatient Number"
            :disabled="true"
            rules="required"
            v-model="form.inpatient_number"
          ></input-field>
        </b-col>
        <b-col cols="12" md="4">
          <date-picker
            :disabled="true"
            label="Surgery Start Date"
            rules="required"
            v-model="form.date_of_surgery"
          ></date-picker>
        </b-col>
        <b-col cols="12" md="4">
          <time-picker
            label="Surgery Start Time"
            rules="required"
            :disabled="true"
            v-model="form.time_of_surgery"
          ></time-picker>
        </b-col>
        <b-col cols="12" md="4">
          <input-field
            :disabled="true"
            label="Status After Surgery"
            rules="required"
            v-model="form.status_after_surgery"
          ></input-field>
        </b-col>
        <b-col cols="12" md="4">
          <input-field
            label="Remarks"
            :disabled="true"
            rules="required"
            v-model="form.remarks"
          ></input-field>
        </b-col>
        <b-col cols="12" md="4">
          <input-field
            label="Warm Ischaemia Time"
            rules="required"
            :disabled="true"
            v-model="form.ischaemia_warm"
          ></input-field>
        </b-col>
        <b-col cols="12" md="4">
          <input-field
            label="Cold Ischaemia Time"
            rules="required"
            :disabled="true"
            v-model="form.ischaemia_cold"
          ></input-field>
        </b-col>
        <b-col cols="12" md="4">
          <date-picker
            label="Surgery Completed Date"
            rules="required"
            :disabled="true"
            v-model="form.end_date"
          ></date-picker>
        </b-col>
        <b-col cols="12" md="4">
          <time-picker
            label="Surgery Completed Time"
            rules="required"
            :disabled="true"
            v-model="form.end_time"
          ></time-picker>
        </b-col>
        <b-col cols="12" md="4">
          <file-input-group
            label="Utilization Certificate"
            :disabled="true"
            rules="required"
            v-model="form.utilization_certificate"
          ></file-input-group>
        </b-col>
      </b-row>
      <div class="d-flex mt-3">
        <div class="flex-grow-1"></div>
        <b-button variant="outline-danger" @click="dialog = false"
          >Cancel
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isDocObject, openFile } from "../../../../../assets/js/common";
import donorService from "../../../../../assets/services/donor.service";
import DatePicker from "../../../../form/DatePicker.vue";
import FileInputGroup from "../../../../form/FileInputGroup.vue";
import InputField from "../../../../form/InputField.vue";
import TimePicker from "../../../../form/TimePicker.vue";
export default {
  components: { InputField, DatePicker, TimePicker, FileInputGroup },
  props: {
    recipeint: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      form: {
        inpatient_number: "",
        date_of_surgery: "",
        time_of_surgery: "",
        status_after_surgery: "",
        remarks: "",
        ischaemia_warm: "",
        ischaemia_cold: "",
        end_date: "",
        end_time: "",
        utilization_certificate: "",
      },
      list: [],
    };
  },
  computed: {
    fields() {
      let doc = {
        first_name: "Name",
        phone_number_1: "Phone Number",
        photo: "View Transport Details",
        action: "Allocation Status",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  methods: {
    open(file) {
      openFile(file);
    },
    async init() {
      const vm = this;
      let { recepeint_allocation_id } = vm.recipeint;
      const doc = {
        dml_indicator: "S",
        recepeint_allocation_id,
      };
      try {
        vm.list = [];
        vm.$loader.show();
        let { data } = await donorService.recipientSurgeryDetailList(doc);
        let { list, status, msg } = data;
        vm.isLoading = false;
        vm.$loader.hide();
        if (!status) return vm.$alert.show(msg);
        if (isDocObject(list)) {
          let doc = list[0];
          Object.keys(vm.form).forEach((key) => {
            if (doc[key]) vm.form[key] = doc[key];
          });
        }
        vm.dialog = true;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
