<template>
  <div>
    <b-button
      variant="primary"
      style="width: 100%"
      v-if="isLiver()"
      @click="checkALF()"
      >Check ALF</b-button
    >
    <b-modal
      v-model="dialog"
      centered
      size="lg"
      scrollable
      ignore-enforce-focus-selector=".select2-search__field"
      no-close-on-backdrop
      hide-footer
      title="ALF List"
    >
      <b-table
        outlined
        :fields="fields"
        responsive="sm"
        :items="list"
        :head-variant="'dark'"
        thead-class="table-light table-bordered"
        tbody-class="table-bordered"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">Loading...</strong>
          </div>
        </template>
        <template #cell(recipient_registration_id)="{ item, value }">
          <internal-recipient-icon
            :item="item"
            :value="value"
          ></internal-recipient-icon>
        </template>

        <template #cell(action)="data">
          <b-button variant="primary" class="me-3" @click="select(data.item)"
            >Select</b-button
          >
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  formatDate,
  LIVER_ID,
  SPLIT_LIVER_1_ID,
  SPLIT_LIVER_2_ID,
} from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import alfService from "../../../../assets/services/alf.service";
import donorService from "../../../../assets/services/donor.service";
import InternalRecipientIcon from "../../../managerecipient/InternalRecipientIcon.vue";
export default {
  components: { InternalRecipientIcon },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      dialog: false,
      isLoading: false,
      list: [],
    };
  },
  computed: {
    fields() {
      let doc = {
        alf_id: "ALF ID",
        created_on: "Date of Registration",
        recipient_registration_id: "UID",
        first_name: "Patient Name",
        MELD_score: "MELD Score",
        hospital_name: "Hospital Name",
        listing_date: "Listing Date",
        alf_name: "Category",
        transtan_status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
  },
  methods: {
    async select(item) {
      const vm = this;
      try {
        vm.$loader.show();
        let list = [];
        let { final_donor_id, id } = vm;
        let {
          organ_allocation_id,
          donor,
          organ_id,
          type_of_allocation,
          allocation_status,
        } = vm.item;
        let { case_officer } = donor;

        let { recipient_registrationid, hospital_id } = item;
        let doc = {
          final_donor_id,
          organ_allocation_id,
          donor_temp_registration_id: id,
          case_officer,
          hospital_id,
          organ_id,
          status: 0,
          recepient_registration_id: recipient_registrationid,
          created_by: vm.created_by,
          updated_by: vm.created_by,
          type_of_allocation,
          allocation_status,
          reject_reason: "",
          dml_indicator: "I",
        };
        list.push(doc);
        let { data } = await donorService.selectALF({
          recepient_organ_allocation_proc: list,
        });
        let { status, msg } = data;
        vm.isLoading = false;
        vm.$loader.hide();
        event.$emit("refresh");
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    isLiver() {
      let { organ_id, allocation_status } = this.item;
      if (allocation_status != 1) return false;
      let organs = [SPLIT_LIVER_1_ID, SPLIT_LIVER_2_ID, LIVER_ID];
      return organs.indexOf(organ_id.toString()) != -1;
    },
    async checkALF() {
      const vm = this;
      vm.dialog = true;
      vm.list = [];
      try {
        vm.isLoading = true;
        let { status, msg, list } = (await alfService.checkALF()).data;
        vm.isLoading = false;
        if (status == false) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.created_on = formatDate(item.created_on);
          item.listing_date = formatDate(item.listing_date);
          item.transtan_status = vm.getPatientStatus(item.transtan_status);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    console.log(this.item);
  },
};
</script>

<style></style>
