<template>
  <div>
    <Header :hospitals="hospitals"></Header>
    <user-filter
      v-model="filter"
      v-if="isUserPage && !isHospitalContact"
      @hospitals="hospitals = $event"
      @fetch="fetch"
    ></user-filter>
    <List :isLoading="isLoading" @fetch="fetch" :list="list"></List>
  </div>
</template>

<script>
import event from "../../assets/js/event";
import userMixins from "../../assets/mixins/user.mixins";
import userService from "../../assets/services/user.service";
import Header from "../../components/manageuser/Header.vue";
import List from "../../components/manageuser/List.vue";
import UserFilter from "../../components/manageuser/UserFilter.vue";
export default {
  components: { List, Header, UserFilter },
  data() {
    return {
      list: [],
      hospitals: [],
      filter: {},
      isLoading: false,
    };
  },
  mixins: [userMixins],
  methods: {
    async fetch() {
      const vm = this;
      if (!vm.filter.hospital_id) return;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await userService.userList(vm.filter);
        let { status, msg, user_list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = user_list;
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    const vm = this;
    if (vm.isHospitalContact) {
      vm.filter = {
        dml_indicator: "DS",
        hospital_id: vm.user.hospital_id
      };
      vm.fetch();
    }
    event.$on("refreshUser", () => {
      this.fetch();
    });
  },
};
</script>

<style></style>
