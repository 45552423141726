<template>
  <div>
    <b-button variant="primary" v-if="isAdmin" @click="dialog = true">
      Final List Approvel
    </b-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Final List Approvel Details"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <autocomplete
              v-model="hospital_id"
              label="Hospital"
              :items="hospitals"
              :multiple="false"
              :max="1"
              rules="required"
            >
            </autocomplete>
          </b-col>
          <b-col cols="12">
            <autocomplete
              label="Organs"
              :items="filterMasterOrgans"
              rules="required"
              v-model="organs"
              :multiple="true"
            ></autocomplete>
          </b-col>
          <b-col cols="12">
            <autocomplete
              label="Blood Group"
              :items="filterBloodGroups"
              rules="required"
              :multiple="true"
              v-model="blood_group"
            ></autocomplete>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { BLOOD_GROUP_VALUES } from '../../../assets/js/common';
import recipientService from "../../../assets/services/recipient.service";
import Autocomplete from "../../form/Autocomplete.vue";
export default {
  props: {
    hospitals: Array,
  },
  components: { Autocomplete },
  data() {
    return {
      dialog: false,
      hospital_id: "",
      blood_group: [],
      organs: [],
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let { hospital_id, organs } = vm;
        let doc = { hospital_id };
        doc.blood_group_list = [];
        vm.blood_group.forEach((id) => {
          BLOOD_GROUP_VALUES[id].forEach((blood_group) => {
            doc.blood_group_list.push({ blood_group });
          });
        });

        doc.multi_organ = organs
          .sort(function (a, b) {
            return a - b;
          })
          .toString();
        let { data } = await recipientService.updateFinalInhouseList(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.$alert.show("Inhouse Rank Updated Successfully");
        vm.hospital_id = "";
        vm.organs = [];
        vm.dialog = false;
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },

    init() {
      const vm = this;
      let { isAdmin, userHospital } = vm;
      if (!isAdmin) vm.hospital_id = userHospital;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
