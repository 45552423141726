<template>
  <div>
    <allocation-recipient-table
      title="Transplant Surgery Details"
      :fields="fields"
      :isBusy="isLoading"
      :item="item"
      :items="list"
    >
      <template v-slot:organ_surgery_status="{ value }">
        <div :class="getBg(getStatus(value))">
          {{ getStatus(value) }}
        </div>
      </template>
      <template v-slot:action="doc">
        <transplant-surgery-details-view
          :recipeint="doc.item"
          :item="item"
          v-if="doc.item.organ_surgery_status == 2"
        ></transplant-surgery-details-view>
        <transplant-surgery-details
          :recipeint="doc.item"
          v-if="isSurgerUpdate(doc.item)"
          :item="item"
          @onSubmit="init()"
        ></transplant-surgery-details>
      </template>
    </allocation-recipient-table>
  </div>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { formatDate } from "../../../../assets/js/common";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
import TransplantSurgeryDetails from "./transplantSurgery/TransplantSurgeryDetails.vue";
import TransplantSurgeryDetailsView from "./transplantSurgery/TransplantSurgeryDetailsView.vue";
export default {
  components: {
    TransplantSurgeryDetailsView,
    TransplantSurgeryDetails,
    AllocationRecipientTable,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      list: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isGenerateFinal() {
      let { list } = this;
      let updated = list.filter((item) => {
        return item.organ_surgery_status != 3;
      });
      if (list.length == 0) return false;
      if (list.length != updated.length) return false;
      return true;
    },
    fields() {
      let doc = {
        organ_surgery_status: "Transplant Status",
        action: "Action",
      };
      if (this.isAllocationReport) delete doc.action;
      return doc;
    },
  },
  methods: {
    isSurgerUpdate({ hospital_id, organ_surgery_status }) {
      if (!this.isHospitalContact) return false;
      if (organ_surgery_status != 1) return false;
      if (this.item.allocation_status != 28) return false;
      return hospital_id == this.user.hospital_id;
    },
    async init() {
      const vm = this;
      let {
        organ_id,
        organ_allocation_id,
        type_of_allocation,
        organ_id_org,
        is_multi,
      } = vm.item;
      if (is_multi) organ_id = organ_id_org;

      const doc = {
        dml_indicator: "LOSFT",
        organ_id,
        organ_allocation_id,
      };
      if (type_of_allocation == 1) doc.dml_indicator = "LOSLT";
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
