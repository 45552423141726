<template>
  <div class="tabs">
    <div class="tab-title">
      <div class="title">
        <a
          href="#left"
          class="title-direction"
          v-bind:class="{ disabled: disabledArrow.left }"
          v-on:click.stop.prevent="directionClick"
        >
          <svg viewBox="0 0 24 24">
            <path
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            />
          </svg>
        </a>
      </div>
      <div class="title title-links" ref="titleLinks">
        <ul v-for="(tabItem, i) in tabItems" :key="i">
          <li>
            <a
              href="#"
              v-on:click.prevent="tabClick(i)"
              v-bind:class="{ active: i == input }"
              v-bind:ref="'tab-item'"
            >
              <slot :name="tabItem.id" v-bind="{ item: tabItem, index: i }">
                {{ tabItem.name }}
              </slot>
            </a>
          </li>
        </ul>
      </div>
      <div class="title">
        <a
          href="#right"
          class="title-direction"
          v-on:click.stop.prevent="directionClick"
        >
          <svg
            viewBox="0 0 24 24"
            v-bind:class="{ disabled: disabledArrow.right }"
          >
            <path
              d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default {
  props: {
    value: {},
    tabItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      //   tabItems: [],
      input: 0,
      disabledArrow: {
        left: true,
        right: false,
      },
    };
  },
  watch: {
    value(val) {
      this.tabClick(val);
    },
  },
  methods: {
    tabClick(index) {
      this.input = index;
      let tabitem = this.$refs["tab-item"][index];
      let titleLinks = this.$refs.titleLinks;
      let offset = tabitem.offsetLeft - titleLinks.offsetLeft;
      this.scrollTab(titleLinks, offset);
      this.$emit("input", this.input);
    },
    directionClick(evt) {
      let elm = null;
      if (evt.target.tagName === "svg") {
        elm = evt.target.parentNode;
      } else if (evt.target.tagName === "path") {
        elm = evt.target.parentNode.parentNode;
      }
      if (elm === null) {
        return;
      }
      let titleLinks = this.$refs.titleLinks;
      let scrollTo = 0;
      let scrollValue = titleLinks.clientWidth;
      switch (elm.getAttribute("href")) {
        case "#left":
          scrollTo = titleLinks.scrollLeft - scrollValue;
          break;
        case "#right":
          scrollTo = titleLinks.scrollLeft + scrollValue;
          break;
      }
      this.scrollTab(titleLinks, scrollTo);
    },
    scrollTab(titleLinks, scrollTo) {
      gsap.killTweensOf(titleLinks);
      gsap
        .to(titleLinks, {
          duration: 0.5,
          scrollTo: { x: scrollTo },
          ease: "power4.out",
        })
        .eventCallback("onUpdate", () => {
          if (titleLinks.scrollLeft === 0) {
            this.disabledArrow = {
              left: true,
              right: false,
            };
          } else if (
            Math.round(titleLinks.scrollLeft) ===
            titleLinks.scrollWidth - titleLinks.clientWidth
          ) {
            this.disabledArrow = {
              left: false,
              right: true,
            };
          } else {
            this.disabledArrow = {
              left: false,
              right: false,
            };
          }
        });
    },
  },
  mounted() {
      this.$nextTick(()=> {
        this.tabClick(this.value)
      })
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  --tabs-height: 20em;
  --header-height: 3em;
  --title-height: auto;
  background-color: #ffffff;
  width: 100%;
  .tab-header {
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    background-color: #0085f8;
    height: var(--header-height);
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 1.25em;
    }
  }
  .tab-title {
    display: flex;
    .title {
      height: var(--title-height);
      white-space: nowrap;
      &.title-links {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        ul {
          width: 100%;
          padding: 0;
          margin: 0;
          list-style-type: none;
          li {
            height: 100%;
            a {
              color: #000000;
              text-decoration: none;
              height: 100%;
              padding: 1em;
              display: flex;
              align-items: center;
              position: relative;
              user-select: none;
              transition: background-color 150ms ease-in;
              &:after {
                content: "";
                width: 100%;
                height: 0.2em;
                background-color: transparent;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: background-color 150ms ease-in;
              }
              &:hover {
                background-color: #f2f2f2;
              }
              &.active {
                background-color: #f2f2f2;
                &:after {
                  background-color: #0085f8;
                }
              }
            }
          }
        }
      }
      .title-direction {
        height: 100%;
        padding: 0 0.4em;
        display: flex;
        align-items: center;
        transition: background-color 150ms ease-in;
        &.disabled {
          cursor: default;
          svg {
            opacity: 0.3;
          }
        }
        &:not(.disabled):hover {
          background-color: #f2f2f2;
        }
        svg {
          width: 1em;
          height: 1em;
          vertical-align: top;
          padding: 0;
          margin: 0;
          transition: 300ms opacity ease-out;
        }
      }
    }
  }
}
</style>
