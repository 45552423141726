<template>
  <b-card class="pa-0 tabs my-2">
    <mobile-tabs v-model="model" @input="$emit('input', model)" activeColor="#556ee6">
      <mobile-tab-item
        v-for="(item, index) in tabs"
        :key="index"
        :disabled="item.disabled"
      >
        <p class="mb-0">
          {{ item.name }}
        </p>
        <div class="font-size-10">
          {{ item.subtitle }}
        </div>
        <div class="font-size-10 mt-1">
          {{ item.created_on }}
        </div>
      </mobile-tab-item>
    </mobile-tabs>
  </b-card>
</template>

<script>
import MobileTabItem from "../../ui/mobileTab/MobileTabItem.vue";
import MobileTabs from "../../ui/mobileTab/MobileTabs.vue";
export default {
  components: { MobileTabs, MobileTabItem },
  props: {
    tabs: {},
    value: {},
  },
  data() {
    return {
      model: 0,
      items: [
        { label: "首页" },
        { label: "推荐" },
        { label: "Android" },
        { label: "前端" },
        { label: "后端" },
        { label: "iOS" },
        { label: "产品" },
        { label: "人工智能" },
        { label: "设计" },
      ],
    };
  },
  methods: {
    onChange() {
      this.$emit("input", this.model);
    },
  },
  watch: {
    value() {
      this.model = this.value;
    },
  },
  mounted() {
    this.model = this.value;
  },
};
</script>

<style lang="scss">
.list-tab {
  border: 1px solid #675ccc;
  width: 100px;
  &.active {
    color: #f00;
  }
}
</style>
