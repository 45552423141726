var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-bottom":"100px"}},[_c('table-view',{staticClass:"noselect",attrs:{"title":_vm.title,"fields":_vm.fields,"isBusy":_vm.isLoading,"items":_vm.live},scopedSlots:_vm._u([{key:"filter",fn:function(){return [(_vm.isHeartLungs)?_c('filter-select',{staticClass:"ms-2",attrs:{"items":_vm.heartLungsList,"placeholder":"All"},model:{value:(_vm.heartLungs),callback:function ($$v) {_vm.heartLungs=$$v},expression:"heartLungs"}}):_vm._e()]},proxy:true},{key:"sno",fn:function(ref){
var item = ref.item;
return [(item.patient_nationlity == 1)?_c('div',{staticClass:"badge bg-warning font-size-13"},[_vm._v(" "+_vm._s(item.sno)+" "),_c('i',{staticClass:"mdi mdi-airplane-takeoff"})]):_c('span',[_vm._v(" "+_vm._s(item.sno)+" ")])]}},{key:"payment_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getPaymentStatus(value))+" ")]}},{key:"transtan_status",fn:function(ref){
var value = ref.value;
return [_c('div',{class:_vm.getBg(_vm.getPatientStatus(value))},[_vm._v(" "+_vm._s(_vm.getPatientStatus(value))+" ")])]}},{key:"hospital_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getBeReadyStatus(value))+" ")]}},{key:"age",fn:function(ref){
var value = ref.value;
return [_c('div',{class:value > 60 ? 'badge bg-danger  font-size-13' : ''},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"CM_insurance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCMInsuranceStatus(value))+" ")]}},{key:"gender",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getGender(value))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }