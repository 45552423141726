<template>
  <Expandable header="Organ Requested" class="mb-10">
    <b-row>
      <b-col cols="12">
        <autocomplete
          label="Organ"
          :items="organs"
          v-model="form.organ_id"
          rules="required"
          :disabled="isDisabled"
          @input="onInput"
          :max="4"
          :multiple="true"
        ></autocomplete>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import {
  isArray,
  LEFT_LUNG_ID,
  LUNG_ID,
  RIGHT_LUNG_ID,
} from "../../../assets/js/common";
import licenseService from "../../../assets/services/license.service";
import Expandable from "../../Expandable.vue";
export default {
  props: {
    value: {},
    details: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { Expandable },
  data() {
    return {
      form: {
        organ_id: [],
      },
      organs: [],
    };
  },
  computed: {
    rules() {
      let isAllowed = false;
      let { organ_id } = this.form;
      let combination = {
        "Heart & lung": ["1", "5"],
        "Heart & Kidney": ["1", "3"],
        "Liver & Kidney": ["3", "4"],
        "Pancreas & Kidney": ["3", "6"],
      };
      if (organ_id.length == 1) isAllowed = true;
      if (organ_id.length > 1) {
        Object.keys(combination).forEach((key) => {
          let item = combination[key];
          console.log("isAllowed", isAllowed);
          if (
            item.indexOf(organ_id[0]) != -1 &&
            item.indexOf(organ_id[1]) != -1
          ) {
            isAllowed = true;
            console.log(key);
          }
        });
      }
      if (organ_id.indexOf("7") != -1) {
        isAllowed = true;
      }
      return isAllowed ? "required" : "required|organ_combination";
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
    async init() {
      const vm = this;
      let { hospital_id } = vm.details;
      try {
        vm.list = [];
        let doc = { hospital_id, dml_indicator: "UL" };
        let { data } = await licenseService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        let isDualLung = list.find((item) => {
          return (
            item.license_available == 1 &&
            item.status == 2 &&
            item.organ_id == LUNG_ID
          );
        });
        list = list.filter((item) => {
          return item.status == 2 && item.license_available == 1;
        });
        if (isDualLung != null) {
          list.push({
            organ_name: "Lungs(L)",
            organ_id: LEFT_LUNG_ID,
          });
          list.push({
            organ_name: "Lungs(R)",
            organ_id: RIGHT_LUNG_ID,
          });
        }
        if (isArray(list)) {
          vm.organs = list.map((item) => {
            return { name: item.organ_name, value: item.organ_id };
          });
        }
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },
  },
  beforeMount() {
    if (this.value != undefined) this.form = this.value;
    this.init();
  },
};
</script>

<style></style>
