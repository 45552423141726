<template>
  <a
    class="ly-tab-item"
    :style="$parent.value === id ? activeStyle : {}"
    :class="disabled ? 'text-muted' : ''"
    @click="onItemClicked"
  >
    <div class="ly-tab-item-label"><slot></slot></div>
  </a>
</template>

<script>
export default {
  name: "LyTabItem",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeStyle() {
      return {
        color: this.$parent.activeColor,
      };
    },
  },
  data() {
    return {
      id: (this.$parent.$children.length || 1) - 1,
    };
  },
  methods: {
    onItemClicked() {
      if (this.disabled) return;
      this.$parent.$emit("input", this.id);
    },
  },
};
</script>

<style>
.ly-tab-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left !important;
  color: black;
}

.ly-tab-item-icon {
  margin: 0 auto 5px;
}
</style>
