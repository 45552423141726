<template>
  <div class="recipient-transfer">
    <b-card>
      <b-card-title class="pb-3">Change Password </b-card-title>
      <form-wrapper @submit="submit">
        <InputField
          type="password"
          rules="required"
          v-model="form.password"
          label="Old Password"
          required
        />
        <InputField
          type="password"
          rules="required|strong_password"
          v-model="form.confirm_password"
          label="New Password"
          required
        />

        <b-button block class="mt-3" variant="primary" type="submit"
          >Submit</b-button
        >
      </form-wrapper>
    </b-card>
  </div>
</template>

<script>
import { USER_TYPES } from "../../assets/constants/hospital.contants";
import { encrypt } from "../../assets/js/common";
import userService from "../../assets/services/user.service";
export default {
  data() {
    return {
      form: {},
      token: "",
    };
  },
  computed: {
    types() {
      return USER_TYPES;
    },
  },
  methods: {
    async submit() {
      const vm = this;
      let doc = {};
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      doc.registration_id = vm.user.registration_id;
      doc.password = encrypt(doc.password);
      doc.confirm_password = encrypt(doc.confirm_password);
      vm.$loader.show();
      try {
        let { data } = await userService.changePassword(doc);
        vm.$loader.hide();
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$router.replace("/hospital-list");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
      console.log(JSON.stringify(doc));
    },
  },
};
</script>

<style scoped>
.login {
  height: calc(100vh - 70px);
}
</style>
