<template>
  <div>
    <add-button v-if="!item.first_name" @click="dialog = true"> </add-button>
    <edit-button @click="dialog = true" v-else></edit-button>
    <b-modal v-model="dialog" width="600" :title="title" hide-footer centered>
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="6">
            <input-field
              v-model.trim="form.first_name"
              label="First Name"
              rules="required|max-50"
            ></input-field>
          </b-col>
          <b-col cols="6">
            <input-field
              v-model.trim="form.last_name"
              label="Last Name"
              rules="required|max-50"
            ></input-field>
          </b-col>
          <b-col cols="6">
            <select-field
              v-model.trim="form.gender"
              label="Gender"
              outlined
              :items="gender"
              rules="required|max-50"
            ></select-field>
          </b-col>
          <b-col cols="6">
            <date-picker
              v-model="form.DOB"
              label="Date of birth"
              outlined
              :max="new Date()"
              rules=""
            ></date-picker>
          </b-col>
          <b-col cols="6">
            <input-field
              v-model.trim="form.email_id"
              label="Email"
              rules="email"
            ></input-field>
          </b-col>
          <b-col cols="6">
            <select-field
              v-model.trim="form.contact_id"
              label="Contact Type"
              :items="contactTypes"
              rules="required"
            ></select-field>
          </b-col>

          <b-col cols="6">
            <phone-field
              v-model.trim="form.phone_number_1"
              :defaultCountry="form.country_code_1"
              label="Phone Number 1"
              @onCountryCode="form.country_code_1 = $event"
              rules="required|integer"
            ></phone-field>
          </b-col>
          <b-col cols="6">
            <phone-field
              v-model.trim="form.phone_number_2"
              :defaultCountry="form.country_code_2"
              label="Phone Number 2"
              @onCountryCode="form.country_code_2 = $event"
              rules="integer"
            ></phone-field>
          </b-col>
          <b-col cols="6" v-if="false">
            <select-field
              label="Blood Group"
              :items="bloodGroups"
              v-model="form.blood_id"
              rules=""
            ></select-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { GENDERS } from "../../../../assets/constants/hospital.contants";
import {
  getDateOnly,
  RECIEPIENT_MASTER_TYPE,
} from "../../../../assets/js/common";
import recipientMixins from "../../../../assets/mixins/recipient.mixins";
import AddButton from "../../../buttons/AddButton.vue";
import EditButton from "../../../buttons/EditButton.vue";
import DatePicker from "../../../form/DatePicker.vue";
import PhoneField from "../../../form/PhoneField.vue";
import SelectField from "../../../form/SelectField.vue";
export default {
  props: {
    list: Array,
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: null,
    },
  },
  mixins: [recipientMixins],
  components: { AddButton, EditButton, SelectField, PhoneField, DatePicker },
  data() {
    return {
      dialog: false,
      isValid: false,
      form: {},
      dob: "",
      loading: true,
    };
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.init();
      }
    },
  },
  computed: {
    gender() {
      return GENDERS;
    },
    title() {
      return this.item.first_name ? "Update Contact" : "Add Contact";
    },
    contactTypes() {
      return this.masterContactTypes.filter((item) => {
        return item.contact_type == RECIEPIENT_MASTER_TYPE;
      });
    },
    master() {
      return this.$store.getters.master;
    },
  },
  methods: {
    getAge() {
      var ageDifMs = Date.now() - new Date(this.DOB);
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    reset() {
      this.form = {
        first_name: "",
        age_registered_date: "",
        age: "",
        last_name: "",
        gender: "",
        email_id: "",
        contact_id: "",
        country_code_1: "91",
        phone_number_1: "",
        country_code_2: "91",
        phone_number_2: "",
        blood_id: "",
        DOB: "",
        status: 1,
        created_by: this.created_by,
        dml_indicator: "I",
      };
      if (this.isUpdate) {
        this.form.recipient_contact_mapping_id = "";
        this.form.recipient_registration_id = "";
        this.form.individual_id = "";
      }
      console.log("contact", this.created_by);
    },
    init() {
      const vm = this;
      vm.reset();
      Object.keys(vm.form).forEach((key) => {
        if (vm.item[key] !== undefined) {
          vm.$set(vm.form, key, vm.item[key]);
        }
      });
    },
    async submit() {
      const vm = this;
      let doc = {};
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      doc.age = vm.getAge();
      doc.age_registered_date = getDateOnly();
      console.log(doc);
      let { email_id, phone_number_1, phone_number_2 } = doc;
      let isEmail = true;
      let isPhone1 = true;
      let isPhone2 = true;
      vm.list.forEach((item, i) => {
        if (i != vm.index) {
          if (email_id && item.email_id == email_id) isEmail = false;
          if (phone_number_1 && item.phone_number_1 == phone_number_1) {
            isPhone1 = false;
          }
          if (phone_number_2 && item.phone_number_2 == phone_number_2) {
            isPhone2 = false;
          }
        }
      });
      if (!isEmail) {
        vm.$alert.show(
          "Another contact already exists with the same <b>Email</b>. Please enter a unique email."
        );
        return;
      }
      if (!isPhone1) {
        vm.$alert.show(
          "Another contact already exists with the same <b>Phone Number 1</b>. Please enter a unique phone number 1."
        );
        return;
      }
      if (!isPhone2) {
        vm.$alert.show(
          "Another contact already exists with the same <b>Phone Number 2</b>. Please enter a unique phone number 2."
        );
        return;
      }
      if (vm.item.first_name) {
        vm.$emit("onUpdate", { doc, index: vm.index });
      } else {
        vm.$emit("onCreate", doc);
      }
      vm.dialog = false;
    },
  },
};
</script>

<style></style>
