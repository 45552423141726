export const HOSPITAL_TYPES = [
  {
    name: "Government Hospital",
    value: 1,
  },
  {
    name: "Private Hospital",
    value: 2,
  },
];
export const USER_TYPES = [
  {
    name: "Hospital",
    value: 1,
  },
];
export const STATUS = {
  1: "Pending Approval",
  2: "Active",
  3: "Expired",
  4: "Deleted",
};
export const DONOR_TYPES = [
  {
    name: "Cadaver",
    value: 1,
  },
  {
    name: "Live",
    value: 2,
  },
  {
    name: "Both",
    value: 3,
  },
];

export const GENDERS = [
  {
    name: "Male",
    value: "1",
  },
  {
    name: "Female",
    value: "2",
  },
];
