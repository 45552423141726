<template>
  <collapse-filter :value="true">
    <b-card class="mt-4">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-input placeholder="Search by UID" v-model="uid" type="number">
          </b-form-input>
        </b-col>
        <b-col cols="12" md="4" class="d-flex mt-3 mt-md-0">
          <b-button @click="$emit('onSeachUid', uid)" variant="primary"
            >Search</b-button
          >
          <b-button class="ms-3" @click="clear()" variant="danger"
            >Clear</b-button
          >
        </b-col>
      </b-row>
    </b-card>
    <h4 class="text-center">Or</h4>
    <b-card class="mt-4">
      <h6 class="mb-2">
        Kindly select Organ, Zone and blood Group to know your zonal rank &
        priority
      </h6>
      <form-wrapper @submit="submit(true)" ref="form">
        <b-row>
          <b-col cols="12" md="4">
            <select-field
              v-model="organ"
              label="Organ"
              rules="required"
              @input="openOrgan()"
              :multiple="true"
              :items="organList"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <autocomplete
              rules="required"
              label="Blood Group"
              :items="filterBloodGroups"
              :multiple="true"
              v-model="blood_group"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4" v-if="isZoneShow">
            <autocomplete
              v-model.trim="zone"
              label="Zone"
              rules="required"
              :multiple="organ == 'Kidney'"
              :items="zones"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4" v-if="organ == 'Liver'">
            <autocomplete
              v-model.trim="hospital_id"
              label="Hospital"
              rules="required"
              :items="filterredHospitals"
            ></autocomplete>
          </b-col>
          <b-col cols="12">
            <div class="d-flex">
              <b-btn
                class="width-100 me-3"
                type="submit"
                variant="primary"
                :class="isMobile ? 'flex-grow-1' : ''"
                >SUBMIT</b-btn
              >
              <b-btn
                class="width-100"
                :class="isMobile ? 'flex-grow-1' : ''"
                @click="reset()"
                variant="outline-secondary"
                >RESET</b-btn
              >
            </div>
          </b-col>
        </b-row>
      </form-wrapper>
    </b-card>
  </collapse-filter>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../assets/constants/recipient.constants";
import {
  BLOOD_GROUP_VALUES,
  HEART_ID,
  KIDNEY_ID,
  LEFT_LUNG_ID,
  LIVER_ID,
  LUNG_ID,
  RIGHT_LUNG_ID,
} from "../../assets/js/common";
import hospitalService from "../../assets/services/hospital.service";
import userService from "../../assets/services/user.service";
import Autocomplete from "../form/Autocomplete.vue";
import SelectField from "../form/SelectField.vue";
import CollapseFilter from "../ui/CollapseFilter.vue";
export default {
  components: {
    Autocomplete,
    SelectField,
    CollapseFilter,
  },
  props: {
    value: {},
  },
  data() {
    return {
      form: {},
      organ: "",
      hospital_id: "",
      organList: [
        {
          value: "Kidney",
          name: "Kidney",
        },
        {
          value: "Heart & lungs",
          name: "Heart & lungs",
        },
        {
          value: "Liver",
          name: "Liver",
        },
      ],
      uid: "",
      zone: [],
      zones: [],
      hospitals: [],
      blood_group: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isZoneShow() {
      return ["Kidney", "Liver"].indexOf(this.zone) == -1;
    },
    filterredHospitals() {
      console.log("zone", this.zone);
      console.log(this.hospitals.filter((i) => i.zone_id == this.zone));
      return [...this.hospitals].filter((i) => i.zone_id == this.zone);
    },
  },
  watch: {
    organ() {
      if (this.organ != "Liver") {
        this.zone = [];
      }
    },
  },
  methods: {
    openOrgan() {
      const vm = this;
      // if (vm.organ == "Liver") {
      //   vm.$alert.show(
      //     "Contact the treating hospital as liver waitlist is based on ROTA & MELD scoring (Inhouse priority)."
      //   );
      //   setTimeout(() => {
      //     vm.organ = "";
      //   }, 100);
      //   return;
      // }
      vm.$emit("onOrganChange", vm.organ);
    },
    submit(isSubmit) {
      const vm = this;
      const doc = {
        dml_indicator: "S",
      };
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.blood_group = [];
      vm.blood_group.forEach((id) => {
        BLOOD_GROUP_VALUES[id].forEach((blood_group) => {
          doc.blood_group.push({ blood_group });
        });
      });
      if (vm.organ == "Heart & lungs") {
        doc.organ = [HEART_ID, LUNG_ID, LEFT_LUNG_ID, RIGHT_LUNG_ID].map(
          (organ_id) => {
            return { organ_id };
          }
        );
      } else {
        doc.organ = [KIDNEY_ID].map((organ_id) => {
          return { organ_id };
        });
      }
      if (vm.organ == "Kidney") {
        doc.zone_list = vm.zone.map((zone) => {
          return { zone };
        });
      } else {
        doc.zone_list = [];
      }
      let liverDoc = {};
      if (vm.organ == "Liver") {
        liverDoc.organ = [{ organ_id: LIVER_ID }];
        liverDoc.inhouse_rank = 2;
        liverDoc.dml_indicator = "SW";
        liverDoc.blood_group = doc.blood_group;
        liverDoc.hospital_id = vm.hospital_id;
        vm.$emit("input", liverDoc);
      } else {
        vm.$emit("input", doc);
      }
      if (isSubmit) {
        vm.$emit("fetch");
      }
    },
    reset() {
      this.form = {
        hospital_id: "0",
        hospital_type: "",
        patient_nationlity: "",
        age: "",
        hospital_status: "",
      };
      this.zone = [];
      this.blood_group = [];
      this.organs = [];
      this.submit(false);
    },
    clear() {
      this.uid = "";
      this.$emit("reset");
    },
    async fetchMasterZone() {
      const vm = this;
      try {
        let { data } = await userService.fetchMasterZone();
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        list = list.filter((item) => {
          return item.status != 4;
        });
        vm.zones = list.map((item) => {
          return {
            name: item.zone_name,
            value: item.zone_id,
          };
        });
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },
    async fetchHospitals() {
      const vm = this;
      try {
        vm.hospitals = [];
        let { data } = await hospitalService.commonList();
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.hospitals = list.Table.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.reset();
    this.fetchMasterZone();
    this.fetchHospitals();
  },
};
</script>

<style></style>
