<template>
  <div class="pa-10">
    <Header></Header>
    <Status :status="status"></Status>
    <List v-model="status"></List>
  </div>
</template>

<script>
import Header from "../../components/transplant/Header.vue";
import List from "../../components/transplant/List.vue";
import Status from "../../components/Status.vue";
export default {
  components: {
    Header,
    Status,
    List,
  },
  data() {
    return {
      status: {},
    };
  },
};
</script>

<style></style>
