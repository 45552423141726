import { initializeApp } from "firebase/app";
import { getDatabase, ref } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
let firebaseConfig = {
  apiKey: "AIzaSyCQpj7rWebBlLIi7sTd_eL_WRH5OkcjpUo",
  authDomain: "vidiyal-3df62.firebaseapp.com",
  projectId: "vidiyal-3df62",
  databaseURL: "https://vidiyal-test.firebaseio.com",
  storageBucket: "vidiyal-3df62.appspot.com",
  messagingSenderId: "375464946912",
  appId: "1:375464946912:web:73220840ac3eb68f70f485",
  measurementId: "G-G7VXDZ8VND",
};
if (location.host == "registry.transtan.tn.gov.in") {
  firebaseConfig.databaseURL = "https://vidiyal-3df62-default-rtdb.firebaseio.com"
}
// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);
const PUBLIC_KEY =
  "BH5F7MRWzOWpHUiP0Q6cPnJmPW2veYd3e93R0FGMLIaKFXMzMj3fqn4CG_1Sks3vPk4aOyrlyO4vYYVIjy1tDLg";
const FUNCTIONS = getFunctions(app);
// connectFunctionsEmulator(FUNCTIONS, "localhost", "5001");
const DB = getDatabase(app);
const CHAT_ROOMS = ref(DB, "chat_rooms");
const AUTH = getAuth();
export {
  PUBLIC_KEY, DB, CHAT_ROOMS, AUTH, FUNCTIONS
};
