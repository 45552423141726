<template>
  <table-view
    :title="title"
    :fields="fields"
    :isBusy="isLoading"
    ref="table"
    :total="total"
    :isMobileView="isMobile"
    :items="formattedList"
    @export="$emit('export')"
    @onPageChange="$emit('onPageChange', $event)"
    :isDynamic="true"
  >
    <template v-slot:recipient_registration_id="{ item, value }">
      <internal-recipient-icon
        :item="item"
        :value="value"
      ></internal-recipient-icon>
    </template>
    <template v-slot:transtan_status="{ value }">
      <div :class="getBg(value)">
        {{ value }}
      </div>
    </template>
    <template v-slot:age="{ value }">
      <div :class="value > 60 ? 'badge bg-danger  font-size-13' : ''">
        {{ value }}
      </div>
    </template>
    <template v-slot:action="{ item }">
      <div class="d-flex">
        <view-button @click="openView(item)"></view-button>
      </div>
    </template>
    <template v-slot:mobile_list="rows">
      <div class="border-bottom"></div>
      <waiting-list-mobile-item
        v-for="(item, i) of rows"
        :key="i"
        :item="item"
        :isZonalRank="true"
        @click="openView(item)"
      />
    </template>
  </table-view>
</template>

<script>
import { encode, formatDate } from "../../../assets/js/common";
import TableView from "../../table/TableView.vue";
import recipientService from "../../../assets/services/recipient.service";
import { PATIENT_STATUS } from "../../../assets/constants/recipient.constants";
import { HEART_LUNGS } from "../../../assets/constants/waiting.list.constants";
import ViewButton from "../../buttons/ViewButton.vue";
import InternalRecipientIcon from "../../managerecipient/InternalRecipientIcon.vue";
import WaitingListMobileItem from "../WaitingListMobileItem.vue";
export default {
  components: {
    TableView,
    ViewButton,
    InternalRecipientIcon,
    WaitingListMobileItem,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    total: {},
    title: {
      type: String,
      default: "Waiting List",
    },
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
      status: null,
      heartLungs: null,
      heartLungsList: HEART_LUNGS,
    };
  },
  watch: {
    title() {
      if (this.isHeartLungs) {
        this.status = "3";
      }
    },
  },
  computed: {
    formattedList() {
      const vm = this;
      return [...vm.list].map((item) => {
        let doc = { ...item };
        doc.CM_insurance = vm.getCMInsuranceStatus(doc.CM_insurance);
        doc.gender = vm.getGender(doc.gender);
        doc.hospital_status = vm.getBeReadyStatus(doc.hospital_status);
        doc.payment_status = vm.getPaymentStatus(doc.payment_status);
        doc.transtan_status = vm.getPatientStatus(doc.transtan_status);
        doc.date = formatDate(doc.created_on);
        doc.bg = vm.getBg(doc.transtan_status);
        return doc;
      });
    },
    isLiver() {
      return this.title.endsWith("Liver");
    },
    isHeartLungs() {
      return this.title.endsWith("Heart & lungs");
    },
    statusList() {
      let allowed = ["3", "10"];
      return PATIENT_STATUS.filter((item) => {
        return allowed.indexOf(item.value) != -1;
      });
    },
    fields() {
      let doc = {
        sno: "S.No",
        recipient_registration_id: "UID",
        zone_rank: "Zonal Rank",
        first_name: "Name",
        aadhar_number: "Aadhar",
        transtan_status: "Patient Status",
        group_desc: "Blood Group",
        age: "Age",
        gender: "Gender",
        date: "Date of Registration",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        payment_status: "Payment Status",
        CM_insurance: "CM Insurance",
        phone_number_1: "Phone Number",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
        if (key == "payment_status" && this.isLiver) {
          list.push({
            key: "MELD_score",
            label: "MELD Score",
          });
        }
      });
      return list;
    },
  },
  methods: {
    openView({ recipient_registrationid }) {
      let id = encode(recipient_registrationid);
      let route = this.$router.resolve(
        `/update-recipient?id=${id}&isView=true`
      );
      window.open(route.href, "_blank");
    },

    getBg(val) {
      switch (val) {
        case "Active":
          return "badge bg-success font-size-13";
        case "Pending Approval":
          return "badge bg-warning font-size-13";
        case "Inactive":
          return "badge bg-danger font-size-13";
        case "Deleted":
          return "badge bg-danger font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
    isDelete(item) {
      return item.transtan_status == 4 || item.transtan_status == 1;
    },
    isApproved(item) {
      return item.transtan_status == 4;
    },
    open({ recipient_registration_id }) {
      let id = encode(recipient_registration_id);
      this.$router.push(`/update-recipient?id=${id}`);
    },
    remove({ recipient_registration_id, transtan_status }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id,
            transtan_status: transtan_status == 1 ? 7 : 5,
            dml_indicator: "A",
          });
        },
      });
    },
    approve({ recipient_registration_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to approve this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id,
            transtan_status: 3,
            dml_indicator: "A",
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await recipientService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        // vm.fetch();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
