<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="(item, i) in menuItems">
        <li v-if="!item.isTitle && !item.isLayout && isAllowed(item)" :key="i">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{
              'has-arrow': !item.badge,
              'has-dropdown': item.badge,
            }"
          >
            <i :class="`mdi ${item.icon}`" v-if="item.icon"></i>
            <span>{{ item.label }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge"
              >{{ item.badge.text }}</span
            >
          </a>

          <router-link
            :to="item.link"
            v-if="!hasItems(item) && isAllowed(item)"
            class="side-nav-link-ref"
          >
            <i :class="`mdi ${item.icon}`" v-if="item.icon"></i>
            <span>{{ formatLabel(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge"
              >{{ item.badge.text }}</span
            >
          </router-link>

          <ul :id="`nav-${item.id}-ul`" v-if="hasItems(item)" class="sub-menu">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                :to="subitem.link"
                v-if="!hasItems(subitem) && isAllowed(subitem)"
                class="side-nav-link-ref"
                :class="{ active: isActiveSubMenu(subitem) }"
                >{{ formatLabel(subitem.label) }}</router-link
              >
              <a
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                >{{ subitem.label }}</a
              >
              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse">
                <template v-for="(subSubitem, index) of subitem.subItems">
                  <li :key="index" v-if="isAllowed(subSubitem)">
                    <router-link
                      :to="subSubitem.link"
                      class="side-nav-link-ref"
                      >{{ subSubitem.label }}</router-link
                    >
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { menuItems } from "./menu";
import commonMixins from "../../assets/mixins/common.mixins";
import menuConstants from "../../assets/constants/menu.constants";
import { isMobile } from "../../assets/js/common";
export default {
  data() {
    return {
      menuItems: menuItems,
      menuData: null,
      menu: null,
    };
  },
  mixins: [commonMixins],
  mounted: function () {
    const vm = this;
    vm.menu = new MetisMenu("#side-menu");
    vm.$nextTick(() => {
      if (!isMobile()) vm.showMenu();
    });
  },
  methods: {
    showMenu() {
      const vm = this;
      let $ = window.$;
      vm.menuItems.forEach((item) => {
        if (vm.isActiveMenu(item)) {
          let ul = $(`#nav-${item.id}-ul`)[0];
          if (ul) vm.menu.show(ul);
        }
      });
    },
    isAllowed({ id }) {
      let { hospital_id } = this.user;
      if (this.isHospitalContact && !hospital_id) {
        let { HOSPITAL_MANAGEMENT, MANAGE_HOSPITAL } = menuConstants;
        if (id != HOSPITAL_MANAGEMENT && id != MANAGE_HOSPITAL) return false;
      }
      let { userRights } = this.$store.getters;
      let item = userRights.find((item) => {
        return item.module_id == id;
      });
      let { ALF_approver } = this.user;
      if (ALF_approver == 1 && id == menuConstants.ALF_APPROVER) return true;
      if (item == null) return false;
      return item.permit == 1;
    },
    formatLabel(label) {
      if (label == "Manage Hospitals" && this.isHospitalContact)
        return "Manage Hospital";
      return label;
    },
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    },
    isActiveMenu(item) {
      const vm = this;
      let { path } = vm.$route;
      if (item.subItems instanceof Array) {
        let sub = item.subItems.find((doc) => {
          return doc.link == path;
        });
        return sub != null;
      }
      return item.link == path;
    },
    isActiveSubMenu(item) {
      const vm = this;
      let { path } = vm.$route;
      return item.link == path;
    },
  },
};
</script>
