<template>
  <expandable header="Evaluation Specific to Heart" class="mb-10">
    <b-row>
      <!-- <b-col cols="12" md="4">
        <input-field
          label="nyh A Grade"
          v-model="form.NYHA_grade"
          rules="required|max-255"
        ></input-field>
      </b-col> -->
      <b-col cols="12" md="4">
        <input-field
          label="Cardiac Index"
          v-model="form.cardiac_index"
          :disabled="isDisabled"
          @input="onInput"
          rules="required|max-255"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="TPG Trans pulmonary gradient"
          v-model="form.TPG"
          :disabled="isDisabled"
          @input="onInput"
          rules="max-255"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help">NA</b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="PVRI"
          v-model="form.PVRI"
          :disabled="isDisabled"
          @input="onInput"
          type="number"
          rules="num-3|dec-2"
        >
          <template v-slot:help>
            <select class="form-select" v-model="form.PVRI_unit">
              <option value="1">WU(Woods Unit)</option>
              <option value="2">Dynes.Sec.cm5</option>
            </select>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="6 Minute Walk Test - Able to Complete"
          type="number"
          v-model="form.walk_test_duration"
          :disabled="isDisabled"
          @input="onInput"
          rules="required|max-255"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> Minutes</b-button>
          </template>
        </yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="form.walk_test_duration != 1">
        <input-group
          label="6 minute Walk Test Distance"
          v-model="form.walk_test_distance"
          :disabled="isDisabled"
          @input="onInput"
          type="number"
          rules="required"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> Meters</b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="NT pro BNP"
          v-model="form.NT_pro_BNP"
          rules="percentage"
          @input="onInput"
          :disabled="isDisabled"
          type="number"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> %</b-button>
          </template>
        </input-group>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <input-field
          label="PRA Status"
          v-model="form.PRA_status"
          rules="required|max-255"
        ></input-field>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <yes-no
          @input="onInput"
          label="History of Previous Non-Transplant Heart & Lung Surgery"
          v-model="form.heart_non_transplant_heart"
          :disabled="isDisabled"
          rules="required"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="form.heart_non_transplant_heart == 1">
        <text-area
          label="Surgery details"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.heart_lung_surgery"
          rules="required|max-255"
        ></text-area>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import InputField from "../../form/InputField.vue";
import InputGroup from "../../form/InputGroup.vue";
import TextArea from '../../form/TextArea.vue';
import YesNo from "../../form/YesNo.vue";

export default {
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { Expandable, InputField, InputGroup, YesNo, TextArea },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    if (this.value != undefined) this.form = this.value;
  },
};
</script>

<style></style>
