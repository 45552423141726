<template>
  <b-button
    variant="primary"
    depressed
    @click="$emit('click')"
    tile
  >
    <b-icon icon="printer" aria-hidden="true"></b-icon>
    <slot> Print </slot>
  </b-button>
</template>

<script>
export default {
  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style></style>
