<template>
  <Expandable header="Written Consent Attachments" class="mb-10">
    <div v-if="loading">
      <table class="table align-middle">
        <thead class="table-light">
          <tr>
            <th class="align-middle">Name</th>
            <th class="align-middle">File</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in files" :key="i">
            <td v-html="formattedLabel(item.name, getRules(item.rules))"></td>
            <td>
              <print-file-input
                v-model="item.url"
                accept=".jpg,capture=camera,.jpeg,.png,.pdf"
              ></print-file-input>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Expandable>
</template>

<script>
import { CONSENT_MASTER_TYPE, openFile } from "../../../assets/js/common";
import { FIELD } from "../../../assets/js/rules";
import hospitalCreateMixins from "../../../assets/mixins/hospital.create.mixins";
import donorService from "../../../assets/services/donor.service";
import Expandable from "../../Expandable.vue";
import PrintFileInput from "../../ui/print/PrintFileInput.vue";
export default {
  props: {
    value: Array,
    filesList: Array,
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  components: { Expandable, PrintFileInput },
  mixins: [hospitalCreateMixins],
  data() {
    return {
      rules: FIELD,
      files: [],
      loading: true,
    };
  },
  computed: {
    master() {
      return this.$store.getters.master;
    },
    uploads() {
      if (this.master.master_fileupload instanceof Array == false) return [];
      return this.master.master_fileupload.filter((item) => {
        return item.file_upload_type_id == CONSENT_MASTER_TYPE;
      });
    },
  },
  watch: {
    uploads() {
      this.init();
    },
  },
  methods: {
    getRules(rules) {
      return rules;
    },
    formattedLabel(label, rules) {
      if (!rules) return label;
      if (rules.indexOf("required") != -1)
        return label + " <span style='color:red'>*</span>";
      return label;
    },
    remove({ donor_investigations_fileupload_mapping_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this attachment?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let { data } = await donorService.removeFile(
              donor_investigations_fileupload_mapping_id
            );
            let { status, msg } = data;
            if (status == false) {
              vm.$alert.show(msg);
            } else {
              vm.$emit(
                "onFileRemoved",
                donor_investigations_fileupload_mapping_id
              );
            }
            vm.$loader.hide();
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    open({ url }) {
      openFile(url);
    },
    init() {
      const vm = this;
      vm.files = vm.uploads.map((item) => {
        let { file_upload_name, file_upload_id, is_required } = item;
        let rules =
          is_required == 1
            ? "required|file-image-pdf|file-size"
            : "file-image-pdf|file-size";
        let file = vm.filesList.find((item) => {
          return item.file_upload_id == file_upload_id;
        });
        let url = null;
        let donor_investigations_fileupload_mapping_id = "";
        if (file instanceof Object) {
          url = file.file_name;
          donor_investigations_fileupload_mapping_id =
            file.donor_investigations_fileupload_mapping_id;
        }
        return {
          name: file_upload_name,
          file_upload_id,
          file: null,
          url,
          donor_investigations_fileupload_mapping_id,
          rules,
        };
      });
    },
    input() {
      const vm = this;
      this.$emit("input", vm.files);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
