<template>
  <div>
    <add-button @click="dialog = true"></add-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Search Recipient"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <input-field
              v-model="id"
              label="UID"
              type="number"
              rules="required"
            ></input-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { encode } from "../../assets/js/common";
import alfService from "../../assets/services/alf.service";
import AddButton from "../buttons/AddButton.vue";
import InputField from "../form/InputField.vue";
export default {
  props: {
    hospitals: Array,
  },
  components: { AddButton, InputField },
  data() {
    return {
      dialog: false,
      id: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await alfService.get(vm.id);
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          vm.$loader.hide();
          return;
        }
        let { recipient_registrationid } = list[0];
        let id = encode(recipient_registrationid);
        vm.$router.push(`/create-alf?id=${id}`);
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
};
</script>

<style></style>
