<template>
  <div>
    <b-dropdown-item-button @click="dialog = true">
      Organ List</b-dropdown-item-button
    >
    <b-modal
      centered
      v-model="dialog"
      title="Organ License List"
      hide-footer
      size="lg"
    >
      <b-table
        outlined
        :fields="headers"
        responsive="sm"
        :busy="loading"
        :items="list"
        :items-per-page="5"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">Loading...</strong>
          </div>
        </template>
        <template #cell(action)="{ item }">
          <edit-button
            v-if="isEdit(item) && !isView(item)"
            @click="open(item)"
          ></edit-button>
          <view-button v-else @click="open(item)"></view-button>
        </template>
        <template #cell(status)="{ item }">
          {{ getStatus(item) }}
        </template>
      </b-table>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-button variant="outline-danger" outlined @click="dialog = false">
          Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { DONOR_TYPES } from "../../../assets/constants/hospital.contants";
import menuConstants from "../../../assets/constants/menu.constants";
import { encode, formatTable, getStatus } from "../../../assets/js/common";
import hospitalService from "../../../assets/services/hospital.service";
import EditButton from "../../buttons/EditButton.vue";
import ViewButton from "../../buttons/ViewButton.vue";
export default {
  components: { EditButton, ViewButton },
  props: {
    hospital: Object,
  },
  data() {
    return {
      list: [],
      dialog: false,
      isLoaded: false,
      loading: true,
    };
  },
  computed: {
    master() {
      return this.$store.getters.master;
    },
    organs() {
      if (this.master.master_organ instanceof Array == false) return [];
      return this.master.master_organ.map((item) => {
        return {
          value: item.organ_id,
          name: item.organ_name,
          license_available: item.license_available,
        };
      });
    },
    headers() {
      let list = [];
      let headers = {
        sno: "S No",
        organ_name: "Organ Type",
        certificate_number: "Certificate No",
        status: "Status",
        action: "Action",
      };
      Object.keys(headers).forEach((key) => {
        let label = headers[key];
        let doc = {
          label,
          key,
        };
        list.push(doc);
      });
      return list;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetch();
      }
    },
  },
  methods: {
    isEdit({ organ_id }) {
      let item = this.organs.find((item) => {
        return item.value == organ_id;
      });
      return item != null && item.license_available == 1 ? true : false;
    },
    getStatus({ status }) {
      return getStatus(status);
    },
    getLicense({ license_type }) {
      let item = DONOR_TYPES.find((item) => {
        return item.value == license_type;
      });
      return item != null ? item.name : "";
    },
    isView({ status }) {
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_HOSPITAL_LICENCE);
      return !hasAccess || status == 4;
    },
    open({ organ_license_id }) {
      organ_license_id = encode(organ_license_id);
      this.$router.push(`/update-transplant-license?id=${organ_license_id}`);
    },
    async fetch() {
      const vm = this;
      try {
        if (vm.isLoaded) return;
        vm.loading = true;

        let id = vm.hospital.hospital_id;
        let { data } = await hospitalService.getHospitalOrgans(id);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.isLoaded = true;
        vm.loading = false;
        vm.list = list.map((item, i) => {
          item.sno = i + 1;
          item.license_type = vm.getLicense(item);
          item.start_date = formatTable(item.start_date);
          item.end_date = formatTable(item.end_date);
          return item;
        });
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
