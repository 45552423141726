<template>
  <div>
    <b-button variant="primary" class="mb-4" depressed @click="dialog = true">
      Create Multi Organ Allocation
    </b-button>
    <b-modal
      v-model="dialog"
      width="600"
      title="Create Multi Organ Allocation"
      hide-footer
      centered
      size="sm"
      no-close-on-backdrop
    >
      <form-wrapper @submit="submit()">
        <select-field
          label="Select Organ"
          rules="required"
          v-model="organ_id"
          :items="items"
          clearable
          @input="onOrganChange"
        ></select-field>
        <select-field
          label="Allocation Type"
          rules="required"
          v-model="type"
          :items="types"
        ></select-field>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { TYPE_OF_ALLOCATION } from "../../../assets/constants/donor.constants";
import {
  KIDNEY_ID,
  KIDNEY_LEFT_ID,
  KIDNEY_RIGHT_ID,
  LEFT_LUNG_ID,
  LIVER_ID,
  LUNG_ID,
  RIGHT_LUNG_ID,
  SPLIT_LIVER_1_ID,
  SPLIT_LIVER_2_ID,
} from "../../../assets/js/common";
import event from "../../../assets/js/event";
import donorService from "../../../assets/services/donor.service";
import FormWrapper from "../../form/FormWrapper.vue";
import SelectField from "../../form/SelectField.vue";
export default {
  components: { SelectField, FormWrapper },
  props: {
    organs: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      type: "",
      organ_id: "",
    };
  },
  computed: {
    items() {
      let organs = [...this.masterOrgans].filter((item) => {
        return item.multi_organ_flag == 1;
      });
      let list = organs.filter((item) => {
        let isExists = [];
        console.log(item.allocated_organ);
        let allocated = item.allocated_organ.split(",");
        allocated.forEach((id) => {
          let organ = this.organs.find((item) => {
            let kidneys = [KIDNEY_LEFT_ID, KIDNEY_RIGHT_ID];
            if (kidneys.indexOf(item.organ_id.toString()) != -1) {
              return id == KIDNEY_ID;
            }
            let livers = [SPLIT_LIVER_1_ID, SPLIT_LIVER_2_ID];
            if (livers.indexOf(item.organ_id.toString()) != -1) {
              return id == LIVER_ID;
            }
            return item.organ_id == id;
          });
          if (organ != null) isExists.push(id);
        });
        return isExists.length == allocated.length;
      });
      return list;
    },
    types() {
      if (!this.organ_id) return [];
      let organ = this.masterOrgans.find((item) => {
        return this.organ_id == item.organ_id;
      });
      if (organ == null) return [];
      let organs = organ.allocated_organ.split(",").map((i) => Number(i));
      return this.allocationTypes(organs);
    },
  },
  methods: {
    onOrganChange() {
      this.type = "";
      if (this.organ_id && this.types.length == 0) {
        this.$alert.show("Please check the hospital organ license");
      }
    },
    allocationTypes(organs) {
      let list = [];
      TYPE_OF_ALLOCATION.filter((item) => {
        item.text = item.name;
        let licenses = organs.filter((id) => {
          let organ = this.list.find((o) => {
            let kidneys = [KIDNEY_LEFT_ID, KIDNEY_RIGHT_ID];
            if (kidneys.indexOf(id.toString()) != -1) {
              return o.organ_id == KIDNEY_ID;
            }
            let livers = [SPLIT_LIVER_1_ID, SPLIT_LIVER_2_ID];
            if (livers.indexOf(id.toString()) != -1) {
              return o.organ_id == LIVER_ID;
            }
            let lungs = [LEFT_LUNG_ID, RIGHT_LUNG_ID];
            if (lungs.indexOf(id.toString()) != -1) {
              return o.organ_id == LUNG_ID;
            }
            return id == o.organ_id;
          });
          if (organ == null) return false;
          let types = this.$parent.allocationTypes(organ).filter((t) => {
            return t.value == item.value;
          });
          return types.length != 0;
        });
        if (organs.length == licenses.length) {
          list.push(item);
        }
      });
      return list;
    },

    async submit() {
      const vm = this;
      let { final_donor_id, id } = vm.$parent;
      let organ_allocation_list = [];
      let { hospital_id, case_officer } = this.$parent.donor;
      let doc = {
        final_donor_id,
        organ_allocation_id: "",
        donor_temp_registration_id: id,
        case_officer,
        hospital_id,
        organ_id: vm.organ_id,
        backup_flow: 0,
        status: 1,
        type_of_allocation: vm.type,
        created_by: vm.created_by,
        dml_indicator: "I",
      };
      organ_allocation_list.push(doc);
      try {
        vm.$loader.show();
        let { data } = await donorService.organAllocation({
          organ_allocation_list,
        });
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        event.$emit("refresh");
        vm.$loader.hide();
        vm.dialog = false;
        vm.type = "";
        vm.organ_id = "";
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
        event.$emit("refresh");
      }
    },
  },
};
</script>

<style></style>
